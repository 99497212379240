import React from 'react'
import { FaRoute } from 'react-icons/fa'
import { Box, Typography } from '@material-ui/core'

import EmptyBucket from 'components/EmptyBucket'
import PaddedFlexCentered from 'components/PaddedFlexCentered'

export const Empty = () => (
  <PaddedFlexCentered>
    <EmptyBucket>
      <FaRoute role="img" />
    </EmptyBucket>
    <Box p={2}>
      <Typography align="center">
        There are no routes currently configured.
      </Typography>
      <Box mt={4}>
        <Typography align="center">
          To create and manage routes, visit <a href="https://datasight.testoil.com">datasight.testoil.com</a> from a desktop browser.
        </Typography>
      </Box>
    </Box>
  </PaddedFlexCentered>
)

export default Empty

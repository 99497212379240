import Intercept from "contexts/api/Intercept";
import InterceptedResponse from "contexts/api/InterceptedResponse";
import Debug from "debug";

import { LocalSample } from "api_supplimental";
import { Transaction } from "idb/Transactions";

import { updateAssetSampleHistory } from "../updateAssetSampleHistory";

const log = Debug("AL:intercept:deleteSample");

export const deleteSample = new Intercept<LocalSample>({
  method: "DELETE",
  pathname: "v1/samples/:sampleIdentifier",
  fetch: async (bag) => {
    const {
      match,
      entitiesDb,
      transactionsContext: { instance: tx },
      fetcher,
      networkState,
      req,
      opts,
    } = bag;
    const sampleIdentifier =
      match && match.params && match.params.sampleIdentifier;
    if (!tx) {
      throw new Error("Transactions database context is required!");
    }
    if (!sampleIdentifier) {
      return new InterceptedResponse({ status: 404 });
    }
    const sample = await entitiesDb.Samples.get({
      identifier: sampleIdentifier,
    });

    if (sample) {
      log("Deleting sample", sample);
      await entitiesDb.Samples.delete([
        sample.labId ?? 0,
        sample.identifier ?? "",
      ] as unknown as string);
    } else {
      log(`No sample found with identifier "${sampleIdentifier}"`);
    }

    // Update the associated asset and routes
    // Note: This needs to be done in `fetch` because we don't have a path back
    //       to the asset after it's deleted.
    // FIX ME: If the network op fails for any reason, the app and the probably
    //    the user's phone will combust. We should do this reactively at the Entity
    //    db level.
    const assetId = sample && sample.assetId;
    if (assetId) {
      const asset = await entitiesDb.Assets.get(assetId.toString());
      if (!asset) {
        throw new Error("Sample assetId could not be traced to an asset");
      }
      log(`Updating asset sample history for asset #${asset.id}`);
      await updateAssetSampleHistory(entitiesDb, assetId.toString());
      log("Updated sample asset");
    }

    if (networkState.online) {
      return fetcher(req, opts);
    }
    if (tx) {
      await tx.Queue.put(new Transaction(req, opts));
    }
    return new InterceptedResponse({ status: 202 });
  },
});

import React from 'react'
import { MdCloudUpload } from 'react-icons/md'

import JQUIListItemLinkWithDelete, { IListItemLinkWithDeleteProps } from 'components/JQUIList/JQUIListItemLinkWithDelete'
import Thumbnail from 'components/Thumbnail'

const styles = require('./AssetMediaListItem.css')

export interface Props extends Omit<IListItemLinkWithDeleteProps, 'onDelete'> {
  onDelete: (file: File) => void
  file: File
}

export const AssetMediaListItemPending = (props: Props) => {
  const {
    file,
    onDelete,
    children,
    ...passedProps
  } = props

  const handleDelete = () => {
    onDelete(file)
  }

  return (
    <JQUIListItemLinkWithDelete
      linkProps={{ style: { padding: 0 } }}
      onDelete={handleDelete}
      {...passedProps}
    >
      <div className={styles.root}>
        <Thumbnail file={file} className={styles.thumbnail} />
        <div className={styles.content}>
          <span>
            {children}
          </span>
          <div className={styles.pending} title="File will upload when this asset is saved.">
            <MdCloudUpload /><small>&nbsp;Pending Upload</small>
          </div>
        </div>
      </div>
    </JQUIListItemLinkWithDelete>
  )
}

export default AssetMediaListItemPending

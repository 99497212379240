import React, { FC } from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import SiteHeadMetadata from 'components/SiteHeadMetadata'

import SampleReadOnly from './SampleReadOnly'
import SampleRouteWrapper from './SampleRouteWrapper'

export const SampleRouter: FC<RouteComponentProps> = () => {
  return (
    <>
      <SiteHeadMetadata title="Sample" />
      <Router basepath="/app/sample">
        <SampleReadOnly path=":bottleId" />
        <SampleRouteWrapper path="/" />
      </Router>
    </>
  )
}

export default SampleRouter

/**
 * Wrapper around AssetSelect with the following features:
 *  - onChange event is called only after the selected asset's tag link is created.
 */
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'

import { AssetTagLinkDTO } from 'api'
import { KnownAsset } from 'api_supplimental'
import InlineError from 'components/Errors/InlineError'
import Spinner from 'components/Spinner'
import useApi, { IApiState } from 'hooks/useApi'

import AssetSelect from './AssetSelect'

export interface Props {
  tagIdentifier: string
  onChange: (assetId: string) => void
}

function usePutAssetTagLink(tagIdentifier?: string): [IApiState<AssetTagLinkDTO>, (asset: KnownAsset) => void] {
  const [selectedAsset, setSelectedAsset] = useState<KnownAsset>()
  const requestUrl = (tagIdentifier && selectedAsset) ? 'v1/asset-tag-links' : undefined
  const requestOptions = {
    method: 'POST',
    body: selectedAsset && JSON.stringify({
      assetId: selectedAsset.id,
      tagIdentifier: tagIdentifier,
    }),
  }
  const linkFetchState = useApi<AssetTagLinkDTO>(requestUrl, undefined, { requestOptions })
  return [linkFetchState, setSelectedAsset]
}

export const AssetSelectCreateAssetTagLink = (props: Props) => {
  const {
    tagIdentifier,
    onChange,
  } = props
  const [linkFetchState, setSelectedAsset] = usePutAssetTagLink(tagIdentifier)
  const {
    isLoading,
    error,
    data: assetTagLink,
  } = linkFetchState

  useEffect(() => {
    if(error || !assetTagLink || !assetTagLink.assetId) {
      return
    }
    onChange(assetTagLink.assetId.toString())
  }, [assetTagLink, onChange, error])

  if(isLoading) {
    return <Spinner />
  }
  if(error) {
    Sentry.captureException(error)
    return <InlineError message={error.message} />
  }

  return (
    <AssetSelect
      onChange={(asset: KnownAsset) => setSelectedAsset(asset)}
    />
  )
}

export default AssetSelectCreateAssetTagLink

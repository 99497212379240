import { Condition } from "../types/api_supplimental.d";

export const conditionColorMap = Object.freeze({
   [Condition.Unknown]: "#444",
  [Condition.Normal]: "#8af07d",
  [Condition.AtRisk]: "#ffec12",
  [Condition.Critical]: "#f07d7d",
  [Condition.Marginal]: "#ffec12",
});

export const conditionNameMap = Object.freeze({
  [Condition.Unknown]: "Unknown",
  [Condition.Normal]: "Normal",
  [Condition.AtRisk]: "At Risk",
  [Condition.Critical]: "Critical",
  [Condition.Marginal]: "Marginal",
});

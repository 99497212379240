import { LocalSample, Result } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getAssetSamples = new Intercept<Result<LocalSample>>({
  pathname: 'v1/assets/:assetId/samples',
  fetch: async ({ entitiesDb, match }) => {
    const assetId = match && match.params && match.params.assetId
    if(!assetId) {
      return new InterceptedResponse({ status: 404 })
    }
    const assetIdNum = Number(assetId)
    if(isNaN(assetIdNum)) {
      return new InterceptedResponse({ data: { data: [] }, status: 200 })
    }
    const data = await entitiesDb.Samples.where('assetId').equals(assetIdNum).sortBy('sampledAt')
    return new InterceptedResponse({ data: { data }, status: 200 })
  },
})

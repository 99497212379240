import { useContext } from 'react'
import useAsync from 'react-use/lib/useAsync'
import samplesContext from 'contexts/LocalEntities/Samples/context'

import { LocalSample } from 'api_supplimental'

export type TParams = {
  maxAge?: number
}
export type TReturn = {
  mostRecentSample?: LocalSample
}

export function useMostRecentSample(params: TParams = {}): TReturn {
  const {
    maxAge = 1000 * 60 * 60 * 24,
  } = params

  const { getUsersRecentSamples } = useContext(samplesContext)
  const { value: mostRecentSample } = useAsync(async () => {
    const recentSamples = await getUsersRecentSamples()
    if(recentSamples && recentSamples.length > 0) {
      const mostRecent = recentSamples[0] // already sorted by sampledAt
      if(mostRecent.sampledAt && !isNaN(mostRecent.sampledAt.getTime())) {
        const age = Date.now() - mostRecent.sampledAt.getTime()
        if(age < maxAge) {
          return mostRecent
        }
      }
    }
    return undefined
  }, [maxAge, getUsersRecentSamples])

  return {
    mostRecentSample,
  }
}

export default useMostRecentSample

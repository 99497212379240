import React, { ReactElement } from 'react'
import clsx from 'clsx'

const styles = require('./TitledSection.css')

export interface Props {
  title: string | React.ReactElement
  className?: string
  actionRight?: ReactElement
}

export default ({ title, children, actionRight, className, ...passedProps }: (Props & React.DOMAttributes<HTMLElement>)) => {
  const $label = (typeof title === 'string') ? <h2 className={styles.title}>{title}</h2> : React.cloneElement(title, { className: styles.title })
  const $actionRight = actionRight || null

  return (
    <section className={clsx(styles.root, className)} {...passedProps}>
      <div className={styles.labelContainer}>
        {$label}
        {$actionRight}
      </div>
      {children}
    </section>
  )
}

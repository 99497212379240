import styled from "@emotion/styled";
import React from "react";
type TProps = {
  assetDescriptionSection?: JSX.Element;
  thumbnailSection?: JSX.Element;
  reportSection?: JSX.Element;
  iconSection?: JSX.Element;
  bottleIdentifierSection?: JSX.Element;
};
export const AssetListItemLayout = (props: TProps) => {
  const {
    assetDescriptionSection,
    reportSection,
    thumbnailSection,
    iconSection,
    bottleIdentifierSection,
  } = props;

  return (
    <SpacedSection>
      {thumbnailSection && (
        <ThumbnailContainer>{thumbnailSection}</ThumbnailContainer>
      )}
      <AssetTextSection>{assetDescriptionSection}</AssetTextSection>
      {!!bottleIdentifierSection && (
        <BottleIDSection>{bottleIdentifierSection}</BottleIDSection>
      )}
      <ReportSection>{reportSection}</ReportSection>
      <IconSection>{iconSection}</IconSection>
    </SpacedSection>
  );
};

const SpacedSection = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;
const AssetTextSection = styled.div`
  display: flex;
  align-items: center;
  /* width: 100%; */
`;

const ThumbnailContainer = styled.div`
  height: 100%;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const BottleIDSection = styled.div`
  height: 100%;
  width: 0px;
  display: flex;
  justify-content: center;
`;

const IconSection = styled.div`
  display: flex;
  justify-content: flex-end;

  align-items: center;
`;

const ReportSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* gap: 5px; */
  padding: 8px 0;
`;

// const StatusSection = styled.div`
//   padding: 5px 0;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   overflow: hidden;
//   min-width: 55px;
// `;

// const DownloadSection = styled.div`
//   white-space: nowrap;
//   min-width: 42px;
// `;

const AssetDetailsSection = styled.div`
  display: flex;
  /* gap: 15px; */
  align-items: center;
  height: 100%;
`;

const ItemGroup = styled.div`
  display: flex;
  align-items: center;
`;

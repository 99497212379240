import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'

import { UserDTO } from 'api'
import AppHeader from 'components/AppHeader'
import InlineError from 'components/Errors/InlineError'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import UserDetailModify, { Props as UserDetailModifyProps } from 'components/UserDetail/UserDetailModify'
import useApi, { IApiState } from 'hooks/useApi'

function usePatchUser(user?: UserDTO): IApiState<UserDTO> {
  const body = JSON.stringify(user)
  const url = user ? 'v1/users' : undefined
  return useApi<UserDTO>(url, undefined, {
    requestOptions: {
      method: 'PATCH',
      body,
    },
    dependencies: [body],
  })
}

interface Props extends UserDetailModifyProps {
  user: Partial<UserDTO>
  onCancel: () => void
  onSubmitted: (user: UserDTO) => void
}
export const ModifyUser = (props: Props) => {
  const {
    user,
    onCancel,
    onSubmitted,
    ...passedProps
  } = props
  const [newUserDetails, setNewUserDetails] = useState<UserDTO>()
  const {
    error,
    isLoading,
    fieldErrors,
    data: nextModifiedUser,
  } = usePatchUser(newUserDetails)

  const handleSubmit = (user: UserDTO) => {
    setNewUserDetails(user)
  }

  useEffect(() => {
    if(newUserDetails && !isLoading && !error && nextModifiedUser) {
      onSubmitted(nextModifiedUser)
    }
  }, [newUserDetails, onSubmitted, nextModifiedUser, isLoading, error])

  const body = (() => {
    return (
      <UserDetailModify
        defaultValues={user}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        fieldErrors={fieldErrors}
        {...passedProps}
        renderHeader={() => (
          <>
            <AppHeader
              actionLeft={<Button type="button" onClick={onCancel}>Cancel</Button>}
              actionRight={<Button type="submit">Save</Button>}
              title="Modify My Details"
              fixed
            />
            {error ? (
              <PaddedFlexCentered>
                <InlineError message={`An error occurred while trying to update this user. (${error.message})`} />
              </PaddedFlexCentered>
            ) : null}
          </>
        )}
      />
    )
  })()
  return (
    <div style={{ paddingTop: '50px' }}>
      {body}
    </div>
  )
}

export default ModifyUser

import React, { ChangeEvent, CSSProperties, ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { MdFilterList } from 'react-icons/md'
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import throttle from 'lodash.throttle'

import { PlantDTO } from 'api'
import EmptyBucket from 'components/EmptyBucket'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import SearchInput from 'components/SearchInput'
import { sortByProp } from 'utils/sortByProp'

import Empty from './Empty'

const styles = require('./FilterablePlantsList.css')

export type Props = {
  plants?: PlantDTO[]
  emptyBucket?: ReactNode
  noMatches?: ReactNode
  filterAssistiveText?: ReactNode
  showFilterWhenPlantCountGreaterThan?: number
}

type ListContainerProps = {
  listRef: Ref<any>
  children?: ReactNode
  className?: string
  style?: CSSProperties
}
const ListContainer = ({ listRef, ...passedProps }: ListContainerProps) => (
  <JQUIList
    ref={listRef}
    {...passedProps}
  />
)

export const FilterablePlantsList = (props: Props) => {
  const {
    plants = [],
    emptyBucket = <Empty />,
    noMatches = <EmptyBucket><small>No plants match your filter</small></EmptyBucket>,
    filterAssistiveText,
    showFilterWhenPlantCountGreaterThan = 3,
  } = props
  const [filterInputValue, setFilterInputValue] = useState('')
  const [filter, setFilter] = useState('')

  const throttledSetFilter = useRef(throttle(setFilter, 300, { leading: false, trailing: true }))

  const getFuseInstance = (rawPlants: PlantDTO[] = []) => new Fuse(rawPlants, {
    shouldSort: true,
    threshold: 0.1,
    location: 5,
    tokenize: true,
    distance: 200,
    maxPatternLength: 10,
    minMatchCharLength: 3,
    keys: [
      {
        name: 'name',
        weight: 0.4,
      },
      {
        name: 'customerName',
        weight: 0.4,
      },
      {
        name: 'id',
        weight: 0.1,
      },
      {
        name: 'number',
        weight: 0.05,
      },
      {
        name: 'customerId',
        weight: 0.05,
      },
    ],
  })

  const fuse = useRef(getFuseInstance(plants))

  useEffect(() => {
    fuse.current = getFuseInstance(plants)
  }, [plants])

  const handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterInputValue(e.target.value)
    throttledSetFilter.current(e.currentTarget.value)
  }

  const filteredPlants = filter.length > 0 ? fuse.current.search(filter) : plants
  const sortedPlants = sortByProp(filteredPlants, ['name', 'customerName'])

  const unfilteredCustomers = useMemo<Set<string>>(() => {
    return plants.reduce((acc, plant) => {
      if(plant.customerName) {
        acc.add(plant.customerName)
      }
      return acc
    }, new Set<string>())
  }, [plants])

  const groupData = useMemo<{
    groups: Map<string, PlantDTO[]>
    groupCounts: number[]
    groupNames: string[]
  }>(() => {
    if(!sortedPlants) {
      return {
        groups: new Map<string, PlantDTO[]>(),
        groupCounts: [0],
        groupNames: ['Unknown'],
      }
    }
    const _groups = sortedPlants.reduce((acc, plant) => {
      const { customerName } = plant
      if(customerName) {
        if(!acc.has(customerName)) {
          acc.set(customerName, [plant])
        }
        const existing = acc.get(customerName) || []
        acc.set(customerName, [...existing, plant])
      }
      return acc
    }, new Map<string, PlantDTO[]>())
    return {
      groups: _groups,
      groupCounts: Array.from(_groups.values()).map(members => (members.length - 1)),
      groupNames: Array.from(_groups.keys()),
    }
  }, [sortedPlants])

  const { groupCounts, groupNames } = groupData

  const $filterAssistiveText = filterAssistiveText ? (
    <Box
      fontSize="small"
      color="text.hint"
      textAlign="center"
      py="0.25em"
    >
      {filterAssistiveText}
    </Box>
  ) : null

  return (
    <div className={styles.root}>
      {plants.length > showFilterWhenPlantCountGreaterThan ? (
        <Box py="0.5rem" px="15px" className={clsx(styles.filterContainer, { [styles.sticky]: filter.length > 0 })}>
          <SearchInput
            value={filterInputValue}
            onChange={handleFilterInputChange}
            icon={<MdFilterList />}
            placeholder="Name, id, number, etc"
          />
          {$filterAssistiveText}
        </Box>
      ) : null}
      {(!plants.length || !sortedPlants.length)
        ? plants.length === 0 ? emptyBucket
          : sortedPlants.length === 0 ? noMatches : null
        : null}
      {unfilteredCustomers.size > 1 ? (
        <GroupedVirtuoso
          className={styles.list}
          groupCounts={groupCounts}
          group={index => {
            return (
              <Box p={1} bgcolor="grey.300">
                <Typography variant="caption">
                  {groupNames[index]}
                </Typography>
              </Box>
            )
          }}
          item={index => {
            const plant = sortedPlants[index]
            if(plant) {
              return (
                <Box
                  key={plant.id}
                >
                  <JQUIListItemLink
                    iconRight={<FaAngleRight />}
                    linkProps={{ to: plant.id ? `/app/plants/${plant.id}` : undefined }}
                  >
                    {plant.name}
                  </JQUIListItemLink>
                </Box>
              )
            }
            return (
              <div key={index} />
            )
          }}
        />
      ) : (
        <Virtuoso
          className={styles.list}
          ListContainer={ListContainer}
          overscan={5}
          totalCount={sortedPlants.length}
          item={index => {
            const plant = sortedPlants[index]
            if(plant) {
              return (
                <JQUIListItemLink
                  key={plant.id}
                  iconRight={<FaAngleRight />}
                  linkProps={{ to: plant.id ? plant.id.toString() : undefined }}
                >
                  {plant.name}
                </JQUIListItemLink>
              )
            }
            return (
              <div key={index} />
            )
          }}
        />
      )}

    </div>
  )
}

export default FilterablePlantsList

/**
 * A hook that periodically removes completed transactions older than a specified age.
 *
 * @author Aaron Lampros <alampros@@testoil.com>
 */

import { useCallback, useEffect, useMemo } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import Debug from 'debug'

import Transactions from 'idb/Transactions'

const log = Debug('AL:Transactions:useCleanupTransactions')

type TConfig = {
  /**
   * Age since dateCompleted in milliseconds before a transaction is removed.
   * @default 86400000 (1 day)
   */
  ttl?: number,
  /**
   * Minimum time in milliseconds between cleaning executions.
   * @default 86400000 (1 day)
   */
  interval?: number
}

const configDefaults = {
  ttl: 1000 * 60 * 60 * 24 * 30, // 30 days
  interval: 1000 * 60 * 60, // 1 hour
}

type TReturn = {
  cleanTransactions: () => void
}

export const useCleanupTransactions = (db?: Transactions, config: TConfig = configDefaults): TReturn => {
  const {
    ttl = configDefaults.ttl,
    interval = configDefaults.interval,
  } = config
  const [lastCleanupDateString, setLastCleanupDateString] = useLocalStorage<string>('transactionsLastCleaned', undefined, { raw: true })
  const lastCleanupDate = useMemo(() => {
    if(lastCleanupDateString) {
      return new Date(lastCleanupDateString)
    }
    return undefined
  }, [lastCleanupDateString])

  const cleanTransactions = useCallback(async () => {
    if(db) {
      log('Cleaning old transactions')
      db.transaction('rw', db.Queue, async () => {
        const now = Date.now()
        const toClean = await db.Queue.where('status').equals('complete').and(t => {
          if(!t.dateCompleted) {
            return false
          }
          const age = now - t.dateCompleted.getTime()
          return age > ttl
        }).toArray()
        if(toClean.length > 0) {
          const idsToDelete = toClean.map(t => t.id).filter(id => id !== undefined) as number[]
          await db.Queue.bulkDelete(idsToDelete)
          log(`Successfully cleaned ${toClean.length} transactions.`)
        } else {
          log('No transactions to clean up.')
        }
        setLastCleanupDateString(new Date().toJSON())
      })
    } else {
      console.warn('No transactions database passed to useCleanupTransactions hook')
    }
  }, [db, setLastCleanupDateString, ttl])

  // check if we should run the cleanup routine
  useEffect(() => {
    let mounted = true;
    (async () => {
      if(!mounted) return
      if(lastCleanupDate) {
        if(Date.now() - lastCleanupDate.getTime() > interval) {
          await cleanTransactions()
        }
      }
      if(!lastCleanupDateString) {
        log('Running initial cleanup for session.')
        await cleanTransactions()
      }
    })()
    return () => { mounted = false }
  }, [cleanTransactions, interval, lastCleanupDate, lastCleanupDateString])

  return {
    cleanTransactions: cleanTransactions,
  }
}

export default useCleanupTransactions

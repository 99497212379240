import React, { ReactNode } from 'react'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'
import * as Sentry from '@sentry/browser'

import InlineError from 'components/Errors/InlineError'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import { IApiState } from 'hooks/useApi'

type Props = {
  fetchState: IApiState<any>
  fallback?: ReactNode
  children?: ReactNode
  pad?: boolean
  spinnerProps?: CircularProgressProps
}

export const FetchStateWrapper = (props: Props) => {
  const {
    fetchState,
    fallback = null,
    children,
    pad,
    spinnerProps = {},
  } = props

  const {
    isLoading,
    error,
    data,
  } = fetchState
  if(isLoading) {
    if(pad) {
      return (
        <PaddedFlexCentered>
          <Spinner {...spinnerProps} />
        </PaddedFlexCentered>
      )
    }
    return <Spinner {...spinnerProps} />
  }
  if(error) {
    Sentry.captureException(error)
    if(pad) {
      return (
        <PaddedFlexCentered>
          <InlineError message={error.message} />
        </PaddedFlexCentered>
      )
    }
    return <InlineError message={error.message} />
  }
  if(!data || (Array.isArray(data) && data.length === 0) || (Array.isArray(data.data) && data.data.length === 0)) {
    if(fallback) {
      if(pad) {
        return (
          <PaddedFlexCentered>
            {fallback}
          </PaddedFlexCentered>
        )
      }
      return <>{fallback}</>
    }
    return null
  }
  return <>{children}</>
}

export default FetchStateWrapper

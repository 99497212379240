import React, { FC } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Redirect, RouteComponentProps } from '@reach/router'

import { CustomerDTO } from 'api'
import { Result } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

export const SelectCustomerRoute: FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const {
    isLoading,
    error,
    data,
  } = useApi<Result<CustomerDTO>>('v1/customers/all')
  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }
  if(error) {
    throw error
  }

  const customers = data && data.data

  const body = (() => {
    if(!customers || !customers.length) {
      return (
        <PaddedFlexCentered>
          <p>No customers to select from</p>
        </PaddedFlexCentered>
      )
    }
    if(customers.length === 1) {
      if(props.navigate) {
        props.navigate(`./${customers[0].id}/`, { replace: true })
        return null
      }
      return <Redirect to={`./${customers[0].id}/`} replace />
    }
    return (
      <JQUIList>
        {customers && customers.map(customer => (
          <JQUIListItemLink
            key={customer.id}
            iconRight={<FaAngleRight />}
            linkProps={{ to: customer.id ? `./${customer.id}` : undefined }}
          >
            {customer.name}
          </JQUIListItemLink>
        ))}
      </JQUIList>
    )
  })()
  return (
    <>
      <AppHeader
        title="Select a Customer"
        fixed
      />
      {body}
    </>
  )
}

export default SelectCustomerRoute

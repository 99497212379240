import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Box, Button } from '@material-ui/core'
import { networkStateContext } from 'contexts/NetworkState'
import Debug from 'debug'
import { useSnackbar } from 'notistack'

import usePrevious from 'hooks/usePrevious'
import { Transaction } from 'idb/Transactions'

import transactionsDbContext from './transactionsDbContext'
import TransactionWorker from './TransactionWorker'
import useCleanupTransactions from './useCleanupTransactions'
import useRefreshLocalEntities from './useRefreshLocalEntities'

const log = Debug('AL:Transactions:Manager')

export const TransactionsManager = () => {
  const {
    instance: db,
    pending,
  } = useContext(transactionsDbContext)
  const { online } = useContext(networkStateContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const setTableNamesToRefresh = useRefreshLocalEntities()

  const numberOfRemainingPendingTransactions: number = useMemo(() => pending.filter(t => t.status === 'pending').length, [pending])
  const lastCount = usePrevious(pending.length)

  if(!db) {
    throw new Error('No transactions database instace available for TransactionsManager to work with.')
  }

  useCleanupTransactions(db)

  const activeTransaction = useMemo<Transaction | undefined>(() => {
    const next = pending[0]
    if(next && next.status !== 'error') {
      return next
    }
  }, [pending])

  useEffect(() => {
    if(!activeTransaction && lastCount === 1) {
      log('Complete!')
      setTableNamesToRefresh(['Samples', 'Plants', 'Assets', 'Routes'])
    }
  }, [activeTransaction, lastCount, setTableNamesToRefresh])

  const clearQueue = useCallback(async () => {
    log('Clearing queued transactions')
    await db.Queue.clear()
  }, [db.Queue])

  const clearErrors = useCallback(async () => {
    log('Clearing error items')
    const errors = pending.filter(t => t.status === 'error' && t.id)
    await db.Queue.bulkDelete(errors.map(t => t.id) as number[])
  }, [db.Queue, pending])

  useEffect(() => {
    const errorTransaction = pending.find(t => t.status === 'error')
    if(!activeTransaction && errorTransaction) {
      enqueueSnackbar('An error occurred while processing one of your offline transactions.', {
        key: 'queue-error',
        variant: 'error',
        preventDuplicate: true,
        action: (
          <ErrorAction
            pending={pending.length}
            remaining={numberOfRemainingPendingTransactions}
            onClearAll={clearQueue}
            onClearErrors={clearErrors}
          />
        ),
        autoHideDuration: null,
      })
    } else {
      closeSnackbar('queue-error')
    }
  }, [activeTransaction, clearErrors, clearQueue, closeSnackbar, enqueueSnackbar, pending, numberOfRemainingPendingTransactions])

  /*****************************************************************
   * Render results
   *****************************************************************/

  if(!online) {
    return null
  }
  if(activeTransaction) {
    return (
      <TransactionWorker
        transaction={activeTransaction}
        db={db}
      />
    )
  }
  return null
}

export default TransactionsManager

type ErrorActionProps = {
  pending: number
  remaining: number
  onClearAll: () => void
  onClearErrors: () => void
}
const ErrorAction = (props: ErrorActionProps) => {
  const { closeSnackbar } = useSnackbar()
  const {
    pending,
    remaining,
    onClearAll,
    onClearErrors,
  } = props
  return (
    <Box>
      <Box mb={2}>
        <Button onClick={onClearAll} variant="contained" fullWidth>
          Delete all {pending} pending transactions
        </Button>
      </Box>
      <Box mb={2}>
        <Button onClick={onClearErrors} variant="contained" fullWidth>
          Process remaining {remaining} items
        </Button>
      </Box>
      <Box mb={2}>
        <Button onClick={() => { closeSnackbar('queue-error') }} variant="outlined" fullWidth>
          Dismiss
        </Button>
      </Box>
    </Box>
  )
}

import * as Sentry from '@sentry/browser'
import Intercept from 'contexts/api/Intercept'
import { getMethod } from 'contexts/api/InterceptAPIProvider'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'
import Dexie from 'dexie'
import { navigate } from 'gatsby'

import { LocalContact } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:API:intercept:putContact')

type TExtra = { contactId: string }

export const putContact = new Intercept<LocalContact>({
  method: 'PUT',
  pathname: 'v1/contacts',
  fetch: async (bag) => {
    const {
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    if(!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 })
    }
    if(networkState.online) {
      return fetcher(req, opts)
    }
    const body: LocalContact = JSON.parse(opts.body as string)
    const uuid = Dexie.Observable.createUUID()
    log(`Adding contact with temporary ID: "${uuid}"`)
    body.id = uuid
    const contactKey = await entitiesDb.Contacts.put(body)
    const data = await entitiesDb.Contacts.get(contactKey)
    // Keep the original request intact, but store the temp UUID for use in
    // postParse to replace with the real one
    const transactionId = await tx.Queue.add(new Transaction<TExtra>(req, opts, { contactId: uuid }))
    log(`Transaction ${transactionId} queued`)
    return new InterceptedResponse({ data, status: 201 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
      originalResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(!(originalResponse instanceof InterceptedResponse)) {
      log('Creating local contact', parsedResponse)
      parsedResponse.id = parsedResponse.id.toString()
      await entitiesDb.Contacts.add(parsedResponse)
    }
  },

  postTransaction: async (bag) => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
      transactionsContext: {
        instance: tx,
      },
    } = bag
    const transaction = bag.transaction as Transaction<TExtra>
    log('Post-transaction putContact...')
    const uuid = transaction && transaction.extra && transaction.extra.contactId
    if(!(opts && opts.body)) {
      throw new Error('postTransaction: No request body provided')
    }
    if(!tx) {
      throw new Error('postTransaction: No transactions idb context provided')
    }
    if(!uuid) {
      throw new Error('postTransaction: No UUID for the temporary local contact provided.')
    }

    const newId = parsedResponse.id.toString()

    //  Mutate existing entities

    log(`Replacing ID: "${uuid}" => "${newId}"`)
    const oldRecord = await entitiesDb.Contacts.get(uuid)
    if(oldRecord) {
      log('Deleting old record', oldRecord)
      await entitiesDb.Contacts.delete(uuid)
      Sentry.setExtra('deletedTemporaryContact', oldRecord)
    }
    parsedResponse.id = parsedResponse.id.toString()
    await entitiesDb.Contacts.add(parsedResponse)
    log('Added new record', newId)

    // Mutate other transactions that might depend on the contact ID
    const pending = await tx.Queue.where('status').equals('pending').toArray()
    await Promise.all<any>(pending.map(async p => {
      if(!p.id) throw new Error('Transaction has no ID')
      const url = typeof p.req === 'string' ? p.req : p.req.url
      const method = getMethod(p.config)
      if(url.includes(uuid)) {
        const newUrl = url.replace(uuid, newId)
        log(`Mutating pending request URL: [${method}] "${url}" => "${newUrl}"`)
        if(url !== newUrl) {
          await tx.Queue.update(p.id, { req: newUrl })
        }
      }
      if(p.config && p.config.body && typeof p.config.body === 'string' && p.config.body.includes(uuid)) {
        log(`Mutating pending request body: [${method}] "${url}"`)
        const pendingBodyString: string | undefined = p.config.body
        const newBody = pendingBodyString.replace(uuid, newId)
        const newConfig = { ...p.config, body: newBody }
        await tx.Queue.update(p.id, { config: newConfig })
      }
      return Promise.resolve()
    }))
    try {
      const pathname = window.location.pathname
      if(pathname.indexOf(uuid) > -1) {
        log('URL contains UUID. Replacing.', pathname)
        const url = new URL(window.location.href)
        url.pathname = url.pathname.replace(uuid, newId)
        log(`Navigating to "${url.pathname}"`)
        navigate(url.pathname)
      }
    } catch(err) {
      console.warn(err)
    }
  },
})

/* eslint-disable filenames/match-exported */
import React from 'react'

import { ImageDTO } from 'api'
import { LocalImageDTO } from 'api_supplimental'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiImagesContext extends ILocalEntityProviderContextBase<LocalImageDTO, ImageDTO[], string> {
 images?: LocalImageDTO[]
 db?: Entities
 get: (guid: string) => Promise<LocalImageDTO | undefined>
 getByAssetId: (assetId: string | number) => Promise<LocalImageDTO[] | undefined>
}

export const defaultContext: ILocalApiImagesContext = {
  db: undefined,
  images: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  getByAssetId() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: {
    isLoading: false,
    isError: false,
    sendCount: 0,
    data: [],
  },
}

export const imagesContext = React.createContext<ILocalApiImagesContext>(defaultContext)

export default imagesContext

import {
  useCallback,
  useContext,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AuthorizationError } from "contexts/api/AuthorizationError";
import currentUserContext from "contexts/CurrentUser/context";
import Debug from "debug";
import Dexie from "dexie";
import { IDatabaseChange } from "dexie-observable/api";

import useApi, { IApiState } from "hooks/useApi";
import { useThrottleFn } from "hooks/useThrottleFn";
import Entities, { TEntityTables } from "idb/Entities";

import useLocalEntitySessionExpiration from "./useLocalEntitySessionExpiration";

const baseLogger = Debug("AL:LocalEntities:useLocalPopulateFromFetch");

export type Config<TFetchResponse, TParsedEntity, TTableIndex> = {
  /**
   * The URL to fetch the entities.
   */
  url: string | (() => string);
  /**
   * Method to transform the fetch response to an array of parsed entities.
   */
  parseResponse: (response: TFetchResponse) => TParsedEntity[] | undefined;
  /**
   * Method to return the dexie table.
   */
  getTable: (db: Entities) => Dexie.Table<TParsedEntity, TTableIndex>;
  /**
   * Whether or not to clear the table before inserting fetched records
   * @default true
   */
  clearBefore?: boolean;
  /**
   * An array of table names to scope updates to on change.
   * If `TEntityTables[]`, updates entities only when the specified tables are changed.
   * If `undefined`, updates only when this entity's table changes (result of `getTable`)
   * If `true`, updates on all changes.
   * If `false`, disable updating entities when the database changes.
   * @default undefined
   */
  updateOnChangesToTables?: Array<TEntityTables> | boolean;
  /**
   * The suffix of the cache key to use for determining expiration.
   * @default table.name
   */
  storageCacheKeyTableName?: string;
  /**
   * Fetch if the IDB table is empty.
   * @default true
   */
  fetchWhenEmpty?: boolean;
};

export type TReturn<TFetchResponse, TParsedEntity, TTableIndex = number> = {
  fetchState: IApiState<TFetchResponse>;
  entities?: TParsedEntity[];
  table: Dexie.Table<TParsedEntity, TTableIndex>;
  isResolving: boolean;
  db: Entities;
};
export function useLocalAndPopulateFromFetch<
  TFetchResponse,
  TParsedEntity,
  TTableIndex = number
>(
  config: Config<TFetchResponse, TParsedEntity, TTableIndex>
): TReturn<TFetchResponse, TParsedEntity, TTableIndex> {
  const {
    parseResponse,
    getTable,
    clearBefore = true,
    updateOnChangesToTables,
    storageCacheKeyTableName,
    fetchWhenEmpty = true,
  } = config;
  const [entities, setEntities] = useState<TParsedEntity[]>();
  const [doPopulate, setDoPopulate] = useState<boolean>(false);
  const [isAwaiting, setAwaiting] = useState<boolean>(true);
  const [lastChanged, setLastChanged] = useState<number>();
  const setLastChangedThrottled = useThrottleFn(setLastChanged, 100);
  const { currentUser } = useContext(currentUserContext);
  if (!(currentUser && currentUser.email)) {
    throw new AuthorizationError("No current user context");
  }
  const db = useMemo(
    () => Entities.getInstance(currentUser.email!),
    [currentUser]
  );
  const table = useMemo(() => getTable(db), [db, getTable]);
  const { setLastUpdated, isExpired } = useLocalEntitySessionExpiration({
    tableName: storageCacheKeyTableName || table.name,
    dbName: db.name,
  });
  const url = typeof config.url === "function" ? config.url() : config.url;
  const fetchState = useApi<TFetchResponse>(
    doPopulate ? url : undefined,
    undefined,
    { requestOptions: { cache: "no-cache" } }
  );
  const fetched = useMemo(
    () => parseResponse(fetchState.data),
    [fetchState.data, parseResponse]
  );
  const log = useMemo(
    () => baseLogger.extend(storageCacheKeyTableName || table.name),
    [storageCacheKeyTableName, table.name]
  );

  useDebugValue(isExpired ? "Expired!" : "Not expired");
  useEffect(() => {
    if (isExpired) {
      setDoPopulate(true);
    }
  }, [isExpired]);

  // Array of table names to trigger changes from
  const updateOnChangesTo = useMemo<
    Exclude<typeof updateOnChangesToTables, undefined>
  >(() => {
    if (typeof updateOnChangesToTables === "undefined")
      return [table.name] as unknown as TEntityTables[];
    return updateOnChangesToTables;
  }, [table.name, updateOnChangesToTables]);

  const handleChanged = useCallback(
    (changes: IDatabaseChange[]) => {
      if (updateOnChangesTo === false) return;
      if (
        updateOnChangesTo === true ||
        changes.some((change) =>
          updateOnChangesTo.includes(change.table as TEntityTables)
        )
      ) {
        setLastChangedThrottled(Date.now());
      }
    },
    [setLastChangedThrottled, updateOnChangesTo]
  );

  // Subscribe to all changes and unsubscribe on cleanup
  useEffect(() => {
    if (db) {
      db.on("changes", handleChanged);
    }
    return () => {
      try {
        if (db) {
          // @ts-ignore
          db.on.changes.unsubscribe(handleChanged);
        }
      } catch (err) {
        // console.warn(err)
      }
    };
  }, [handleChanged, db, log]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (db) {
        const nextEntities = await table.toArray();
        if (mounted) {
          setEntities(nextEntities);
        }
      }
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, lastChanged]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let mounted = true;
    (async () => {
      const count = await table.count();
      if (count === 0 && !doPopulate) {
        if (mounted) {
          if (fetchWhenEmpty) {
            log("No entities in table. Requesting fetch.");
            setDoPopulate(true);
          } else {
            log(
              "No entities in table, but fetchWhenEmpty is false. Skipping fetch."
            );
          }
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (fetched) {
      log("Setting last updated date");
      setLastUpdated();
    }
  }, [fetched]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (mounted) {
        try {
          setAwaiting(true);
          if (fetched) {
            if (clearBefore) {
              log("Clearing table");
              await table.clear();
            }
            log(`Populating table from ${fetched.length} fetched entities`);
            if (fetched.length > 0) {
              await table.bulkPut(fetched).catch((err) => {
                console.warn("bulkPut() failed", err);
              });
            }
          }
          const dbEntities = await table.toArray();
          if (mounted) {
            // and yet again in case we've been unmounted while awaiting
            setAwaiting(false);
            if (dbEntities) {
              setEntities(dbEntities);
            }
          }
        } catch (err) {
          console.warn("Trapped err:", err);
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, [fetched]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    fetchState,
    entities,
    table,
    isResolving: fetchState.isLoading || isAwaiting,
    db,
  };
}

export default useLocalAndPopulateFromFetch;

import React, { ReactNode } from 'react'
import InterceptAPIProvider from 'contexts/api/InterceptAPIProvider'
import { CurrentUserProvider } from 'contexts/CurrentUser'
import EntitiesApiProvider from 'contexts/LocalEntities/EntitiesApiProvider'
import TransactionsManager from 'contexts/Transactions/TransactionsManager'
import TransactionsProvider from 'contexts/Transactions/TransactionsProvider'

import BrowserOnly from 'components/BrowserOnly'

import 'global.css'

export const App = (props: { children: ReactNode }) => {
  const {
    children,
  } = props

  return (
    <BrowserOnly>
      <CurrentUserProvider blocking>
        <EntitiesApiProvider>
          <TransactionsProvider>
            <InterceptAPIProvider>
              <TransactionsManager />
              {children}
            </InterceptAPIProvider>
          </TransactionsProvider>
        </EntitiesApiProvider>
      </CurrentUserProvider>
    </BrowserOnly>
  )
}

export default App

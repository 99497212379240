import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'
import * as Sentry from '@sentry/browser'

import InlineError from 'components/Errors/InlineError'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import { IApiState } from 'hooks/useApi'

type Props = {
  fetchState: IApiState<any>
  blocking?: boolean
  children?: ReactNode
  spinnerProps?: CircularProgressProps
  loadingMessage?: ReactNode
  isResolving: boolean
}

const MessageBox = ({ children }: { children: ReactNode }) => (
  <Box my={2} component="small">{children}</Box>
)

export const LocalEntityProviderFetchStateWrapper = (props: Props) => {
  const {
    fetchState,
    blocking = false,
    children,
    spinnerProps = {},
    loadingMessage = 'Loading',
    isResolving,
  } = props

  const {
    error,
  } = fetchState

  if(blocking && isResolving) {
    return (
      <PaddedFlexCentered>
        <Spinner {...spinnerProps} />
        <MessageBox>{loadingMessage}...</MessageBox>
      </PaddedFlexCentered>
    )
  }

  if(error) {
    Sentry.captureException(error)
    return (
      <PaddedFlexCentered>
        <InlineError message={error.message} />
        <MessageBox>{loadingMessage}</MessageBox>
      </PaddedFlexCentered>
    )
  }
  return <>{children}</>
}

export default LocalEntityProviderFetchStateWrapper

/**
 * InterceptAPIProvider
 *
 * Provides a context that resolves an API fetcher and parser that resolves
 * from IndexedDb first, then from the API.
 *
 * Optionally, it can stub requests with fixtures by matching request URLs.
 *
 * Stubs can be specified as a single Stub or an array of Stubs.
 */
import React, { ReactNode, useContext, useMemo } from "react";
// @ts-ignore
import { pick as pickRoute } from "@reach/router/lib/utils";
import currentUserContext from "contexts/CurrentUser/context";
import networkContext from "contexts/NetworkState/context";
import transactionsDbContext from "contexts/Transactions/transactionsDbContext";
import Debug from "debug";

import useApiPrefix from "hooks/useApiPrefix";
import Entities from "idb/Entities";

import baseFetch from "./baseFetch";
import baseParser from "./baseParser";
import context, { FetchExtra } from "./context";
import {
  FetchBag,
  HTTPRequestMethod,
  IInterceptMatch,
  ParseBag,
} from "./Intercept";
import InterceptedResponse from "./InterceptedResponse";
import intercepts from "./intercepts";

const { Provider } = context;

const log = Debug("AL:API:Provider");

type Stub = {
  // Only resolve with response if URL matches this
  match: string | RegExp;
  // The response to resolve with if matched.
  response: any;
};

export interface Props {
  stub?: Stub | Stub[];
  /**
   * Simulate network operation timing of a random fraction of `delay`ms.
   * If `true`, defaults to `1000`.
   * @default false
   */
  delay?: number | boolean;
  children: ReactNode;
}

export const InterceptAPIProvider = (props: Props) => {
  const { children, stub, delay } = props;

  const apiPrefix = useApiPrefix();
  const { currentUser } = useContext(currentUserContext);
  const networkState = useContext(networkContext);
  const { startNetworkTimeout, clearNetworkTimeout } = networkState;
  const entitiesDb =
    currentUser && currentUser.email && Entities.getInstance(currentUser.email);
  const transactionsContext = useContext(transactionsDbContext);

  const stubs = useMemo(() => {
    if (!stub) return [];
    if (Array.isArray(stub)) {
      return stub;
    }
    return [stub];
  }, [stub]);

  const getMatchedStub = useMemo(
    () =>
      (url: RequestInfo): Stub | undefined => {
        return stubs.find((_stub) => {
          if (typeof _stub.match === "string") {
            return _stub.match === url.toString();
          } else if (_stub.match instanceof RegExp) {
            return _stub.match.test(url.toString());
          } else {
            throw new Error("Unknown match type provided to stub.");
          }
        });
      },
    [stubs]
  );

  const fetchWithPrefix = useMemo(
    () =>
      (url: RequestInfo, opts?: RequestInit): Promise<Response> => {
        const prefixedUrl = `${apiPrefix}${url}`;
        return baseFetch(prefixedUrl, opts);
      },
    [apiPrefix]
  );

  const interceptFetcher = (
    url: RequestInfo,
    opts?: RequestInit,
    fetchExtra?: FetchExtra
  ): Promise<Response | InterceptedResponse> => {
    return new Promise<Response | InterceptedResponse>((resolve) => {
      const matchedStub = getMatchedStub(url);

      let modifiedOriginOpts: RequestInit = {
        ...opts,
        referrerPolicy: "unsafe-url",
      };

      if (matchedStub) {
        /* eslint-disable no-console */
        console.groupCollapsed(`Response stubbed for "${url}"`);
        console.info("Stubbed response:", matchedStub.response);
        console.groupEnd();
        /* eslint-enable no-console */
        if (delay) {
          const _delay = typeof delay === "boolean" ? 1000 : delay;
          setTimeout(() => {
            return resolve(matchedStub.response);
          }, Math.random() * _delay);
        } else {
          return resolve(matchedStub.response);
        }
      } else {
        startNetworkTimeout(url);
        const match = getMatchedInterceptIfCacheable(url, modifiedOriginOpts);
        if (match) {
          const method = getMethod(modifiedOriginOpts);
          const intercept = match.route.intercept;
          log(
            `Intercepting API call [${method}] "${url}"`,
            match.route.intercept
          );
          if (!entitiesDb) {
            throw new Error("Entities database not provided to fetcher.");
          }
          if (!transactionsContext) {
            throw new Error("Transactions context not provided to fetcher.");
          }
          if (!currentUser) {
            throw new Error("Current user not provided to fetcher.");
          }
          // build the bag of goodies for the intercept's fetch
          const fetchBag: FetchBag = {
            networkState,
            entitiesDb,
            transaction: fetchExtra && fetchExtra.transaction,
            transactionsContext,
            match,
            fetcher: fetchWithPrefix,
            req: url,
            opts: modifiedOriginOpts,
            currentUser,
          };
          const fetchResult = intercept.fetch(fetchBag);
          return resolve(fetchResult);
        }
        return resolve(fetchWithPrefix(url, opts));
      }
    }).finally((...params) => {
      clearNetworkTimeout(url);
      return params;
    });
  };

  const parser = async <TResponse,>(
    url: RequestInfo,
    opts: RequestInit | undefined,
    resp: InterceptedResponse<TResponse> | Response,
    fetchExtra: FetchExtra
  ): Promise<TResponse | any> => {
    const matchedStub = getMatchedStub(url);
    if (matchedStub) {
      return resp;
    }

    let modifiedOriginOpts: RequestInit = {
      ...opts,
      referrerPolicy: "unsafe-url",
    };

    const parsedResponse = await baseParser<TResponse>(
      url,
      modifiedOriginOpts,
      resp
    );

    const match = getMatchedIntercept(url, modifiedOriginOpts);
    if (match) {
      const intercept = match.route.intercept;
      if (!entitiesDb) {
        throw new Error("Entities database not provided to parser.");
      }
      if (!transactionsContext) {
        throw new Error("Transactions context not provided to parser.");
      }
      if (!currentUser) {
        throw new Error("Current user not provided to parser.");
      }
      const bag: ParseBag<TResponse> = {
        originalResponse: resp,
        parsedResponse,
        transaction: fetchExtra.transaction,
        networkState,
        fetcher: fetchWithPrefix,
        entitiesDb,
        transactionsContext,
        match,
        req: url,
        opts: modifiedOriginOpts,
        currentUser,
      };
      const method = getMethod(modifiedOriginOpts);
      if (intercept.postParse && !fetchExtra.transaction) {
        log(`Calling postParse intercept method for URL: [${method}] "${url}"`);
        await intercept.postParse(bag);
      }
      if (intercept.postTransaction && fetchExtra.transaction) {
        log(
          `Calling postTransaction intercept method for URL: [${method}] "${url}"`
        );
        await intercept.postTransaction(bag);
      }
    }

    return parsedResponse;
  };
  return (
    <Provider
      value={{
        fetch: interceptFetcher,
        parseResponse: parser,
      }}
    >
      {children}
    </Provider>
  );
};

/**
 * Gets an HTTP request's method from fetch config or defaults to `"GET"`.
 * @param reqOpts Fetch config
 */
export const getMethod = (reqOpts?: RequestInit): HTTPRequestMethod => {
  return (
    reqOpts && reqOpts.method ? reqOpts.method : "GET"
  ) as HTTPRequestMethod;
};

/**
 * Gets first matching intercept found (using `@reach/router/lib/utils/pick()`),
 * or void if the request's `cache` option is `no-cache`.
 * @param reqInfo The url or config to request
 * @param opts fetch options
 */
const getMatchedInterceptIfCacheable = (
  reqInfo: RequestInfo,
  opts?: RequestInit
): IInterceptMatch | undefined => {
  if (opts && opts.cache && opts.cache === "no-cache") {
    return;
  }
  return getMatchedIntercept(reqInfo, opts);
};

/**
 * Gets first matching intercept found (using `@reach/router/lib/utils/pick()`).
 * @param reqInfo The url or config to request
 * @param opts fetch options
 */
const getMatchedIntercept = (
  reqInfo: RequestInfo,
  opts?: RequestInit
): IInterceptMatch | undefined => {
  const url = typeof reqInfo === "string" ? reqInfo : reqInfo.url;
  const method = getMethod(opts);
  const methodIntercepts = intercepts.filter((i) => i.method === method);
  return pickRoute(
    methodIntercepts.map((i) => ({ path: i.pathname, intercept: i })),
    url
  );
};

export default InterceptAPIProvider;

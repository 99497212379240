import { LocalSample } from "api_supplimental";

import Intercept from "../Intercept";
import InterceptedResponse from "../InterceptedResponse";

export const getSample = new Intercept<LocalSample>({
  pathname: "v1/samples/:sampleIdentifier",
  fetch: async ({ entitiesDb, match }) => {
    const sampleIdentifier =
      match && match.params && match.params.sampleIdentifier;
    if (!sampleIdentifier) {
      return new InterceptedResponse({ status: 400 });
    }
    const id = sampleIdentifier;
    const data = await entitiesDb.Samples.get({ identifier: id });
    if (data) {
      return new InterceptedResponse({ data, status: 200 });
    }
    return new InterceptedResponse({ status: 404 });
  },
});

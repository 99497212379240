export function pruneObjectShallow<T>(obj: {
  [P in keyof T]: T[P];
}): Partial<T> {
  const result = {} as T
  for(const k in obj) {
    const v = obj[k]
    if((typeof v === 'string' && v.length > 0) || typeof v !== 'string') {
      result[k] = v
    }
  }
  return result
}

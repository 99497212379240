import React, { useContext } from 'react'
import offlineAssetsContext from 'contexts/LocalEntities/Assets/context'

import { KnownAsset } from 'api_supplimental'
import FilterableAssetList, { Props as FilterableAssetListProps } from 'components/Assets/FilterableAssetList'

const styles = require('./AssetSelect.css')

export interface Props extends FilterableAssetListProps {
  onChange?: (asset: KnownAsset) => void
  filter?: (asset: KnownAsset) => boolean | undefined | void
}

export const AssetSelect = (props: Props) => {
  const {
    onChange,
    filter = () => true,
    ...passedProps
  } = props
  const { assets } = useContext(offlineAssetsContext)

  const handleItemClick = async (e: React.MouseEvent, asset: KnownAsset) => {
    e.preventDefault()
    e.stopPropagation()
    if(onChange) {
      onChange(asset)
    }
  }

  const filteredAssets = assets && assets.filter(filter)

  return (
    <div className={styles.root}>
      <FilterableAssetList
        assets={filteredAssets}
        itemProps={{
          onClick: handleItemClick,
        }}
        {...passedProps}
      />
    </div>
  )
}

export default AssetSelect

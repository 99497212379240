import React, { HTMLProps, useContext, useEffect, useState } from 'react'
import useAsync from 'react-use/lib/useAsync'
import fluidsContext from 'contexts/LocalEntities/Fluids/context'

import { FluidDTO } from 'api'
import FluidAttributes from 'components/FluidAttributes'
import { Props as FluidAttributesProps } from 'components/FluidAttributes/FluidAttributes'
import LabelledValue, { Props as LabelledValueProps } from 'components/LabelledValue'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import { PaddedSection } from 'components/PaddedSection'
import Spinner from 'components/Spinner'

type FluidFormikRecord = {
  fluidId?: string,
}

interface Props extends HTMLProps<HTMLSelectElement> {
  errors?: FluidFormikRecord
  touched?: FluidFormikRecord
  required?: boolean
  value?: number
  labelledValueProps?: Partial<LabelledValueProps>
  attributesDisplayProps?: Partial<FluidAttributesProps>
}

export const FluidSelect = (props: Props) => {
  const {
    value,
    onChange,
    required,
    errors = {},
    touched = {},
    labelledValueProps = {},
    attributesDisplayProps = {},
    ...passedProps
  } = props
  const [selectedFluidManufacturer, setSelectedFluidManufacturer] = useState<string>()
  const { fluids, get } = useContext(fluidsContext)

  const fluidId = value && parseInt(value.toString(), 10)
  if(fluidId && isNaN(fluidId)) {
    throw new TypeError('FluidSelect value is not a number')
  }

  useEffect(() => {
    let mounted = true;
    (async () => {
      if(mounted && fluidId) {
        const _f = await get(fluidId)
        if(_f && mounted) {
          setSelectedFluidManufacturer(_f.manufacturer)
        }
      }
    })()
    return () => { mounted = false }
  }, [fluidId, get])

  const { value: fluid } = useAsync(() => {
    return fluidId ? get(fluidId) : Promise.resolve(undefined)
  }, [fluidId, get])

  if(!fluids || fluids.length === 0) {
    return <PaddedFlexCentered><Spinner size={22} /></PaddedFlexCentered>
  }

  const manufacturers = fluids && fluids.reduce((acc, f) => {
    if(f.manufacturer && !acc.includes(f.manufacturer)) {
      acc.push(f.manufacturer)
    }
    return acc
  }, [] as string[])

  const fluidsByManufacturer: FluidDTO[] = selectedFluidManufacturer ? fluids.filter(f => f.manufacturer === selectedFluidManufacturer) : []

  return (
    <>
      <LabelledValue
        label="Fluid Manufacturer"
        feedback={errors.fluidId}
        required={required}
        {...labelledValueProps}
      >
        <select
          value={selectedFluidManufacturer || (fluid && fluid.manufacturer) || ''}
          onChange={(e) => {
            setSelectedFluidManufacturer(e.target.value)
            if(typeof onChange === 'function') {
              e.currentTarget.name = 'fluidId'
              e.currentTarget.value = ''
              onChange(e)
            }
          }}
        >
          <option disabled key="" value="">Select a Fluid Manufacturer</option>
          {(manufacturers || []).map((name) => <option key={name} value={name}>{name}</option>)}
        </select>
      </LabelledValue>
      <LabelledValue
        label="Fluid Type"
        error={!!(errors.fluidId && touched.fluidId)}
        feedback={errors.fluidId}
        required={required}
        {...labelledValueProps}
      >
        {(selectedFluidManufacturer || fluid) ? (
          <select {...passedProps} value={fluidId} onChange={onChange}>
            <option disabled key="" value="">Select a Fluid Type</option>
            {fluidsByManufacturer.map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
          </select>
        ) : (
          <select disabled value="">
            <option disabled key="disabled" value="">Select a fluid manufacturer first</option>
          </select>
        )}
      </LabelledValue>
      {fluid ? (
        <PaddedSection>
          <FluidAttributes fluid={fluid} {...attributesDisplayProps} />
        </PaddedSection>
      ) : null}
    </>
  )
}
export default FluidSelect

import React, { useMemo } from "react";

import {
  AssetDTO,
  SampleDataPreview,
  SampleDTO,
  SampleReportAvailabilityMap,
} from "../../types/api.d";
import { Result, SampleWithReportStatus } from "api_supplimental";
import EmptyBucket from "components/EmptyBucket";
import FetchStateWrapper from "components/FetchStateWrapper";
import JQUIList from "components/JQUIList";
import useApi from "hooks/useApi";

import SampleHistoryItem from "./SampleHistoryItem";

type Props = {
  asset: AssetDTO;
};

export const SampleHistory = (props: Props) => {
  const { asset } = props;
  const fetchState = useApi<Result<SampleDTO>>(`v1/assets/${asset.id}/samples`);

  let samples = (fetchState.data && fetchState.data.data) || [];
  samples.sort((b, a) =>
    b.sampledAt && a.sampledAt
      ? Date.parse(a.sampledAt) - Date.parse(b.sampledAt)
      : a.sampledAt
      ? 1
      : b.sampledAt
      ? -1
      : 0
  );

  const body = useMemo(
    () => JSON.stringify({ labIds: samples.map((s) => s.labId) }),
    [fetchState.data && fetchState.data.data && fetchState.data.data.length]
  );

  const reportAvailabilityState = useApi<SampleReportAvailabilityMap>(
    `v1/samples/report/verifyall`,
    [],
    {
      requestOptions: { body },
      dependencies: [
        fetchState.data && fetchState.data.data && fetchState.data.data.length,
      ],
    }
  );

  const mappedSamples: SampleWithReportStatus[] = samples.map((s) => {
    const match =
      !reportAvailabilityState.isLoading &&
      reportAvailabilityState.data &&
      typeof s.labId !== "undefined"
        ? reportAvailabilityState.data[s.labId]
        : null;

    const reportAvailable = !!match;
    const machineCondition = reportAvailable
      ? match.machineCondition
      : undefined;
    const lubricantCondition = reportAvailable
      ? match.lubricantCondition
      : undefined;

    return { ...s, reportAvailable, machineCondition, lubricantCondition };
  });

  return (
    // <FetchStateWrapper fetchState={reportAvailabilityState}>
    <FetchStateWrapper
      fallback={
        <EmptyBucket>
          <small>No sample history for this asset</small>
        </EmptyBucket>
      }
      fetchState={
        fetchState.isError || fetchState.isLoading
          ? fetchState
          : reportAvailabilityState
      }
      pad
    >
      <JQUIList>
        {mappedSamples.map((sample) => (
          <SampleHistoryItem
            asset={asset}
            sample={sample}
            key={sample.identifier}
          />
        ))}
      </JQUIList>
    </FetchStateWrapper>
    // </FetchStateWrapper>
  );
};

export default SampleHistory;

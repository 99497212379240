import React, { HTMLProps } from 'react'
import clsx from 'clsx'

const styles = require('./EmptyBucket.css')

export const EmptyBucket = (props: HTMLProps<HTMLDivElement>) => {
  const {
    className,
    children,
    ...passedProps
  } = props
  return (
    <div className={clsx(className, styles.root)} {...passedProps}>
      {children}
    </div>
  )
}
export default EmptyBucket

import React from 'react'
import { FaIndustry } from 'react-icons/fa'
import { Link } from 'gatsby'

import { KnownAsset } from 'api_supplimental'
import { TagIdentifier } from 'components/TagIdentifier'
import Thumbnail from 'components/Thumbnail'
import useAssetImages from 'hooks/useAssetImages'
import { getBlobImageUrl } from 'utils/remote-image-utils'

const styles = require('./AssetCardHorizontal.css')

export type Props = {
  asset: KnownAsset
}

export const AssetCardHorizontal = (props: Props) => {
  const {
    asset,
  } = props

  const images = useAssetImages(asset.id)

  if(!asset) return null

  const {
    name,
    plantName,
    id: assetId,
    tagIdentifier,
  } = asset

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div><Link to={`/app/assets/${assetId}/`}>{name}</Link></div>
        <small><FaIndustry />{plantName}</small>
        {tagIdentifier && (<TagIdentifier tagIdentifier={tagIdentifier} />)}
      </div>
      {(images && images.length > 0) ? (
        <Thumbnail
          imageUrl={images[0].imagePath && getBlobImageUrl(images[0].imagePath, 'tn')}
          className={styles.thumbnail}
        />
      ) : null}
    </div>
  )
}

export default AssetCardHorizontal

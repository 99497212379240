import { RouteDTO } from 'api'

type TGroupData = { groupCounts: number[], groupCustomerIds: number[] }

export default function useRouteGroups(routes?: RouteDTO[]): TGroupData {
  if(!routes || routes.length === 0) {
    return {
      groupCounts: [0],
      groupCustomerIds: [-1],
    }
  }

  const groups = routes.reduce((acc, route) => {
    const { customerId } = route
    if(customerId) {
      if(!acc.has(customerId)) {
        acc.set(customerId, [route])
      }
      const existing = acc.get(customerId) || []
      acc.set(customerId, [...existing, route])
    }
    return acc
  }, new Map<number, RouteDTO[]>())

  return {
    groupCounts: Array.from(groups.values()).map(members => (members.length - 1)),
    groupCustomerIds: Array.from(groups.keys()),
  }
}

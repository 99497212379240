import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { PlantDetailDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiPlantDetailContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalPlantDetailsProvider = (props: Props) => {
  const {
    children,
  } = props
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((r: Result<PlantDetailDTO>) => r && r.data, [])
  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<PlantDetailDTO>, PlantDetailDTO>({
    url: `v1/customers/${activeCustomer.id}/plants/details`,
    getTable: (db) => db.PlantDetails,
    parseResponse,
  })
  const plantDetails = useMemo(() => (entities) && sortByProp(entities, ['name']), [entities])

  const value: ILocalApiPlantDetailContext = {
    plantDetails,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Plant Details" blocking>
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalPlantDetailsProvider

import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import useClearLocalEntitiesDb from 'contexts/LocalEntities/useClearLocalEntitiesDb'
import { useSnackbar } from 'notistack'

import AppHeader from 'components/AppHeader'
import LabelledValue from 'components/LabelledValue'
import TitledSection from 'components/TitledSection'
import useApiPrefix from 'hooks/useApiPrefix'

import { AppVersion } from './AppVersion'

export const DebugPage = () => {
  const { clearEntitiesDb } = useClearLocalEntitiesDb()
  const { enqueueSnackbar } = useSnackbar()
  const apiPrefix = useApiPrefix()

  const handleRebuildClick = async () => {
    await clearEntitiesDb()
    enqueueSnackbar('Entities db cleared', { autoHideDuration: 2000 })
    window.location.reload()
  }
  return (
    <div>
      <AppHeader
        title="Debug Tools"
        fixed
      />
      <Box p={2}>
        <LabelledValue label="Version">
          <AppVersion />
        </LabelledValue>
        <LabelledValue label="API Prefix">
          <code>{apiPrefix}</code>
        </LabelledValue>
      </Box>
      <TitledSection title="Actions">
        <Box p={2}>
          <Button type="button" onClick={handleRebuildClick} variant="outlined" color="secondary" fullWidth>
            Clear Local Entities DB
          </Button>
          <Typography variant="caption">Clears the local indexeddb databases on this device and refreshes the app.</Typography>
        </Box>
      </TitledSection>
    </div>

  )
}

export default DebugPage

/* eslint-disable no-console */
import { useContext, useMemo } from 'react'
import useAsync, { AsyncState } from 'react-use/lib/useAsync'
import { currentUserContext } from 'contexts/CurrentUser'
import Debug from 'debug'

import usePrevious from 'hooks/usePrevious'
import Entities from 'idb/Entities'

const log = Debug('AL:useClearLocalCustomerRelatedTables')

export type TReturn = AsyncState<void>

/**
 * Empties all tables that should be scoped to the active customer.
 * Should be fired on active customer change.
 */
export function useClearLocalCustomerRelatedTables(activeCustomerId?: number): TReturn {
  const { currentUser } = useContext(currentUserContext)
  const previousCustomerId = usePrevious(activeCustomerId)
  const db = useMemo(() => {
    const uid = currentUser?.email
    if(uid) {
      try {
        return new Entities(uid)
      } catch(err) {
        console.warn(err)
      }
    } else {
      console.warn('No currentUserId param given to useClearLocalEntitiesDb. Databases might not be cleared correctly.')
    }
  }, [currentUser])

  const state = useAsync(async () => {
    if(typeof activeCustomerId === 'undefined' && typeof previousCustomerId === 'number') {
      log(`Active customer changed: [${previousCustomerId}] => [${activeCustomerId}]`)
      try {
        if(db) {
          log('Clearing customer-scoped entity tables')
          await db.Assets.clear()
          await db.Samples.clear()
          await db.Routes.clear()
          await db.Plants.clear()
          await db.PlantDetails.clear()
          await db.Images.clear()
        } else {
          console.error('No entities database found to clear.')
        }
      } catch(err) {
        console.warn(err)
      }
    }
  }, [db, activeCustomerId])

  return state
}

export default useClearLocalCustomerRelatedTables

import { LocalContact } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getContact = new Intercept<LocalContact>({
  pathname: 'v1/contacts/:contactId',
  fetch: async ({ entitiesDb, match }) => {
    const contactId = match && match.params && match.params.contactId
    if(!contactId) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Contacts.get(contactId)
    return new InterceptedResponse({ data, status: 200 })
  },
})

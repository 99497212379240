import { RECENT_SAMPLE_AGE } from "app_constants";

import { KnownAsset, KnownAssetWithReportStatus } from "api_supplimental";

export function assetIsRecentlySampled(
  asset: KnownAsset | KnownAssetWithReportStatus
): boolean {
  const lastSampleDate =
    "sampledAt" in asset &&
    typeof (asset as KnownAssetWithReportStatus).sampledAt !== "undefined"
      ? new Date((asset as KnownAssetWithReportStatus).sampledAt!)
      : undefined;

  const dateLastSampled = asset.dateLastSampled
    ? new Date(asset.dateLastSampled!)
    : undefined;

  if (dateLastSampled || lastSampleDate) {
    const mostRecentDate = [dateLastSampled, lastSampleDate].sort((a, b) =>
      !b ? -1 : !a ? 1 : b.getTime() - a.getTime()
    )[0]!;

    const age = Date.now() - mostRecentDate.getTime();
    return age < RECENT_SAMPLE_AGE;
  }
  return false;
}

import React, { ReactNode } from 'react'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { Intersection } from 'utility-types'
import * as Yup from 'yup'

import { ContactDTO, NewContactDTO } from 'api'
import LabelledValue from 'components/LabelledValue'
import { modifyUserSchemaShape } from 'components/UserDetail/UserDetailModify'
import getFieldErrors, { FieldErrorsMap } from 'utils/getFieldErrors'

const styles = require('./ContactDetailModify.css')

export type ContactDetailFormValues = Intersection<ContactDTO, Omit<NewContactDTO, 'companyId'>>

export const modifyContactSchemaShape = {
  ...modifyUserSchemaShape,
  phone: modifyUserSchemaShape.phoneNumber,
}
delete modifyContactSchemaShape.phoneNumber
const ModifyContactSchema = Yup.object().shape(modifyContactSchemaShape)

export interface Props {
  onSubmit?: (formValues: Required<ContactDetailFormValues>) => void
  renderHeader?: (formikBag: FormikProps<ContactDetailFormValues>) => ReactNode
  renderFooter?: (formikBag: FormikProps<ContactDetailFormValues>) => ReactNode
  defaultValues?: any & ContactDetailFormValues
  ineditableFields?: (keyof ContactDetailFormValues)[]
  fieldErrors?: FieldErrorsMap<ContactDetailFormValues>
}

export const ContactDetailModify = (props: Props) => {
  const {
    onSubmit,
    defaultValues,
    ineditableFields = [],
    fieldErrors,
    renderHeader = () => null,
    renderFooter = () => null,
    ...passedProps
  } = props
  const initialValues: Required<ContactDetailFormValues> = Object.assign({}, {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  }, defaultValues)
  if(initialValues.phone) {
    initialValues.phone = formatIncompletePhoneNumber(initialValues.phone, 'US')
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ModifyContactSchema}
      onSubmit={(values: Required<ContactDetailFormValues>) => {
        const nextValues = { ...values }
        if(values.phone) {
          const Phone = parsePhoneNumberFromString(values.phone, 'US')
          if(Phone) {
            nextValues.phone = Phone.formatNational()
          }
        }
        onSubmit && onSubmit(nextValues)
      }}
      {...passedProps}
      render={(formikBag: FormikProps<ContactDetailFormValues>) => {
        const {
          values,
        } = formikBag

        return (
          <div onSubmit={e => e.stopPropagation()}>
            <Form className={styles.root}>
              {renderHeader(formikBag)}
              <LabelledValue
                label="First Name"
                value={ineditableFields.includes('firstName') ? values.firstName : <Field name="firstName" type="text" />}
                className={styles.labelledValue}
                autoComplete="given-name"
                required
                {...getFieldErrors<ContactDTO>('firstName', formikBag, fieldErrors)}
              />
              <LabelledValue
                label="Last Name"
                value={ineditableFields.includes('lastName') ? values.lastName : <Field name="lastName" type="text" />}
                className={styles.labelledValue}
                autoComplete="family-name"
                required
                {...getFieldErrors<ContactDTO>('lastName', formikBag, fieldErrors)}
              />
              <LabelledValue
                label="Phone"
                autoComplete="tel-national"
                required
                value={ineditableFields.includes('phone') ? values.phone : (
                  <Field
                    name="phone"
                    render={({ field, form }: FieldProps) => (
                      <input
                        {...field}
                        name="phone"
                        type="tel"
                        placeholder="(555) 555-5555"
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          form.setFieldValue('phone', formatIncompletePhoneNumber(e.currentTarget.value, 'US'))
                          field.onBlur(e)
                        }}
                      />
                    )}
                  />
                )}
                {...getFieldErrors<ContactDTO>('phone', formikBag, fieldErrors)}
                className={styles.labelledValue}
              />
              <LabelledValue
                label="Email"
                autoComplete="email"
                required
                value={ineditableFields.includes('email') ? values.email : <Field name="email" type="email" />}
                {...getFieldErrors<ContactDTO>('email', formikBag, fieldErrors)}
                className={styles.labelledValue}
              />
              {renderFooter(formikBag)}
            </Form>
          </div>
        )
      }}
    />
  )
}

export default ContactDetailModify

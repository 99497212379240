import { CustomerTestPackage, Result } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getTestPackages = new Intercept<Result<CustomerTestPackage>>({
  pathname: 'v1/customers/:customerIdentifier/test-packages/all',
  fetch: async ({ entitiesDb, match /* fetcher, networkState, req, opts */ }) => {
    const customerIdentifier = match && match.params && match.params.customerIdentifier
    // if(networkState.online) {
    //   return fetcher(req, opts)
    // }
    if(!customerIdentifier) {
      return new InterceptedResponse({ status: 404 })
    }
    const testPackages = await entitiesDb.TestPackages.where('customerId').equals(Number(customerIdentifier)).toArray()
    return new InterceptedResponse({ data: { data: testPackages }, status: 200 })
  },
})

import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { PlantDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalPlantsProvider = (props: Props) => {
  const {
    children,
  } = props
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((r: Result<PlantDTO>) => r && r.data, [])

  const {
    fetchState,
    entities,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<PlantDTO>, PlantDTO>({
    url: `v1/customers/${activeCustomer.id}/plants/all`,
    getTable: (db) => db.Plants,
    parseResponse,
  })

  const plants = useMemo(() => (entities) && sortByProp(entities, ['number', 'name']), [entities])

  return (
    <Provider value={{ plants }}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Plants">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalPlantsProvider

import React, { FC } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Redirect, RouteComponentProps } from '@reach/router'

import { PlantDTO } from 'api'
import { Result } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

interface Props {
  customerId: string
}

export const SelectPlantRoute: FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const {
    customerId,
  } = props
  const {
    isLoading,
    error,
    data,
  } = useApi<Result<PlantDTO>>(`v1/customers/${customerId}/plants/all`)
  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }
  if(error) {
    throw error
  }

  const plants = data && data.data

  const body = (() => {
    if(!plants || !plants.length) {
      return (
        <PaddedFlexCentered>
          <p>No plants to select from</p>
        </PaddedFlexCentered>
      )
    }
    if(plants.length === 1) {
      if(props.navigate) {
        props.navigate(`./${plants[0].id}/`, { replace: true })
        return null
      }
      return <Redirect to={`./${plants[0].id}/`} />
    }
    return (
      <JQUIList>
        {plants && plants.map(plant => (
          <JQUIListItemLink
            key={plant.id}
            iconRight={<FaAngleRight />}
            linkProps={{ to: plant.id ? `./${plant.id}/` : undefined }}
          >
            {plant.name}
          </JQUIListItemLink>
        ))}
      </JQUIList>
    )
  })()
  return (
    <>
      <AppHeader
        title="Select a Plant"
        fixed
      />
      {body}
    </>
  )
}

export default SelectPlantRoute

import { KnownAsset } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getTagAsset = new Intercept<KnownAsset>({
  pathname: 'v1/tags/:tagIdentifier/asset',
  fetch: async ({ entitiesDb, match, fetcher, networkState, req, opts }) => {
    const tagIdentifier = match && match.params && match.params.tagIdentifier
    if(networkState.online) {
      return fetcher(req, opts)
    }
    if(!tagIdentifier) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Assets.where('tagIdentifier').equals(tagIdentifier).first()
    return new InterceptedResponse({ data, status: 200 })
  },
})

import { useEffect, useState } from 'react'

import { ImageLoadingStatus } from './useImageStatus'

export const useImageFileDataUrl = (file?: File): [string | undefined, ImageLoadingStatus | undefined] => {
  const [dataUrl, setDataUrl] = useState<string>()
  const [status, setStatus] = useState<ImageLoadingStatus>()

  useEffect(() => {
    if(!file) return
    setStatus('loading')

    const reader = new FileReader()

    const onLoaded = () => {
      if(reader.result) {
        setDataUrl(reader.result as string)
        setStatus('loaded')
      }
    }

    const onError = () => {
      setDataUrl(undefined)
      setStatus('failed')
    }

    reader.addEventListener('load', onLoaded, false)
    reader.addEventListener('error', onError, false)

    reader.readAsDataURL(file)

    return function cleanup() {
      reader.removeEventListener('load', onLoaded)
      reader.removeEventListener('error', onError)
      setDataUrl(undefined)
      setStatus(undefined)
    }
  }, [file])
  return [dataUrl, status]
}

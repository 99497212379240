import { AssetTagLinkDTO } from 'api'
import { Result } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export type OfflineTagLinkDTO = Pick<AssetTagLinkDTO, 'active' | 'assetId'>
export const getAssetTagLinks = new Intercept<Result<(AssetTagLinkDTO | OfflineTagLinkDTO)>>({
  pathname: 'v1/tags/:tagIdentifier/asset-tag-links',
  fetch: async ({ entitiesDb, match, fetcher, networkState, req, opts }) => {
    const tagIdentifier = match && match.params && match.params.tagIdentifier
    if(networkState.online) {
      return fetcher(req, opts)
    }
    if(!tagIdentifier) {
      return new InterceptedResponse({ status: 404 })
    }
    const tagged = await entitiesDb.Assets.where('tagIdentifier').equals(tagIdentifier).toArray()
    const data = tagged.map(asset => {
      return {
        assetId: Number(asset.id),
        active: true,
      }
    })
    return new InterceptedResponse({ data: { data }, status: 200 })
  },
})

import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'

import { UserDTO } from 'api'
import AppHeader from 'components/AppHeader'
import BottomNav from 'components/BottomNav'
import FourOhFour from 'components/Errors/FourOhFour'
import Modal from 'components/Modal'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import TitledSection from 'components/TitledSection'
import UserDetail from 'components/UserDetail/UserDetail'
import useApi from 'hooks/useApi'

import Authorizations from './Authorizations/Authorizations'
import ModifyUser from './ModifyUser'

interface Props {
  userId: string
}

export const UserDetails = (props: (RouteComponentProps<Props>)) => {
  const [isModify, setIsModify] = useState<boolean>()
  const [modifiedUser, setModifiedUser] = useState<UserDTO>()
  const {
    userId,
  } = props
  const {
    isLoading,
    error,
    data: user,
    statusCode,
  } = useApi<UserDTO>(userId && `v1/users/${userId}`, undefined, { dependencies: [modifiedUser] })
  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }
  if(error) {
    if(statusCode === 404) {
      return (
        <FourOhFour />
      )
    }
    throw error
  }
  if(!user) {
    return null
  }

  const handleModifyCancel = () => {
    setIsModify(false)
  }

  const handleModifySubmitted = (modified: UserDTO) => {
    setIsModify(false)
    setModifiedUser(modified)
  }
  return (
    <div style={{ backgroundColor: 'var(--color-bg-body)' }}>
      <AppHeader
        title={`${user.lastName}, ${user.firstName}`}
        subtitle={user.email}
        fixed
        actionRight={<Button type="button" onClick={() => setIsModify(true)}>Modify</Button>}
      />
      <TitledSection title="User Details">
        <div style={{ padding: '1rem' }}>
          <UserDetail user={user} />
        </div>
      </TitledSection>
      <Authorizations userId={userId} />
      <BottomNav />
      <Modal id="accountModify" show={isModify}>
        <ModifyUser
          user={user}
          onCancel={handleModifyCancel}
          onSubmitted={handleModifySubmitted}
        />
      </Modal>
    </div>
  )
}

export default UserDetails

import { useContext } from 'react'
import { AuthorizationLevels } from 'app_constants'
import companiesContext from 'contexts/LocalEntities/Companies/context'
import customersContext from 'contexts/LocalEntities/Customers/context'
import plantsContext from 'contexts/LocalEntities/Plants/context'
import routesContext from 'contexts/LocalEntities/Routes/context'

import { AuthorizationDTO, CompanyDTO, CustomerDTO, PlantDTO, RouteDTO } from 'api'

type TReturn = CompanyDTO | RouteDTO | CustomerDTO | PlantDTO | undefined

export const useAuthorizationEntity = (auth: AuthorizationDTO): TReturn => {
  const {
    authorizationLevelId,
    entityId,
  } = auth
  const { companies } = useContext(companiesContext)
  const { customers } = useContext(customersContext)
  const { routes } = useContext(routesContext)
  const { plants } = useContext(plantsContext)
  switch(authorizationLevelId) {
    case AuthorizationLevels.Company:
      return companies?.find(c => c.id === entityId)
    case AuthorizationLevels.Customer:
      return customers?.find(c => c.id === entityId)
    case AuthorizationLevels.Plant:
      return plants?.find(c => c.id === entityId)
    case AuthorizationLevels.Route:
      return routes?.find(c => c.id === entityId)
  }
  return undefined
}

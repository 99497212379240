import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import { KnownAsset } from 'api_supplimental'
import Spinner from 'components/Spinner'
import useAssetImages from 'hooks/useAssetImages'
import { getBlobImageUrl } from 'utils/remote-image-utils'

import Empty from './Empty'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const styles = require('./AssetImages.css')

type Props = {
  asset?: KnownAsset
  isLoading: boolean
}

export const AssetImages = ({ asset, isLoading }: Props) => {
  const images = useAssetImages(asset?.id)
  const $images = images && images.map((img, idx) => {
    if(!img.imagePath) return null
    return (
      <div
        key={idx}
        className={styles.imageContainer}
      >
        <img
          key={idx}
          className={styles.carouselImage}
          src={getBlobImageUrl(img.imagePath, 'md')}
        />
      </div>

    )
  }).filter(a => a)
  const $body = isLoading
    ? <Spinner />
    : ($images && $images.length > 0)
      ? <Carousel showThumbs={false} dynamicHeight={false}>{$images}</Carousel>
      : <Empty />
  return (
    <div className={styles.root}>
      <div className={styles.carouselContainer}>
        {$body}
      </div>
    </div>
  )
}
export default AssetImages

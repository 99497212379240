import React from 'react'

import { CompanyDTO } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalCompaniesContext extends ILocalEntityProviderContextBase<CompanyDTO, Result<CompanyDTO>> {
 companies?: CompanyDTO[]
}

export const defaultContext: ILocalCompaniesContext = {
  companies: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalCompaniesContext>(defaultContext)

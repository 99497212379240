import React from 'react'
import { FaAngleRight } from 'react-icons/fa'

import { RouteDTO } from 'api'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'

export interface Props {
  route: RouteDTO
  className?: string
}

export default ({ route, ...passedProps }: (Props & React.HTMLAttributes<HTMLElement>)) => {
  return (
    <JQUIListItemLink
      iconRight={<FaAngleRight />}
      linkProps={{
        to: route.id ? route.id.toString() : undefined,
      }}
      {...passedProps}
    >
      {route.name}
    </JQUIListItemLink>
  )
}

import React from 'react'
import { FaExclamation } from 'react-icons/fa'

import { LinkPlantContactDTO, PlantDTO } from 'api'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

const styles = require('./ContactMutationSideEffectWarning.css')

type Props = {
  header?: React.ReactNode
  renderFooter?: (links: LinkPlantContactDTO[]) => React.ReactNode
  plantContactLinks: LinkPlantContactDTO[]
}

const PlantFetcher = ({ plantId }: { plantId: string }) => {
  const {
    isLoading,
    data: plant,
  } = useApi<PlantDTO>(`v1/plants/${plantId}`)
  if(isLoading) {
    return <Spinner />
  }
  if(!plant) {
    return (
      <span>Unknown plant</span>
    )
  }
  return (
    <strong>{plant.name}</strong>
  )
}

export default (props: Props) => {
  const {
    plantContactLinks,
    renderFooter,
    header,
  } = props
  return (
    <div>
      {header}
      <div className={styles.root}>
        <div className={styles.warning}>
          <FaExclamation />
          <span>
            This contact is associated with multiple plants
          </span>
        </div>
        <div className={styles.body}>
          <p>This contact is the <strong>primary contact</strong> for the following plants:</p>
          <ul>
            {plantContactLinks.map(plantContactLink => {
              const { plantId } = plantContactLink
              if(!plantId) return null
              return (
                <li key={plantId}>
                  <PlantFetcher plantId={plantId.toString()} />
                </li>
              )
            })}
          </ul>
          <p>Modifying this contact’s information will effect all of these plants.</p>
        </div>
        <div className={styles.footerActions}>
          {renderFooter && renderFooter(plantContactLinks)}
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import Rating from '@material-ui/lab/Rating'

import LabelledValue, { Props as LabelledValueProps } from 'components/LabelledValue'

export type Props = {
  value: number
  labelledValueProps?: Partial<LabelledValueProps>

}
function getAssistiveTextForSampleSourceRating(value: string | number) {
  if(typeof value !== 'number') {
    value = parseInt(value)
  }
  switch(value) {
    case (1):
      return 'A sample location increasing the potential for system contamination through its use, such a hatch, breather, dipstick, or fill cap.'
    case (2):
      return 'Sampling from a drain, the bottom of a sump or reservoir.'
    case (3):
      return 'A sample location that is outside of an active zone and requires extensive flushing.'
    case (4):
      return 'A dedicated sample port that requires briefly shutting down the machine for access or extensive flushing to obtain sample.'
    case (5):
      return 'A dedicated sample port where the sample can be pulled while the machine is running with minimal flushing, drawing from an active zone.'
    default:
      return 'If a sample source rating is not given, "Not Specified" will appear on the report.'
  }
}
export const SampleSourceRating = (props: Props) => {
  const {
    labelledValueProps = {},
    ...passedProps
  } = props

  const assistive = getAssistiveTextForSampleSourceRating(props.value)

  return (
    <LabelledValue
      label="Sample Source Rating"
      assistiveText={assistive}
      {...labelledValueProps}
      value={
        <Rating
          size="large"
          {...passedProps}
        />
      }
    />
  )
}
export default SampleSourceRating

import React from 'react'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import { FluidDTO } from 'api'
import LabelledValue from 'components/LabelledValue'

import WarningBubble from './WarningBubble'

export type BottleType = 'pete' | 'glass' | 'abs'

const styles = require('./FluidAttributes.css')

export type Props = {
  fluid: FluidDTO
  showManufacturer?: boolean
  showName?: boolean
}

export const FluidAttributes = (props: Props) => {
  const {
    fluid: {
      manufacturer,
      name,
      type,
      expected40CViscosity,
    },
    showManufacturer = false,
    showName = false,
  } = props
  const bottleType = props.fluid.bottleType as BottleType
  const $manufacturer = showManufacturer && manufacturer && (
    <LabelledValue label="Lubricant Manufacturer">{manufacturer}</LabelledValue>
  )
  const $name = showName && name && (
    <LabelledValue label="Lubricant Name">{name}</LabelledValue>
  )
  const $type = type && (
    <LabelledValue label="Lubricant Type">{type}</LabelledValue>
  )
  const $expected40CViscosity = expected40CViscosity && (
    <LabelledValue label="Expected Viscosity" units="cSt at 40℃">
      {expected40CViscosity}
    </LabelledValue>
  )
  const bottleTypeLabelMap: Record<BottleType, string> = {
    pete: 'PET',
    glass: 'GLASS',
    abs: 'ABS',
  }
  const bottleTypeClassMap: Partial<Record<BottleType, string>> = {
    pete: styles.warning,
    glass: styles.warning,
  }
  const $bottleType = bottleType && (
    <LabelledValue label="Bottle Type Requirement">
      <div className={styles.col}>
        <Chip label={bottleTypeLabelMap[bottleType] || bottleType} size="small" className={clsx(styles.chip, bottleTypeClassMap[bottleType])} />
        {bottleType !== 'abs' && (
          <WarningBubble>This Lubricant may corrode standard ABS bottles. Please ensure your sample is stored in a PET or glass bottle.</WarningBubble>
        )}
      </div>
    </LabelledValue>
  )
  return (
    <>
      {$manufacturer}
      {$name}
      <div className={clsx(styles.row, styles.fill)}>
        {$type}
        {$expected40CViscosity}
      </div>
      {$bottleType}
    </>
  )
}

export default FluidAttributes

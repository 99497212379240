import React from 'react'
import * as Sentry from '@sentry/browser'

import { AddressDTO } from 'api'

import InlineError from './Errors/InlineError'

export interface Props {
  address: AddressDTO
}

export const MapFrame = ({ address }: Props) => {
  const {
    addressLine1,
    city,
    postalCode,
  } = address
  const q = encodeURIComponent(`${addressLine1} ${city} ${postalCode}`)
  const rootPath = 'https://www.google.com/maps/embed/v1/place'
  const key = process.env.GATSBY_ASSETLINK_GOOGLE_API_KEY
  if(!key) {
    Sentry.captureException(new Error('No Google Maps API key configured'))
    return (
      <InlineError
        message={
          <p>
            <small>Could not find a Google API key. Please be sure to set the environment variable <code>GATSBY_ASSETLINK_GOOGLE_API_KEY</code>.</small>
          </p>
        }
      />
    )
  }
  const frameSrc = `${rootPath}?key=${key}&q=${q}`
  return (
    <iframe
      frameBorder={0}
      allowFullScreen
      style={{
        width: '100vw',
        height: '28vh',
      }}
      src={frameSrc}
    />
  )
}

export default MapFrame

import React from 'react'

import { PortTypeDTO } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalPortTypesContext extends ILocalEntityProviderContextBase<PortTypeDTO, Result<PortTypeDTO>> {
 portTypes?: PortTypeDTO[]
}

export const defaultContext: ILocalPortTypesContext = {
  portTypes: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalPortTypesContext>(defaultContext)

import Dexie from 'dexie'

import 'dexie-observable'

export type TransactionStatus = 'pending' | 'error' | 'complete'

export interface INetworkTransaction {
  id?: number
  req: RequestInfo
  config?: RequestInit
  extra?: any
  dateCreated: Date
  dateCompleted?: Date
  dateLastAttempted?: Date
  attempts?: number
  status: TransactionStatus
  error?: string
}

export class Transaction<TExtra = any> implements INetworkTransaction {
  constructor(req: RequestInfo, config?: RequestInit, extra?: TExtra) {
    this.req = req
    this.status = 'pending'
    this.dateCreated = new Date()
    this.extra = extra
    if(config) {
      const storableConfig = Object.entries(config).reduce((acc, [k, v]) => {
        if(['headers', 'signal'].includes(k)) return acc
        acc[k] = v
        return acc
      }, {} as { [k: string]: any})
      this.config = storableConfig
    }
    this.attempts = 0
    delete this.id
  }

  id?: number
  req: RequestInfo
  config?: RequestInit
  extra?: TExtra
  dateCreated: Date
  dateCompleted?: Date
  dateLastAttempted?: Date
  attempts?: number
  status: TransactionStatus
  error?: string
}

export class Transactions extends Dexie {
    Queue: Dexie.Table<INetworkTransaction, number>

    private static instance: Transactions;

    constructor(userId: string) {
      const userIdNormalized = userId.toLowerCase()
      super(`Transactions_${userIdNormalized || 'UNKNOWN_USER'}`)
      if(!userId) {
        throw new Error('No userId passed to Transactions database constructor')
      }

      this.version(1).stores({
        Queue: '++id, req, status, dateCreated',
      })
      this.version(2).stores({
        Queue: '++id, status, dateCreated',
      })

      this.Queue = this.table('Queue')
      this.Queue.mapToClass(Transaction)
    }

    static getInstance(userId: string) {
      if(!Transactions.instance) {
        Transactions.instance = new Transactions(userId)
      }
      return Transactions.instance
    }
}

export default Transactions

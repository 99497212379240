/**
 * Modified version of https://github.com/streamich/react-use/blob/master/src/useMediaDevices.ts
 * Adds update when media device permissions change.
 */
import { useEffect, useState } from 'react'

import useCameraPermissions from 'components/Sample/Scanner/useCameraPermissions'

const noop = () => {}

const useMediaDevices = () => {
  const [state, setState] = useState({})
  const permission = useCameraPermissions()

  useEffect(() => {
    let mounted = true

    const onChange = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          if(mounted) {
            setState({
              devices: devices.map(({ deviceId, groupId, kind, label }) => ({ deviceId, groupId, kind, label })),
            })
          }
        })
        .catch(noop)
    }
    navigator.mediaDevices.addEventListener('devicechange', onChange)
    onChange()

    return () => {
      mounted = false
      navigator.mediaDevices.removeEventListener('devicechange', onChange)
    }
  }, [permission])

  return state
}

const useMediaDevicesMock = () => ({})

export default typeof navigator === 'object' && !!navigator.mediaDevices ? useMediaDevices : useMediaDevicesMock

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { ContactDTO } from 'api'
import { LocalContact } from 'api_supplimental'

const log = Debug('AL:intercept:patchContacts')

export const patchContacts = new Intercept<ContactDTO>({
  method: 'PATCH',
  pathname: 'v1/contacts',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      networkState,
      fetcher,
    } = fetchBag
    if(networkState.online) {
      return fetcher(req, opts)
    }
    return new InterceptedResponse({ status: 501 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local contact', parsedResponse)
      if(!parsedResponse.id) {
        throw new Error('No contact ID provided')
      }
      const localContact: LocalContact = {
        ...parsedResponse,
        id: parsedResponse.id.toString(),
      }
      await entitiesDb.Contacts.put(localContact)
    }
  },
})

import { useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import Debug from 'debug'

import useApi, { IApiState } from 'hooks/useApi'
import Transactions, { Transaction } from 'idb/Transactions'
import { dataURLtoBlob } from 'utils/blob-utils'

const logger = Debug('AL:Transactions:Worker')

export type TransactionEventHandler = (transaction: Transaction, fetchState: IApiState<any>) => void

export type Props = {
  transaction: Transaction
  db: Transactions
  onComplete?: TransactionEventHandler
}

export const TransactionWorker = (props: Props) => {
  const {
    db,
    transaction,
    onComplete,
  } = props

  const {
    req,
    config = {},
  } = transaction

  let url = typeof req === 'string' ? req : (req ? req.url : undefined)

  /**
   * Used to flag the useApi hook to omit the default content type. When
   * uploading images, we have to omit the content type entirely or the fetch
   * API will send a bad request.
   * See https://stackoverflow.com/a/55073430/131397
   */
  let noDefaultContentType: boolean = false

  // Special cases

  // Put Image
  const putImageRE = /^\/?v1\/assets\/.*\/images/i
  if(url && putImageRE.test(url) && config.method === 'PUT') {
    logger('Converting request body to FormData')
    if(typeof config.body === 'string' && config.body.startsWith('data')) {
      const blob = dataURLtoBlob(config.body)
      const fd = new FormData()
      fd.append('image', blob, 'asset-image.jpg')
      config.body = fd
      noDefaultContentType = true
      logger('Request body converted')
    }
    if(transaction.extra.guid) {
      logger('Adding GUID URL param')
      const searchParams = new URLSearchParams({ guid: transaction.extra.guid })
      url += ('?' + searchParams.toString())
    }
  }

  const requestOptionsWithInterceptBypass: RequestInit = {
    ...config,
    /**
     * Tells intercepts to bypass this request
     */
    cache: 'no-cache',
    /**
     * Disables request abortion when component unmounts. This prevents
     * back-to-back calls with the same url from being aborted too soon.
     */
    signal: undefined,
  }

  const fetchState = useApi(url, undefined, {
    requestOptions: requestOptionsWithInterceptBypass,
    dependencies: [transaction.id],
    fetchExtra: {
      transaction,
    },
    noDefaultContentType,
  })
  const {
    statusCode,
    isLoading,
    error,
  } = fetchState

  const log = useCallback((...args) => {
    const method = config.method || 'GET'
    logger(`Tx#${transaction.id} [${method}] => "${url}": `, ...args)
  }, [config.method, transaction.id, url])

  // Setup shop
  useEffect(() => {
    log('Setting attempt metadata.')
    if(transaction) {
      transaction.dateLastAttempted = new Date()
      transaction.attempts = (transaction.attempts || 0) + 1
    }
  }, [log, transaction])

  // Handle completed requests
  useEffect(() => {
    let mounted = true;
    (async () => {
      if(mounted && transaction && !isLoading && (statusCode || error)) {
        log('Handling completion')
        if(error) {
          console.warn(`Tx#${transaction.id}: `, 'Transaction error:', error)
          transaction.error = error.message
          transaction.status = 'error'
          Sentry.setContext('transaction', transaction)
          Sentry.captureException(error)
        } else {
          log('Setting completed')
          transaction.dateCompleted = new Date()
          transaction.status = 'complete'
        }
        log('Saving transaction.')
        await db.Queue.put(transaction)
        if(onComplete) {
          log('Notifying manager of completion.')
          onComplete(transaction, fetchState)
        }
        log('Done.', '\n\n------------------\n\n')
      }
    })()
    return () => { mounted = false }
  }, [db.Queue, error, fetchState, isLoading, log, onComplete, statusCode, transaction])

  return null
}

export default TransactionWorker

export function dataURLtoBlob(dataUrl: string): Blob {
  const arr = dataUrl.split(',')
  if(!arr || arr.length === 0) {
    throw new Error('Invalid dataUrl')
  }
  const mimeMatch = arr[0].match(/:(.*?);/)
  const mime = mimeMatch && mimeMatch[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime || 'jpeg' })
}

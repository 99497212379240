import { useCallback } from 'react'

export type Props = {
  freq?: number
  duration?: number
}

var RAMP_VALUE = 0.00001

export const useBeep = (props: Props = {}): () => void => {
  const {
    freq = 800,
    duration = 300,
  } = props
  return useCallback(() => {
    let ctx: AudioContext
    try {
      ctx = new AudioContext()
      const currentTime = ctx.currentTime
      const oscillator = ctx.createOscillator()
      const gain = ctx.createGain()

      oscillator.connect(gain)
      gain.connect(ctx.destination)

      gain.gain.setValueAtTime(gain.gain.value, currentTime)
      gain.gain.exponentialRampToValueAtTime(RAMP_VALUE, currentTime + (duration / 1000))

      oscillator.onended = () => {
        gain.disconnect(ctx.destination)
        oscillator.disconnect(gain)
      }

      oscillator.type = 'sine'
      oscillator.frequency.value = freq
      oscillator.start(currentTime)
      oscillator.stop(currentTime + (duration / 1000))
    } catch(err) {
      console.warn('No beeps.')
    }
  }, [duration, freq])
}

import React from 'react'

import { FluidDTO } from 'api'
import { Result } from 'api_supplimental'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiFluidsContext extends Pick<ILocalEntityProviderContextBase<FluidDTO, Result<FluidDTO>>, 'get'> {
 fluids?: FluidDTO[]
}

export const defaultContext: ILocalApiFluidsContext = {
  fluids: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: (_id: number) => {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
}

export default React.createContext<ILocalApiFluidsContext>(defaultContext)

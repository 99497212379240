import React, { ReactNode } from 'react'

import { AuthorizationDTO } from 'api'
import FetchStateWrapper from 'components/FetchStateWrapper'
import TitledSection from 'components/TitledSection'
import useApi from 'hooks/useApi'

import AuthorizationsHierarchyList from './AuthorizationsHierarchyList'

interface Props{
  userId: string | number | undefined
  title?: string
  emptyText?: ReactNode
}

export const Authorizations = (props: Props) => {
  if(!props.userId) {
    throw new Error('No userId provided')
  }
  const userId = typeof props.userId === 'string' ? parseInt(props.userId, 10) : props.userId
  const {
    title: propsTitle = 'Authorizations',
    emptyText,
  } = props

  const userAuthorizationsFetchState = useApi<AuthorizationDTO[]>(`/v1/users/${userId}/authorizations`, undefined, undefined)

  const auths = userAuthorizationsFetchState.data

  const title = auths ? <h2>{propsTitle} <small>({auths.length} entities)</small></h2> : propsTitle

  return (
    <TitledSection title={title}>
      <FetchStateWrapper fetchState={userAuthorizationsFetchState} spinnerProps={{ size: 22 }} pad>
        {auths && (
          <AuthorizationsHierarchyList
            authorizations={auths}
            emptyText={emptyText}
          />
        )}
      </FetchStateWrapper>
    </TitledSection>
  )
}

export default Authorizations

import React, { FC } from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import CreateAssetRoute from './CreateAssetRoute'
import SelectCustomerRoute from './SelectCustomerRoute'
import SelectPlantRoute from './SelectPlantRoute'

export const CreateAssetRouter: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <SelectCustomerRoute path="/" />
      <SelectPlantRoute path=":customerId" />
      <CreateAssetRoute path=":customerId/:plantId" />
    </Router>
  )
}
export default CreateAssetRouter

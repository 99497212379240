import { useEffect, useMemo } from 'react'
import Debug from 'debug'
import { Optional } from 'utility-types'

import { AddressDTO, LinkPlantAddressDTO, LinkPlantContactDTO, PlantDetailDTO } from 'api'
import { getAddressFromPlantRecord, getContactFromPlantRecord } from 'components/PlantDetail'
import useApi, { IApiError, IApiState } from 'hooks/useApi'
import { pruneObjectShallow } from 'utils/pruneObjectShallow'

import { PlantDetailFormValues } from './PlantDetailModify'

const log = Debug('AL:useUpdatePlant')

function useUpdateContactLink(
  plantContactLink: LinkPlantContactDTO | undefined,
  method: 'PUT' | 'PATCH'
): IApiState<LinkPlantContactDTO> {
  const url = `v1/plant-contact-links${(method === 'PATCH' && plantContactLink) ? `/${plantContactLink.id}` : ''}`
  const body = useMemo<string | undefined>(() => plantContactLink && JSON.stringify(plantContactLink), [plantContactLink])
  const gate = typeof plantContactLink !== 'undefined'
  useEffect(() => {
    if(gate) {
      if(method === 'PUT') {
        log('Creating new plant contact link')
      }
      if(method === 'PATCH') {
        log('Updating plant contact link')
      }
    }
  }, [gate, method])
  return useApi<LinkPlantContactDTO>(gate ? url : undefined, undefined, {
    requestOptions: {
      method,
      body,
    },
    dependencies: [body],
  })
}

function useUpdatePlantContact(
  plant: PlantDetailDTO,
  primaryContactId?: number
): IApiState<LinkPlantContactDTO> {
  const primaryContactLink: LinkPlantContactDTO | undefined = getContactFromPlantRecord(plant)
  const method = primaryContactLink ? 'PATCH' : 'PUT'
  const nextContactLink = useMemo<LinkPlantContactDTO | undefined>(() => {
    if(!primaryContactId) return undefined
    if(primaryContactLink) {
      if(primaryContactId === primaryContactLink.contactId) {
        log('Contact ID is the same and does not need updating.')
        // no need to update
        return undefined
      }
      log(`Preparing a new contact link (${primaryContactLink.contactId} => ${primaryContactId})`)
      return {
        ...primaryContactLink,
        contactId: primaryContactId,
      }
    }
    // create a new link (put)
    const newLinkPlantContact: LinkPlantContactDTO = {
      contactId: primaryContactId,
      plantId: plant.id,
      contactTypeName: 'Primary',
      contactTypeId: 1,
    }
    log('Plant has no primary contact record. Creating a new one.', newLinkPlantContact)
    return newLinkPlantContact
  }, [plant.id, primaryContactId, primaryContactLink])
  return useUpdateContactLink(nextContactLink, method)
}

type NewAddressDTO = Optional<AddressDTO, 'country' | 'latitude' | 'longitude'>

function formValsToAddressDTO(formVals?: PlantDetailFormValues): NewAddressDTO | undefined {
  if(!formVals) return
  const {
    latLong,
    ...rest
  } = formVals
  const [latitude, longitude] = latLong ? latLong.split(',').map(s => parseFloat(s)) : [undefined, undefined]
  return {
    ...rest,
    latitude,
    longitude,
  }
}

function useUpdateOrCreateAddress(
  formVals: PlantDetailFormValues | undefined,
  addressId: number | false | undefined
): IApiState<AddressDTO> {
  const isAddressEmpty = useIsAddressEmpty(formVals)
  const method = addressId ? 'PATCH' : 'PUT'
  const url = `v1/addresses${(method === 'PATCH' && addressId) ? `/${addressId}` : ''}`
  const addressDTO = formValsToAddressDTO(formVals)
  if(addressId && addressDTO) {
    addressDTO.id = addressId
  }
  const body = addressDTO && JSON.stringify(addressDTO)
  const gate = useMemo(() => {
    return !isAddressEmpty
  }, [isAddressEmpty])
  useEffect(() => {
    if(gate) {
      if(method === 'PUT') {
        log('Creating new plant address link')
      }
      if(method === 'PATCH') {
        log('Updating plant address link')
      }
    }
  }, [gate, method])
  return useApi<AddressDTO>(gate ? url : undefined, undefined, {
    requestOptions: {
      method,
      body,
    },
    dependencies: [body],
  })
}

function useCreatePlantAddressLink(
  plant: PlantDetailDTO,
  addressId: number | false | undefined
): IApiState<LinkPlantAddressDTO> {
  const existingAddressLink = getAddressFromPlantRecord(plant)
  const doCreate: boolean = !existingAddressLink && !!addressId
  const body = useMemo<string | undefined>(() => {
    if(plant && addressId) {
      const plantAddressLink: LinkPlantAddressDTO = {
        addressId: addressId,
        plantId: plant.id,
        addressTypeId: 2,
        addressTypeName: 'Location',
      }
      return JSON.stringify(plantAddressLink)
    }
  }, [addressId, plant])
  return useApi<LinkPlantAddressDTO>((doCreate && body) ? 'v1/plant-address-links' : undefined, existingAddressLink, {
    requestOptions: {
      method: 'PUT',
      body,
    },
  })
}

function useIsAddressEmpty(formVals?: PlantDetailFormValues): boolean {
  return useMemo<boolean>(() => {
    if(!formVals) return true
    const { latLong, siteInstructions, ...addressProps } = formVals // eslint-disable-line @typescript-eslint/no-unused-vars
    return Object.keys(pruneObjectShallow(addressProps)).length === 0
  }, [formVals])
}

function useUpdatePlantAddress(
  plant: PlantDetailDTO,
  formVals?: PlantDetailFormValues
): {
  linkPlantAddress: IApiState<LinkPlantAddressDTO>,
  createAddressLink: IApiState<LinkPlantAddressDTO>,
} {
  const isAddressEmpty = useIsAddressEmpty(formVals)
  const existingAddressLink = getAddressFromPlantRecord(plant)
  const maybeAddressId = (!isAddressEmpty && existingAddressLink && existingAddressLink.addressId)
  const updateAddressState = useUpdateOrCreateAddress(formVals, maybeAddressId)
  const createAddressLinkState = useCreatePlantAddressLink(plant, (updateAddressState.data ? updateAddressState.data.id : maybeAddressId))
  return {
    linkPlantAddress: updateAddressState,
    createAddressLink: createAddressLinkState,
  }
}

function useUpdatePlantDetails(
  plant: PlantDetailDTO,
  formVals?: PlantDetailFormValues
): IApiState<PlantDetailDTO> {
  const bodyObj = { ...plant }
  delete bodyObj.addresses
  delete bodyObj.contacts
  bodyObj.samplingInstructions = formVals && formVals.siteInstructions
  const body = useMemo<string | undefined>(() => (plant && formVals && JSON.stringify(bodyObj)), [bodyObj, formVals, plant])
  return useApi<LinkPlantAddressDTO>(body ? `v1/plants/${plant.id}` : undefined, undefined, {
    requestOptions: {
      method: 'PATCH',
      body,
    },
  })
}

export type CompoundApiState<ResponseBodyType> = {
  errors?: IApiError[]
  isError: boolean
  isLoading: boolean
  sendCounts: number[]
  data: ResponseBodyType
  statusCodes: number[]
  fieldErrors: any
}

export const useUpdatePlant = (
  plant: PlantDetailDTO,
  formVals?: PlantDetailFormValues,
  primaryContactId?: number
): CompoundApiState<PlantDetailDTO> => {
  const addressUpdateState = useUpdatePlantAddress(plant, formVals)
  const contactUpdateState = useUpdatePlantContact(plant, primaryContactId)
  const plantUpdateState = useUpdatePlantDetails(plant, formVals)
  const states: IApiState<any>[] = [plantUpdateState, addressUpdateState.createAddressLink, addressUpdateState.linkPlantAddress, contactUpdateState]
  const errors = states.map(s => s.error).filter(s => s) as IApiError[]
  const ret: CompoundApiState<PlantDetailDTO> = {
    errors: errors.length > 0 ? errors : undefined,
    isError: errors && errors.length > 0,
    isLoading: states.some(s => s.isLoading),
    sendCounts: states.map(s => s.sendCount),
    data: plantUpdateState.data,
    statusCodes: states.map(s => s.statusCode).filter(s => s) as number[],
    fieldErrors: states.reduce((acc, s) => {
      return { ...acc, ...s.fieldErrors }
    }, {}),
  }
  return ret
}

export default useUpdatePlant

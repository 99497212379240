import React, { HTMLProps } from 'react'
import clsx from 'clsx'

type Props = HTMLProps<HTMLUListElement>

const styles = require('./JQUIList.css')

export const JQUIList = React.forwardRef<HTMLUListElement, Props>(({ children, className, ...passedProps }: HTMLProps<HTMLUListElement>, ref) => {
  return (
    <ul className={clsx(styles.root, className)} {...passedProps} ref={ref}>
      {children}
    </ul>
  )
})

export default JQUIList

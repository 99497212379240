import React, { FC, useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import offlinePlantsContext from 'contexts/LocalEntities/Plants/context'

import AppHeader from 'components/AppHeader'

import FilterablePlantsList from './FilterablePlantsList'

export const PlantsList: FC<RouteComponentProps> = () => {
  const { plants } = useContext(offlinePlantsContext)

  return (
    <>
      <AppHeader
        title="Plants"
        fixed
      />
      <FilterablePlantsList plants={plants} />
    </>
  )
}

export default PlantsList

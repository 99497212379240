import React from 'react'

import { TestPackageDTO } from 'api'
import { CustomerTestPackage, Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalTestPackagesContext extends ILocalEntityProviderContextBase<CustomerTestPackage, Result<TestPackageDTO>> {
 testPackages?: CustomerTestPackage[]
}

export const defaultContext: ILocalTestPackagesContext = {
  testPackages: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalTestPackagesContext>(defaultContext)

/**
 * A modified version of useApi that ignores the APIProvider context and the
 * API Prefix, and detects crappy google 200 Ok errors.
 *
 * @author Aaron Lampros <alampros@testoil.com>
 */

import { useEffect, useReducer } from 'react'
import { createFetchError } from 'contexts/api/FetchError'

import { apiFetchReducer, IApiFetchAction, IApiState, IUseApiConfig } from 'hooks/useApi'

export const defaultRequestOptions: RequestInit = {}
const defaultContentType = 'application/json'

export function useFetch<ResponseBodyType, RequestBodyType = ResponseBodyType>(
  url?: string,
  initialData?: ResponseBodyType,
  config: IUseApiConfig = {}
): IApiState<ResponseBodyType, RequestBodyType> {
  const [state, dispatch] = useReducer(apiFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    sendCount: 0,
  })

  const {
    dependencies = [],
    noDefaultContentType,
    requestOptions = defaultRequestOptions,
  } = config

  useEffect(() => {
    if(!url) {
      return
    }
    let dispatchSafe = (action: IApiFetchAction<ResponseBodyType>) => dispatch(action)
    dispatchSafe({ type: 'INIT', payload: initialData })
    const abortController = new AbortController();
    (async () => {
      let statusCode
      try {
        dispatchSafe({ type: 'FETCH_INIT' })
        // Add default headers if not present
        const headers = new Headers(requestOptions.headers || {})
        if(!requestOptions.headers && !headers.has('Content-Type') && !noDefaultContentType) {
          headers.set('Content-Type', defaultContentType)
        }
        requestOptions.headers = headers

        const body = await fetch(url, {
          ...requestOptions,
          signal: abortController.signal,
        })
          .then(resp => {
            statusCode = resp.status
            return resp
          })
          .then(resp => parseResponse<ResponseBodyType>(url, resp))
          .catch(err => {
            throw err
          })
        dispatchSafe({ type: 'FETCH_SUCCESS', payload: body, statusCode })
      } catch(error) {
        if(error.name !== 'AbortError') {
          console.warn(`useApi: [${error.name}] [${url}]: ${error.toString()}`)
        }
        statusCode = error.response && error.response.status
        return dispatchSafe({ type: 'FETCH_FAILURE', payload: error, statusCode })
      }
    })()
    const cleanup = () => {
      dispatchSafe = () => null // we should not dispatch after cleanup.
      abortController.abort()
      dispatch({ type: 'INIT', payload: initialData })
    }
    return cleanup
  }, [url, ...dependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  return state as IApiState<ResponseBodyType, RequestBodyType>
}

const parseResponse = async <T, >(_url: RequestInfo, resp: Response): Promise<T> => {
  if(resp.ok) {
    const contentType = resp.headers.get('content-type')
    let body
    try {
      if(contentType && contentType.includes('json')) {
        body = await resp.json()
      } else {
        body = await resp.text()
      }
    } catch(err) {
      body = resp.body
      console.warn('Could not parse JSON response from text:', err)
    }
    // Thanks, google. HTTP response codes are for chumps anyway.
    if(body && body.error_message) {
      throw new Error(body.error_message)
    }
    return body
  }
  return Promise.reject(await createFetchError(resp.clone()))
}

export default useFetch

import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

import { AssetTagLinkDTO } from 'api'
import { KnownAsset, Result } from 'api_supplimental'
import AssetCardHorizontal from 'components/Asset/AssetCardHorizontal'
import { IApiState } from 'hooks/useApi'

import InnerError from './InnerError'
import UnlinkedAssetTag from './UnlinkedAssetTag'

export type Props = {
  tagIdentifier?: string
  tagIsLinked?: boolean
  onManualEntry: () => void
  assetFetchState: IApiState<KnownAsset | undefined>
  tagLinksFetchState: IApiState<Result<AssetTagLinkDTO>>
}

export const InnerAsset = (props: Props) => {
  const {
    assetFetchState,
    tagLinksFetchState,
    tagIdentifier,
    tagIsLinked,
    onManualEntry,
  } = props
  const allFetchStates = [assetFetchState, tagLinksFetchState]
  const isLoading = allFetchStates.some(fs => fs.isLoading)
  const asset = assetFetchState.data
  if(assetFetchState.error) {
    if(assetFetchState.statusCode === 404) {
      return (
        <div style={{ padding: '0.5em' }}>
          <span>No asset found linked to tag <code>{tagIdentifier}</code></span>
        </div>
      )
    }
    return (
      <InnerError
        error={assetFetchState.error}
        queryEntityName="Asset"
        queryValue={tagIdentifier}
        statusCode={assetFetchState.statusCode}
      />
    )
  }
  if(tagLinksFetchState.error) {
    return (
      <InnerError
        error={tagLinksFetchState.error}
        queryEntityName="Tag"
        queryValue={tagIdentifier}
        statusCode={tagLinksFetchState.statusCode}
      />
    )
  }
  if(tagIsLinked === false) {
    if(!tagIdentifier) {
      console.warn('Got an unlinked tag without a tagIdentifier...somehow.')
      return null
    }
    return (
      <UnlinkedAssetTag
        tagIdentifier={tagIdentifier}
      />
    )
  }
  if(isLoading) {
    return <CircularProgress />
  }
  if(asset) {
    return <AssetCardHorizontal asset={asset} />
  }

  return (
    <Button
      type="button"
      style={{ width: '100%', height: '100%' }}
      onClick={onManualEntry}
    >
      Click to select an asset
    </Button>
  )
}

export default InnerAsset

import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'

import { ContactDTO } from 'api'
import AppHeader from 'components/AppHeader'
import ContactDetailModify, { Props as ContactDetailModifyProps } from 'components/ContactDetail/ContactDetailModify'
import InlineError from 'components/Errors/InlineError'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi, { IApiState } from 'hooks/useApi'

function usePatchContact(contact?: ContactDTO): IApiState<ContactDTO> {
  const body = JSON.stringify(contact)
  const url = contact ? 'v1/contacts' : undefined
  return useApi<ContactDTO>(url, undefined, {
    requestOptions: {
      method: 'PATCH',
      body,
    },
    dependencies: [body],
  })
}

interface Props extends ContactDetailModifyProps {
  contact: ContactDTO
  onCancel: () => void
  onSubmitted: (contact: ContactDTO) => void
}

export const ModifyContact = (props: Props) => {
  const {
    contact,
    onCancel,
    onSubmitted,
    ...passedProps
  } = props
  const [newContactDetails, setNewContactDetails] = useState<ContactDTO>()
  const {
    error,
    isLoading,
    data: nextModifiedContact,
  } = usePatchContact(newContactDetails)

  const handleSubmit = (contact: ContactDTO) => {
    setNewContactDetails(contact)
  }

  useEffect(() => {
    if(newContactDetails && !isLoading && !error && nextModifiedContact) {
      onSubmitted(nextModifiedContact)
    }
  }, [newContactDetails, onSubmitted, nextModifiedContact, isLoading, error])

  const body = (() => {
    if(error) {
      Sentry.captureException(error)
      return <InlineError message={error.message} />
    }
    if(isLoading) {
      return <PaddedFlexCentered><Spinner /></PaddedFlexCentered>
    }

    return (
      <ContactDetailModify
        defaultValues={contact}
        onSubmit={handleSubmit}
        {...passedProps}
        renderHeader={() => (
          <AppHeader
            actionLeft={<Button type="button" onClick={onCancel}>Cancel</Button>}
            actionRight={<Button type="submit">Save</Button>}
            title="Modify Contact"
            fixed
          />
        )}
      />
    )
  })()
  return (
    <div style={{ paddingTop: '50px' }}>
      {body}
    </div>
  )
}

export default ModifyContact

import React, { HTMLProps } from 'react'
import clsx from 'clsx'

const styles = require('./Blockquote.css')

export const Blockquote = (props: HTMLProps<HTMLElement>) => {
  const {
    className,
    children,
    ...passedProps
  } = props
  return (
    <blockquote className={clsx(className, styles.root)} {...passedProps}>
      {children}
    </blockquote>
  )
}

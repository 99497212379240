import React, { useMemo } from 'react'

import { KnownAsset, LocalImageDTO } from 'api_supplimental'
import EmptyBucket from 'components/EmptyBucket'
import JQUIList from 'components/JQUIList'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import useAssetImages from 'hooks/useAssetImages'

import { AssetMediaListItem } from './AssetMediaListItem'
import AssetMediaListItemPending from './AssetMediaListItemPending'

export interface Props {
  asset?: KnownAsset
  showDelete?: boolean
  pendingUploads?: File[]
  onDelete: (pointImage: LocalImageDTO) => void
  onDeletePending: (file: File) => void
}

export const AssetMediaList = (props: Props) => {
  const {
    asset,
    showDelete,
    pendingUploads = [],
    onDelete,
    onDeletePending,
  } = props

  const imagesOrUndef = useAssetImages(asset?.id)
  const images = imagesOrUndef || []

  const $images = useMemo(() => images.map((pointImage: LocalImageDTO, idx) => (
    <AssetMediaListItem
      pointImage={pointImage}
      onDelete={onDelete}
      showDelete={showDelete}
      key={idx}
    >
      <span>Image {idx + 1}</span>
    </AssetMediaListItem>
  )), [images, onDelete, showDelete])

  const $pending = pendingUploads.map((file: File) => {
    return (
      <AssetMediaListItemPending
        file={file}
        onDelete={onDeletePending}
        showDelete={showDelete}
        key={file.name}
      >
        <span>{file.name}</span>
      </AssetMediaListItemPending>
    )
  })
  const totalNumberOfImages = images.length + pendingUploads.length
  const $summary = totalNumberOfImages > 0 ? (
    <PaddedFlexCentered>
      <small style={{ color: 'var(--color-text-muted, #ccc)' }}><strong>{totalNumberOfImages}</strong> {`image${totalNumberOfImages > 1 ? 's' : ''}`}</small>
      {pendingUploads.length > 0 && (
        <div style={{ color: 'var(--color-text-muted, #ccc)' }}>
          <small><strong>{pendingUploads.length}</strong> {`image${pendingUploads.length > 1 ? 's' : ''} will be uploaded when this asset is saved.`}</small>
        </div>
      )}
    </PaddedFlexCentered>
  ) : (
    <PaddedFlexCentered>
      <EmptyBucket><small>This asset has no images.</small></EmptyBucket>
    </PaddedFlexCentered>
  )

  return (
    <>
      <JQUIList>
        {$pending}
        {$images}
        {$summary}
      </JQUIList>
    </>
  )
}
export default AssetMediaList

import { KnownAsset, Result } from "api_supplimental";

import Intercept from "../Intercept";
import InterceptedResponse from "../InterceptedResponse";

export const getAssetsAll = new Intercept<Result<KnownAsset>>({
  pathname: "v1/assets/all",
  fetch: async ({ entitiesDb }) => {
    const data = await entitiesDb.Assets.toCollection().sortBy("name");
    return new InterceptedResponse({ data: { data }, status: 200 });
  },
});

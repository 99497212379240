import React, { ReactNode, useCallback, useContext } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { KnownAsset, Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiAssetsContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalAssetsProvider = (props: Props) => {
  const {
    children,
  } = props
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((d: Result<KnownAsset>) => {
    return d && d.data && d.data.map(asset => {
      asset.id = asset.id.toString()
      asset.dateLastSampled = asset.dateLastSampled ? new Date(asset.dateLastSampled) : undefined
      return asset
    })
  }, [])

  const {
    fetchState,
    entities,
    table,
    isResolving,
    db,
  } = useLocalAndPopulateFromFetch<Result<KnownAsset>, KnownAsset, string>({
    url: `v1/customers/${activeCustomer.id}/assets`,
    getTable: (db) => db.Assets,
    parseResponse,
  })

  const assets = sortByProp(entities || [], ['name'])

  const value: ILocalApiAssetsContext = {
    assets,
    fetchState,
    db,
    get: async (id: string) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} blocking loadingMessage="Loading assets">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalAssetsProvider

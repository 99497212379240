import React, { ReactNode } from 'react'
import clsx from 'clsx'

const styles = require('./JQUIListItem.css')

export type Props = Omit<React.HTMLProps<HTMLLIElement>, 'ref'> & {
  iconLeft?: ReactNode
  iconRight?: ReactNode
  pre?: ReactNode
  active?: boolean
}

export type ListItemLinkProps = Props & {
  linkProps?: React.HTMLProps<HTMLAnchorElement>
}

export const JQUIListItemLink = (props: ListItemLinkProps) => {
  const {
    children,
    className,
    iconLeft,
    iconRight,
    pre,
    active,
    linkProps,
    ...passedProps
  } = props
  return (
    <li className={clsx(className, styles.root, { [styles.active]: active })} {...passedProps}>
      <a {...linkProps} className={clsx(linkProps && linkProps.className, styles.link)}>
        {pre}
        {iconLeft}
        <span className={styles.title}>
          {children}
        </span>
        {iconRight}
      </a>
    </li>
  )
}

export const JQUIListItem = ({
  children,
  className,
  iconLeft,
  iconRight,
  pre,
  active,
  ...passedProps
}: Props) => {
  const cls = clsx({
    [styles.active]: active,
  }, className, styles.root)
  return (
    <li {...passedProps} className={cls}>
      {pre}
      {iconLeft}
      <span className={styles.title}>
        {children}
      </span>
      {iconRight}
    </li>
  )
}

export default JQUIListItem

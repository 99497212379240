import React, { CSSProperties, useMemo, useRef } from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import Spinner from 'components/Spinner'
import { useImageFileDataUrl } from 'hooks/useImageFileDataUrl'
import { useImageStatus } from 'hooks/useImageStatus'

const styles = require('./Thumbnail.css')

type Props = {
  imageUrl?: string
  file?: undefined
  className?: string
  style?: CSSProperties
} | {
  file: File
  imageUrl?: undefined
  className?: string
  style?: CSSProperties
}

export const Thumbnail = (props: Props) => {
  const {
    imageUrl,
    file,
    className,
    style,
  } = props

  const imageRef = useRef<HTMLImageElement>(null)
  const [fileImageSrc, fileStatus] = useImageFileDataUrl(file)
  const imageStatus = useImageStatus(imageRef)
  const status = imageStatus || fileStatus

  const $spinner = useMemo(() => (
    <motion.span
      key="spinner"
      className={styles.center}
      initial={{ opacity: 0, scale: 0, x: '-50%', y: '-50%' }}
      animate={{ opacity: 1, scale: 1, x: '-50%', y: '-50%' }}
      exit={{ opacity: 0, scale: 0 }}
    >
      <Spinner size={22} disableShrink />
    </motion.span>
  ), [])
  const $error = useMemo(() => (
    <motion.span
      key="error"
      className={clsx(styles.center, styles.error)}
      initial={{ color: '#840008', opacity: 0, x: '-50%', y: '-50%', scale: 0 }}
      animate={{ color: '#fff', opacity: 1, x: '-50%', y: '-50%', scale: 1 }}
      exit={{ opacity: 0, x: '50%', scale: 0 }}
    >
      <FaQuestionCircle />
    </motion.span>
  ), [])
  const $img = useMemo(() => (
    <motion.img
      key="img"
      ref={imageRef}
      src={imageUrl || fileImageSrc}
      className={styles.img}
      variants={{
        loading: { opacity: 0 },
        loaded: { opacity: 1 },
        error: { opacity: 0 },
      }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    />
  ), [fileImageSrc, imageUrl])

  return (
    <motion.span
      className={clsx(className, styles.root)}
      initial={{ backgroundColor: '#fff' }}
      variants={{
        loading: { backgroundColor: '#ececec' },
        loaded: { backgroundColor: '#fff' },
        error: { backgroundColor: '#ccc' },
      }}
      animate={status}
      style={style}
    >
      <AnimatePresence>
        {status === 'loading' && $spinner}
        {status === 'failed' && $error}
        {$img}
      </AnimatePresence>
    </motion.span>
  )
}

export default Thumbnail

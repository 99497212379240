export class ExtendableError extends Error {
  statusCode?: number = undefined

  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
    if(typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

import React from 'react'

import { SampleDTO } from 'api'
import { LocalSample, Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiSamplesContext extends Omit<ILocalEntityProviderContextBase<LocalSample, Result<SampleDTO>>, 'get'> {
 samples?: LocalSample[]
 get: (id: string) => Promise<LocalSample | undefined>
 db?: Entities
 getUsersRecentSamples: () => Promise<LocalSample[] | undefined>
}

export const defaultContext: ILocalApiSamplesContext = {
  samples: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: (_id: string) => {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
  getUsersRecentSamples: () => {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
}

export default React.createContext<ILocalApiSamplesContext>(defaultContext)

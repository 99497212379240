import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { FaGripLines } from 'react-icons/fa'
import { motion } from 'framer-motion'

import { KnownAsset } from 'api_supplimental'
import AssetListItem from 'components/AssetListItem'

export type Props = {
  asset: KnownAsset
  index: number
  isEdit?: boolean
}

const IconRightMotion = () => (
  <motion.span
    initial={{ opacity: 0, x: '120%' }}
    animate={{ opacity: 1, x: 0 }}
  >
    <FaGripLines />
  </motion.span>
)

export const RouteAssetDraggable = (props: Props) => {
  const {
    asset,
    index,
    isEdit,
  } = props
  if(isEdit) {
    return (
      <Draggable draggableId={asset.id.toString()} index={index}>
        {provided => (
          <AssetListItem
            asset={asset}
            iconRight={isEdit ? <IconRightMotion /> : null}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      </Draggable>
    )
  }
  return (
    <AssetListItem asset={asset} />
  )
}

export default RouteAssetDraggable

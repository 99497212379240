import React, { FC, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { KnownAsset } from 'api_supplimental'
import ModifyAsset from 'components/Asset/Modify'

interface Props {
  customerId: string
  plantId: string
}

export const CreateAssetRoute: FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const {
    customerId,
    plantId,
  } = props
  const customerIdInt = customerId ? parseInt(customerId, 10) : undefined
  const plantIdInt = plantId ? parseInt(plantId, 10) : undefined
  const [newAsset, setNewAsset] = useState<KnownAsset>()

  const handleSubmitted = (asset: KnownAsset) => {
    setNewAsset(asset)
  }
  useEffect(() => {
    if(newAsset) {
      navigate(`/app/assets/${newAsset.id}/`)
    }
  }, [newAsset])

  return (customerIdInt && plantIdInt) ? (
    <ModifyAsset
      customerId={customerIdInt}
      plantId={plantIdInt}
      onSubmitted={handleSubmitted}
    />
  ) : (
    <div>Unknown plant or customer</div>
  )
}

export default CreateAssetRoute

import { LinkPlantAddressDTO, LinkPlantContactDTO, PlantDetailDTO } from 'api'

export { default } from './PlantDetail'

// TODO: `PlantDetailDTO.addresses` will eventually have a `type` so we can identify the location contact properly
export function getContactFromPlantRecord(plant?: PlantDetailDTO): (LinkPlantContactDTO | undefined) {
  if(plant && plant.contacts && plant.contacts.length) {
    return plant.contacts.find(link => (link.contactTypeName && link.contactTypeName.toLowerCase() === 'primary'))
  }
}

// TODO: `PlantDetailDTO.addresses` will eventually have a `type` so we can identify the plant address properly
export function getAddressFromPlantRecord(plant?: PlantDetailDTO): (LinkPlantAddressDTO | undefined) {
  if(plant && plant.addresses && plant.addresses.length) {
    return plant.addresses.find(link => (link.addressTypeName && link.addressTypeName.toLowerCase() === 'location'))
  }
}

import React, { HTMLProps } from "react";
import { FaTag } from "react-icons/fa";
import clsx from "clsx";

const styles = require("./TagIdentifier.css");

export type Props = {
  tagIdentifier: string;
} & HTMLProps<HTMLElement>;

export const TagIdentifier = ({
  tagIdentifier,
  className,
  ...passedProps
}: Props) => {
  return (
    <small className={clsx(className, styles.root)} {...passedProps}>
      <FaTag />
      <pre>
        <code>{tagIdentifier}</code>
      </pre>
    </small>
  );
};

import React, { ReactNode, useCallback, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { ContactDTO } from 'api'
import { LocalContact, Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiContactsContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalContactsProvider = (props: Props) => {
  const {
    children,
  } = props
  const parseResponse = useCallback((d: Result<ContactDTO>): LocalContact[] | undefined => {
    return d && d.data && d.data.map(contact => {
      const lc = contact as unknown as LocalContact
      if(!lc.id) {
        throw new Error('Contact has no id')
      }
      lc.id = lc.id.toString()
      return lc
    })
  }, [])
  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<ContactDTO>, LocalContact, string>({
    url: 'v1/contacts/all',
    getTable: (db) => db.Contacts,
    parseResponse,
    fetchWhenEmpty: false,
  })
  const Contacts = useMemo(() => (entities) && sortByProp(entities, ['lastName']), [entities])

  const value: ILocalApiContactsContext = {
    contacts: Contacts,
    fetchState,
    get: async (id: string) => table.get(id),
  }
  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Contacts" blocking>
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalContactsProvider

import React, { ReactNode } from 'react'
import { Switch } from '@material-ui/core'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { Intersection } from 'utility-types'
import * as Yup from 'yup'

import { NewUserDTO, UserDTO } from 'api'
import LabelledValue from 'components/LabelledValue'
import getFieldErrors, { FieldErrorsMap } from 'utils/getFieldErrors'
import { pruneObjectShallow } from 'utils/pruneObjectShallow'

const styles = require('./UserDetailModify.css')

export type UserDetailFormValues = Intersection<NewUserDTO, UserDTO>

export const modifyUserSchemaShape = {
  firstName: Yup.string()
    .max(50),
  lastName: Yup.string()
    .required('Please enter a last name')
    .max(50),
  phoneNumber: Yup.string()
    .required('Please enter a phone number')
    .test('is-valid-phone', 'Invalid phone number', function(value: string) {
      return new Promise((resolve, reject) => {
        if(!value || value.length === 0) {
          return resolve(true)
        }
        const phone = parsePhoneNumberFromString(value, 'US')
        if(phone) {
          const valid = phone.isValid()
          if(valid) {
            return resolve(true)
          }
        }
        return reject(this.createError())
      })
    }),
  email: Yup.string()
    .required('Please enter an email address')
    .email('Please enter a valid email address'),
}
const ModifyUserSchema = Yup.object().shape(modifyUserSchemaShape)

export interface Props {
  onSubmit?: (formValues: UserDetailFormValues) => void
  isLoading?: boolean
  renderHeader?: (formikBag: FormikProps<UserDetailFormValues>) => ReactNode
  renderFooter?: (formikBag: FormikProps<UserDetailFormValues>) => ReactNode
  defaultValues?: any & UserDetailFormValues
  ineditableFields?: (keyof UserDetailFormValues)[]
  fieldErrors?: Partial<FieldErrorsMap<UserDetailFormValues>>
}

export const UserDetailModify = (props: Props) => {
  const {
    onSubmit,
    defaultValues,
    ineditableFields = [],
    fieldErrors,
    renderHeader = () => null,
    renderFooter = () => null,
    isLoading,
    ...passedProps
  } = props
  const initialValues: UserDetailFormValues = Object.assign({}, {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  }, defaultValues)
  if(initialValues.phoneNumber) {
    initialValues.phoneNumber = formatIncompletePhoneNumber(initialValues.phoneNumber, 'US')
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ModifyUserSchema}
      onSubmit={(values: UserDetailFormValues) => {
        if(isLoading) {
          return
        }
        const nextValues = pruneObjectShallow(values) as UserDetailFormValues
        if(values.phoneNumber) {
          const Phone = parsePhoneNumberFromString(values.phoneNumber, 'US')
          if(Phone) {
            nextValues.phoneNumber = Phone.formatNational()
          }
        }
        onSubmit && onSubmit(nextValues)
      }}
      {...passedProps}
      render={(formikBag: FormikProps<UserDetailFormValues>) => {
        const {
          values,
        } = formikBag

        return (
          <Form className={styles.root}>
            {renderHeader(formikBag)}
            <LabelledValue
              label="First Name"
              className={styles.labelledValue}
              autoComplete="given-name"
              required
              {...getFieldErrors<UserDTO>('firstName', formikBag, fieldErrors)}
            >
              {ineditableFields.includes('firstName') ? values.firstName : (
                <Field name="firstName" type="text" disabled={isLoading} />
              )}
            </LabelledValue>
            <LabelledValue
              label="Last Name"
              className={styles.labelledValue}
              autoComplete="family-name"
              required
              {...getFieldErrors<UserDTO>('lastName', formikBag, fieldErrors)}
            >
              {ineditableFields.includes('lastName') ? values.lastName : (
                <Field name="lastName" type="text" disabled={isLoading} />
              )}
            </LabelledValue>
            <LabelledValue
              label="Phone"
              autoComplete="tel-national"
              required
              value={ineditableFields.includes('phoneNumber') ? values.phoneNumber : (
                <Field
                  name="phoneNumber"
                  render={({ field, form }: FieldProps) => (
                    <input
                      {...field}
                      name="phoneNumber"
                      type="tel"
                      placeholder="(555) 555-5555"
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        form.setFieldValue('phoneNumber', formatIncompletePhoneNumber(e.currentTarget.value, 'US'))
                        field.onBlur(e)
                      }}
                      disabled={isLoading}
                    />
                  )}
                />
              )}
              {...getFieldErrors<UserDTO>('phoneNumber', formikBag, fieldErrors)}
              className={styles.labelledValue}
            />
            <LabelledValue
              label="Email"
              autoComplete="email"
              {...getFieldErrors<UserDTO>('email', formikBag, fieldErrors)}
              className={styles.labelledValue}
            >
              {ineditableFields.includes('email') ? values.email : (
                <Field name="email" type="email" disabled={isLoading} />
              )}
            </LabelledValue>
            <LabelledValue
              label="Email Notifications"
              className={styles.labelledValue}
            >
              <Field
                name="emailNotify"
                render={({ field }: FieldProps) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    color="primary"
                    disabled={isLoading}
                  />
                )}
              />
            </LabelledValue>

            {renderFooter(formikBag)}
          </Form>
        )
      }}
    />
  )
}

export default UserDetailModify

import React from 'react'

export type Props = {
  error: Error
  statusCode?: number
  queryEntityName?: string
  queryValue?: any
}
export const InnerError = (props: Props) => {
  const {
    error,
    statusCode,
    queryEntityName,
    queryValue,
  } = props
  const message = statusCode === 404 ? (
    <span>{`${queryEntityName} `} <code>{queryValue}</code> not found.</span>
  ) : error.message
  return (
    <div style={{ padding: '0.5em' }}>{message}</div>
  )
}

export default InnerError

import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import Debug from 'debug'
import { useSnackbar } from 'notistack'

import { AssetTagLinkDTO } from 'api'
import useApi, { IApiState } from 'hooks/useApi'

import { TagAssetProps } from './DoTagAsset'

const log = Debug('AL:DoRetagAsset')

function captureFetchError(fetchState: IApiState<any>): void {
  if(fetchState.error) {
    Sentry.captureException(fetchState.error)
  }
}

export const DoRetagAsset = (props: TagAssetProps) => {
  const { asset, tagIdentifier, onSuccess } = props
  const linkPostState = useApi<AssetTagLinkDTO>('v1/asset-tag-links', undefined, {
    requestOptions: {
      method: 'POST',
      body: JSON.stringify({
        tagIdentifier,
        assetId: asset.id,
      }),
    },
  })

  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if(linkPostState.error) {
      captureFetchError(linkPostState)
      if(linkPostState.statusCode === 404) {
        const $mail = <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }} component="a" href={`mailto:ces@testoil.com?subject=${encodeURIComponent('AssetLink tag not found: ' + tagIdentifier)}`}>Report Tag #</Button>
        const $body = <span>We have no records for tag <code style={{ whiteSpace: 'pre' }}>{tagIdentifier}</code>. Use a different tag or email us.</span>
        enqueueSnackbar($body, { variant: 'error', action: $mail })
      } else {
        enqueueSnackbar(linkPostState.error.body, { variant: 'error' })
      }
    }
  }, [enqueueSnackbar, linkPostState, tagIdentifier])

  useEffect(() => {
    if(linkPostState.data) {
      log('Retag success')
      enqueueSnackbar(<span><strong>Success!</strong> Linked tag <code>{tagIdentifier}</code></span>, { variant: 'success' })
      onSuccess(linkPostState.data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkPostState.data])
  return null
}

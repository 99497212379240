import React, {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdFilterList } from "react-icons/md";
import { GroupedVirtuoso, Virtuoso } from "react-virtuoso";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import plantsContext from "contexts/LocalEntities/Plants/context";
import throttle from "lodash.throttle";

import { KnownAsset, KnownAssetWithReportStatus } from "api_supplimental";
import AssetListItem from "components/AssetListItem";
import { Props as AssetListItemProps } from "components/AssetListItem/AssetListItem";
import EmptyBucket from "components/EmptyBucket";
import JQUIList from "components/JQUIList";
import SearchInput from "components/SearchInput";
import { sortByProp } from "utils/sortByProp";

import useAssetGroups from "./useAssetGroups";
import { AssetListItemHeader } from "./AssetListItemHeader";

const styles = require("./FilterableAssetList.css");

export type Props = {
  assets?: KnownAssetWithReportStatus[];
  emptyBucket?: ReactNode;
  noMatches?: ReactNode;
  itemProps?: Partial<AssetListItemProps>;
  filterAssistiveText?: ReactNode;
};

export type TAssetWithCustomerName = KnownAssetWithReportStatus & {
  customerName?: string;
};

type ListContainerProps = {
  listRef: Ref<any>;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};
const ListContainer = ({
  listRef,
  style,
  ...passedProps
}: ListContainerProps) => (
  <JQUIList
    ref={listRef}
    style={{ ...style, marginBottom: 0 }}
    {...passedProps}
  />
);

export const FilterableAssetList = (props: Props) => {
  const {
    assets = [],
    emptyBucket = (
      <EmptyBucket>
        <small>No assets to display</small>
      </EmptyBucket>
    ),
    noMatches = (
      <EmptyBucket>
        <small>No assets match your filter</small>
      </EmptyBucket>
    ),
    itemProps = {},
    filterAssistiveText,
  } = props;
  const [filterInputValue, setFilterInputValue] = useState("");
  const [filter, setFilter] = useState("");
  const { plants } = useContext(plantsContext);

  const throttledSetFilter = useRef(
    throttle(
      (v: string) => {
        setFilter(v.trim().toLowerCase());
      },
      500,
      { leading: false, trailing: true }
    )
  );

  const assetsWithCustomers = useMemo<TAssetWithCustomerName[]>(() => {
    if (!plants || plants.length === 0) {
      return assets;
    }
    return assets.map((asset) => {
      const plant = plants?.find((p) => p.id === asset.plantId);
      if (!plant) {
        return asset;
      }
      return {
        ...asset,
        customerName: plant.customerName,
      };
    });
  }, [assets, plants]);

  const numOfCustomersInUnfilteredAssets: number = useMemo(() => {
    if (!plants || plants.length === 0) {
      return 0;
    }
    const ret = new Set();
    assets.forEach((a) => {
      const plant = plants.find((p) => p.id === a.plantId);
      if (plant) {
        ret.add(plant.customerId);
      }
    });
    return ret.size;
  }, [assets, plants]);

  const handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterInputValue(e.target.value);
    throttledSetFilter.current(e.currentTarget.value);
  };

  const filteredAssets = useMemo<TAssetWithCustomerName[]>(() => {
    if (filter.length === 0) {
      return assetsWithCustomers;
    }
    return assetsWithCustomers.filter((a) => {
      if (a.name.toLowerCase().indexOf(filter) > -1) return true;
      if (a.tagIdentifier && a.tagIdentifier.toLowerCase().indexOf(filter) > -1)
        return true;
      if (
        a.customerEquipmentId &&
        a.customerEquipmentId.toLowerCase().indexOf(filter) > -1
      )
        return true;
    });
  }, [assetsWithCustomers, filter]);
  const sortedAssets = sortByProp(filteredAssets, [
    "name",
    "plantName",
    "customerName",
  ]);

  const { groupCounts, groupPlantIds } = useAssetGroups(sortedAssets);

  const $filterAssistiveText = filterAssistiveText ? (
    <Box fontSize="small" color="text.hint" textAlign="center" py="0.25em">
      {filterAssistiveText}
    </Box>
  ) : null;

  const getGroupHeader = useCallback(
    (groupIndex: number) => {
      const plantId = groupPlantIds[groupIndex];
      const plant = plants?.find((p) => p.id === plantId);
      return (
        <Box p={1} bgcolor="grey.300" key={groupIndex}>
          <Typography variant="caption">
            {plant
              ? numOfCustomersInUnfilteredAssets > 1
                ? `${plant.customerName} - ${plant.name}`
                : plant.name
              : `Plant #${plantId}`}
          </Typography>
        </Box>
      );
    },
    [groupPlantIds, numOfCustomersInUnfilteredAssets, plants]
  );

  const $results = useMemo(() => {
    if (filter.length > 0 || groupPlantIds.length > 1) {
      return (
        <GroupedVirtuoso
          groupCounts={groupCounts}
          group={getGroupHeader}
          item={(index) => {
            const asset = sortedAssets[index];
            if (!asset) {
              return <div>?</div>;
            }
            return <AssetListItem asset={asset} key={index} {...itemProps} />;
            // return null;
          }}
        />
      );
    }
    return (
      <Virtuoso
        ListContainer={ListContainer}
        totalCount={filteredAssets.length}
        overscan={10}
        item={(index) => {
          const asset = filteredAssets[index];
          return <AssetListItem asset={asset} key={index} {...itemProps} />;
        }}
      />
    );
  }, [
    filter.length,
    filteredAssets,
    getGroupHeader,
    groupCounts,
    groupPlantIds.length,
    itemProps,
    sortedAssets,
  ]);

  return (
    <div className={styles.root}>
      <Box
        py="0.5rem"
        px="15px"
        className={clsx(styles.filterContainer, {
          [styles.sticky]: filter.length > 0,
        })}
      >
        <SearchInput
          value={filterInputValue}
          onChange={handleFilterInputChange}
          icon={<MdFilterList />}
          placeholder="Name, tag #, or equipment #"
        />
        {$filterAssistiveText}
      </Box>
      {/* <AssetListItemHeader /> */}
      {!assets.length || !sortedAssets.length
        ? assets.length === 0
          ? emptyBucket
          : sortedAssets.length === 0
          ? noMatches
          : null
        : null}
      {$results}
    </div>
  );
};

export default FilterableAssetList;

import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTag, FaVial, FaWineGlassAlt } from "react-icons/fa";
import { CircularProgress, ListItem, ListItemText } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GatsbyLinkProps, Link } from "gatsby";
import prettyMs from "pretty-ms";

import { KnownAsset, KnownAssetWithReportStatus } from "api_supplimental";
import useAssetImages from "hooks/useAssetImages";
import { assetIsRecentlySampled } from "utils/asset-utils";
import { getBlobImageUrl } from "utils/remote-image-utils";

import IconPETE from "!!svg-react-loader!./img/Resin-identification-code-1-PETE.svg"; //eslint-disable-line
import styled from "@emotion/styled";
import useApiPrefix from "hooks/useApiPrefix";
import { fileNameContentDispositionRegex } from "utils/regex-library";
import { AssetStatusIndicator } from "components/Asset/AssetStatusIndicator";
import { Condition } from "../../types/api_supplimental.d";
import { AssetListItemLayout } from "components/Assets/AssetListItemLayout";

export type Props = Omit<GatsbyLinkProps<any>, "to" | "ref" | "onClick"> &
  Omit<
    ListItemProps<"a">,
    "dense" | "component" | "button" | "className" | "onClick"
  > & {
    asset: KnownAssetWithReportStatus;
    iconRight?: ReactNode;
    onClick?: (e: React.MouseEvent, asset: KnownAsset) => void;
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: "flex",
      color: theme.palette.text.primary,
      // padding: 0,
      // paddingRight: theme.spacing(1),
      // lineHeight: 1,
      // borderTop: `1px solid ${theme.palette.divider}`,
    },
    thumbnail: {
      flex: "0 0 100%",
      height: "75px",
      alignSelf: "stretch",
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginRight: theme.spacing(1),
    },
    button: {
      backgroundColor: theme.palette.primary.main,
    },
    icons: {
      minWidth: "40px",
      display: "inline-block",
      "& svg": {
        marginLeft: theme.spacing(1 / 2),
      },
    },
  })
);

export const AssetListItem: FC<Props> = (props: Props) => {
  const { asset, iconRight, onClick, ...passedProps } = props;
  const assetId = asset.id;
  const styles = useStyles({ hasTag: asset.hasTag });

  const images = useAssetImages(assetId);

  const handleClick = (e: any): void => {
    if (typeof onClick === "function") {
      onClick(e, asset);
    }
  };
  const $bottleType = useMemo(() => {
    if (asset.fluid) {
      if (asset.fluid.bottleType === "pete") {
        return <IconPETE height="16px" width="16px" />;
      }
      if (asset.fluid.bottleType === "glass") {
        return <FaWineGlassAlt />;
      }
    }
  }, [asset.fluid]);

  const [reportDownloadStatus, setReportDownloadStatus] = useState<{
    loading: boolean;
  }>({ loading: false });

  const apiPrefix = useApiPrefix();
  const downloadReport = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      const endpoint = `${apiPrefix}v1/samples/Report/Download/FromLabID/${asset.customerId}/${asset.lastSampleId}`;
      // const endpoint = `${apiPrefix}/v1/assets/LatestReport/Download/${asset.id}`;
      setReportDownloadStatus({ loading: true });
      fetch(endpoint)
        .then((res) => {
          if (!res.ok)
            return Promise.reject(`${res.status}: ${res.statusText}`);

          const contentDisposition = res.headers.get("content-disposition")!;
          const contentDispositionMatch =
            fileNameContentDispositionRegex.exec(contentDisposition)!;

          return new Promise<{ fileName: string; data: Blob }>(
            (resolve, reject) => {
              res
                .blob()
                .then((blob) => {
                  resolve({
                    fileName:
                      contentDispositionMatch?.groups?.filename ??
                      `${assetId}.pdf`,
                    data: blob,
                  });
                })
                .catch((err) => reject(err));
            }
          );
        })
        .then((fileData) => {
          const url = window.URL.createObjectURL(fileData.data);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `${fileData.fileName}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {})
        .finally(() => {
          setReportDownloadStatus({ loading: false });
        });
    },
    [asset, apiPrefix]
  );

  const downloadAnchor = useRef<HTMLAnchorElement>(null);
  const downloadTextSpan = useRef<HTMLSpanElement>(null);
  const fontSize = Number(downloadTextSpan.current?.style?.fontSize || 16);
  const spinnerMargin =
    fontSize * (downloadTextSpan.current?.textContent?.length || 0);
  const $reportDownloadButton = useMemo(() => {
    if (!asset.reportAvailable || !asset.customerId || !asset.lastSampleId)
      return null;

    return (
      <DownloadButtonContainer>
        <a
          ref={downloadAnchor}
          href={""}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <CenteredDiv>
            <CircularProgress
              style={{
                width: fontSize,
                height: fontSize,
                margin: `0 ${spinnerMargin}`,
                display: reportDownloadStatus.loading ? "block" : "none",
                position: "absolute",
                left: "50%",
                translate: "-50%",
              }}
            />
            <span
              style={{
                visibility: reportDownloadStatus.loading ? "hidden" : "visible",
              }}
              ref={downloadTextSpan}
            >
              Latest Report
            </span>
          </CenteredDiv>
        </a>
      </DownloadButtonContainer>
    );
  }, [asset, reportDownloadStatus]);
  const $icons = useMemo(() => {
    if (iconRight) {
      return <span className={styles.icons}>{iconRight}</span>;
    }
    return (
      <span className={styles.icons}>
        {assetIsRecentlySampled(asset) && <FaVial />}
        {$bottleType}
        {asset.hasTag && <FaTag />}
      </span>
    );
  }, [$bottleType, asset, iconRight, styles.icons]);

  const ForwardedLink = React.forwardRef<
    HTMLAnchorElement,
    Omit<GatsbyLinkProps<any>, "ref">
  >((itemProps, ref) => (
    <Link innerRef={ref} {...itemProps} onClick={handleClick} />
  ));

  const sampledAgo = useMemo(() => {
    const lastSampleDate =
      typeof asset.sampledAt !== "undefined"
        ? new Date(asset.sampledAt)
        : undefined;

    const dateLastSampled = asset.dateLastSampled
      ? new Date(asset.dateLastSampled!)
      : undefined;

    if (dateLastSampled || lastSampleDate) {
      const mostRecentDate = [dateLastSampled, lastSampleDate].sort((a, b) =>
        !b ? -1 : !a ? 1 : b.getTime() - a.getTime()
      )[0]!;

      return prettyMs(new Date().getTime() - mostRecentDate.getTime(), {
        compact: true,
      });
    }
  }, [asset.dateLastSampled]);

  const $thumbnail = useMemo(() => {
    const imageUrl =
      images &&
      images.length > 0 &&
      images[0].imagePath &&
      getBlobImageUrl(images[0].imagePath, "tn");
    if (imageUrl) {
      return (
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(\"${imageUrl}\")`,
          }}
        />
      );
    }
    return <div />;
  }, [images, styles.thumbnail]);

  return (
    <ListItem
      {...passedProps}
      onClick={handleClick}
      dense
      component={ForwardedLink}
      to={`/app/assets/${assetId}`}
      className={styles.root}
    >
      <AssetListItemLayout
        thumbnailSection={$thumbnail}
        assetDescriptionSection={
          <ListItemText
            primary={asset.name}
            secondary={
              sampledAgo ? `Last sampled ${sampledAgo} ago` : "Never sampled"
            }
          />
        }
        reportSection={
          <InlineDiv
            onClick={
              reportDownloadStatus.loading
                ? (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                : downloadReport
            }
            onMouseDown={() => {
              downloadAnchor.current?.focus();
            }}
            onMouseUp={() => {
              downloadAnchor.current?.blur();
            }}
          >
            {$reportDownloadButton ?? undefined}
            {!(
              typeof asset.machineCondition === "undefined" &&
              typeof asset.lubricantCondition === "undefined"
            ) ? (
              <AssetStatusIndicator
                lubricantCondition={
                  asset.lubricantCondition ?? Condition.Unknown
                }
                machineCondition={asset.machineCondition ?? Condition.Unknown}
              />
            ) : (
              <StatusIndicatorPlaceholder />
            )}
          </InlineDiv>
        }
        iconSection={$icons}
      />
    </ListItem>
  );
};

export default AssetListItem;

const DownloadButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CenteredDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const InlineDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 10px;
`;

const StatusIndicatorPlaceholder = styled.div`
  width: 80px;
`;

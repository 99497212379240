import React, { ReactNode, useContext, useMemo } from 'react'
import { FaBuilding, FaIndustry, FaRoute, FaStoreAlt, FaUser } from 'react-icons/fa'
import { AuthorizationLevelNames, AuthorizationLevels } from 'app_constants'
import clsx from 'clsx'
import currentUserContext from 'contexts/CurrentUser/context'

import { AuthorizationDTO } from 'api'
import { JQUIListItem } from 'components/JQUIList'

import RoleTag from './RoleTag'
import { useAuthorizationEntity } from './useAuthorizationEntity'

const styles = require('./Authorization.css')

interface Props {
  authorization: AuthorizationDTO
  children?: ReactNode
}

const AuthLevelIcons = {
  [AuthorizationLevels.Company]: FaBuilding,
  [AuthorizationLevels.Customer]: FaStoreAlt,
  [AuthorizationLevels.Plant]: FaIndustry,
  [AuthorizationLevels.Route]: FaRoute,
  [AuthorizationLevels.User]: FaUser,
}

export const Authorization = (props: Props) => {
  const {
    authorization,
    children,
  } = props
  const { currentUser } = useContext(currentUserContext)
  const {
    authorizationLevelId,
    entityId,
    roleId,
    parentAuthorizationLogicalId,
  } = authorization
  const entity = useAuthorizationEntity(authorization)

  const iconLeft = useMemo(() => {
    if(currentUser && currentUser.id && authorizationLevelId === AuthorizationLevels.User && entityId === currentUser.id) {
      return <span className={styles.iconLeft}>🧙‍♂️</span>
    }
    const IconLeft = (authorizationLevelId && AuthLevelIcons[authorizationLevelId]) ? AuthLevelIcons[authorizationLevelId] : null
    return <span className={styles.iconLeft}>{IconLeft && <IconLeft />}</span>
  }, [authorizationLevelId, currentUser, entityId])

  const title = useMemo<string>(() => {
    if(currentUser && currentUser.id && authorizationLevelId === AuthorizationLevels.User && entityId === currentUser.id) {
      return 'Pinball Wizard ⚡️'
    }
    if(entity && entity.name) return entity.name
    if(authorizationLevelId) {
      return `${AuthorizationLevelNames[authorizationLevelId]} #${entityId}`
    }
    return `Entity #${entityId}`
  }, [authorizationLevelId, currentUser, entity, entityId])

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.info(authorization)
  }

  return (
    <JQUIListItem className={clsx(styles.root, { [styles.hasChildren]: !!children })}>
      <div className={styles.contentWrapper} onClick={handleClick}>
        {iconLeft}
        {title}
        {!parentAuthorizationLogicalId ? <RoleTag roleCode={roleId} className={styles.roleLabel} /> : null}
      </div>
      {children}
    </JQUIListItem>
  )
}
export default Authorization

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { AddressDTO } from 'api'

const log = Debug('AL:intercept:patchAddresses')

export const patchAddresses = new Intercept<AddressDTO>({
  method: 'PATCH',
  pathname: 'v1/addresses/:addressId',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      networkState,
      fetcher,
    } = fetchBag
    if(networkState.online) {
      return fetcher(req, opts)
    }
    return new InterceptedResponse({ status: 501 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local address', parsedResponse)
      if(!parsedResponse.id) {
        throw new Error('No address ID provided')
      }
      await entitiesDb.Addresses.put(parsedResponse)
    }
  },
})

import React from 'react'

import { AddressDTO } from 'api'
import LabelledValue from 'components/LabelledValue'

const styles = require('./Address.css')

type Props = {
  address: AddressDTO
}

export const Address = (props: Props) => {
  const {
    address: {
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
    },
  } = props
  return (
    <LabelledValue label="Address" className={styles.root}>
      <p className="h-adr">
        {addressLine1 && <span className="p-street-address">{addressLine1}</span>}
        {addressLine2 && <span className="p-extended-address">{addressLine2}</span>}
        {city && <span className="p-locality">{city}</span>}
        {state && <span className="p-region">{state}</span>}
        {postalCode && <span className="p-postal-code">{postalCode}</span>}
      </p>
    </LabelledValue>
  )
}

export default Address

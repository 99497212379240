import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { LinkPlantContactDTO, PlantDetailDTO } from 'api'

const log = Debug('AL:intercept:patchPlantContactLinks')

export const patchPlantContactLinks = new Intercept<LinkPlantContactDTO>({
  method: 'PATCH',
  pathname: 'v1/plant-contact-links/:linkId',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      networkState,
      fetcher,
    } = fetchBag
    if(networkState.online) {
      return fetcher(req, opts)
    }
    return new InterceptedResponse({ status: 501 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local plant contact links', parsedResponse)
      const { contactId, plantId } = parsedResponse
      if(!contactId) {
        log('No contactId found in LinkPlantContactDTO')
        throw new Error('No contactId found in LinkPlantContactDTO')
      }
      if(!plantId) {
        log('No plantId found in LinkPlantContactDTO')
        throw new Error('No plantId found in LinkPlantContactDTO')
      }
      log('Saving link plant contacts')
      const updatedValues: Pick<PlantDetailDTO, 'contacts'> = {
        contacts: [parsedResponse],
      }
      await entitiesDb.PlantDetails.update(plantId, updatedValues)
    }
  },
})

import React from 'react'
import { MdCenterFocusStrong } from 'react-icons/md'
import { Button } from '@material-ui/core'

import { KnownAsset } from 'api_supplimental'
import LabelledValue from 'components/LabelledValue'

const styles = require('./AssetTagLabelledValueWithTagButton.css')

export type Props = {
  asset?: KnownAsset
  onTagAssetClick: () => void
}
export const AssetTagLabelledValueWithTagButton = (props: Props) => {
  const {
    asset,
    onTagAssetClick,
  } = props
  const tagIdentifier = asset && asset.tagIdentifier
  if(tagIdentifier) {
    return (
      <div className={styles.root}>
        <LabelledValue label="Tag #" className={styles.labelledValue}>
          {tagIdentifier}
        </LabelledValue>
        <Button color="primary" variant="contained" type="button" onClick={onTagAssetClick}>
          Replace Tag
        </Button>
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <Button color="primary" variant="contained" type="button" onClick={onTagAssetClick}>
        <MdCenterFocusStrong /> Tag this Asset
      </Button>
    </div>
  )
}

export default AssetTagLabelledValueWithTagButton

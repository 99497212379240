/* eslint-disable quote-props */
/* eslint-disable filenames/match-exported */
export const BOTTLE_STATUS = {
  Unknown: -1,
  Created: 1,
  ShippedToCustomer: 2,
  Reserved: 3,
  Linked: 4,
  ShippedToLab: 5,
  ReceivedByLab: 6,
  BatchedReceivedByLab: 7,
  BatchReceiveIssue: 8,
  Testing: 9,
  TestingCompleted: 10,
  CompletedWithIssue: 11,
  Completed: 12,
}

export type BottleStatusType = keyof typeof BOTTLE_STATUS

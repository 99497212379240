import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

import useUploadImage from './useUploadImage'

export interface Props {
  assetId?: string
  file: File
  onComplete: (file: File) => void
}

export const DoImageUpload = (props: Props): null => {
  const {
    assetId,
    file,
    onComplete,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const fetchState = useUploadImage(assetId, file)

  useEffect(() => {
    if(fetchState.statusCode === 201) {
      onComplete(file)
    }
  }, [fetchState, file, onComplete])

  useEffect(() => {
    if(fetchState.error) {
      console.error(fetchState.error)
      enqueueSnackbar(`Error: ${fetchState.error.message}`, { variant: 'error' })
    }
  }, [enqueueSnackbar, fetchState.error])

  return null
}

export default DoImageUpload

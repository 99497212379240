import { useMemo } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'

import useMediaDevices from 'hooks/useMediaDevices'

export type IDeviceChooser = (filteredDevices: MediaDeviceInfo[], videoDevices: MediaDeviceInfo[], facingMode: VideoFacingModeEnum) => MediaDeviceInfo

export function defaultDeviceChooser(filteredDevices: MediaDeviceInfo[], videoDevices: MediaDeviceInfo[]): MediaDeviceInfo {
  // Filtered devices will be empty if a feed is not active
  if(filteredDevices.length > 0) {
    return filteredDevices[0]
  }
  return videoDevices[0]
}

export type TUseVideoDevicesState = {
  activeDevice?: MediaDeviceInfo
  selectedVideoDeviceId?: string
  setSelectedVideoDeviceId: (deviceId: string) => void
  availableDevices?: MediaDeviceInfo[]
}

export const useVideoDevices = (): TUseVideoDevicesState => {
  const { devices } = useMediaDevices() as { devices?: MediaDeviceInfo[] }
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useLocalStorage<string>('selectedVideoDeviceId')

  const facingModePattern = /rear|back|environment/ig

  const availableDevices = useMemo(() => {
    if(!devices) return
    // Filter out non-videoinputs
    return devices.filter(device => device.kind === 'videoinput')
  }, [devices])

  const activeDevice = useMemo(() => {
    if(!availableDevices || availableDevices.length === 0) {
      return undefined
    }
    if(selectedVideoDeviceId) {
      const selectedDevice = availableDevices.find(dev => dev.deviceId === selectedVideoDeviceId)
      if(selectedDevice) {
        return selectedDevice
      }
      console.warn(`Could not find selected device with id ${selectedVideoDeviceId}. Devices:`, devices)
    }
    // take a stab at selecting a device ourselves
    const filteredDevices = availableDevices.filter(({ label }) => facingModePattern.test(label))
    if(filteredDevices.length > 0) {
      return filteredDevices[0]
    }
    return availableDevices[0]
  }, [availableDevices, devices, facingModePattern, selectedVideoDeviceId])

  return {
    activeDevice,
    selectedVideoDeviceId,
    setSelectedVideoDeviceId,
    availableDevices,
  }
}

export default useVideoDevices

/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from "react";
import throttle from "lodash.throttle";

export const useThrottleFn = <T extends (...args: any) => any>(
  fn: T,
  ms = 1000,
  throttleSettings = { leading: true, trailing: false }
) => {
  const throttledFn = useMemo(() => {
    return throttle(fn, ms, throttleSettings);
  }, [fn, ms, throttleSettings]);
  useEffect(() => {
    return () => {
      throttledFn.cancel();
    };
  }, [throttledFn]);
  return throttledFn;
};

import React, { ReactNode, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { AssetType } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalAssetTypesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalAssetTypesProvider = (props: Props) => {
  const {
    children,
  } = props
  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<AssetType>, AssetType>({
    url: 'v1/assets/types/all',
    getTable: (db) => db.AssetTypes,
    parseResponse: (d) => d && d.data,
  })
  const assetTypes = useMemo(() => (entities) && sortByProp(entities, ['name']), [entities])

  const value: ILocalAssetTypesContext = {
    assetTypes,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Asset Types">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalAssetTypesProvider

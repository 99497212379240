import React from 'react'

import { AssetManufacturer } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalAssetManufacturersContext extends ILocalEntityProviderContextBase<AssetManufacturer, Result<AssetManufacturer>> {
 manufacturers?: AssetManufacturer[]
}

export const defaultContext: ILocalAssetManufacturersContext = {
  manufacturers: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalAssetManufacturersContext>(defaultContext)

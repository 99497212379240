import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { AssetTagLinkDTO } from 'api'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:intercept:patchAssetTagLink')

export const patchAssetTagLink = new Intercept<AssetTagLinkDTO>({
  method: 'PATCH',
  pathname: 'v1/asset-tag-links/:tagId',
  fetch: async (bag) => {
    const {
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 })
    }
    if(networkState.online) {
      log('Not intercepting')
      return fetcher(req, opts)
    }
    log('Intercepting')
    const body: AssetTagLinkDTO = JSON.parse(opts.body as string)
    /*
    const {
      assetId,
      tagIdentifier,
      active,
    } = body
    if(!assetId) {
      log('No assetId found in AssetTagLinkDTO')
      throw new Error('No assetId found in AssetTagLinkDTO')
    }
    if(!tagIdentifier) {
      log('No tagIdentifier found in AssetTagLinkDTO')
      throw new Error('No tagIdentifier found in AssetTagLinkDTO')
    }
    if(active) {
      log('Updating asset tag')
      await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier, hasTag: true })
    } else {
      log('Removing tag from old asset')
      await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier: undefined, hasTag: false })
    }
    */
    const data = body

    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    const txid = await tx.Queue.add(new Transaction(req, opts))
    log(`Transaction ${txid} queued`)
    return new InterceptedResponse({ data, status: 201 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local asset tag', parsedResponse)
      const {
        assetId,
        tagIdentifier,
        active,
      } = parsedResponse
      if(!assetId) {
        log('No assetId found in AssetTagLinkDTO')
        throw new Error('No assetId found in AssetTagLinkDTO')
      }
      if(active) {
        log('Updating asset tag')
        await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier, hasTag: true })
      } else {
        log('Removing tag from old asset')
        await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier: undefined, hasTag: false })
      }
    }
  },
})

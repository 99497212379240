import React, { Context } from 'react'

import { CustomerDTO } from 'api'

export type ActiveCustomerContext = {
  activeCustomer: CustomerDTO
  setActiveCustomer: (customer?: CustomerDTO) => void
  // Passed thru from LocalCustomersContext
  customers: CustomerDTO[]
}

export default React.createContext<ActiveCustomerContext | null>(null) as Context<ActiveCustomerContext>

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'

import { PlantDetailDTO } from 'api'

export const getPlantDetail = new Intercept<PlantDetailDTO>({
  pathname: 'v1/plants/:plantId/details',
  fetch: async ({ entitiesDb, match }) => {
    const plantId = match && match.params && match.params.plantId
    if(!plantId) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.PlantDetails.get(Number(plantId))
    return new InterceptedResponse({ data, status: 200 })
  },
})

import { useEffect } from 'react'

export type Props = {
  pattern?: number[]
}

export const Vibrate = ({ pattern = [20] }: Props) => {
  useEffect(() => {
    try {
      if(navigator && navigator.vibrate) {
        navigator.vibrate(pattern)
      }
    } catch(err) {
      console.warn('Buzzkilled.')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

import { MutableRefObject, useEffect, useState } from 'react'

export type ImageLoadingStatus = 'loading' | 'loaded' | 'failed'

export const useImageStatus = (imgRef: MutableRefObject<HTMLImageElement | null>): ImageLoadingStatus | undefined => {
  const [status, setStatus] = useState<ImageLoadingStatus>()

  useEffect(() => {
    if(!imgRef || !imgRef.current) return
    setStatus('loading')

    const onLoaded = () => {
      setStatus('loaded')
    }

    const onError = () => {
      setStatus('failed')
    }

    const img = imgRef.current

    if(img.complete) {
      setStatus('loaded')
    }

    img.addEventListener('load', onLoaded)
    img.addEventListener('error', onError)

    return function cleanup() {
      img.removeEventListener('load', onLoaded)
      img.removeEventListener('error', onError)
      setStatus(undefined)
    }
  }, [imgRef])
  return status
}

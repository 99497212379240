import { LocalSample } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'
import { updateAssetSampleHistory } from '../updateAssetSampleHistory'

export const patchSample = new Intercept<LocalSample>({
  method: 'PATCH',
  pathname: 'v1/samples/:identifier',
  fetch: async (bag) => {
    const {
      match,
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!(opts && opts.body && match && match.params && match.params.identifier)) {
      return new InterceptedResponse({ status: 400 })
    }
    const body = JSON.parse(opts.body as string)
    const newSample: Partial<LocalSample> = {
      identifier: match.params.identifier,
      ...body,
    }
    if(!newSample.assetId) {
      throw new Error('No assetId supplied for sample')
    }
    if(networkState.online) {
      return fetcher(req, opts)
    }
    if(tx) {
      await tx.Queue.add(new Transaction(req, opts))
    }
    return new InterceptedResponse({ data: newSample, status: 200 })
  },
  postParse: async bag => {
    const {
      entitiesDb,
      parsedResponse,
    } = bag
    if(parsedResponse) {
      const {
        assetId,
      } = parsedResponse
      if(parsedResponse.sampledAt) {
        parsedResponse.sampledAt = new Date(parsedResponse.sampledAt)
      }
      await entitiesDb.Samples.put(parsedResponse)
      if(!assetId) {
        throw new Error('Sample requires an asset ID')
      }
      const asset = await entitiesDb.Assets.get(assetId.toString())
      if(!asset) {
        throw new Error(`No asset found with ID ${assetId}`)
      }
      await entitiesDb.Assets.update(asset.id, { sampleSourceRating: parsedResponse.sampleSourceRating })
      updateAssetSampleHistory(entitiesDb, assetId.toString())
    }
  },
})

import { PickByValue } from 'utility-types'

import { AddressDTO } from 'api'

type AddressComponentTypeName = 'street_number' | 'route' | 'locality' | 'administrative_area_level_1' | 'country' | 'postal_code'
type SkinnyAddressDTO = Omit<AddressDTO, 'id' | 'addressLine2' | 'latitude' | 'longitude'>

export const geocoderResultToAddressDTO = (result: google.maps.GeocoderResult): Partial<AddressDTO> | undefined => {
  const partsMap: Record<keyof SkinnyAddressDTO, AddressComponentTypeName | AddressComponentTypeName[]> = {
    addressLine1: ['street_number', 'route'],
    city: 'locality',
    state: 'administrative_area_level_1',
    country: 'country',
    postalCode: 'postal_code',
  }
  const address: Partial<AddressDTO> = Object.entries(partsMap).reduce((acc, [k, v]) => {
    if(!v) return acc
    const parts: AddressComponentTypeName[] = Array.isArray(v) ? v : [v]
    const longNames = ['state', 'city']
    const compound = parts.map(part => findAddressComponentByTypeName(result.address_components, part, longNames.includes(k) ? 'long_name' : undefined)).join(' ')
    if(compound) {
      acc[k as keyof SkinnyAddressDTO] = compound
    }
    return acc
  }, {} as Record<keyof SkinnyAddressDTO, string>)
  return address
}

function findAddressComponentByTypeName(
  components: google.maps.GeocoderAddressComponent[],
  typeName: AddressComponentTypeName,
  nameProp: keyof PickByValue<google.maps.GeocoderAddressComponent, string> = 'short_name'
): string | void {
  const component = components.find(c => c.types.includes(typeName))
  if(component) {
    return component[nameProp]
  }
}

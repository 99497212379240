import {
  AssetDTO,
  ContactDTO,
  ImageDTO,
  SampleDTO,
  SampleLink,
  TestPackageDTO,
  SampleDataPreview,
} from "./api";

export interface FreeformAsset extends Omit<AssetDTO, "id"> {
  id: undefined;
}

export interface KnownAsset extends Omit<AssetDTO, "id" | "dateLastSampled"> {
  id: string;
  dateLastSampled?: Date;
  images?: ImageDTO[];
}

export type SampleWithReportStatus = SampleDTO &
  SampleDataPreview & {
    reportAvailable: boolean;
  };

export type KnownAssetWithReportStatus = KnownAsset &
  Omit<SampleDataPreview, "labId"> & {
    reportAvailable?: boolean;
    lastSampleId?: number | undefined;
  };

export interface LocalContact extends Omit<ContactDTO, "id"> {
  id: string;
}

export interface LocalSample extends Omit<SampleDTO, "sampledAt"> {
  sampledAt?: Date;
}

export type CustomerTestPackage = TestPackageDTO & {
  customerId: number;
};

export type Result<T> = {
  data?: T[];
};

export type SampleLinkSansStatus = Omit<SampleLink, "bottleStatus">;

export interface LocalImageDTO
  extends Omit<ImageDTO, "guid" | "id" | "assetId"> {
  assetId: string;
  guid: string;
  id?: string;
}

export enum Condition {
  InProgress = -1,
  Unknown = 0,
  Normal = 1,
  Marginal = 2,
  Critical = 3,
  AtRisk = 4,
}

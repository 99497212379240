import { TAssetWithCustomerName } from './FilterableAssetList'

type TGroupData = { groupCounts: number[], groupPlantIds: number[] }

export default function useAssetGroups(assets: TAssetWithCustomerName[]): TGroupData {
  if(!assets || assets.length === 0) {
    return {
      groupCounts: [0],
      groupPlantIds: [-1],
    }
  }

  const groups = assets.reduce((acc, asset) => {
    const { plantId } = asset
    if(plantId) {
      if(!acc.has(plantId)) {
        acc.set(plantId, [asset])
      }
      const existing = acc.get(plantId) || []
      acc.set(plantId, [...existing, asset])
    }
    return acc
  }, new Map<number, TAssetWithCustomerName[]>())

  return {
    groupCounts: Array.from(groups.values()).map(members => (members.length - 1)),
    groupPlantIds: Array.from(groups.keys()),
  }
}

import React, { FormEvent, useEffect, useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import { Badge, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { KnownAsset, LocalImageDTO } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItem from 'components/JQUIList/JQUIListItem'
import Modal from 'components/Modal'
import OnlineOnly from 'components/OnlineOnly'
import TitledSection from 'components/TitledSection'
import { pruneObjectShallow } from 'utils/pruneObjectShallow'

import AssetMedia from './Media/AssetMedia'
import AssetModifyErrorBoundary from './AssetModifyErrorBoundary'
import DoImageDelete from './DoImageDelete'
import DoImageUpload from './DoImageUpload'
import ModifyAssetForm, { AssetDetailFormValues } from './ModifyAssetForm'
import useCreateAsset from './useCreateAsset'
import usePatchAsset from './usePatchAsset'

type PropsCommon = {
  onSubmitted: (asset: KnownAsset) => void
  onCancel?: () => void
  onImagesChanged?: () => void
}

export interface PropsModify extends PropsCommon {
  asset: KnownAsset
  customerId?: never
  plantId?: never
}

export interface PropsCreate extends PropsCommon {
  asset?: never
  customerId: number
  plantId: number
}

export type Props = PropsCreate | PropsModify

export const ModifyAsset = (props: Props) => {
  const {
    asset,
    customerId,
    plantId,
    onCancel,
    onSubmitted,
    onImagesChanged,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const [showMediaModal, setShowMediaModal] = useState<boolean>(false)
  const [assetToUpdate, setAssetToUpdate] = useState<AssetDetailFormValues>()
  // Image files to upload (waits until form is submitted and asset is created)
  const [pendingUploads, setPendingUploads] = useState<File[]>([])
  const [activeUploads, setActiveUploads] = useState<File[]>([])
  const [completedUploads, setCompletedUploads] = useState<File[]>([])
  const [pendingDeletes, setPendingDeletes] = useState<LocalImageDTO[]>([])
  const [deleted, setDeleted] = useState<LocalImageDTO[]>([])

  const patchFetchState = usePatchAsset(asset, assetToUpdate)
  const createFetchState = useCreateAsset(assetToUpdate, customerId, plantId)

  const assetAvailableForUploads: boolean = !!assetToUpdate || createFetchState.statusCode === 201
  const modifiedAsset = patchFetchState.data || createFetchState.data
  const assetId = (asset && asset.id) || (modifiedAsset && modifiedAsset.id)
  const isLoading = patchFetchState.isLoading || createFetchState.isLoading

  const handleMediaClick = () => {
    setShowMediaModal(true)
  }

  function removePendingFile(file: File) {
    const nextPendingUploads = pendingUploads.filter(f => getFileKey(f) !== getFileKey(file))
    setPendingUploads(nextPendingUploads)
  }

  const handleCancelClick = (e: FormEvent) => {
    e.preventDefault()
    onCancel && onCancel()
  }

  const handleFormSubmit = (formVals: AssetDetailFormValues) => {
    const prunedVals = pruneObjectShallow(formVals) as AssetDetailFormValues
    setAssetToUpdate(prunedVals)
  }

  const handleFileSelectedChange = (files: File[]) => {
    const newFiles: File[] = [...pendingUploads]
    files.forEach(file => {
      if(!newFiles.map(getFileKey).includes(getFileKey(file))) {
        newFiles.push(file)
      } else {
        enqueueSnackbar('Image already queued for upload')
      }
    })
    setPendingUploads(newFiles)
  }

  const handleImageUploadComplete = (file: File) => {
    setCompletedUploads([...completedUploads, file])
    if(pendingUploads.length > 0) {
      removePendingFile(file)
    }
  }

  useEffect(() => {
    if(assetAvailableForUploads) {
      setActiveUploads(pendingUploads)
    }
  }, [assetAvailableForUploads, pendingUploads])

  // When uploads finish
  useEffect(() => {
    if(activeUploads.length > 0 && pendingUploads.length === 0) {
      const num = completedUploads.length
      enqueueSnackbar(`${num} image${num > 1 ? 's' : ''} uploaded successfully.`, { variant: 'success' })
      if(typeof onImagesChanged === 'function') {
        onImagesChanged()
      }
      setActiveUploads([])
    }
  }, [activeUploads.length, completedUploads.length, enqueueSnackbar, onImagesChanged, pendingUploads.length])

  // when deletes finish
  useEffect(() => {
    if(deleted.length > 0 && pendingDeletes.length === 0) {
      enqueueSnackbar(`${deleted.length} images deleted successfully.`, { variant: 'success' })
      if(typeof onImagesChanged === 'function') {
        onImagesChanged()
      }
      setDeleted([])
    }
  }, [deleted.length, enqueueSnackbar, onImagesChanged, pendingDeletes.length])

  // Notify user of errors when updating asset
  useEffect(() => {
    if(patchFetchState.error) {
      enqueueSnackbar(`Error: ${patchFetchState.error.message}`, { variant: 'error' })
    }
  }, [enqueueSnackbar, patchFetchState])

  // Call onSubmitted after created or updated
  useEffect(() => {
    if(pendingUploads.length === 0 && activeUploads.length === 0 && deleted.length === 0 && pendingDeletes.length === 0) {
      if(patchFetchState.statusCode === 200 || createFetchState.statusCode === 201) {
        onSubmitted(modifiedAsset)
      }
    }
  }, [activeUploads.length, createFetchState.statusCode, deleted.length, modifiedAsset, onSubmitted, patchFetchState.statusCode, pendingDeletes.length, pendingUploads.length])

  // Notify user of errors when creating asset
  useEffect(() => {
    if(createFetchState.error) {
      console.error(createFetchState.error)
      enqueueSnackbar(`Error: ${createFetchState.error.message}`, { variant: 'error' })
    }
  }, [enqueueSnackbar, createFetchState])

  const handleDeleteImage = (pointImage: LocalImageDTO) => {
    setPendingDeletes([...pendingDeletes, pointImage])
  }

  const handleImageDeleted = (pointImage: LocalImageDTO) => {
    const nextPendingDeletes = pendingDeletes.filter(f => f.imagePath !== pointImage.imagePath)
    setPendingDeletes(nextPendingDeletes)
    setDeleted([...deleted, pointImage])
  }

  const $actionLeft = typeof onCancel === 'function' ? (
    <Button type="reset" onClick={handleCancelClick}>Cancel</Button>
  ) : undefined

  const $header = (
    <AppHeader
      title={asset ? asset.name : 'Add An Asset'}
      actionLeft={$actionLeft}
      getLayoutParent="parent"
      fixed
    />
  )
  return (
    <div className="modify-asset">
      <AssetModifyErrorBoundary header={$header}>
        <OnlineOnly fallback={null}>
          <TitledSection title="Media">
            <JQUIList>
              <JQUIListItem
                onClick={handleMediaClick}
                iconLeft={
                  <Badge badgeContent={pendingUploads.length} color="primary" style={{ marginRight: '1rem' }}>
                    <FaCamera />
                  </Badge>
                }
              >
                Manage Asset Media
              </JQUIListItem>
            </JQUIList>
          </TitledSection>
        </OnlineOnly>
        <ModifyAssetForm
          asset={asset}
          plantId={plantId}
          isInitialValid={!!asset}
          onSubmit={handleFormSubmit}
          disabled={isLoading}
          renderHeader={({ isValid }) => (
            <AppHeader
              title={asset ? asset.name : 'Add An Asset'}
              actionLeft={$actionLeft}
              actionRight={<Button type="submit" disabled={!isValid || !(pendingUploads.length === 0 || activeUploads.length === 0)}>Submit</Button>}
              fixed
            />
          )}
        />
        <Modal id="UploadMediaNew" show={showMediaModal} overflowTargetSelector="main#app">
          <AppHeader
            title="Media"
            actionLeft={<Button type="reset" onClick={(e) => { e.preventDefault(); setShowMediaModal(false) }}>Cancel</Button>}
            actionRight={<Button type="button" onClick={() => setShowMediaModal(false)}>Done</Button>}
          />
          <AssetMedia
            asset={asset}
            onFilesSelectedChange={handleFileSelectedChange}
            assetListProps={{
              pendingUploads,
              onDelete: handleDeleteImage,
              onDeletePending: removePendingFile,
            }}
          />
        </Modal>
        {(assetAvailableForUploads && activeUploads.length > 0) ? activeUploads.map(file => (
          <DoImageUpload
            key={file.name}
            assetId={assetId}
            file={file}
            onComplete={handleImageUploadComplete}
          />
        )) : null}
        {(pendingDeletes.length > 0) ? pendingDeletes.map(pointImage => (
          <DoImageDelete
            key={pointImage.id}
            pointImage={pointImage}
            onComplete={handleImageDeleted}
          />
        )) : null}
      </AssetModifyErrorBoundary>
    </div>
  )
}

export default ModifyAsset

export const getFileKey = (file: File): string => {
  return `${file.name}_${file.size}_${file.type}`
}

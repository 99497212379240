import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaAddressBook, FaUserEdit, FaUserPlus } from 'react-icons/fa'
import { GeoLocationSensorState } from 'react-use/lib/useGeolocation'
import { Box, Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import Debug from 'debug'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import throttle from 'lodash.throttle'
import * as Yup from 'yup'

import { AddressDTO, ContactDTO, PlantDetailDTO } from 'api'
import AppHeader from 'components/AppHeader'
import ContactSelect from 'components/ContactSelect/ContactSelect'
import InlineError from 'components/Errors/InlineError'
import JQUIList, { JQUIListItem } from 'components/JQUIList'
import LabelledValue from 'components/LabelledValue'
import Modal from 'components/Modal'
import usePlantPrimaryAddress from 'components/PlantDetail/usePlantPrimaryAddress'
import usePlantPrimaryContact from 'components/PlantDetail/usePlantPrimaryContact'
import TitledSection from 'components/TitledSection'
import statesJson from 'data/states.json'
import getFieldErrors from 'utils/getFieldErrors'

import ContactMutationSideEffectCheck from './ContactMutationSideEffectCheck'
import CreateContact from './CreateContact'
import { geocoderResultToAddressDTO } from './geocoderResultToAddressDTO'
import GeoLocationInput from './GeoLocationInput'
import ModifyContact from './ModifyContact'
import { useReverseGeocode } from './useReverseGeocode'
import useUpdatePlant from './useUpdatePlant'

const styles = require('./PlantDetailModify.css')

const log = Debug('AL:PlantDetailModify')

export interface PlantDetailFormValues {
  siteInstructions: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postalCode: string
  latLong?: string
  contactId?: number
}

export const ModifyPlantSchema = Yup.object().shape({
  siteInstructions: Yup.string().max(1000),
  addressLine1: Yup.string()
    .required()
    .min(2)
    .max(100),
  addressLine2: Yup.string()
    .min(2)
    .max(100),
  city: Yup.string()
    .required()
    .min(2)
    .max(100),
  state: Yup.string()
    .required()
    .min(3)
    .max(100),
  postalCode: Yup.string()
    .required()
    .min(5)
    .max(5),
  latLong: Yup.string()
    .min(5)
    .max(255)
    .matches(/^-?[0-9]{1,3}(?:\.[0-9]{1,20})?, ?-?[0-9]{1,3}(?:\.[0-9]{1,20})?$/, 'Must match the format "{latitude},{longitude}"'),
})

interface Props {
  plantDetails: PlantDetailDTO
  onCancel: () => void
  onSubmitted: (next: PlantDetailDTO) => void
  portalTarget?: string
}

type PrimaryContactMode = undefined | 'select' | 'modify' | 'new'

export const PlantDetailModify = (props: Props) => {
  const {
    plantDetails,
    onCancel,
    onSubmitted,
  } = props
  const googleApiKey = process.env.GATSBY_ASSETLINK_GOOGLE_API_KEY

  if(!googleApiKey) {
    Sentry.captureException(new Error('No Google Maps API key configured'))
  }
  const [doSubmit, setDoSubmit] = useState<boolean>(false)
  const [bypassContactMutationCheck, setBypassContactMutationCheck] = useState<boolean>(false)
  const [geolocation, setGeolocation] = useState<GeoLocationSensorState>()
  const geocodeFetchState = useReverseGeocode(geolocation, { key: googleApiKey })
  const [resolvedAddressFromGeocoords, setResolvedAddressFromGeocoords] = useState<Partial<AddressDTO>>({})
  const [plantContactMode, setPlantContactMode] = useState<PrimaryContactMode>()
  const [newLocationContact, setNewLocationContact] = useState<ContactDTO>()
  const [updateFormVals, setUpdateFormVals] = useState<PlantDetailFormValues>()
  const updatePlantState = useUpdatePlant(plantDetails, doSubmit ? updateFormVals : undefined, (doSubmit && newLocationContact) ? newLocationContact.id : undefined)
  const addressFetchState = usePlantPrimaryAddress(plantDetails)
  const contactFetchState = usePlantPrimaryContact(plantDetails)

  const fieldErrors = updatePlantState.fieldErrors

  useEffect(() => {
    const results = geocodeFetchState.data && geocodeFetchState.data.results
    if(results && results.length) {
      try {
        const address = geocoderResultToAddressDTO(results[0])
        if(address) {
          log('Setting resolved address from geocoordinates')
          setResolvedAddressFromGeocoords(address)
        }
      } catch(err) {
        console.warn(err)
      }
    }
  }, [geocodeFetchState.data])

  const updatePlantStateIsOk = useMemo(() => updatePlantState.statusCodes.every(c => (c >= 200 && c < 400)), [updatePlantState.statusCodes])

  const throttledOnSubmitted = useCallback(throttle(onSubmitted, 200, { leading: false, trailing: true }), [])

  useEffect(() => {
    if(updatePlantState.data &&
      !updatePlantState.isLoading &&
      !updatePlantState.isError &&
      updatePlantStateIsOk) {
      // FIX ME: Find a better way to determine when updatePlantState is done.
      log('Firing onSubmitted')
      throttledOnSubmitted(updatePlantState.data)
    }
  }, [throttledOnSubmitted, updatePlantState.data, updatePlantState.isError, updatePlantState.isLoading, updatePlantState.sendCounts.length, updatePlantStateIsOk])

  useEffect(() => {
    setBypassContactMutationCheck(false)
  }, [plantContactMode])

  if(addressFetchState.error) {
    if(addressFetchState.statusCode === 500) {
      throw addressFetchState.error
    }
    return <InlineError message={addressFetchState.error.message} />
  }
  if(contactFetchState.error) {
    if(contactFetchState.statusCode === 500) {
      throw contactFetchState.error
    }
    return <InlineError message={contactFetchState.error.message} />
  }
  if(updatePlantState.errors) {
    if(updatePlantState.statusCodes.some(c => c === 500)) {
      updatePlantState.errors.forEach(err => { throw err })
    }
    if(!updatePlantState.fieldErrors) {
      return (
        <>
          {updatePlantState.errors.map((err, i) => (<InlineError message={err.message} key={i} />))}
        </>
      )
    }
    throw new Error('An unknown error occurred while updating plant details')
  }

  if(!plantDetails) {
    return null
  }

  const {
    companyId,
    samplingInstructions,
  } = plantDetails
  const locationAddress: AddressDTO = addressFetchState.data || {}
  const locationContact: (ContactDTO | undefined) = newLocationContact || contactFetchState.data

  if(!companyId) {
    throw new Error('Plant does not have required companyId.')
  }

  const handleSubmit = (nextFormVals: PlantDetailFormValues) => {
    setUpdateFormVals(nextFormVals)
    setDoSubmit(true)
  }

  const handleClose = () => {
    onCancel()
  }

  const initialValues: PlantDetailFormValues = {
    siteInstructions: samplingInstructions || '',
    addressLine1: resolvedAddressFromGeocoords.addressLine1 || locationAddress.addressLine1 || '',
    addressLine2: locationAddress.addressLine2 || '',
    city: resolvedAddressFromGeocoords.city || locationAddress.city || '',
    state: resolvedAddressFromGeocoords.state || locationAddress.state || '',
    postalCode: resolvedAddressFromGeocoords.postalCode || locationAddress.postalCode || '',
    latLong: (() => {
      if(geolocation) {
        return `${geolocation.latitude},${geolocation.longitude}`
      }
      if(locationAddress.latitude && locationAddress.longitude) {
        return `${locationAddress.latitude},${locationAddress.longitude}`
      }
      return ''
    })(),
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ModifyPlantSchema}
      enableReinitialize
      isInitialValid
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<PlantDetailFormValues>) => {
        const {
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          isValid,
        } = formikBag

        const handleLocationChange = (location: GeoLocationSensorState) => {
          setGeolocation(location)
          setFieldValue('latLong', `${location.latitude},${location.longitude}`)
        }

        const handleContactChange = (contact: ContactDTO) => {
          setNewLocationContact(contact)
          setPlantContactMode(undefined)
        }

        return (
          <Form className={styles.root}>
            <AppHeader
              title={plantDetails.name}
              actionLeft={<Button type="reset" onClick={handleClose}>Cancel</Button>}
              actionRight={<Button type="submit" disabled={isSubmitting || !isValid}>Save</Button>}
              fixed
            />
            <LabelledValue
              label="Site Instructions"
              value={<Field name="siteInstructions" component="textarea" className={styles.textArea} />}
              error={!!(errors.siteInstructions && touched.siteInstructions)}
              feedback={<ErrorMessage name="siteInstructions" />}
              className={styles.labelledValue}
            />
            <TitledSection title="Address">
              {!googleApiKey ? (
                <InlineError
                  message={
                    <p>
                      <small>Could not find a Google API key. Please be sure to set the environment variable <code>GATSBY_ASSETLINK_GOOGLE_API_KEY</code>.</small>
                    </p>
                  }
                />
              ) : null}
              <LabelledValue
                label="Geo Location"
                value={(
                  <Field
                    name="latLong"
                    render={({ field }: FieldProps) => (
                      <GeoLocationInput
                        onLocationChange={handleLocationChange}
                        {...field}
                      />
                    )}
                  />
                )}
                assistiveText="Geo Location will attempt to auto-fill address information."
                error={!!(errors.latLong && touched.latLong)}
                feedback={<ErrorMessage name="latLong" />}
                className={styles.labelledValue}
              />
              <LabelledValue
                label="Street"
                value={<Field name="addressLine1" type="text" />}
                className={styles.labelledValue}
                {...getFieldErrors<Partial<AddressDTO>>('addressLine1', formikBag, fieldErrors)}
              />
              <LabelledValue
                label="Street 2"
                value={<Field name="addressLine2" type="text" />}
                className={styles.labelledValue}
                {...getFieldErrors<Partial<AddressDTO>>('addressLine2', formikBag, fieldErrors)}
              />
              <LabelledValue
                label="City"
                value={<Field name="city" type="text" />}
                {...getFieldErrors<Partial<AddressDTO>>('city', formikBag, fieldErrors)}
                className={styles.labelledValue}
              />
              <LabelledValue
                label="State"
                value={(
                  <Field name="state" component="select" placeholder="Select a state">
                    <option key="none" value="" disabled>Select a state</option>
                    {statesJson.map(({ name }) => (
                      <option key={name} value={name}>{name}</option>
                    ))}
                  </Field>
                )}
                {...getFieldErrors<Partial<AddressDTO>>('state', formikBag, fieldErrors)}
                className={styles.labelledValue}
              />
              <LabelledValue
                label="ZIP"
                value={<Field name="postalCode" type="text" />}
                {...getFieldErrors<Partial<AddressDTO>>('postalCode', formikBag, fieldErrors)}
                className={styles.labelledValue}
              />
            </TitledSection>
            <TitledSection title="Plant Contact">
              <JQUIList>
                <JQUIListItem iconLeft={<FaAddressBook />} onClick={() => { setPlantContactMode('select') }}>
                  {locationContact ? 'Select a different contact' : 'Select a contact'}
                </JQUIListItem>
                {locationContact && (
                  <JQUIListItem iconLeft={<FaUserEdit />} onClick={() => { setPlantContactMode('modify') }}>
                    Modify <strong>{`${locationContact.firstName} ${locationContact.lastName}`}</strong>&apos;s information
                  </JQUIListItem>
                )}
                <JQUIListItem iconLeft={<FaUserPlus />} onClick={() => { setPlantContactMode('new') }}>
                  Create a New Contact
                </JQUIListItem>
              </JQUIList>
            </TitledSection>
            <Modal id="contactSelect" show={plantContactMode === 'select'} overflowTargetSelector={`.${styles.root}`}>
              {plantContactMode === 'select' && (
                // TODO: Scope the contacts to the current plant's company
                <>
                  <AppHeader
                    title="Select a Contact"
                    actionLeft={<Button type="button" onClick={() => setPlantContactMode(undefined)}>Cancel</Button>}
                    fixed
                  />
                  <ContactSelect
                    className={styles.contactSelect}
                    onChange={handleContactChange}
                    value={locationContact}
                  />
                </>
              )}
            </Modal>
            <Modal id="contactModify" show={plantContactMode === 'modify' && !!locationContact} overflowTargetSelector={`.${styles.root}`}>
              {plantContactMode === 'modify' && !!locationContact && (
                <ContactMutationSideEffectCheck
                  contactId={(locationContact as Required<ContactDTO>).id.toString()}
                  bypass={bypassContactMutationCheck}
                  renderFooter={(links) => (
                    <Box flexDirection="column">
                      <Box marginTop="1em">
                        <Button
                          variant="contained"
                          fullWidth
                          color="secondary"
                          onClick={() => setBypassContactMutationCheck(true)}
                        >
                          Modify contact for {links.length} plants
                        </Button>
                      </Box>
                      <Box marginTop="1em">
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => setPlantContactMode('new')}
                        >
                          Create a new contact for this plant
                        </Button>
                      </Box>
                    </Box>
                  )}
                  header={(
                    <AppHeader
                      actionLeft={<Button type="button" onClick={() => setPlantContactMode(undefined)}>Cancel</Button>}
                      title="Modify Contact"
                    />
                  )}
                >
                  <ModifyContact
                    contact={locationContact}
                    onSubmitted={handleContactChange}
                    onCancel={() => setPlantContactMode(undefined)}
                  />
                </ContactMutationSideEffectCheck>
              )}
            </Modal>
            <Modal id="contactNew" show={plantContactMode === 'new'} overflowTargetSelector={`.${styles.root}`}>
              {plantContactMode === 'new' && (
                <CreateContact
                  companyId={companyId}
                  onCancel={() => setPlantContactMode(undefined)}
                  onSubmitted={handleContactChange}
                />
              )}
            </Modal>
          </Form>
        )
      }}
    />
  )
}
export default PlantDetailModify

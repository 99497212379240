import React, { ReactNode } from 'react'
import { MdPhotoCamera } from 'react-icons/md'

import EmptyBucket from 'components/EmptyBucket'

type Props = {
  message?: ReactNode
}
export const Empty = (props: Props) => {
  const {
    message = 'This asset has no images',
  } = props
  return (
    <EmptyBucket>
      <div role="img"><MdPhotoCamera /></div>
      <small>{message}</small>
    </EmptyBucket>
  )
}

export default Empty

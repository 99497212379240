import Debug from 'debug'

import { KnownAsset } from 'api_supplimental'
import Entities from 'idb/Entities'

import { updateRouteProgress } from './updateRouteProgress'

const log = Debug('AL:intercept:updateAssetSampleHistory')

export const updateAssetSampleHistory = async (db: Entities, assetId: string): Promise<void> => {
  log(`Updating asset sample recency props: ${assetId}`)
  const asset = await db.Assets.get(assetId)
  if(!asset) {
    throw new Error('Tag Identifier could not be traced to an asset')
  }
  const samples = await db.Samples.where('assetId').equals(assetId).sortBy('sampledAt')
  const mostRecentSample = samples.reverse()[0]
  const newProps: Partial<KnownAsset> = mostRecentSample ? {
    dateLastSampled: mostRecentSample.sampledAt,
  } : {
    dateLastSampled: undefined,
  }
  await db.Assets.update(asset.id, newProps)
  await updateRouteProgress(db, asset.routeId)
}

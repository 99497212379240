import { useContext, useEffect, useMemo, useState } from 'react'
import { AuthorizationError } from 'contexts/api/AuthorizationError'
import { currentUserContext } from 'contexts/CurrentUser'
import { SESSION_KEY_PREFIX } from 'contexts/LocalEntities/useLocalEntitySessionExpiration'
import { networkStateContext } from 'contexts/NetworkState'
import Debug from 'debug'

import Entities from 'idb/Entities'

const log = Debug('AL:LocalEntities:useRefreshLocalEntities')

export default function useRefreshLocalEntities(): (tablesNamesToClear: string[]) => void {
  const { currentUser } = useContext(currentUserContext)
  if(!(currentUser && currentUser.email)) {
    throw new AuthorizationError('No current user context')
  }
  const [tablesToClear, setTablesToClear] = useState<string[]>([])
  const db = useMemo(() => Entities.getInstance(currentUser.email!), [currentUser])
  const { online } = useContext(networkStateContext)

  useEffect(() => {
    if(tablesToClear && tablesToClear.length > 0 && online && db && typeof window !== 'undefined') {
      log('Triggering refresh for tables:', tablesToClear)
      tablesToClear.forEach((tableName) => {
        const ssSlug = [SESSION_KEY_PREFIX, db.name, tableName].join('_')
        window.sessionStorage.removeItem(ssSlug)
      })
      setTablesToClear([])
    }
  }, [db, online, tablesToClear])

  return setTablesToClear
}

import React, { ReactNode } from 'react'

import PaddedFlexCentered from 'components/PaddedFlexCentered'

type Props = {
  message: ReactNode
}
export const CallCESError = (props: Props) => {
  const { message } = props
  const $phone = <a href="tel:+12162512510">(216) 251-2510</a>
  const $mail = <a href={`mailto:ces@testoil.com?subject=${encodeURIComponent('AssetLink: ' + message)}`}>ces@testoil.com</a>
  return (
    <PaddedFlexCentered>
      <h1>Sorry</h1>
      <p style={{ textAlign: 'center' }}>
        {message}
      </p>
      <p style={{ textAlign: 'center' }}>
        Please contact our Customer Engagement Specialists at {$phone} or {$mail} to make modifications to this asset.
      </p>
    </PaddedFlexCentered>
  )
}

import React, { ReactNode, useCallback, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { AssetManufacturer } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalAssetManufacturersContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalAssetManufacturersProvider = (props: Props) => {
  const {
    children,
  } = props

  const parseResponse = useCallback((r: Result<AssetManufacturer>) => r && r.data, [])

  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<AssetManufacturer>, AssetManufacturer>({
    url: 'v1/assets/manufacturers/all',
    getTable: (db) => db.AssetManufacturers,
    parseResponse,
  })

  const manufacturers = useMemo(() => (entities) && sortByProp(entities, ['name']), [entities])

  const value: ILocalAssetManufacturersContext = {
    manufacturers,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Asset Manufacturers">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalAssetManufacturersProvider

import { AssetReportAvailabilityMap } from "api";
import path from "path";
import { serialize } from "utils/api-utils";
import baseParser from "../baseParser";

import Intercept from "../Intercept";
import InterceptedResponse from "../InterceptedResponse";

const reportURL = `v1/assets/latestreport/verifyall`;

const expirationTimeout = 60000;

type ExpirableData = {
  data: AssetReportAvailabilityMap;
  createdAt: Date;
};
const cache = {} as { [key: string]: ExpirableData };
export const getAssetHasReport = new Intercept<AssetReportAvailabilityMap>({
  pathname: reportURL,
  fetch: async ({ fetcher, opts }) => {
    if (!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 });
    }

    const queryString = opts?.body as string;
    const cachedData = cache[queryString];
    if (
      cachedData &&
      Date.now() - cachedData.createdAt.getTime() < expirationTimeout
    ) {
      const data = cache[queryString].data;
      return new InterceptedResponse({ data: data, status: 200 });
    } else if (
      cachedData &&
      Date.now() - cachedData.createdAt.getTime() > expirationTimeout
    ) {
      delete cache[queryString];
    }

    const params = JSON.parse(queryString) as { pointIDs: number[] };

    if (!params || !params.pointIDs || !params.pointIDs.length) {
      return new InterceptedResponse({ status: 400 });
    }

    const serializedParams = serialize(params);
    const url = path.join(reportURL, serializedParams);

    delete opts?.body;
    const interceptedResponse = await fetcher(url, opts)
      .then((res) => baseParser<AssetReportAvailabilityMap>(url, opts, res))
      .then((parsedResponse) => {
        cache[queryString] = { data: parsedResponse, createdAt: new Date() };
        return new InterceptedResponse({ data: parsedResponse, status: 200 });
      });

    return interceptedResponse;
  },
});

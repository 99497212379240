import React, { ReactNode } from 'react'
import { ActiveCustomerProvider } from 'contexts/ActiveCustomer'

import LocalAddressesProvider from './Addresses/LocalAddressesProvider'
import LocalAssetManufacturersProvider from './AssetManufacturers/LocalAssetManufacturersProvider'
import LocalAssetsProvider from './Assets/LocalAssetsProvider'
import LocalAssetTypesProvider from './AssetTypes/LocalAssetTypesProvider'
import LocalCompaniesProvider from './Companies/LocalCompaniesProvider'
import LocalContactsProvider from './Contacts/LocalContactsProvider'
import LocalCustomersProvider from './Customers/LocalCustomersProvider'
import LocalFluidsProvider from './Fluids/LocalFluidsProvider'
import LocalImagesProvider from './Images/LocalImagesProvider'
import LocalPlantDetailsProvider from './PlantDetail/LocalPlantDetailsProvider'
import LocalPlantsProvider from './Plants/LocalPlantsProvider'
import LocalPortTypesProvider from './PortTypes/LocalPortTypesProvider'
import LocalRoutesProvider from './Routes/LocalRoutesProvider'
import LocalSamplesProvider from './Samples/LocalSamplesProvider'
import LocalTestPackagesProvider from './TestPackages/LocalTestPackagesProvider'

export interface Props {
  children: ReactNode
}
export const EntitiesApiProvider = (props: Props) => {
  const {
    children,
  } = props
  return (
    <LocalCustomersProvider>
      <ActiveCustomerProvider>
        <LocalTestPackagesProvider>
          <LocalAssetTypesProvider>
            <LocalAssetManufacturersProvider>
              <LocalPortTypesProvider>
                <LocalAssetsProvider>
                  <LocalImagesProvider>
                    <LocalAddressesProvider>
                      <LocalCompaniesProvider>
                        <LocalContactsProvider>
                          <LocalPlantsProvider>
                            <LocalPlantDetailsProvider>
                              <LocalRoutesProvider>
                                <LocalSamplesProvider>
                                  <LocalFluidsProvider>
                                    {children}
                                  </LocalFluidsProvider>
                                </LocalSamplesProvider>
                              </LocalRoutesProvider>
                            </LocalPlantDetailsProvider>
                          </LocalPlantsProvider>
                        </LocalContactsProvider>
                      </LocalCompaniesProvider>
                    </LocalAddressesProvider>
                  </LocalImagesProvider>
                </LocalAssetsProvider>
              </LocalPortTypesProvider>
            </LocalAssetManufacturersProvider>
          </LocalAssetTypesProvider>
        </LocalTestPackagesProvider>
      </ActiveCustomerProvider>
    </LocalCustomersProvider>
  )
}

export default EntitiesApiProvider

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'

import { PlantDTO } from 'api'

export const getPlant = new Intercept<PlantDTO>({
  pathname: 'v1/plants/:plantId',
  fetch: async ({ entitiesDb, match }) => {
    const plantId = match && match.params && match.params.plantId
    if(!plantId) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Plants.get(Number(plantId))
    return new InterceptedResponse({ data, status: 200 })
  },
})

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { LocalSample } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:intercept:deleteImage')

export const deleteImage = new Intercept<LocalSample>({
  method: 'DELETE',
  pathname: 'v1/images/:imageId',
  fetch: async (bag) => {
    const {
      match,
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      networkState,
      req,
      opts,
      fetcher,
    } = bag
    const imageId = match?.params.imageId
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    if(!imageId) {
      return new InterceptedResponse({ status: 404 })
    }
    const image = await entitiesDb.Images.get({ id: imageId })
    if(image) {
      log('Deleting image', image)
      await entitiesDb.Images.delete(image.guid)
    } else {
      log(`No image found with image id "${imageId}"`)
    }
    if(networkState.online) {
      return fetcher(req, opts)
    }
    if(tx) {
      await tx.Queue.put(new Transaction(req, opts))
    }
    return new InterceptedResponse({ status: 202 })
  },
})

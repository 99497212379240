import { PlantDTO } from 'api'
import { Transaction } from 'idb/Transactions'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const patchPlant = new Intercept<PlantDTO>({
  method: 'PATCH',
  pathname: 'v1/plants/:plantId',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      match,
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      networkState,
      fetcher,
    } = fetchBag
    if(!(opts && opts.body && match && match.params && match.params.plantId)) {
      return new InterceptedResponse({ status: 400 })
    }
    const plantId = Number(match.params.plantId)
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    const body: PlantDTO = JSON.parse(opts.body as string)
    const updatedValues: Partial<PlantDTO> = {
      samplingInstructions: body.samplingInstructions,
    }
    await entitiesDb.Plants.update(plantId, updatedValues)
    await entitiesDb.PlantDetails.update(plantId, updatedValues)
    const updatedEntity = await entitiesDb.Plants.get(plantId)
    if(networkState.online) {
      return fetcher(req, opts)
    } else {
      try {
        await tx.Queue.add(new Transaction(req, opts))
      } catch(err) {
        console.warn(err)
      }
    }
    return new InterceptedResponse({ data: updatedEntity, status: 200 })
  },
})

import React, { ReactNode, useCallback } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { CompanyDTO } from 'api'
import { Result } from 'api_supplimental'

import context, { ILocalCompaniesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalCompaniesProvider = (props: Props) => {
  const {
    children,
  } = props
  const parseResponse = useCallback((r: Result<CompanyDTO>) => r && r.data, [])
  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<CompanyDTO>, CompanyDTO>({
    url: 'v1/companies/all',
    getTable: (db) => db.Companies,
    parseResponse,
  })

  const value: ILocalCompaniesContext = {
    companies: entities,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Companies">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalCompaniesProvider

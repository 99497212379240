import { DependencyList, useCallback, useContext, useDebugValue, useEffect, useMemo, useState } from 'react'
import { networkStateContext } from 'contexts/NetworkState'
import Debug from 'debug'

import { useInterval } from 'hooks/useInterval'
import useSessionStorage from 'hooks/useSessionStorage'

const baseLogger = Debug('AL:LocalEntities:useLocalEntitySessionExpiration')

export type Config = {
  /**
   * The name of the db to use in the sessionStorage key.
   */
  dbName: string
  /**
   * The name of the table to use in the sessionStorage key.
   */
  tableName: string
  /**
   * The max age (time to live) in miliseconds for the cache.
   */
  ttl?: number
  /**
   * Dependencies to pass to the setLastUpdated callback.
   */
  deps?: DependencyList
}

export type TReturn = {
  setLastUpdated: () => void
  lastUpdatedDate?: Date
  isExpired: boolean
}

export const SESSION_KEY_PREFIX = 'lastupdated'

const useSessionStoragePolling = <T, >(key: string, initialValue?: T, raw?: boolean): [T, (value: T) => void] => {
  const [val, setVal] = useSessionStorage<T>(key, initialValue, raw)
  useInterval(() => {
    if(typeof window !== 'undefined') {
      const sessionStorageValue = sessionStorage.getItem(key)
      const nextVal = raw
        ? sessionStorageValue
        : sessionStorageValue ? JSON.parse(sessionStorageValue) : undefined
      if(val !== nextVal) {
        setVal(nextVal)
      }
    }
  }, 10000)
  return [val, setVal]
}

export function useLocalEntitySessionExpiration(config: Config): TReturn {
  const {
    dbName,
    tableName,
    ttl = 1000 * 60 * 60 * 12, // 12 hours
    deps = [],
  } = config
  const ssSlug = [SESSION_KEY_PREFIX, dbName, tableName].join('_')
  const [lastUpdatedDateString, setLastUpdatedDate] = useSessionStoragePolling<string>(ssSlug, undefined, true)
  const [isExpired, setExpired] = useState<boolean>(false)
  const { online } = useContext(networkStateContext)
  const lastUpdatedDate = useMemo(() => {
    if(lastUpdatedDateString) {
      const d = new Date(lastUpdatedDateString)
      if(!isNaN(d.getTime())) {
        return d
      }
    }
    return undefined
  }, [lastUpdatedDateString])

  useDebugValue(lastUpdatedDate, d => d && d.toLocaleTimeString())
  const log = baseLogger.extend(tableName)

  useEffect(() => {
    if(lastUpdatedDateString === 'null') {
      setLastUpdatedDate('')
    }
  }, [lastUpdatedDateString, setLastUpdatedDate, ssSlug])

  useEffect(() => {
    if(online) {
      if(lastUpdatedDate) {
        if(Date.now() - lastUpdatedDate.getTime() > ttl) {
          log('Expiring cache: Age is older than TTL.')
          setExpired(true)
        }
      } else {
        // eslint-disable-next-line no-console
        log('Expiring cache: No expiration date set.')
        setExpired(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedDate, online, ttl])

  /* eslint-disable react-hooks/exhaustive-deps */
  const setLastUpdated = useCallback(() => {
    setLastUpdatedDate(new Date().toJSON())
  }, deps)
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    lastUpdatedDate,
    setLastUpdated,
    isExpired,
  }
}

export default useLocalEntitySessionExpiration

import React, { HTMLProps } from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import { Button } from '@material-ui/core'
import clsx from 'clsx'

import { TagIdentifier } from 'components/TagIdentifier'

const styles = require('./UnlinkedTagWarning.css')

export type Props = {
  tagIdentifier: string
  onLinkUnlinkTag: () => void
} & HTMLProps<HTMLDivElement>

export const UnlinkedTagWarning = ({ tagIdentifier, className, onLinkUnlinkTag, ...passedProps }: Props) => {
  return (
    <div className={clsx(className, styles.root)} {...passedProps}>
      <div className={clsx(styles.dim, styles.bigIcon)}>
        <FaQuestionCircle />
      </div>
      <p>This tag is not linked to any assets.</p>
      <div className={styles.dim}>
        <TagIdentifier tagIdentifier={tagIdentifier} />
      </div>
      <p>Would you like to select an asset to link it to?</p>
      <Button
        onClick={onLinkUnlinkTag}
        type="button"
        variant="outlined"
        color="primary"
        size="large"
      >
        Link this tag to an Asset
      </Button>
    </div>
  )
}

export default UnlinkedTagWarning

import React from 'react'
import { Button } from '@material-ui/core'

import BottleCard from './BottleCard'

export type Props = {
  bottleIdentifier?: string
  onManualEntry: () => void
}

export const InnerBottle = (props: Props) => {
  const {
    bottleIdentifier,
    onManualEntry,
  } = props

  if(bottleIdentifier) {
    return <BottleCard identifier={bottleIdentifier} />
  }

  return (
    <Button
      type="button"
      style={{ width: '100%', height: '100%' }}
      onClick={onManualEntry}
    >
      Click to enter a bottle ID
    </Button>
  )
}

export default InnerBottle

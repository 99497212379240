import Intercept, { FetchBag } from "contexts/api/Intercept";
import InterceptedResponse from "contexts/api/InterceptedResponse";

import { KnownAsset } from "api_supplimental";
import { paramsfromURL } from "utils/string-utils";

export const getAsset = new Intercept<KnownAsset>({
  pathname: "v1/assets/:assetId",
  fetch: async ({ entitiesDb, match }) => {
    const assetId = match && match.params && match.params.assetId;
    if (!assetId) {
      return new InterceptedResponse({ status: 404 });
    }
    const data = await entitiesDb.Assets.get(assetId);
    return new InterceptedResponse({ data, status: 200 });
  },
});

export const getAssetByEquipmentID = new Intercept<KnownAsset>({
  pathname: "v1/assets/ByModel",
  fetch: async ({ entitiesDb, match, req }) => {
    const params = match
      ? paramsfromURL(typeof req === "string" ? req : req.url)
      : null;
    const equipmentID = params ? params.get("equipmentID") : null;

    if (!equipmentID) {
      return new InterceptedResponse<KnownAsset>({
        status: 404,
        isError: true,
        error: "Equipment ID not provided.",
      });
    }

    const assetsByMachName = entitiesDb.Assets.where({
      name: equipmentID,
    });

    const assetByMachName = await assetsByMachName.first();

    const assetsByEquipmentID = entitiesDb.Assets.where({
      customerEquipmentId: equipmentID,
    });

    const assetByEquipmentID = await assetsByEquipmentID.first();
    const duplicatesDetected =
      (await assetsByMachName.count()) > 1 ||
      (await assetsByEquipmentID.count()) > 1;

    if (duplicatesDetected) {
      return new InterceptedResponse<KnownAsset>({
        data: undefined,
        error: `Multiple assets were found for equipment identifier ${equipmentID}.`,
        isError: true,
        status: 409,
      });
    }

    const bothAreNull = assetByMachName == null && assetByEquipmentID == null;
    const eitherIsNull = assetByMachName == null || assetByEquipmentID == null;
    const assetsAreIdentical =
      !eitherIsNull && assetByMachName.id == assetByEquipmentID.id;
    const onlyOneEntityReturned =
      (!bothAreNull && eitherIsNull) || assetsAreIdentical;

    if (onlyOneEntityReturned) {
      return new InterceptedResponse<KnownAsset>({
        data: assetByMachName ?? assetByEquipmentID,
        status: 200,
      });
    }

    if (!eitherIsNull) {
      return new InterceptedResponse<KnownAsset>({
        data: undefined,
        error: `Multiple assets were found for equipment identifier ${equipmentID}.`,
        isError: true,
        status: 409,
      });
    }

    return new InterceptedResponse<KnownAsset>({
      data: undefined,
      isError: true,
      error: `Asset with a name or equipment ID of ${equipmentID} could not be found.`,
      status: 404,
    });
  },
});

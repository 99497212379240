import React from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import { Box } from '@material-ui/core'

import ComicallyLargeEmoji from './ComicallyLargeEmoji'

const styles = require('./FourOhFour.css')

export interface Props {
  message?: string | React.ReactNode
}
export const FourOhFour = ({ message }: Props) => {
  return (
    <Box textAlign="center" p={4} className={styles.root}>
      <ComicallyLargeEmoji><FaQuestionCircle /></ComicallyLargeEmoji>
      <h1>Not Found</h1>
      <p>
        {message || 'We could not find this resource.'}
      </p>
    </Box>
  )
}

export default FourOhFour

import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { SnackbarProvider } from 'contexts/SnackbarProvider'

import BrowserOnly from 'components/BrowserOnly'

import Sample from './Sample'

export const SampleRouteWrapper: FC<RouteComponentProps> = () => {
  return (
    <BrowserOnly>
      <SnackbarProvider>
        <Sample />
      </SnackbarProvider>
    </BrowserOnly>
  )
}

export default SampleRouteWrapper

import React, { ReactNode } from 'react'
import { FormikProps } from 'formik'

export type FieldErrorsMap<T> = { readonly [K in keyof T]: string }

interface ILabelledValueFormFieldErrorsReturn {
  error: boolean,
  feedback: ReactNode
}
export const getFieldErrors = <T, >(
  fieldName: keyof T,
  formikBag: FormikProps<T>,
  fieldErrors?: FieldErrorsMap<T>
): ILabelledValueFormFieldErrorsReturn => {
  const {
    errors,
    touched,
  } = formikBag
  const feedback = (() => {
    if(touched[fieldName] && errors[fieldName]) return errors[fieldName]
    if(fieldErrors && fieldErrors[fieldName]) {
      return fieldErrors[fieldName]
    }
  })()
  return {
    feedback: feedback ? <div>{feedback}</div> : undefined,
    error: Boolean(feedback),
  }
}

export default getFieldErrors

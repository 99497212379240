import React from 'react'
import { Box } from '@material-ui/core'

import ContactDetail, { Props } from 'components/ContactDetail/ContactDetail'
import TitledSection from 'components/TitledSection'

export default (props: Props) => {
  return (
    <TitledSection title="Primary Contact">
      <Box p={2}>
        <ContactDetail {...props} />
      </Box>
    </TitledSection>
  )
}

import React, { HTMLProps } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { TagIdentifier } from 'components/TagIdentifier'

const styles = require('./TagOutsideCustomerWarning.css')

export type Props = {
  tagIdentifier: string
  onClearButtonClick: () => void
} & HTMLProps<HTMLDivElement>

export const TagOutsideCustomerWarning = ({ tagIdentifier, className, onClearButtonClick, ...passedProps }: Props) => {
  return (
    <div className={clsx(className, styles.root)} {...passedProps}>
      <div className={clsx(styles.dim, styles.bigIcon)}>
        <FaExclamationCircle />
      </div>
      <Typography paragraph>This tag is linked to an asset that belongs to another customer.</Typography>
      <div className={styles.dim}>
        <TagIdentifier tagIdentifier={tagIdentifier} />
      </div>
      <Button
        onClick={onClearButtonClick}
        type="button"
        variant="outlined"
        color="primary"
        size="large"
      >
        Ok
      </Button>
    </div>
  )
}

export default TagOutsideCustomerWarning

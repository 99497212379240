import React from 'react'

import LabelledValue from 'components/LabelledValue'

const styles = require('./BottleCard.css')

export type Props = {
  identifier: string
}

export const BottleCard = (props: Props) => {
  if(!props.identifier) return null
  const {
    identifier,
  } = props
  return (
    <div className={styles.root}>
      <LabelledValue label="Bottle ID" className={styles.labelledValue}>
        <code>{identifier}</code>
      </LabelledValue>
    </div>
  )
}

export default BottleCard

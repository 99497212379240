import { memo, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export interface Props {
  id: string
  children?: ReactNode
  target?: string | HTMLElement
  className?: string
}
const DynamicPortal = (props: Props) => {
  const {
    id,
    children,
    className,
  } = props

  const elementRef = useRef(document.getElementById(id) || document.createElement('div'))
  const target = props.target ? props.target : document.querySelector('main#app')
  let targetEl = typeof target === 'string' ? document.querySelector(target) : target
  if(!targetEl) {
    targetEl = document.body
  }
  const [dynamic] = useState(!elementRef.current.parentElement)
  useEffect(() => {
    const el = elementRef.current
    if(dynamic) {
      el.id = id
      if(className) {
        el.className = className
      }
      if(targetEl) {
        targetEl.appendChild(el)
      } else {
        console.warn('Could not locate the target element to append the portal node to.', target)
      }
    }
    return () => {
      if(dynamic && el.parentElement) {
        el.parentElement.removeChild(el)
      }
    }
  }, [className, dynamic, id, target, targetEl])
  return createPortal(children, elementRef.current)
}
export default memo(DynamicPortal)

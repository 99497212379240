export function serialize<T extends Partial<object>>(
  obj: T | undefined
): string {
  if (typeof obj === "undefined") {
    return "";
  }
  const str: string[] = [];
  Object.keys(obj).forEach((p) => {
    if (obj[p] && Array.isArray(obj[p])) {
      obj[p]?.forEach((val) => {
        str.push(`${p}=${val}`);
      });
    } else {
      if (obj[p]) {
        str.push(`${p}=${obj[p]}`);
      }
    }
  });
  return `?${str.join("&")}`;
}

/***
 * An input field with an attached button to fetch geolocation.
 *
 * TODO: move error display outside the scope of this component
 */
import React, { HTMLAttributes, useEffect, useState } from 'react'
import { MdGpsFixed, MdGpsNotFixed } from 'react-icons/md'
import useGeolocation, { GeoLocationSensorState } from 'react-use/lib/useGeolocation'
import * as Sentry from '@sentry/browser'
import clsx from 'clsx'

const styles = require('./GeoLocationInput.css')

interface IGeoButtonProps {
  onLocationChange?: (geo: GeoLocationSensorState) => void
  locationFixed?: boolean
}

const GeoButtonLoading = (props: (IGeoButtonProps & HTMLAttributes<HTMLButtonElement>)) => {
  const {
    onLocationChange,
    className,
    ...passedProps
  } = props
  const location = useGeolocation()
  const {
    loading,
    latitude,
    longitude,
    error,
    timestamp,
  } = location
  useEffect(() => {
    if(onLocationChange && !loading) {
      onLocationChange(location)
    }
  }, [loading, latitude, longitude, error, timestamp]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <button className={clsx(className, styles.loading)} type="button" {...passedProps}>
      <MdGpsNotFixed className={styles.icon} />
    </button>
  )
}

const GeoButton = (props: (IGeoButtonProps & HTMLAttributes<HTMLButtonElement>)) => {
  const {
    locationFixed,
    ...passedProps
  } = props
  return (
    <button type="button" {...passedProps}>
      {locationFixed ? (
        <MdGpsFixed className={styles.icon} />
      ) : (
        <MdGpsNotFixed className={styles.icon} />
      )}
    </button>
  )
}

interface Props {
  onLocationChange?: (location: GeoLocationSensorState) => void
}

export default (props: (Props & HTMLAttributes<HTMLInputElement>)) => {
  const {
    onLocationChange,
    ...passedProps
  } = props
  const [isGeofetching, setGeofetching] = useState(false)
  const [gotLocation, setGotLocation] = useState(false)
  const [error, setError] = useState<Error | PositionError | null>(null)
  const handleLocationChange = (location: GeoLocationSensorState) => {
    const {
      error: locError,
      loading,
      latitude,
      longitude,
    } = location
    if(locError) {
      setError(locError)
      setGeofetching(false)
    }
    if((!loading && latitude && longitude)) {
      setGotLocation(true)
      if(onLocationChange) {
        onLocationChange(location)
      }
    }
  }

  useEffect(() => {
    if(gotLocation) {
      setGeofetching(false)
    }
  }, [gotLocation])

  useEffect(() => {
    if(error) {
      console.error('error getting location:', error)
      Sentry.captureException(error)
    }
  }, [error])

  const handleGeoClick = () => {
    setError(null)
    setGotLocation(false)
    setGeofetching(true)
  }

  const $btn = (!isGeofetching || error) ? (
    <GeoButton
      locationFixed={gotLocation}
      className={clsx({ [styles.error]: !!error }, styles.geo)}
      onClick={handleGeoClick}
    />
  ) : (
    <GeoButtonLoading
      className={clsx({ [styles.error]: !!error }, styles.geo)}
      onLocationChange={handleLocationChange}
    />
  )

  return (
    <div className={styles.root}>
      <div className={styles.inputGroup}>
        <input type="text" {...passedProps} />
        {$btn}
      </div>
      {error && (
        <small className={styles.error}>Error! {error.message}</small>
      )}
    </div>
  )
}

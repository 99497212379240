import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import * as Sentry from '@sentry/browser'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import throttle from 'lodash.throttle'

import { ContactDTO } from 'api'
import { Result } from 'api_supplimental'
import InlineError from 'components/Errors/InlineError'
import JQUIList from 'components/JQUIList'
import SearchInput from 'components/SearchInput'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import ContactListItem from './ContactListItem'
import EmptyContactsList from './EmptyContactsList'

const styles = require('./ContactSelect.css')

interface Props {
  value?: ContactDTO
  onChange?: (newContact: ContactDTO) => void
  onAddNewContactClick?: () => void
  className?: string
}
export const ContactSelect = (props: Props) => {
  const {
    className,
    onChange,
    onAddNewContactClick,
    value,
  } = props
  const {
    data,
    isLoading,
    isError,
  } = useApi<Result<ContactDTO>>('v1/contacts/all')
  const contacts = (data && data.data) || []
  const [filterInputValue, setFilterInputValue] = useState('')
  const [filter, setFilter] = useState('')

  const throttledSetFilter = useRef(throttle(setFilter, 300, { leading: false, trailing: true }))

  const getFuseInstance = (rawContacts: ContactDTO[] = []) => new Fuse(rawContacts, {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      {
        name: 'lastName',
        weight: 0.7,
      },
      {
        name: 'email',
        weight: 0.5,
      },
      {
        name: 'firstName',
        weight: 0.3,
      },
    ],
  })

  const fuse = useRef(getFuseInstance(contacts))

  useEffect(() => {
    fuse.current = getFuseInstance(contacts)
  }, [contacts])

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterInputValue(e.currentTarget.value)
    throttledSetFilter.current(e.currentTarget.value)
  }

  if(isError) {
    Sentry.captureException(new Error('Error loading contacts'))
    return (
      <div className={className}>
        <InlineError message="Error loading contacts" />
      </div>
    )
  }
  const filteredContacts = filter.length > 0 ? fuse.current.search(filter) : contacts
  const $contacts = (() => {
    if(contacts.length === 0) {
      return (
        <EmptyContactsList onAddNewContactClick={onAddNewContactClick} />
      )
    }
    if(filteredContacts.length === 0) {
      return (
        <div>
          <p><small>No contacts match your filter.</small></p>
        </div>
      )
    }
    return (
      <JQUIList className={styles.list}>
        <Virtuoso
          totalCount={filteredContacts.length}
          computeItemKey={i => i}
          item={index => {
            const contact = filteredContacts[index]
            return (
              <ContactListItem
                contact={contact}
                onClick={onChange}
                key={contact.email}
                active={value && value.id === contact.id}
              />
            )
          }}
        />
      </JQUIList>
    )
  })()

  return (
    <div className={clsx(styles.root, className)}>
      {contacts.length > 0 && (
        <div className={styles.search}>
          <SearchInput
            value={filterInputValue}
            onChange={handleFilterChange}
          />
        </div>
      )}
      <div className={clsx('test', styles.content)}>
        {isLoading ? (
          <Spinner />
        ) : $contacts}
      </div>
    </div>
  )
}

export default ContactSelect

import { useEffect } from 'react'

export function sortByProp<T, K extends keyof T>(entityArray: T[], propsToSortBy: K | K[]): T[] {
  if(!entityArray) {
    return entityArray
  }
  const sortProps = Array.isArray(propsToSortBy) ? propsToSortBy : [propsToSortBy]
  const sorted = entityArray
  sortProps.forEach(propName => {
    sorted.sort((objA, objB) => {
      const a = objA[propName]
      const b = objB[propName]
      if(typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase().localeCompare((b || '').toLowerCase())
      }
      if(typeof a === 'number' && typeof b === 'number') {
        return a - b
      }
      return 0
    })
  })
  return sorted
}

export function useSortByProp<T, K extends keyof T>(entityArray: T[] | undefined, setEntityArray: (arr: T[] | undefined) => void, propsToSortBy: K | K[]) {
  useEffect(() => {
    if(!entityArray || !entityArray.length) {
      return
    }
    const sorted = sortByProp(entityArray, propsToSortBy)
    setEntityArray(sorted)
  }, [entityArray, propsToSortBy, setEntityArray])
}

import React from 'react'
import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'

type Props = {
  onAddNewContactClick?: () => void
  buttonProps?: ButtonProps
}
export const EmptyContactsList = ({ onAddNewContactClick, buttonProps }: Props) => {
  return (
    <div>
      <p>Your company has no contacts yet.</p>
      <p>
        <small>Contacts allow you to specify who to call or email for a given Plant.</small>
      </p>

      {onAddNewContactClick && (
        <Button
          type="button"
          color="primary"
          variant="contained"
          {...buttonProps}
          onClick={onAddNewContactClick}
        >
          Add a new contact
        </Button>
      )}
    </div>
  )
}

export default EmptyContactsList

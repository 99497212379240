import React, { FC, useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import currentUserContext from 'contexts/CurrentUser/context'
import { networkStateContext } from 'contexts/NetworkState'

import { UserDTO } from 'api'
import AppHeader from 'components/AppHeader'
import BottomNav from 'components/BottomNav'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import Modal from 'components/Modal'
import OnlineOnly from 'components/OnlineOnly'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import TitledSection from 'components/TitledSection'
import UserDetail from 'components/UserDetail/UserDetail'
import useApi from 'hooks/useApi'

import Authorizations from './Authorizations/Authorizations'
import ModifyUser from './ModifyUser'

export const Account: FC<RouteComponentProps> = () => {
  const [isModify, setIsModify] = useState<boolean>()
  const userContext = useContext(currentUserContext)
  const { online } = useContext(networkStateContext)
  const [modifiedUser, setModifiedUser] = useState<UserDTO>()
  const {
    error,
    data,
  } = useApi<UserDTO>('v1/users/me', undefined, { dependencies: [modifiedUser] })

  if(error) {
    throw error
  }

  const handleModifyCancel = () => {
    setIsModify(false)
  }

  const handleModifySubmitted = (modified: UserDTO) => {
    setIsModify(false)
    setModifiedUser(modified)
  }

  const userDetails = data || userContext.currentUser
  return (
    <div>
      <AppHeader
        actionRight={online ? <Button type="button" onClick={() => setIsModify(true)}>Modify</Button> : undefined}
        title="My Account"
        fixed
      />
      {userDetails && (
        <TitledSection title="User Details">
          <div style={{ padding: '15px' }}>
            <UserDetail user={userDetails} />
          </div>
        </TitledSection>
      )}
      <OnlineOnly fallback={(
        <PaddedFlexCentered>
          <small>Account management actions are only available online.</small>
        </PaddedFlexCentered>
      )}
      >
        <TitledSection title="Actions">
          <JQUIList>
            <JQUIListItemLink linkProps={{ to: '/forgotpassword' }}>Change my Password</JQUIListItemLink>
            <JQUIListItemLink linkProps={{ to: '/app/account/users' }}>
              Manage Users
            </JQUIListItemLink>
          </JQUIList>
        </TitledSection>
        <Authorizations
          userId={userDetails.id}
          title="My Authorizations"
          emptyText="Somehow, you are not authorized to any entities. Please contact your administrator."
        />
      </OnlineOnly>
      <BottomNav />
      {userDetails && (
        <Modal id="accountModify" show={isModify}>
          <ModifyUser
            user={userDetails}
            onCancel={handleModifyCancel}
            onSubmitted={handleModifySubmitted}
            ineditableFields={['email']}
          />
        </Modal>
      )}
    </div>
  )
}

export default Account

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { AssetTagLinkDTO } from 'api'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:intercept:putAssetTagLink')

export const putAssetTagLink = new Intercept<AssetTagLinkDTO>({
  method: 'PUT',
  pathname: 'v1/asset-tag-links',
  fetch: async (bag) => {
    const {
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 })
    }
    if(networkState.online) {
      log('Not intercepting')
      return fetcher(req, opts)
    }
    log('Intercepting')
    const body: AssetTagLinkDTO = JSON.parse(opts.body as string)
    const { assetId, tagIdentifier } = body
    if(!assetId) {
      log('No assetId found in AssetTagLinkDTO')
      throw new Error('No assetId found in AssetTagLinkDTO')
    }
    if(!tagIdentifier) {
      log('No tagIdentifier found in AssetTagLinkDTO')
      throw new Error('No tagIdentifier found in AssetTagLinkDTO')
    }
    log('Updating asset tag')
    await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier, hasTag: true })

    const data = body
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    const txid = await tx.Queue.add(new Transaction(req, opts))
    log(`Transaction ${txid} queued`)
    return new InterceptedResponse({ data, status: 201 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local asset tag', parsedResponse)
      const { assetId, tagIdentifier } = parsedResponse
      if(!assetId) {
        log('No assetId found in AssetTagLinkDTO')
        throw new Error('No assetId found in AssetTagLinkDTO')
      }
      await entitiesDb.Assets.update(assetId.toString(), { tagIdentifier, hasTag: true })
    }
  },
})

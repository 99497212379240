import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useAsync from 'react-use/lib/useAsync'
import { AuthorizationError } from 'contexts/api/AuthorizationError'
import { currentUserContext } from 'contexts/CurrentUser'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import { IDatabaseChange } from 'dexie-observable/api'

import { CustomerDTO, TestPackageDTO } from 'api'
import { CustomerTestPackage, Result } from 'api_supplimental'
import { IApiState } from 'hooks/useApi'
import { useThrottleFn } from 'hooks/useThrottleFn'
import Entities from 'idb/Entities'

import context, { ILocalTestPackagesContext } from './context'
import TestPackageLoader from './TestPackageLoader'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalTestPackagesProvider = (props: Props) => {
  const {
    children,
  } = props
  const [entities, setEntities] = useState<CustomerTestPackage[]>()
  const [lastChanged, setLastChanged] = useState<number>()
  const setLastChangedThrottled = useThrottleFn(setLastChanged, 100)
  const { currentUser } = useContext(currentUserContext)
  if(!(currentUser && currentUser.email)) {
    throw new AuthorizationError('No current user context')
  }
  const db = useMemo(() => Entities.getInstance(currentUser.email!), [currentUser])

  const customersPromise = useAsync<CustomerDTO[]>(async () => {
    return db.Customers.toArray()
  }, [])
  if(customersPromise.error) {
    throw customersPromise.error
  }

  const handleChanged = useCallback((changes: IDatabaseChange[]) => {
    if(changes.some(change => change.table === 'TestPackages')) {
      setLastChangedThrottled(Date.now())
    }
  }, [setLastChangedThrottled])

  // Subscribe to all changes and unsubscribe on cleanup
  useEffect(() => {
    if(db) {
      db.on('changes', handleChanged)
    }
    return () => {
      try {
        if(db) {
          // @ts-ignore
          db.on.changes.unsubscribe(handleChanged)
        }
      } catch(err) {
        // console.warn(err)
      }
    }
  }, [db, handleChanged])

  useEffect(() => {
    let mounted = true;
    (async () => {
      if(db) {
        const nextEntities = await db.TestPackages.toArray()
        if(mounted) {
          setEntities(nextEntities)
        }
      }
    })()
    return () => { mounted = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, lastChanged])

  const $loaders = useMemo(() => customersPromise.value && customersPromise.value.map(customer => {
    if(!customer.id) {
      throw new Error('No customer ID')
    }
    return (
      <TestPackageLoader key={customer.id} customerId={customer.id} />
    )
  }), [customersPromise.value])

  const fetchState: IApiState<Result<TestPackageDTO>> = {
    error: customersPromise.error,
    isError: !!customersPromise.error,
    isLoading: false,
    data: {},
    sendCount: 1,
  }
  const value: ILocalTestPackagesContext = {
    testPackages: entities,
    get: async (id: number) => db.TestPackages.get(id),
    fetchState,
  }
  return (
    <Provider value={value}>
      {$loaders}
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving loadingMessage="Loading Customers">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalTestPackagesProvider

import React, { useContext, useMemo } from 'react'
import useAsync from 'react-use/lib/useAsync'
import fluidsContext from 'contexts/LocalEntities/Fluids/context'

import { SampleDTO } from 'api'
import FluidAttributes from 'components/FluidAttributes'
import LabelledValue from 'components/LabelledValue'
import { PaddedSection } from 'components/PaddedSection'
import TitledSection from 'components/TitledSection'

export type Props = {
  sample: SampleDTO
}

const UnknownValue = () => (
  <div className="text-muted">Unknown</div>
)

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  // @ts-ignore
  dateStyle: 'long',
  timeStyle: 'short',
})

export const SampleReadOnlyBody = (props: Props) => {
  const { sample } = props
  const { get: getFluid } = useContext(fluidsContext)

  const { value: fluid } = useAsync(() => {
    return sample.fluidId ? getFluid(sample.fluidId) : Promise.resolve(undefined)
  }, [sample])

  const sampleDate: string | undefined = useMemo(() => {
    if(sample.sampledAt) {
      const d = new Date(sample.sampledAt)
      return dateFormatter.format(d)
    }
  }, [sample])
  return (
    <>
      <PaddedSection>
        <LabelledValue
          label="Notes"
          value={sample.comments || <div className="text-muted">None</div>}
        />

        <LabelledValue
          label="Most Recent Oil Change Date"
          value={sampleDate || <UnknownValue />}
        />

        <LabelledValue
          label="Machine Hours"
          value={sample.assetHours || <UnknownValue />}
        />

      </PaddedSection>
      <TitledSection title="Lubricant">
        <PaddedSection>
          <LabelledValue
            label="Lubricant Hours"
            value={sample.fluidHours}
          />
          {fluid ? (
            <FluidAttributes fluid={fluid} showManufacturer showName />
          ) : null}
        </PaddedSection>
      </TitledSection>
    </>
  )
}

export default SampleReadOnlyBody

import React, { useContext, useState } from 'react'
import { Button, LinearProgress } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import offlineRoutesContext from 'contexts/LocalEntities/Routes/context'
import { SnackbarProvider } from 'contexts/SnackbarProvider'

import AppHeader from 'components/AppHeader'
import FourOhFour from 'components/Errors/FourOhFour'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'

import PercentSampledMetric from './PercentSampledMetric'
import RouteAssetList from './RouteAssetList'

type Props = {
  routeId?: string
}

const numberFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 1,
  maximumFractionDigits: 0,
})

const RouteDetail = (props: RouteComponentProps<Props>) => {
  if(!props.routeId) {
    throw new Error('No routeId provided')
  }
  const routeId = parseInt(props.routeId, 10)
  const { routes, fetchState, isLoading } = useContext(offlineRoutesContext)
  const routeDetails = routes && routes.find(r => r.id === routeId)
  const [isEdit, setEdit] = useState<boolean>(false)

  const toggleModify = () => {
    setEdit(!isEdit)
  }

  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }

  if(fetchState.error) {
    throw fetchState.error
  }

  if(!routeDetails) {
    return (
      <FourOhFour message="Route details not found" />
    )
  }
  return (
    <>
      <AppHeader
        title={(routeDetails && routeDetails.name) ? routeDetails.name : '...'}
        actionRight={<Button type="button" onClick={toggleModify}>{isEdit ? 'Done' : 'Modify'}</Button>}
        fixed
      />
      {routeDetails.percentRecentlySampled ? (
        <PercentSampledMetric>
          <div>{numberFormatter.format(routeDetails.percentRecentlySampled)}%</div>
          <small>Assets with Pending Samples.</small>
          <LinearProgress variant="determinate" value={routeDetails.percentRecentlySampled} />
        </PercentSampledMetric>
      ) : null}
      <SnackbarProvider>
        <RouteAssetList routeId={routeId} isEdit={isEdit} />
      </SnackbarProvider>
    </>
  )
}

export default RouteDetail

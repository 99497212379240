import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'

import { PlantDTO } from 'api'
import { Result } from 'api_supplimental'

export const getCustomerPlants = new Intercept<Result<PlantDTO>>({
  pathname: 'v1/customers/:customerId/plants/all',
  fetch: async ({ entitiesDb, match }) => {
    const customerId = match && match.params && match.params.customerId
    if(!customerId) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Plants.where('customerId').equals(Number(customerId)).toArray()
    return new InterceptedResponse({ data: { data }, status: 200 })
  },
})

import React, { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { ButtonBase } from '@material-ui/core'
import clsx from 'clsx'
import { motion, useSpring, useTransform } from 'framer-motion'

import JQUIListItemLink, { IListItemLinkProps } from './JQUIListItemLink'

const styles = require('./JQUIListItemLinkWithDelete.css')

export interface IListItemLinkWithDeleteProps extends IListItemLinkProps {
  showDelete?: boolean
  onDelete?: () => void
}

export const JQUIListItemLinkWithDelete = (props: IListItemLinkWithDeleteProps) => {
  const {
    showDelete = false,
    onDelete = () => null,
    className,
    children,
    ...passedProps
  } = props
  const pinnedOffset = 85
  const minSlideDistance = 20
  const [isPinned, setPinned] = useState<boolean>(showDelete)
  const x = useSpring(0, { stiffness: 300, damping: 10, mass: 1 / 4 })
  const trashX = useTransform(x, [0, pinnedOffset], [-20, 0])
  const trashScale = useTransform(x, [0, pinnedOffset], [0.5, 1])

  useEffect(() => {
    setPinned(showDelete)
  }, [showDelete])

  useEffect(() => {
    const targetX = (isPinned || showDelete) ? pinnedOffset : 0
    x.stop()
    x.set(targetX)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPinned])

  const handleTapEnd = () => {
    setPinned(false)
  }

  const handleDragEnd = (e: TouchEvent) => {
    e.stopImmediatePropagation()
    const _x = x.get()
    if(isPinned) {
      setPinned(false)
      return
    }
    if(_x > minSlideDistance) {
      setPinned(true)
    }
  }

  return (
    <JQUIListItemLink className={clsx(className, styles.root)} {...passedProps}>
      <div className={styles.root}>
        <motion.span key="delete-btn" className={styles.deleteContainer}>
          <ButtonBase
            type="button"
            onClick={onDelete}
            className={styles.delete}
          >
            <motion.span style={{ x: trashX, scale: trashScale }}>
              <FaTrash />
            </motion.span>
          </ButtonBase>
        </motion.span>
        <motion.div
          className={styles.draggable}
          style={{ x, perspective: 1 }}
          drag={showDelete ? undefined : 'x'}
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={handleDragEnd}
          onTap={handleTapEnd}
        >
          {children}
        </motion.div>
      </div>
    </JQUIListItemLink>
  )
}

export default JQUIListItemLinkWithDelete

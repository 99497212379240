import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import clsx from 'clsx'

const styles = require('./InlineError.css')

export interface Props {
  message: string | React.ReactNode
  className?: string
}
export const InlineError = ({ message, className }: Props) => {
  return (
    <div className={clsx(className, styles.root)}>
      <FaExclamationTriangle className={styles.icon} />
      <div className={styles.message}>{message}</div>
    </div>
  )
}

export default InlineError

import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { RouteDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalRoutesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalRoutesProvider = (props: Props) => {
  const {
    children,
  } = props
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((r: Result<RouteDTO>) => r && r.data, [])

  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<RouteDTO>, RouteDTO>({
    url: `v1/customers/${activeCustomer.id}/routes`,
    getTable: (db) => db.Routes,
    parseResponse,
  })
  const routes = useMemo(() => (entities) && sortByProp(entities, ['name', 'number']), [entities])

  const value: ILocalRoutesContext = {
    routes,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Routes">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalRoutesProvider

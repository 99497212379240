import React from 'react'
import { Assign } from 'utility-types'

import { ContactDTO } from 'api'
import { JQUIListItem, Props as JQUIListItemProps } from 'components/JQUIList/JQUIListItem'

export type Props = Assign<JQUIListItemProps, {
  contact: ContactDTO
  onClick?: (contact: ContactDTO) => void
}>

export default ({ contact, onClick, ...passedProps }: Props) => {
  const handleClick = () => {
    if(onClick) {
      onClick(contact)
    }
  }
  return (
    <JQUIListItem key={contact.email} onClick={handleClick} {...passedProps}>
      <div>{`${contact.firstName} ${contact.lastName}`}</div>
      <small>{contact.email}</small>
    </JQUIListItem>
  )
}

import React, { useCallback, useMemo, useRef, useState } from "react";
import useTimeAgo from "@rooks/use-time-ago";

import JQUIListItemLink from "components/JQUIList/JQUIListItemLink";
import { SampleWithReportStatus } from "api_supplimental";
import useApiPrefix from "hooks/useApiPrefix";
import { AssetDTO} from "../../types/api.d";
import { Condition } from "../../types/api_supplimental.d";

import { fileNameContentDispositionRegex } from "utils/regex-library";
import styled from "@emotion/styled";
import { CircularProgress } from "@material-ui/core";
import { AssetStatusIndicator } from "./AssetStatusIndicator";
import { AssetListItemLayout } from "components/Assets/AssetListItemLayout";

const styles = require("./SampleHistoryItem.css");

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

const SampleHistoryItem = (props: {
  asset: AssetDTO;
  sample: SampleWithReportStatus;
}) => {
  const {
    asset,
    sample: {
      assetId,
      identifier: bottleIdentifier,
      sampledAt,
      reportAvailable,
      labId,
      lubricantCondition,
      machineCondition,
    },
  } = props;
  const [didWarnInvalidDate, setDidWarnInvalidDate] = useState<boolean>();
  const [reportDownloadStatus, setReportDownloadStatus] = useState<{
    loading: boolean;
  }>({ loading: false });

  const dateSampled = sampledAt ? new Date(sampledAt) : undefined;
  if (!dateSampled && !didWarnInvalidDate) {
    console.warn("This sample has an invalid dateSampled:", props.sample);
    setDidWarnInvalidDate(true);
  }
  // @ts-ignore
  const timeAgo = useTimeAgo(dateSampled, { intervalMs: 1000 });
  const href =
    assetId && bottleIdentifier
      ? `/app/sample?asset=${encodeURIComponent(
          assetId
        )}&bottle=${encodeURIComponent(bottleIdentifier)}`
      : undefined;
  const $time = dateSampled ? (
    <time dateTime={dateSampled.toJSON()} className={styles.time}>
      <span>{dateFormatter.format(dateSampled)}</span>
      <small>{timeAgo}</small>
    </time>
  ) : (
    <span className={styles.time}>Prehistoric</span>
  );

  const apiPrefix = useApiPrefix();
  const downloadReport = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      const endpoint = `${apiPrefix}v1/samples/Report/Download/FromLabID/${asset.customerId}/${labId}`;
      setReportDownloadStatus({ loading: true });
      fetch(endpoint)
        .then((res) => {
          if (!res.ok)
            return Promise.reject(`${res.status}: ${res.statusText}`);

          const contentDisposition = res.headers.get("content-disposition")!;
          const contentDispositionMatch =
            fileNameContentDispositionRegex.exec(contentDisposition)!;

          return new Promise<{ fileName: string; data: Blob }>(
            (resolve, reject) => {
              res
                .blob()
                .then((blob) => {
                  resolve({
                    fileName:
                      contentDispositionMatch?.groups?.filename ??
                      `${assetId}.pdf`,
                    data: blob,
                  });
                })
                .catch((err) => reject(err));
            }
          );
        })
        .then((fileData) => {
          const url = window.URL.createObjectURL(fileData.data);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `${fileData.fileName}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {})
        .finally(() => {
          setReportDownloadStatus({ loading: false });
        });
    },
    [asset, apiPrefix]
  );

  const downloadTextSpan = useRef<HTMLSpanElement>(null);
  const fontSize = Number(downloadTextSpan.current?.style?.fontSize || 16);
  const spinnerMargin =
    fontSize * (downloadTextSpan.current?.textContent?.length || 0);
  const $reportDownloadButton = useMemo(() => {
    if (!reportAvailable || !asset.customerId || !labId) return null;

    return (
      <DownloadButtonContainer>
        <a href={""}>
          <CenteredDiv>
            <CircularProgress
              style={{
                width: fontSize,
                height: fontSize,
                margin: `0 ${spinnerMargin}`,
                display: reportDownloadStatus.loading ? "block" : "none",
                position: "absolute",
                left: "50%",
                translate: "-50%",
              }}
            />
            <span
              style={{
                visibility: reportDownloadStatus.loading ? "hidden" : "visible",
              }}
              ref={downloadTextSpan}
            >
              {labId}
            </span>
          </CenteredDiv>
        </a>
      </DownloadButtonContainer>
    );
  }, [asset, reportDownloadStatus]);

  return (
    <JQUIListItemLink linkProps={{ to: href }}>
      <div className={styles.row}>
        <AssetListItemLayout
          assetDescriptionSection={<Small>{$time}</Small>}
          bottleIdentifierSection={
            bottleIdentifier ? (
              <InlineDiv style={{ padding: "8px 0" }}>
                <span>
                  <b>Bottle Identifier</b>
                </span>
                <Small>
                  <code>{bottleIdentifier}</code>
                </Small>
              </InlineDiv>
            ) : undefined
          }
          reportSection={
            <InlineDiv
              onClick={
                reportDownloadStatus.loading
                  ? (event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }
                  : downloadReport
              }
            >
              {$reportDownloadButton ?? undefined}
              {!(
                typeof machineCondition === "undefined" &&
                typeof lubricantCondition === "undefined"
              ) ? (
                <AssetStatusIndicator
                  lubricantCondition={lubricantCondition ?? Condition.Unknown}
                  machineCondition={machineCondition ?? Condition.Unknown}
                />
              ) : (
                <StatusIndicatorPlaceholder />
              )}
            </InlineDiv>
          }
        />
      </div>
    </JQUIListItemLink>
  );
};

export default SampleHistoryItem;

const DownloadButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CenteredDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Small = styled.div`
  white-space: nowrap;
  text-align: center;
`;

const StatusIndicatorPlaceholder = styled.div`
  width: 80px;
`;

const InlineDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 10px;
`;

import React, { HTMLAttributes } from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AnimatePresence, motion, MotionProps } from 'framer-motion'
import { Link } from 'gatsby'

import { KnownAsset } from 'api_supplimental'
import AssetCardHorizontal from 'components/Asset/AssetCardHorizontal'

export type Props = {
  asset?: KnownAsset
  className?: string
  onClick?: (asset: KnownAsset) => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  motionCard: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    padding: theme.spacing(),
  },
}))

type TMotionCardProps = {
  asset: KnownAsset
  onButtonClick?: (asset: KnownAsset) => void
} & Omit<Props, 'asset'> & HTMLAttributes<HTMLDivElement> & MotionProps
const MotionCard = (props: TMotionCardProps) => {
  const {
    asset,
    onButtonClick,
    ...passedProps
  } = props
  const styles = useStyles()

  const handleClick = () => {
    if(asset && onButtonClick) {
      onButtonClick(asset)
    }
  }

  const $caption = asset && asset.routeId && asset.routeName ? (
    <Typography variant="caption">Next asset on <Link to={`/app/routes/${asset.routeId}`}>{asset.routeName}</Link> route:</Typography>
  ) : (
    <Typography variant="caption">Next asset on route:</Typography>
  )

  return (
    <motion.div
      {...passedProps}
      className={styles.motionCard}
      initial={{ y: '120%' }}
      animate={{ y: 0 }}
      exit={{ y: '120%' }}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          {$caption}
        </Grid>
        <Grid item>
          <Paper>
            <AssetCardHorizontal asset={asset} />
          </Paper>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Sample this asset
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  )
}

export const SuggestedNextAsset = (props: Props) => {
  const {
    asset,
    onClick,
  } = props
  return (
    <AnimatePresence>
      {asset && (
        <MotionCard key="asset" asset={asset} onButtonClick={onClick} />
      )}
    </AnimatePresence>
  )
}

export default SuggestedNextAsset

import React, { ReactNode } from 'react'

import { AuthorizationDTO } from 'api'
import EmptyBucket from 'components/EmptyBucket'
import ComicallyLargeEmoji from 'components/Errors/ComicallyLargeEmoji'
import JQUIList from 'components/JQUIList'

import Authorization from './Authorization'
import unflattenAuthorizationsList, { TNestedAuthorization } from './unflattenAuthorizationsList'

interface Props {
  authorizations: AuthorizationDTO[]
  emptyText?: ReactNode
}

const AuthListItem = ({ authorization }: { authorization: TNestedAuthorization }) => {
  const kids = authorization.children && authorization.children.length > 0 ? (
    <JQUIList>
      {authorization.children.map(a => <AuthListItem authorization={a} key={a.logicalId} />)}
    </JQUIList>
  ) : null
  return <Authorization authorization={authorization}>{kids}</Authorization>
}

export const AuthorizationsHierarchyList = (props: Props) => {
  const {
    authorizations,
    emptyText = 'No authorizations found for this user.',
  } = props

  const unflattened = unflattenAuthorizationsList(authorizations.sort((a, b) => {
    return (a.authorizationLevelId || 0) - (b.authorizationLevelId || 0)
  }))

  if(unflattened.length === 0) {
    return (
      <EmptyBucket>
        <ComicallyLargeEmoji>👻</ComicallyLargeEmoji>
        {emptyText}
      </EmptyBucket>
    )
  }

  return (
    <JQUIList>
      {unflattened.map(a => (<AuthListItem authorization={a} key={a.logicalId} />))}
    </JQUIList>
  )
}

export default AuthorizationsHierarchyList

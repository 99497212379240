import { ExtendableError } from './ExtendableError'

export class AuthorizationError extends ExtendableError {
  constructor(message = 'UNAUTHORIZED') {
    super(message)
    this.message = message
  }

  statusCode?: number = 401

  name = 'AuthorizationError';
}

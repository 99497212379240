import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { LinkPlantAddressDTO, PlantDetailDTO } from 'api'

const log = Debug('AL:intercept:patchPlantAddressLinks')

export const patchPlantAddressLinks = new Intercept<LinkPlantAddressDTO>({
  method: 'PATCH',
  pathname: 'v1/plant-address-links/:linkId',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      networkState,
      fetcher,
    } = fetchBag
    if(networkState.online) {
      return fetcher(req, opts)
    }
    return new InterceptedResponse({ status: 501 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(parsedResponse) {
      log('Updating local plant address links', parsedResponse)
      const { addressId, plantId } = parsedResponse
      if(!addressId) {
        log('No addressId found in LinkPlantAddressDTO')
        throw new Error('No addressId found in LinkPlantAddressDTO')
      }
      if(!plantId) {
        log('No plantId found in LinkPlantAddressDTO')
        throw new Error('No plantId found in LinkPlantAddressDTO')
      }
      log('Saving link plant addresses')
      const updatedValues: Pick<PlantDetailDTO, 'addresses'> = {
        addresses: [parsedResponse],
      }
      await entitiesDb.PlantDetails.update(plantId, updatedValues)
    }
  },
})

import React, { ReactNode } from 'react'
import { FaExclamation, FaLink, FaUnlink } from 'react-icons/fa'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

const styles = require('./LinkageIndicator.css')

export type Props = {
  isLinked?: boolean
  isLoading?: boolean
  isLinkConflict?: boolean
  onUnlink?: () => void
  children?: ReactNode
}

export const LinkageIndicator = (props: Props) => {
  const {
    isLinked,
    isLoading,
    isLinkConflict,
    onUnlink,
    children,
  } = props
  const rootCls = clsx(
    styles.root,
    {
      [styles.linked]: isLinked,
      [styles.loading]: isLoading,
      [styles.conflict]: isLinkConflict,
    }
  )
  // const $spinner = <Spinner style={{ fontSize: '0.25em' }} />
  const $spinner = <CircularProgress style={{ padding: 0, margin: 0, height: '1em', width: '1em' }} />
  const $icon = isLinkConflict ? <FaExclamation /> : isLinked ? <FaLink /> : <FaUnlink />
  return (
    <div className={rootCls}>
      <div className={styles.borderContainer}>
        <div className={styles.iconContainer}>
          {isLoading ? $spinner : $icon}
        </div>
        {isLinkConflict ? (
          <span>Conflict</span>
        ) : null}
        {(isLinked && !isLinkConflict && typeof onUnlink === 'function') ? (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={onUnlink}
            disabled={isLoading}
          >
            <FaUnlink className={styles.buttonIcon} />
            Unlink this sample
          </Button>
        ) : <>{children}</>}
      </div>
    </div>
  )
}

export default LinkageIndicator

import { KnownAsset } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const patchAssets = new Intercept<KnownAsset[]>({
  method: 'PATCH',
  pathname: 'v1/assets',
  fetch: async (fetchBag) => {
    const {
      req,
      opts,
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      networkState,
      fetcher,
    } = fetchBag
    if(!(opts && opts.body)) {
      return new InterceptedResponse({ status: 400 })
    }
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    const body = JSON.parse(opts.body as string)
    await entitiesDb.Assets.bulkPut(body)
    const updatedEntities = await entitiesDb.Assets.toArray()
    if(networkState.online) {
      return fetcher(req, opts)
    } else {
      try {
        await tx.Queue.add(new Transaction(req, opts))
      } catch(err) {
        console.warn(err)
      }
    }
    return new InterceptedResponse({ data: updatedEntities, status: 200 })
  },
})

import React from 'react'

import PaddedFlexCentered from 'components/PaddedFlexCentered'

export const Empty = () => (
  <PaddedFlexCentered>
    <p>
      <small>There are no users to manage.</small>
    </p>
  </PaddedFlexCentered>
)

export default Empty

import React from 'react'

import { ContactDTO } from 'api'
import { LocalContact, Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiContactsContext extends ILocalEntityProviderContextBase<LocalContact, Result<ContactDTO>, string> {
 contacts?: LocalContact[]
 db?: Entities
 get: (id: string) => Promise<LocalContact | undefined>
}

export const defaultContext: ILocalApiContactsContext = {
  db: undefined,
  contacts: [],
  fetchState: defaultIApiState,
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
}

export default React.createContext<ILocalApiContactsContext>(defaultContext)

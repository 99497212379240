import styled from "@emotion/styled";
import { Condition } from "../../../types/api_supplimental.d";
import { StatusIndicator } from "components/StatusIndicator";
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faDroplet } from "@fortawesome/free-solid-svg-icons";
import {
  conditionColorMap,
  conditionNameMap,
} from "utils/conditions-utilities";
import Popper from "@material-ui/core/Popper";
import { Fade } from "@material-ui/core";

type TProps = {
  machineCondition: Condition;
  lubricantCondition: Condition;
};
export const AssetStatusIndicator = ({
  machineCondition,
  lubricantCondition,
}: TProps) => {
  const id = "condition-popper";
  const [showMachineCondition, setShowMachineCondition] = useState(false);
  const [showLubricantCondition, setShowLubricantCondition] = useState(false);
  const onMachineConditionHover = () => {
    setShowMachineCondition(true);
  };

  const onMouseLeaveMachineConditiion = () => {
    setShowMachineCondition(false);
  };

  const onLubricantConditionHover = () => {
    setShowLubricantCondition(true);
  };

  const onMouseLeaveLubricantConditiion = () => {
    setShowLubricantCondition(false);
  };

  const machConditionTitle = `Machine Condition: ${
    conditionNameMap[machineCondition] ?? conditionNameMap[Condition.Unknown]
  }`;

  const lubeConditionTitle = `Lubricant Condition: ${
    conditionNameMap[lubricantCondition] ?? conditionNameMap[Condition.Unknown]
  }`;
  const machineConditionRef = useRef<HTMLDivElement>(null);
  const lubeConditionRef = useRef<HTMLDivElement>(null);

  const machColor =
    conditionColorMap[machineCondition] ?? conditionColorMap[Condition.Unknown];
  const lubeColor =
    conditionColorMap[lubricantCondition] ??
    conditionColorMap[Condition.Unknown];

  return (
    <>
      <Popper
        id={id}
        open={showMachineCondition}
        anchorEl={machineConditionRef.current}
        placement={"top"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopperBody>
              <Title>{machConditionTitle}</Title>
            </PopperBody>
          </Fade>
        )}
      </Popper>
      <Popper
        id={id}
        open={showLubricantCondition}
        anchorEl={lubeConditionRef.current}
        placement={"top"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopperBody>
              <Title>{lubeConditionTitle}</Title>
            </PopperBody>
          </Fade>
        )}
      </Popper>
      <AssetStatusIndicatorBody>
        <IconBox
          ref={machineConditionRef}
          onPointerEnter={onMachineConditionHover}
          onPointerLeave={onMouseLeaveMachineConditiion}
        >
          <FontAwesomeIcon
            icon={faGear}
            style={{ fontSize: "24px" }}
            color={machColor}
          />
        </IconBox>
        <IconBox
          ref={lubeConditionRef}
          onPointerEnter={onLubricantConditionHover}
          onPointerLeave={onMouseLeaveLubricantConditiion}
        >
          <FontAwesomeIcon
            icon={faDroplet}
            style={{ fontSize: "24px" }}
            color={lubeColor}
          />
        </IconBox>
      </AssetStatusIndicatorBody>
    </>
  );
};

const AssetStatusIndicatorBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 10px;
  cursor: pointer;
`;

const IconBox = styled.div`
  width: 35px;
  text-align: center;
`;

const SlowSpinningIcon = styled(FontAwesomeIcon)`
  /* -webkit-animation: slow-spin 4s infinite linear;
  animation: slow-spin 4s infinite linear;

  @keyframes slow-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  } */
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin: 0;
`;

const PopperBody = styled.div`
  border: thin solid rgb(100, 100, 100);
  background-color: whitesmoke;
  border-radius: 5px;
  pointer-events: none;
  padding: 5px 15px 5px 15px;
  z-index: 9999;
`;

import React, { CSSProperties, FC, ReactNode, Ref, useCallback, useContext } from 'react'
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso'
import { Box, Typography } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import customersContext from 'contexts/LocalEntities/Customers/context'
import routesContext from 'contexts/LocalEntities/Routes/context'

import { RouteDTO } from 'api'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'

import Empty from './Empty'
import ListItem from './ListItem'
import useRouteGroups from './useRouteGroups'

export interface Props {
  routes: RouteDTO[]
  className?: string
}

type ListContainerProps = {
  listRef: Ref<any>
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

const ListContainer = ({ listRef, style, ...passedProps }: ListContainerProps) => (
  <JQUIList
    ref={listRef}
    style={{ ...style, marginBottom: 0 }}
    {...passedProps}
  />
)

export const RoutesList: FC<RouteComponentProps> = () => {
  const { routes } = useContext(routesContext)
  const { customers } = useContext(customersContext)
  const { groupCounts, groupCustomerIds } = useRouteGroups(routes)

  const getGroupHeader = useCallback((groupIndex: number) => {
    const customerId = groupCustomerIds[groupIndex]
    const customer = customers?.find(c => c.id === customerId)
    return (
      <Box p={1} bgcolor="grey.300" key={groupIndex}>
        <Typography variant="caption">
          {customer
            ? customer.name
            : `Customer #${customerId}`}
        </Typography>
      </Box>
    )
  }, [customers, groupCustomerIds])

  if(!routes || !routes.length) {
    return <Empty />
  }
  return (
    <>
      <AppHeader
        title="Routes"
        fixed
      />
      {groupCounts.length > 1 ? (
        <GroupedVirtuoso
          style={{ position: 'relative' }}
          groupCounts={groupCounts}
          group={getGroupHeader}
          item={index => {
            const route = routes[index]
            if(route) {
              return (
                <Box
                  key={route.id}
                >
                  <ListItem key={route.id} route={route} />
                </Box>
              )
            }
            return (
              <div key={index} />
            )
          }}
        />
      ) : (
        <Virtuoso
          ListContainer={ListContainer}
          overscan={5}
          totalCount={routes.length}
          item={index => {
            const route = routes[index]
            if(route) {
              return (
                <ListItem key={route.id} route={route} />
              )
            }
            return (
              <div key={index} />
            )
          }}
        />
      )}
    </>
  )
}

export default RoutesList

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import offlineAssetsContext from 'contexts/LocalEntities/Assets/context'
import { useSnackbar } from 'notistack'

import { KnownAsset } from 'api_supplimental'
import EmptyBucket from 'components/EmptyBucket'
import JQUIList from 'components/JQUIList'
import TitledSection from 'components/TitledSection'
import useApi from 'hooks/useApi'
import { sortByProp } from 'utils/sortByProp'

import RouteAssetDraggable from './RouteAssetDraggable'

export interface Props {
  routeId: number
  isEdit: boolean
}

const reorder = (list: KnownAsset[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const RouteAssetList = (props: Props) => {
  const {
    routeId,
    isEdit,
  } = props
  const { assets } = useContext(offlineAssetsContext)
  const [assetsPendingPatch, setAssetsPendingPatch] = useState<KnownAsset[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const patchBody = useMemo(() => JSON.stringify(assetsPendingPatch), [assetsPendingPatch])
  const patchFetchState = useApi<KnownAsset[]>(assetsPendingPatch.length > 0 ? 'v1/assets' : undefined, undefined, {
    requestOptions: {
      method: 'PATCH',
      body: patchBody,
    },
  })

  const filteredAssets: KnownAsset[] = useMemo(() => {
    const displayedAssets = assetsPendingPatch.length > 0 ? assetsPendingPatch : assets
    if(displayedAssets && routeId) {
      return sortByProp(displayedAssets.filter(a => (a.id && a.routeId === routeId)), ['name', 'routePosition']) as KnownAsset[]
    }
    return []
  }, [assets, assetsPendingPatch, routeId])

  useEffect(() => {
    if(patchFetchState.statusCode === 200) {
      enqueueSnackbar('Route positions updated', { variant: 'success', preventDuplicate: true })
      setAssetsPendingPatch([])
    }
    if(patchFetchState.error) {
      enqueueSnackbar('Could not save assets', { variant: 'error', preventDuplicate: true })
    }
  }, [enqueueSnackbar, patchFetchState.error, patchFetchState.statusCode])

  const onDragEnd = async (result: DropResult) => {
    if(!result.destination || result.destination.index === result.source.index) {
      return
    }
    const reorderedAssets = reorder(
      filteredAssets || [],
      result.source.index,
      result.destination.index
    )
    const updated = reorderedAssets.map((a, idx) => {
      a.routePosition = idx
      return a
    })
    setAssetsPendingPatch(updated)
  }

  const $assets = filteredAssets && filteredAssets.map((asset, idx) => (
    <RouteAssetDraggable
      asset={asset}
      index={idx}
      key={asset.id}
      isEdit={isEdit}
    />
  ))

  return (
    <TitledSection title="Assets">
      {(filteredAssets && filteredAssets.length > 0) ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <JQUIList ref={provided.innerRef} {...provided.droppableProps}>
                {$assets}
                {provided.placeholder}
              </JQUIList>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyBucket>
          <small>This route has no assets.</small>
        </EmptyBucket>
      )}
    </TitledSection>
  )
}

export default RouteAssetList

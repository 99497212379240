import { useContext } from 'react'
import imagesContext from 'contexts/LocalEntities/Images/context'

import { LocalImageDTO } from 'api_supplimental'

export default function useAssetImages(assetId?: string | number): LocalImageDTO[] | undefined {
  const imagesCtx = useContext(imagesContext)
  return imagesCtx
    .images?.filter(i => i.assetId === assetId)
    .sort((a, b) => {
      return (a.id || '').localeCompare(b.id || '')
    })
}

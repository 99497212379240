import React, { HTMLProps } from 'react'
import clsx from 'clsx'

import { TagIdentifier } from 'components/TagIdentifier'

const styles = require('./UnlinkedAssetTag.css')

export type Props = {
  tagIdentifier: string
} & HTMLProps<HTMLDivElement>

export const UnlinkedAssetTag = ({ tagIdentifier, className, ...passedProps }: Props) => {
  return (
    <div className={clsx(className, styles.root)} {...passedProps}>
      <div>
        <TagIdentifier tagIdentifier={tagIdentifier} /> is not linked to any assets
      </div>
    </div>
  )
}

export default UnlinkedAssetTag

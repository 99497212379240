import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import SnackbarProvider from 'contexts/SnackbarProvider'

import { KnownAsset } from 'api_supplimental'
import PaddedFlexCentered from 'components/PaddedFlexCentered'

import AssetMediaList, { Props as AssetMediaListProps } from './AssetMediaList'

export interface Props {
  asset?: KnownAsset
  assetListProps: Omit<AssetMediaListProps, 'assetId'>
  onFilesSelectedChange: (files: File[]) => void
}

export const AssetMedia = (props: Props) => {
  const {
    asset,
    assetListProps,
    onFilesSelectedChange = () => null,
  } = props
  const fileInput = useRef<HTMLInputElement>(null)

  const handleInputChange = () => {
    if(!fileInput.current) return
    if(fileInput.current.files) {
      onFilesSelectedChange(Array.from(fileInput.current.files))
    }
  }

  return (
    <SnackbarProvider>
      <PaddedFlexCentered>
        <label htmlFor="image-file-input" style={{ flex: 1 }}>
          <Button variant="contained" component="span" style={{ flex: 1 }}>
            Add Photos
          </Button>
        </label>
      </PaddedFlexCentered>
      <AssetMediaList
        asset={asset}
        {...assetListProps}
      />
      <input
        ref={fileInput}
        type="file"
        id="image-file-input"
        name="imageFileInput"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
    </SnackbarProvider>
  )
}

export default AssetMedia

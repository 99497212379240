import { ContactDTO, LinkPlantContactDTO, PlantDetailDTO } from 'api'
import useApi, { IApiState, IUseApiConfig } from 'hooks/useApi'

import { getContactFromPlantRecord } from '.'

export default function usePlantPrimaryContact(
  plantDetails?: PlantDetailDTO,
  initialData?: ContactDTO,
  config: IUseApiConfig = {}
): IApiState<ContactDTO> {
  const primaryContactLink: LinkPlantContactDTO | undefined = getContactFromPlantRecord(plantDetails)
  const url = (primaryContactLink && primaryContactLink.contactId) ? `v1/contacts/${primaryContactLink.contactId}` : undefined
  return useApi<ContactDTO>(url, initialData, config)
}

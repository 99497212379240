import { useEffect, useState } from 'react'

type PermissionState = PermissionStatus['state'] | 'unsupported'

export default function useCameraPermissions(): (PermissionState | undefined) {
  const [permission, setPermission] = useState<PermissionState | undefined>()
  useEffect(() => {
    (async () => {
      function onPermissionChange(this: PermissionStatus) {
        setPermission(this.state)
      }
      if('permissions' in navigator) {
        const result = await navigator.permissions.query({ name: 'camera' })
        setPermission(result.state)
        result.addEventListener('change', onPermissionChange)
        return () => {
          result.removeEventListener('change', onPermissionChange)
        }
      } else {
        setPermission('unsupported')
      }
    })()
  }, [permission])

  return permission
}

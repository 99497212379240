import React, { FC, ReactNode, useRef } from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Account from 'components/Account'
import ManageUsersRouter from 'components/Account/ManageUsersRouter'
import App from 'components/App'
import Asset from 'components/Asset'
import AssetsList from 'components/Assets/AssetsList'
import CreateAssetRouter from 'components/Assets/CreateAssetRouter'
import BottomNav from 'components/BottomNav'
import DebugPage from 'components/DebugPage'
import MoreMenu from 'components/MoreMenu'
import PlantDetail from 'components/PlantDetail'
import PlantsList from 'components/PlantsList/PlantsList'
import RouteDetail from 'components/RouteDetail'
import RoutesList from 'components/Routes/RoutesList'
import SampleRouter from 'components/Sample'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

type TitledRouteProps = {
  title: string,
  children: ReactNode,
} & RouteComponentProps

const TitledRoute: FC<TitledRouteProps> = ({ title, children }: TitledRouteProps) => (
  <>
    <SiteHeadMetadata title={title} />
    {children}
  </>
)

const AssetsRouter: FC<RouteComponentProps> = () => (
  <TitledRoute title="Assets">
    <Router>
      <CreateAssetRouter path="/new/*" />
      <Asset path="/:assetId" />
      <AssetsList default path="/" />
    </Router>
  </TitledRoute>
)

const PlantsRouter: FC<RouteComponentProps> = () => (
  <TitledRoute title="Plants">
    <Router>
      <PlantsList path="/" />
      <PlantDetail path="/:plantId" />
    </Router>
  </TitledRoute>
)

const RoutesRouter: FC<RouteComponentProps> = () => (
  <TitledRoute title="Routes">
    <Router>
      <RouteDetail path="/:routeId" />
      <RoutesList path="/" />
    </Router>
  </TitledRoute>
)

const AccountRouter: FC<RouteComponentProps> = () => (
  <TitledRoute title="My Account">
    <Router>
      <Account path="/" />
      <ManageUsersRouter path="/users/*" />
    </Router>
  </TitledRoute>
)

const DebugRoute: FC<RouteComponentProps> = () => (
  <TitledRoute title="Debug">
    <DebugPage />
  </TitledRoute>
)

const MorePage: FC<RouteComponentProps> = () => {
  return (
    <TitledRoute title="More">
      <SiteHeadMetadata title="More" />
      <MoreMenu />
    </TitledRoute>
  )
}

const WithBottomNavRouter: FC<RouteComponentProps> = () => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  return (
    <div ref={parentRef}>
      <Router>
        <AssetsRouter path="/assets/*" />
        <PlantsRouter path="/plants/*" />
        <RoutesRouter path="/routes/*" />
        <AccountRouter path="/account/*" />
        <MorePage path="/more/" />
        <DebugRoute path="/debug/" />
      </Router>
      <BottomNav parentRef={parentRef} />
    </div>
  )
}

export const AppIndex = () => {
  return (
    <App>
      <SiteHeadMetadata />
      <Router>
        <WithBottomNavRouter path="/app/*" />
        <SampleRouter path="/app/sample/*" />
      </Router>
    </App>
  )
}

export default AppIndex

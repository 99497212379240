/**
 * TODO:
 *  - Provide means to add an asset when empty.
 */
import React, { useContext, useMemo } from 'react'
import offlineAssetsContext from 'contexts/LocalEntities/Assets/context'

import FilterableAssetList from 'components/Assets/FilterableAssetList'
import EmptyBucket from 'components/EmptyBucket'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import TitledSection from 'components/TitledSection'
import { sortByProp } from 'utils/sortByProp'

export interface Props {
  plantId: string
}

export default (props: Props) => {
  if(!props.plantId) {
    throw new Error('No plantId provided')
  }
  const plantId = parseInt(props.plantId, 10)
  const { assets, isLoading } = useContext(offlineAssetsContext)

  const plantAssets = useMemo(() => assets && sortByProp(assets.filter(a => a.plantId === plantId), ['name', 'number']), [assets, plantId])

  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }
  return (
    <TitledSection title="Assets">
      <FilterableAssetList
        assets={plantAssets}
        emptyBucket={
          <EmptyBucket>
            <small>This plant has no assets configured.</small>
          </EmptyBucket>
        }
      />
    </TitledSection>
  )
}

import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { FaSave, FaUndo } from 'react-icons/fa'
import { IconButton } from '@material-ui/core'

const styles = require('./BottleIdInput.css')

export type Props = {
  onChange: (bottleId: string) => void
  onCancel: () => void
}

export const BottleIdInput = (props: Props) => {
  const {
    onChange,
    onCancel,
    ...passedProps
  } = props
  const [val, setVal] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if(inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value)
  }
  const handleReset = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onCancel()
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onChange(val)
  }
  return (
    <form onSubmit={handleSubmit} onReset={handleReset} className={styles.root}>
      <input
        type="number"
        onChange={handleInputChange}
        value={val}
        ref={inputRef}
        {...passedProps}
      />
      <IconButton type="reset" size="small"><FaUndo /></IconButton>
      <IconButton type="submit" size="small"><FaSave /></IconButton>
    </form>
  )
}

export default BottleIdInput

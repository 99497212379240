import React, { ReactNode, useCallback, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { CustomerDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalCustomersContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalCustomersProvider = (props: Props) => {
  const {
    children,
  } = props
  const parseResponse = useCallback((r: Result<CustomerDTO>) => r && r.data, [])
  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<CustomerDTO>, CustomerDTO>({
    url: 'v1/customers/all',
    getTable: (db) => db.Customers,
    parseResponse,
  })
  const customers = useMemo(() => {
    return entities ? sortByProp(entities, ['name']) : []
  }, [entities])

  const value: ILocalCustomersContext = {
    customers,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Customers" blocking>
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalCustomersProvider

import React from 'react'

import { AddressDTO } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiAddressesContext extends ILocalEntityProviderContextBase<AddressDTO, Result<AddressDTO>, number> {
 addresses?: AddressDTO[]
 db?: Entities
 get: (id: number) => Promise<AddressDTO | undefined>
}

export const defaultContext: ILocalApiAddressesContext = {
  db: undefined,
  addresses: [],
  fetchState: defaultIApiState,
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
}

export default React.createContext<ILocalApiAddressesContext>(defaultContext)

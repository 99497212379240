import React, { ReactNode } from 'react'

import { CallCESError } from './CallCESError'

type AssetModifyErrorBoundaryProps = {
  header: ReactNode
}

type AssetModifyErrorBoundaryState = {
  caughtError?: Error
}

const catchableErrors = ['No test packages available for this asset']

export default class AssetModifyErrorBoundary extends React.Component<AssetModifyErrorBoundaryProps, AssetModifyErrorBoundaryState> {
  state: AssetModifyErrorBoundaryState = {
    caughtError: undefined,
  }

  static getDerivedStateFromError(error: any): AssetModifyErrorBoundaryState {
    if(error) {
      if(catchableErrors.includes(error.message)) {
        return {
          caughtError: error,
        }
      }
    }
    return { caughtError: undefined }
  }

  componentDidCatch(error: any) {
    if(!catchableErrors.includes(error.message)) {
      throw error
    }
  }

  render() {
    const { header, children } = this.props
    const {
      caughtError,
    } = this.state
    if(caughtError) {
      return (
        <>
          {header}
          <CallCESError message={caughtError.message} />
        </>
      )
    }

    return children
  }
}

import React, { FC, HTMLAttributes, HTMLProps, ReactNode } from 'react'
import clsx from 'clsx'
import { Assign } from 'utility-types'

const styles = require('./LabelledValue.css')

export const UnknownValue: FC<HTMLProps<HTMLSpanElement>> = ({ children, ...passedProps }) => {
  return <span className={styles.unknownValue} {...passedProps}>{children}</span>
}

export type Props = Assign<HTMLProps<HTMLDivElement>, {
  label: ReactNode
  value?: ReactNode
  units?: ReactNode
  assistiveText?: ReactNode
  required?: boolean
  error?: boolean
  feedback?: ReactNode
  className?: string

}>

export const LabelledValue = ({
  label,
  value,
  units,
  assistiveText,
  className,
  required,
  feedback,
  error,
  children,
  ...passedProps
}: (Props & HTMLAttributes<HTMLDivElement>)) => {
  const requiredFeedback = required ? '*Required' : null
  const $subtext = <small className={styles.assistiveText}>{assistiveText}</small>
  const $feedback = <small className={styles.feedback}>{error ? feedback : requiredFeedback}</small>
  const $units = units && <small className={styles.units}>{units}</small>
  return (
    <div
      className={clsx(styles.root, className, { [styles.error]: error })}
      {...passedProps}
    >
      <label className={styles.label}>{label}</label>
      <div className={styles.value}>{value || children}{$units}</div>
      {$subtext}
      {$feedback}
    </div>
  )
}

export default LabelledValue

import React from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { ContactDTO } from 'api'
import LabelledValue from 'components/LabelledValue'

export type Props = {
  contact: ContactDTO
}
export default ({ contact }: Props) => {
  const {
    firstName = '',
    lastName = '',
    phone,
    email,
  } = contact
  const parsedPhone = phone && parsePhoneNumberFromString(phone, 'US')
  const name = `${firstName} ${lastName}`.trim()
  const $name = (name && name.length > 0) && <LabelledValue label="Name" value={name} />
  const $phone = (phone && phone.length > 0) && (
    <LabelledValue label="Phone">
      {parsedPhone ? (
        <a href={parsedPhone.format('RFC3966')}>{parsedPhone.formatNational()}</a>
      ) : (
        <span>{phone}</span>
      )}
    </LabelledValue>
  )
  const $email = (email && email.length > 0) && (
    <LabelledValue label="Email">
      <a href={`mailto:${email}`}>{email}</a>
    </LabelledValue>
  )
  if(!($name || $phone || $email)) {
    return <span>¯\_(ツ)_/¯</span>
  }
  return (
    <>
      {$name}
      {$phone}
      {$email}
    </>
  )
}

import React, { FC, useContext, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Button } from '@material-ui/core'
import { Redirect, RouteComponentProps } from '@reach/router'
import { currentUserContext } from 'contexts/CurrentUser'

import { UserDTO } from 'api'
import { Result } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import BottomNav from 'components/BottomNav'
import JQUIList, { JQUIListItem } from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import Modal from 'components/Modal'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import Tag from 'components/Tag'
import useApi from 'hooks/useApi'

import CreateUser from '../CreateUser'

import Empty from './Empty'

interface Props {
  companyId: string
}

export const ManageUsers: FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const companyId = props.companyId && parseInt(props.companyId, 10)
  const { currentUser } = useContext(currentUserContext)
  const [isCreateUser, setIsCreateUser] = useState<boolean>()
  const [usersCreated, setUsersCreated] = useState<number>(0)
  const {
    isLoading,
    error,
    data,
  } = useApi<Result<UserDTO>>(companyId ? `v1/companies/${companyId}/users` : undefined, { data: [] }, { dependencies: [usersCreated] })
  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }

  if(!companyId) {
    console.warn('No companyId provided to ManageUsers. Redirecting.')
    return <Redirect to=".." />
  }

  if(error) {
    throw error
  }

  const handleCreateCancel = () => {
    setIsCreateUser(false)
  }

  const handleCreateSubmitted = () => {
    setIsCreateUser(false)
    setUsersCreated(usersCreated + 1)
  }

  const users = data.data

  const body = (() => {
    if(!users || !users.length) {
      return <Empty />
    }
    return (
      <JQUIList>
        {users && users.map(user => {
          if(user.id === currentUser?.id) {
            return (
              <JQUIListItem
                key={user.id}
              >
                {`${user.lastName}, ${user.firstName}`}
                <small><Tag color="info" title="This is your user record">You</Tag> {user.email}</small>
              </JQUIListItem>
            )
          }
          return (
            <JQUIListItemLink
              key={user.id}
              iconRight={<FaAngleRight />}
              linkProps={{ to: `${user.id}` }}
            >
              {`${user.lastName}, ${user.firstName}`}
              <small>{user.email}</small>
            </JQUIListItemLink>
          )
        })}
      </JQUIList>
    )
  })()
  return (
    <>
      <AppHeader
        actionRight={<Button type="button" onClick={() => setIsCreateUser(true)} style={{ lineHeight: 1 }}>Add a user</Button>}
        title="Manage Users"
        fixed
      />
      {body}
      <BottomNav />
      <Modal id="createUserMode" show={isCreateUser}>
        <CreateUser
          companyId={companyId}
          onCancel={handleCreateCancel}
          onSubmitted={handleCreateSubmitted}
        />
      </Modal>
    </>
  )
}

export default ManageUsers

import React from 'react'
import { Box } from '@material-ui/core'

import { PlantDetailDTO } from 'api'
import { Blockquote } from 'components/Blockquote'
import EmptyBucket from 'components/EmptyBucket'
import FetchStateWrapper from 'components/FetchStateWrapper'
import LabelledValue from 'components/LabelledValue'
import MapFrame from 'components/MapFrame'
import OnlineOnly from 'components/OnlineOnly'

import Address from './Address'
import PrimaryContact from './PrimaryContact'
import usePlantPrimaryAddress from './usePlantPrimaryAddress'
import usePlantPrimaryContact from './usePlantPrimaryContact'

export interface Props {
  plantDetails: PlantDetailDTO
}

export default (props: Props) => {
  const {
    plantDetails = {},
  } = props
  const {
    samplingInstructions: siteInstructions,
  } = plantDetails
  const addressFetchState = usePlantPrimaryAddress(plantDetails)
  const contactFetchState = usePlantPrimaryContact(plantDetails)

  return (
    <>
      {siteInstructions ? (
        <Box p={2}>
          <LabelledValue label="Site Instructions">
            <Blockquote style={{ whiteSpace: 'pre-wrap' }}>
              {siteInstructions}
            </Blockquote>
          </LabelledValue>
        </Box>
      ) : (
        <EmptyBucket><small>This plant has no site instructions.</small></EmptyBucket>
      )}
      <FetchStateWrapper
        fetchState={addressFetchState}
        fallback={<EmptyBucket><small>This plant has no address configured.</small></EmptyBucket>}
      >
        {addressFetchState.data && (
          <OnlineOnly fallback={(
            <Box p={2}>
              <Address address={addressFetchState.data} />
            </Box>
          )}
          >
            <MapFrame address={addressFetchState.data} />
          </OnlineOnly>
        )}
      </FetchStateWrapper>
      <FetchStateWrapper
        fetchState={contactFetchState}
        fallback={<EmptyBucket><small>This plant has no primary contact configured.</small></EmptyBucket>}
      >
        {contactFetchState.data && (
          <PrimaryContact contact={contactFetchState.data} />
        )}
      </FetchStateWrapper>
    </>
  )
}

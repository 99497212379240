import React, { HTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'

const styles = require('./Tag.css')

type Props = {
  color?: 'info' | 'warning' | 'danger'
  className?: string
  children?: ReactNode
} & HTMLAttributes<HTMLSpanElement>

export const Tag = (props: Props) => {
  const {
    color,
    className,
    children,
    ...passedProps
  } = props

  const colorClass = color && styles[color]
  return (
    <span {...passedProps} className={clsx(className, styles.root, colorClass)}>{children}</span>
  )
}
export default Tag

import { AuthorizationDTO } from 'api'

export type TNestedAuthorization = AuthorizationDTO & {
  children?: TNestedAuthorization[]
}

type TAuthArray = (AuthorizationDTO | TNestedAuthorization)[]

export default function unflattenAuthorizationsList(authorizations: TAuthArray, parentId?: number | null): TNestedAuthorization[] {
  return (authorizations.filter(a => {
    if(!parentId) {
      return !a.parentAuthorizationLogicalId
    }
    return a.parentAuthorizationLogicalId === parentId
  }) as TNestedAuthorization[])
    .map(a => {
      return {
        ...a,
        children: unflattenAuthorizationsList(authorizations, a.logicalId),
      }
    })
}

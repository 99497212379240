import { KnownAsset } from 'api_supplimental'
import useApi, { IApiState } from 'hooks/useApi'

import { ModifyAssetDetailFormValues } from './ModifyAssetForm'

export const usePatchAsset = (asset?: KnownAsset, formValues?: ModifyAssetDetailFormValues): IApiState<KnownAsset> => {
  const bodyObj: Partial<KnownAsset> | undefined = (() => {
    if(asset && formValues) {
      const ret = {
        id: asset.id,
        ...formValues,
      }
      if(ret.portTypeId && ret.portTypeId < 1) {
        delete ret.portTypeId
      }
      return ret
    }
  })()
  const body = bodyObj ? JSON.stringify(bodyObj) : undefined
  const patchState = useApi<KnownAsset>((asset && body) ? `v1/assets/${asset.id}` : undefined, undefined, {
    requestOptions: {
      method: 'PATCH',
      body,
    },
    dependencies: [body],
  })

  return patchState
}

export default usePatchAsset

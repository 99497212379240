import React, { FC } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Redirect, RouteComponentProps } from '@reach/router'

import { CompanyDetailDTO } from 'api'
import { Result } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

export const SelectCompany: FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const {
    isLoading,
    error,
    data,
  } = useApi<Result<CompanyDetailDTO>>('v1/companies/all')
  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }
  if(error) {
    throw error
  }

  const companies = data && data.data

  const body = (() => {
    if(!companies || !companies.length) {
      return (
        <PaddedFlexCentered>
          <p>No companies to select from</p>
        </PaddedFlexCentered>
      )
    }
    if(companies.length === 1) {
      if(props.navigate) {
        props.navigate(`./${companies[0].id}/`, { replace: true })
        return null
      }
      return <Redirect to={`./${companies[0].id}/`} />
    }
    return (
      <JQUIList>
        {companies && companies.map(company => (
          <JQUIListItemLink
            key={company.id}
            iconRight={<FaAngleRight />}
            linkProps={{ to: `./${company.id}/` }}
          >
            {company.name}
          </JQUIListItemLink>
        ))}
      </JQUIList>
    )
  })()
  return (
    <>
      <AppHeader
        title="Select a Company"
        fixed
      />
      {body}
    </>
  )
}

export default SelectCompany

import { NetworkStateContextType } from "contexts/NetworkState/context";
import { ITransactionsDbContext } from "contexts/Transactions/transactionsDbContext";

import { UserDTO } from "api";
import Entities from "idb/Entities";
import { Transaction } from "idb/Transactions";

import InterceptedResponse from "./InterceptedResponse";

export type HTTPRequestMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "PATCH"
  | "DELETE"
  | "OPTIONS"
  | "HEAD";

export type FetchBag = {
  entitiesDb: Entities;
  transactionsContext: ITransactionsDbContext;
  transaction?: Transaction;
  match?: IInterceptMatch;
  fetcher: (url: RequestInfo, opts?: RequestInit) => Promise<Response>;
  networkState: NetworkStateContextType;
  req: RequestInfo;
  opts?: RequestInit;
  currentUser: UserDTO;
};

export interface ParseBag<T> extends FetchBag {
  originalResponse: InterceptedResponse<T> | Response;
  parsedResponse: T;
}

type IRoute = {
  path: string;
  default?: string;
  value?: string;
};

interface IInterceptRoute extends IRoute {
  intercept: Intercept;
}

export interface IInterceptMatch {
  route: IInterceptRoute;
  params: {
    [paramName: string]: string;
  };
  uri: string;
}

export interface IInterceptConfig<T = any> {
  method: HTTPRequestMethod;
  pathname: string;
  /**
   * Handle the request.
   */
  fetch: (fetchBag: FetchBag) => Promise<InterceptedResponse<T> | Response>;
  /**
   * Handle the response after initial fetch (intercepted or not).
   */
  postParse?: (parseBag: ParseBag<T>) => Promise<void>;
  /**
   * Performa an action after intercepted fetch is processed.
   */
  postTransaction?: (parseBag: ParseBag<T>) => Promise<void>;
}

export default class Intercept<T = any> {
  method: HTTPRequestMethod = "GET";
  /**
   * The URL path to match. You can use path parameters here (eg:
   * "/assets/:assetId") just like route matching.
   */
  pathname: string = "";
  /**
   * Allows the intercept to update it's backing database after an online
   * request has been resolved.
   */
  postParse?: (parseBag: ParseBag<T>) => Promise<void>;
  /**
   * Allows the intercept to update entities or transactions db after the
   * intercepted transaction has been parsed.
   */
  postTransaction?: (parseBag: ParseBag<T>) => Promise<void>;

  constructor(
    p: Partial<IInterceptConfig<T>> & Pick<IInterceptConfig<T>, "pathname">
  ) {
    if (p.fetch) {
      this.fetch = p.fetch;
    }
    if (p.method) {
      this.method = p.method;
    }
    if (p.postParse) {
      this.postParse = p.postParse;
    }
    if (p.postTransaction) {
      this.postTransaction = p.postTransaction;
    }
    this.pathname = p.pathname;
  }

  fetch = async ({
    fetcher,
    req: url,
    opts,
  }: FetchBag): Promise<InterceptedResponse<T> | Response> => {
    console.warn(
      `The fetch method of an intercept (for pathname: "${this.pathname}") has not been defined. This is an abstract method that must be provided in the Intercept instance implementation.`
    );
    return fetcher(url, opts);
  };
}

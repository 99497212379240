import Debug from 'debug'

import Entities from 'idb/Entities'
import { assetIsRecentlySampled } from 'utils/asset-utils'

const log = Debug('AL:intercept:updateRouteProgress')

export const updateRouteProgress = async (db: Entities, routeId?: number): Promise<void> => {
  if(routeId) {
    log(`Updating route completion percentage for routeId: ${routeId}`)
    const routeAssets = await db.Assets.where('routeId').equals(routeId).toArray()
    const numRecentlySampled = routeAssets.filter(assetIsRecentlySampled).length
    const percentRecentlySampled = numRecentlySampled / routeAssets.length * 100 // why!!?!
    log(`Route assets with recent samples: ${numRecentlySampled} (of ${routeAssets.length})`)
    await db.Routes.update(routeId, { percentRecentlySampled })
  } else {
    log('No routeId to update')
  }
}

import React from "react";
import { Button } from "@material-ui/core";

import AppHeader from "components/AppHeader";
import Scanner, { Props as ScannerProps } from "components/Sample/Scanner";

const styles = require("./TagAssetModal.css");

type Props = {
  title: string;
  subtitle?: string;
  onClose: () => void;
} & Partial<ScannerProps>;

export const TagAssetModal = (props: Props) => {
  const { title, subtitle, onClose, ...passedProps } = props;
  return (
    <div className={styles.root}>
      <AppHeader
        title={title}
        subtitle={subtitle}
        actionLeft={
          <Button type="reset" onClick={onClose}>
            Cancel
          </Button>
        }
      />
      <Scanner
        cameraTimeout={null}
        scanOnMount
        className={styles.scanner}
        scanners={["QRCode"]}
        {...passedProps}
      />
    </div>
  );
};

export default TagAssetModal;

import React, { ReactNode, useCallback, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { FluidDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiFluidsContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalFluidsProvider = (props: Props) => {
  const {
    children,
  } = props

  const parseResponse = useCallback((r: Result<FluidDTO>) => r && r.data, [])

  const {
    fetchState,
    entities,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<FluidDTO>, FluidDTO>({
    url: 'v1/fluids/all',
    getTable: (db) => db.Fluids,
    parseResponse,
  })

  const fluids = useMemo(() => (entities) && sortByProp(entities, ['name']), [entities])

  const value: ILocalApiFluidsContext = {
    fluids,
    get: (id) => Promise.resolve(fluids && fluids.find(f => f.id === id)),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Fluids">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalFluidsProvider

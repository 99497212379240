import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { LocalImageDTO } from 'api_supplimental'

import useDeleteImage from './useDeleteImage'

export interface Props {
  pointImage: LocalImageDTO
  onComplete: (pointImage: LocalImageDTO) => void
}

export const DoImageDelete = (props: Props): null => {
  const {
    pointImage,
    onComplete,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const fetchState = useDeleteImage(pointImage)

  useEffect(() => {
    if(fetchState.statusCode && fetchState.statusCode >= 200 && fetchState.statusCode < 300) {
      onComplete(pointImage)
    }
  }, [fetchState, pointImage, onComplete])

  useEffect(() => {
    if(fetchState.error) {
      console.error(fetchState.error)
      enqueueSnackbar(`Error: ${fetchState.error.message}`, { variant: 'error' })
    }
  }, [enqueueSnackbar, fetchState.error])

  return null
}

export default DoImageDelete

import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import { currentUserContext } from 'contexts/CurrentUser'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { SampleDTO } from 'api'
import { LocalSample, Result } from 'api_supplimental'
import { BOTTLE_STATUS } from 'utils/bottle-status'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiSamplesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalSamplesProvider = (props: Props) => {
  const {
    children,
  } = props
  const { currentUser } = useContext(currentUserContext)
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((d: Result<SampleDTO>): LocalSample[] | undefined => {
    return d && d.data && d.data.map(sample => {
      if(sample.sampledAt) {
        (sample as LocalSample).sampledAt = new Date(sample.sampledAt)
      }
      return sample as LocalSample
    })
  }, [])

  const {
    fetchState,
    entities,
    isResolving,
    db,
  } = useLocalAndPopulateFromFetch<Result<SampleDTO>, LocalSample, string>({
    url: `v1/customers/${activeCustomer.id}/samples/all`,
    getTable: (db) => db.Samples,
    parseResponse,
  })

  const samples = useMemo(() => (entities) && sortByProp(entities, 'sampledAt'), [entities])

  const value: ILocalApiSamplesContext = {
    samples,
    get: (id: string) => Promise.resolve(samples && samples.find(f => f.identifier === id)),
    fetchState,
    getUsersRecentSamples: useCallback(async () => {
      if(!(currentUser && currentUser.id && db)) {
        return
      }
      const ordered = await db.Samples.where('userId').equals(currentUser.id).sortBy('sampledAt')
      const filtered = ordered.reverse().filter(sample => (sample.status && sample.status <= BOTTLE_STATUS.Linked))
      return filtered
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, db, samples]),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Samples">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalSamplesProvider

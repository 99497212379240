import { urlParamsRegex, urlRegex } from "./regex-library";

export function quickStringHash(s: string): number {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export function paramsfromURL(url: string) {
  const index = url.indexOf("?");
  const subs = index > 0 ? url.slice(index) : url;
  return new URLSearchParams(subs);
}
export type ParameterSet = {
  parameter: string;
  values: string[];
};

export class URLParseResult {
  isUrl: boolean;
  urlParameters: ParameterSet[];

  constructor() {
    this.isUrl = false;
    this.urlParameters = [];
  }
}

/**Currently only functions on the first url parameter and only returns a single value. */
export function parseUrlString(url: string): URLParseResult {
  const result = new URLParseResult();

  if (!url) return result;

  const urlParseResult = urlRegex.exec(url);
  const isUrlResult = urlParseResult && urlParseResult[0] != null;

  result.isUrl = !!isUrlResult;

  if (!isUrlResult) return result;
  if (urlParseResult.length < 4) return result;

  const paramString = urlParseResult[4];
  const paramsMatch = urlParamsRegex.exec(paramString);
  if (!paramsMatch || paramsMatch.length < 3) return result;

  const parameterName = paramsMatch[2];
  const parameterValue = paramsMatch[3];

  result.urlParameters.push({
    parameter: parameterName,
    values: [parameterValue],
  });

  return result;
}

import React from 'react'

import { RouteDTO } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalRoutesContext extends ILocalEntityProviderContextBase<RouteDTO, Result<RouteDTO>> {
 routes?: RouteDTO[]
}

export const defaultContext: ILocalRoutesContext = {
  routes: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalRoutesContext>(defaultContext)

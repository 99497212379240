import React, { useMemo } from 'react'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Link } from 'gatsby'
import * as Yup from 'yup'

import { NewSampleDTO, SampleDTO } from 'api'
import { KnownAsset, SampleLinkSansStatus } from 'api_supplimental'
import InlineError from 'components/Errors/InlineError'
import FluidSelect from 'components/FluidSelect'
import LabelledValue from 'components/LabelledValue'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import TitledSection from 'components/TitledSection'
import { IApiState } from 'hooks/useApi'

import SampleSourceRating from './SampleSourceRating'

const styles = require('./SampleForm.css')

export type Props = {
  sampleFetchState: IApiState<SampleDTO | NewSampleDTO | SampleLinkSansStatus | undefined>
  asset?: KnownAsset
  onSubmit: (sample: SampleDTO) => void
}

export type SampleFormValues = {
  comments?: string
  oilChangedAt?: string
  assetHours?: string | number
  fluidHours?: string | number
  fluidId?: number
  sampleSourceRating?: string | number
}

export const SampleFormSchema = Yup.object().shape({
  comments: Yup.string(),
  oilChangedAt: Yup.date()
    .max(new Date(), 'Please select a date in the past'),
  assetHours: Yup.number()
    .integer('Please enter a valid number of hours')
    .min(0, 'Machine hours must be greater than zero'),
  fluidHours: Yup.number()
    .integer('Please enter a valid number of hours')
    .min(0, 'Lubricant hours must be greater than zero'),
  fluidId: Yup.number()
    .required()
    .integer()
    .min(0, 'Please specify the fluid'),
  sampleSourceRating: Yup.number()
    .integer(),
})

export const SampleForm = (props: Props) => {
  const {
    sampleFetchState: {
      isLoading,
      error,
      data: sample,
    },
    onSubmit,
    asset,
  } = props

  const dateStr = useMemo<string | null>(() => {
    if(sample) {
      try {
        const dateField = 'sampledAt' in sample ? sample.sampledAt : 'createdAt' in sample ? sample.createdAt : undefined
        if(!dateField) {
          return null
        }
        const d = dateField && new Date(dateField)
        const dateIsValid = d instanceof Date
        return (dateIsValid && d) ? d.toLocaleString() : null
      } catch(err) {
        console.warn('Error while parsing sampledAt:', err)
      }
    }
    return null
  }, [sample])

  if(error) {
    Sentry.captureException(error)
    return (
      <PaddedFlexCentered>
        <section className={styles.sampleError}>
          <div role="img" aria-label="ouch">🤕</div>
          <p>
            Something went wrong while looking up this sample.
          </p>
          <p>{error.message}</p>
        </section>
      </PaddedFlexCentered>
    )
  }
  if(isLoading) {
    return <PaddedFlexCentered><Spinner /></PaddedFlexCentered>
  }
  if(!sample) {
    return <PaddedFlexCentered><InlineError message="No sample provided" /></PaddedFlexCentered>
  }

  const dateFormatter = (date?: Date | string): string => {
    if(!date) return ''
    if(typeof date === 'string') {
      const dateStringRE = /(\d{4}-\d{2}-\d{2}).*?/
      const match = date.match(dateStringRE)
      if(match && match.length > 1) {
        return match[1]
      }
      console.warn(`Unknown string passed as date to dateFormatter: "${date}"`)
    }
    if(date instanceof Date && !isNaN(date.valueOf())) {
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    }
    console.warn('Got unknown type for for date:', date)
    return ''
  }

  const initialValues: SampleFormValues = {
    comments: sample.comments || '',
    oilChangedAt: dateFormatter(sample.oilChangedAt),
    assetHours: (sample.assetHours && sample.assetHours.toString()) || '',
    fluidHours: (sample.fluidHours && sample.fluidHours.toString()) || '',
    fluidId: sample.fluidId || (asset && asset.fluid.id) || -1,
    sampleSourceRating: asset && asset.sampleSourceRating,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SampleFormSchema}
      onSubmit={(values: SampleFormValues) => {
        const newSample: SampleDTO = {
          comments: values.comments,
        }
        if(values.assetHours) {
          newSample.assetHours = Number(values.assetHours)
        }
        if(values.fluidHours) {
          newSample.fluidHours = Number(values.fluidHours)
        }
        if(values.fluidId) {
          newSample.fluidId = Number(values.fluidId)
        }
        if(values.oilChangedAt && values.oilChangedAt.length) {
          newSample.oilChangedAt = values.oilChangedAt
        }
        if(values.sampleSourceRating) {
          newSample.sampleSourceRating = Number(values.sampleSourceRating)
        }
        onSubmit(newSample)
      }}
      isInitialValid
      render={(formikBag: FormikProps<SampleFormValues>) => {
        const {
          errors,
          touched,
          isValid,
        } = formikBag
        return (
          <Form className={styles.root}>
            <LabelledValue label="Sample Date" className={styles.labelledValue}>
              <span>{dateStr}</span>
            </LabelledValue>

            <LabelledValue
              label="Notes"
              value={<Field name="comments" component="textarea" />}
              error={!!(errors.comments && touched.comments)}
              feedback={<ErrorMessage name="comments" />}
              className={styles.labelledValue}
            />

            <LabelledValue
              label="Most Recent Oil Change Date"
              value={<Field name="oilChangedAt" type="date" max={new Date().toISOString().slice(0, 10)} />}
              error={!!(errors.oilChangedAt && touched.oilChangedAt)}
              feedback={<ErrorMessage name="oilChangedAt" />}
              className={styles.labelledValue}
            />

            <LabelledValue
              label="Machine Hours"
              value={<Field name="assetHours" type="number" />}
              error={!!(errors.assetHours && touched.assetHours)}
              feedback={<ErrorMessage name="assetHours" />}
              className={styles.labelledValue}
            />
            <Field
              name="sampleSourceRating"
              render={({ field }: FieldProps) => (
                <SampleSourceRating
                  labelledValueProps={{ className: styles.labelledValue }}
                  {...field}
                />
              )}
            />
            {asset && (
              <LabelledValue label="Plant" className={styles.labelledValue}>
                <Link to={`/app/plants/${asset.plantId}`}>{asset.plantName || asset.plantId}</Link>
              </LabelledValue>
            )}

            <TitledSection title="Lubricant">
              <LabelledValue
                label="Lubricant Hours"
                value={<Field name="fluidHours" type="number" />}
                error={!!(errors.fluidHours && touched.fluidHours)}
                feedback={<ErrorMessage name="fluidHours" />}
                className={styles.labelledValue}
              />
              <Field
                name="fluidId"
                render={({ field }: FieldProps) => (
                  <FluidSelect
                    labelledValueProps={{ className: styles.labelledValue }}
                    {...field}
                  />
                )}
              />
            </TitledSection>

            <div className={styles.footer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid}
              >
                Save and New
              </Button>
            </div>
          </Form>
        )
      }}
    />
  )
}
export default SampleForm

import React, { ReactNode, useCallback, useContext } from 'react'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { ImageDTO } from 'api'
import { LocalImageDTO } from 'api_supplimental'

import AssetImagesPrecacher from './AssetImagesPrecacher'
import context, { ILocalApiImagesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalImagesProvider = (props: Props) => {
  const {
    children,
  } = props
  const { activeCustomer } = useContext(activeCustomerContext)

  const parseResponse = useCallback((d: ImageDTO[]): LocalImageDTO[] => {
    return d?.map(image => {
      const localImg: LocalImageDTO = image as unknown as LocalImageDTO
      localImg.id = image.id.toString()
      if(image.assetId) {
        localImg.assetId = image.assetId.toString()
      }
      return localImg as LocalImageDTO
    })
  }, [])

  const {
    fetchState,
    entities: images,
    table,
    isResolving,
    db,
  } = useLocalAndPopulateFromFetch<ImageDTO[], LocalImageDTO, string>({
    url: `v1/customers/${activeCustomer.id}/images/all`,
    getTable: (db) => db.Images,
    parseResponse,
  })

  const value: ILocalApiImagesContext = {
    images,
    fetchState,
    db,
    get: async (guid: string) => table.get(guid),
    getByAssetId: async (assetId: string | number) => {
      return table.where('assetId').equals(assetId).toArray()
    },
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} blocking loadingMessage="Loading Images">
        <AssetImagesPrecacher />
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalImagesProvider

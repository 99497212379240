import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'

import { NewUserDTO, UserDTO } from 'api'
import AppHeader from 'components/AppHeader'
import InlineError from 'components/Errors/InlineError'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import UserDetailModify, { Props as UserDetailModifyProps, UserDetailFormValues } from 'components/UserDetail/UserDetailModify'
import useApi, { IApiState } from 'hooks/useApi'

const styles = require('./CreateUser.css')

function usePutUser(user?: NewUserDTO): IApiState<UserDTO, NewUserDTO> {
  const body = JSON.stringify(user)
  const url = user ? 'v1/users' : undefined
  return useApi<UserDTO, NewUserDTO>(url, undefined, {
    requestOptions: {
      method: 'PUT',
      body,
    },
    dependencies: [body],
  })
}

interface Props extends UserDetailModifyProps {
  companyId: number
  onCancel: () => void
  onSubmitted: (user: UserDTO) => void
}
export const CreateUser = (props: Props) => {
  const {
    companyId,
    onCancel,
    onSubmitted,
    ...passedProps
  } = props
  const [newUserDetails, setNewUserDetails] = useState<NewUserDTO>()
  const {
    error,
    isLoading,
    data: nextModifiedUser,
    fieldErrors,
  } = usePutUser(newUserDetails)

  const handleSubmit = (user: UserDetailFormValues) => {
    const newUser: NewUserDTO = {
      ...user,
      companyId,
    }
    setNewUserDetails(newUser)
  }

  useEffect(() => {
    if(newUserDetails && !isLoading && !error && nextModifiedUser) {
      onSubmitted(nextModifiedUser)
    }
  }, [newUserDetails, onSubmitted, nextModifiedUser, isLoading, error])

  const $header = (
    <AppHeader
      actionLeft={<Button type="button" onClick={onCancel}>Cancel</Button>}
      actionRight={(isLoading || error) ? undefined : <Button type="submit">Create</Button>}
      title="Create New User"
      fixed
    />
  )
  const body = (() => {
    if(error) {
      Sentry.captureException(error)
      if(!fieldErrors || Object.keys(fieldErrors).length === 0) {
        return (
          <>
            {$header}
            <PaddedFlexCentered>
              <InlineError message={error.message} />
            </PaddedFlexCentered>
          </>
        )
      }
    }
    return (
      <UserDetailModify
        onSubmit={handleSubmit}
        {...passedProps}
        defaultValues={newUserDetails}
        fieldErrors={fieldErrors}
        renderHeader={() => $header}
        isLoading={isLoading}
        renderFooter={({ isValid }) => (
          <footer>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              Create User
            </Button>
          </footer>
        )}
      />
    )
  })()
  return (
    <div className={styles.root}>
      {body}
    </div>
  )
}

export default CreateUser

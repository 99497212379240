import React, { FC } from 'react'
import { Button } from '@material-ui/core'

const styles = require('./ConfirmUnlink.css')

type Props = {
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmUnlink: FC<Props> = ({ onConfirm, onCancel }: Props) => {
  return (
    <div className={styles.root}>
      <h1>Are you sure you wish to unlink this sample?</h1>
      <p className={styles.colorDanger}>
        The following information <strong>will be lost</strong>:
      </p>
      <ul className={styles.colorDanger}>
        <li>Sample notes</li>
        <li>Oil change date</li>
        <li>Machine Hours</li>
        <li>Lubricant Hours</li>
        <li>Lubricant overrides</li>
      </ul>
      <footer className={styles.footer}>
        <p>
          <small>Once unlinked, this sample bottle will be made available to link to another asset.</small>
        </p>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          Unlink this sample
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </footer>
    </div>
  )
}

export default ConfirmUnlink

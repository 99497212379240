import { LocalContact, Result } from 'api_supplimental'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getContacts = new Intercept<Result<LocalContact>>({
  pathname: 'v1/contacts/all',
  fetch: async ({ entitiesDb }) => {
    const data = await entitiesDb.Contacts.toCollection().sortBy('lastName')
    return new InterceptedResponse({ data: { data }, status: 200 })
  },
})

import { GeoLocationSensorState } from 'react-use/lib/useGeolocation'
import * as Sentry from '@sentry/browser'

import { IApiState } from 'hooks/useApi'
import useFetch from 'hooks/useFetch'

export type GeocoderResponse = {
  results?: google.maps.GeocoderResult[]
  status: google.maps.GeocoderStatus
}

type Config = {
  key?: string
}
export const useReverseGeocode = (location: (GeoLocationSensorState | undefined) = undefined, config: Config): IApiState<GeocoderResponse> => {
  const { key } = config
  const serviceUrlBase = 'https://maps.googleapis.com/maps/api/geocode/json'// ?latlng=40.714224,-73.961452&key=AIzaSyDBFIjFoGgVTCen6S_JpxPCsuwpQ6H2e6A
  const url = new URL(serviceUrlBase)
  if(location) {
    url.searchParams.set('latlng', `${location.latitude},${location.longitude}`)
  }
  if(key) {
    url.searchParams.set('key', key)
  }
  const fetchUrl = (location && key) ? url.toString() : undefined
  const fetchState = useFetch<GeocoderResponse>(fetchUrl, undefined, { noDefaultContentType: true, requestOptions: { mode: 'cors' } })
  if(fetchState.error) {
    Sentry.captureException(fetchState.error)
  }
  return fetchState
}

export default useReverseGeocode

import { useContext, useEffect, useState } from 'react'
import assetsContext from 'contexts/LocalEntities/Assets/context'
import Debug from 'debug'

import { KnownAsset } from 'api_supplimental'
import { assetIsRecentlySampled } from 'utils/asset-utils'

import useMostRecentSample from './useMostRecentSample'

const log = Debug('AL:useSuggestedNextAction')

export type AssetSuggestion = {
  derrivedFrom: 'route' | 'plant' | 'asset'
  asset: KnownAsset
}
export type TReturn = {
  nextAssetSuggestion?: AssetSuggestion
}

export function useSuggestedNextAction(): TReturn {
  const [nextAssetSuggestion, setNextAssetSuggestion] = useState<AssetSuggestion>()
  const { mostRecentSample } = useMostRecentSample()
  const { db } = useContext(assetsContext)

  useEffect(() => {
    let mounted = true;
    (async () => {
      if(mostRecentSample) {
        if(db && mostRecentSample.assetId) {
          const mostRecenttAsset = await db.Assets.get(mostRecentSample.assetId.toString())
          const routeId = mostRecenttAsset && mostRecenttAsset.routeId
          if(mostRecenttAsset && routeId) {
            const routeAssets = await db.Assets.where('routeId').equals(routeId).sortBy('routePosition')
            if(routeAssets && routeAssets.length > 0) {
              const indexOfMostRecent = routeAssets.findIndex(a => a.id === mostRecenttAsset.id)
              const nextRouteAsset =
                routeAssets.find((a, i) => !assetIsRecentlySampled(a) && i > indexOfMostRecent) ||
                routeAssets.find(a => !assetIsRecentlySampled(a))
              if(nextRouteAsset) {
                if(mounted) {
                  log('Found next suggested action:', nextRouteAsset)
                  setNextAssetSuggestion({
                    derrivedFrom: 'route',
                    asset: nextRouteAsset,
                  })
                }
              }
            }
          }
        }
      }
    })()
    return () => { mounted = false }
  }, [db, mostRecentSample])

  return {
    nextAssetSuggestion,
  }
}

export default useSuggestedNextAction

import React, { FC, useEffect } from 'react'
import { FaAngleRight, FaSignOutAlt } from 'react-icons/fa'
import { Box } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'

import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'

import ActiveCustomerDisplay from './ActiveCustomerDisplay'
import { AppVersion } from './AppVersion'

export const MoreMenu: FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <AppHeader
        title="More"
        fixed
      />
      <ActiveCustomerDisplay />
      <JQUIList>
        <JQUIListItemLink
          key="account"
          iconRight={<FaAngleRight />}
          linkProps={{ to: '/app/account' }}
        >
          My Account
        </JQUIListItemLink>
        <JQUIListItemLink
          key="help"
          iconRight={<FaAngleRight />}
          linkProps={{ to: '/help' }}
        >
          Help
        </JQUIListItemLink>
        <JQUIListItemLink
          key="privacy"
          iconRight={<FaAngleRight />}
          linkProps={{ to: '/privacy' }}
        >
          Privacy
        </JQUIListItemLink>
        {process.env.NODE_ENV === 'development' && (
          <JQUIListItemLink
            key="debug"
            iconRight={<FaAngleRight />}
            linkProps={{ to: '/app/debug' }}
          >
            Debug
          </JQUIListItemLink>
        )}
        <JQUIListItemLink
          key="logout"
          iconRight={<FaSignOutAlt />}
          linkProps={{ to: '/logout' }}
        >
          Log Out
        </JQUIListItemLink>
      </JQUIList>
      <Box my={3} mx={2} marginTop={24} textAlign="center" style={{ opacity: 0.5 }}>
        <AppVersion component="div" variant="subtitle2" />
      </Box>
    </>
  )
}

export default MoreMenu

import React, { ReactNode } from 'react'

import LinkageIndicator from './LinkageIndicator'

const styles = require('./Linkage.css')

export type Props = {
  asset: ReactNode
  bottle: ReactNode
  isLoading?: boolean
  isLinked?: boolean
  isLinkConflict?: boolean
  onUnlink?: () => void
  children?: ReactNode
}

export const Linkage = (props: Props) => {
  const {
    asset,
    bottle,
    isLinked,
    isLoading,
    isLinkConflict,
    onUnlink,
    children,
  } = props
  return (
    <div className={styles.root}>
      {asset}
      <LinkageIndicator
        isLinked={isLinked}
        isLinkConflict={isLinkConflict}
        onUnlink={onUnlink}
        isLoading={isLoading}
      >
        {children}
      </LinkageIndicator>
      {bottle}
    </div>
  )
}

export default Linkage

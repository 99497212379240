import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import { networkStateContext } from 'contexts/NetworkState'

import { PlantDetailDTO, PlantDTO } from 'api'
import AppHeader from 'components/AppHeader'
import Modal from 'components/Modal'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import PlantDetailModify from './Modify'
import PlantAssetList from './PlantAssetList'
import PlantDetailHeader from './PlantDetailHeader'

type Props = {
  plantId?: string
}

const PlantDetail = (props: RouteComponentProps<Props>) => {
  const plantId = props.plantId
  const [updatedDetails, setUpdatedDetails] = useState<PlantDTO>()
  const { online } = useContext(networkStateContext)

  if(!plantId) {
    throw new Error('No plantId provided')
  }

  const {
    error,
    isLoading,
    data: plantDetails,
  } = useApi<PlantDetailDTO>(`/v1/plants/${plantId}/details`, undefined, { dependencies: [updatedDetails] })

  const [isEdit, setEdit] = useState(false)

  if(isLoading && !plantDetails) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }

  if(error) {
    throw error
  }

  const handleModifyClick = () => {
    setEdit(true)
  }

  const handleModified = (next: PlantDTO) => {
    setEdit(false)
    setUpdatedDetails(next)
  }

  const title: string = (plantDetails && plantDetails.name) ? plantDetails.name : '...'
  return (
    <>
      <AppHeader
        title={title}
        actionRight={online ? <Button type="button" onClick={handleModifyClick}>Modify</Button> : undefined}
        fixed
        getLayoutParent="parent"
      />
      <Helmet title={`Plant: ${title}`} />
      <PlantDetailHeader plantDetails={plantDetails} />
      <PlantAssetList plantId={plantId} />
      <Modal id="plantDetailsModify" show={isEdit}>
        <PlantDetailModify
          plantDetails={plantDetails}
          onCancel={() => { setEdit(false) }}
          onSubmitted={handleModified}
        />
      </Modal>
    </>
  )
}

export default PlantDetail

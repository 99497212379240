import React, { HTMLProps, useState } from "react";
import { FaCamera, FaCog, FaSadTear } from "react-icons/fa";
import clsx from "clsx";
import prettyMs from "pretty-ms";

import { useTimeout } from "hooks/useTimeout";

import ActiveScanner, { Props as ActiveScannerProps } from "./ActiveScanner";
import useCameraPermissions from "./useCameraPermissions";
// import useVideoDevices from './useVideoDevices'

const styles = require("./Scanner.css");

export type Props = ActiveScannerProps & {
  cameraTimeout?: number | null;
  scanOnMount?: boolean;
};

const Wrapper = ({
  children,
  className,
  ...passedProps
}: HTMLProps<HTMLDivElement>) => (
  <div className={clsx(styles.root, className)} {...passedProps}>
    <div className={clsx(styles.centerWrapper)}>{children}</div>
  </div>
);

export const Scanner = (props: Props) => {
  const {
    cameraTimeout = 60000,
    scanOnMount = true,
    onBottleScan,
    onTagScan,
    onEquipmentIDScan,
    className,
    ...passedProps
  } = props;

  const [scan, setScan] = useState(scanOnMount);
  const [lastScanned, setLastScanned] = useState<Date>();
  const permission = useCameraPermissions();

  const handleTouchStart = () => {
    setScan(true);
  };

  const handleBottleScan = (val: string) => {
    setLastScanned(new Date());
    onBottleScan && onBottleScan(val);
  };

  const handleEquipmentBarcodeScan = (equipmentID: string) => {
    if (!equipmentID) return;
    setLastScanned(new Date());
    if (onEquipmentIDScan && typeof onEquipmentIDScan === "function") {
      onEquipmentIDScan && onEquipmentIDScan(equipmentID);
    }
  };

  const handleTagScan = (val: string) => {
    setLastScanned(new Date());
    onTagScan && onTagScan(val);
  };

  useTimeout(
    () => {
      setScan(false);
    },
    scan ? cameraTimeout : null,
    [lastScanned]
  );

  const commonWrapperProps = {
    className,
    onTouchStart: handleTouchStart,
    onClick: handleTouchStart,
  };

  const $prompt = (
    <Wrapper {...commonWrapperProps}>
      <small>Touch to grant camera access</small>
      <FaCog />
    </Wrapper>
  );

  const $scanner = (
    <ActiveScanner
      className={className}
      onBottleScan={handleBottleScan}
      onTagScan={handleTagScan}
      onEquipmentIDScan={handleEquipmentBarcodeScan}
      {...passedProps}
    />
  );

  const $timedOut = (
    <Wrapper {...commonWrapperProps}>
      <small>Touch to start QR scanner</small>
      <FaCamera />
      {cameraTimeout && (
        <small>
          Scanner will deactivate after{" "}
          <strong>{prettyMs(cameraTimeout)}</strong> to conserve your battery.
        </small>
      )}
    </Wrapper>
  );

  switch (permission) {
    case "unsupported":
    case "granted":
      return scan ? $scanner : $timedOut;
    case "denied":
      return (
        <Wrapper {...commonWrapperProps}>
          <span>Could not access the camera.</span>
          <FaSadTear />
        </Wrapper>
      );
    case "prompt":
      return scan ? $scanner : $prompt;
    default:
      break;
  }
  return <span>...</span>;
};

export default Scanner;

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'

import { AssetType } from 'api'
import { Result } from 'api_supplimental'

export const getAssetTypes = new Intercept<Result<AssetType>>({
  pathname: 'v1/assets/types/all',
  fetch: async ({ entitiesDb }) => {
    const data = await entitiesDb.AssetTypes.toArray()
    if(data) {
      return new InterceptedResponse({ data: { data }, status: 200 })
    }
    return new InterceptedResponse({ status: 404 })
  },
})

import React from 'react'

import { PlantDetailDTO } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiPlantDetailContext extends ILocalEntityProviderContextBase<PlantDetailDTO, Result<PlantDetailDTO>, number> {
 plantDetails?: PlantDetailDTO[]
 db?: Entities
 get: (id: number) => Promise<PlantDetailDTO | undefined>
}

export const defaultContext: ILocalApiPlantDetailContext = {
  db: undefined,
  plantDetails: [],
  fetchState: defaultIApiState,
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
}

export default React.createContext<ILocalApiPlantDetailContext>(defaultContext)

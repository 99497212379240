import React from 'react'

import { KnownAsset, Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'
import Entities from 'idb/Entities'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalApiAssetsContext extends ILocalEntityProviderContextBase<KnownAsset, Result<KnownAsset>, string> {
 assets?: KnownAsset[]
 db?: Entities
 get: (id: string) => Promise<KnownAsset | undefined>
}

export const defaultContext: ILocalApiAssetsContext = {
  db: undefined,
  assets: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalApiAssetsContext>(defaultContext)

import React, { FC, ReactNode, useContext } from 'react'
import fluidsContext from 'contexts/LocalEntities/Fluids/context'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikConfig, FormikProps } from 'formik'
import * as Yup from 'yup'

import { AssetManufacturerResult, AssetTypeResult, PlantDTO, PortTypeDTOResult, TestPackageDTOResult } from 'api'
import { KnownAsset } from 'api_supplimental'
import FluidSelect from 'components/FluidSelect'
import LabelledValue from 'components/LabelledValue'
import { PaddedSection } from 'components/PaddedSection'
import SampleSourceRating from 'components/Sample/SampleForm/SampleSourceRating'
import TitledSection from 'components/TitledSection'
import useApi from 'hooks/useApi'
import { pruneObjectShallow } from 'utils/pruneObjectShallow'

export interface Props extends Partial<FormikConfig<AssetDetailFormValues>> {
  readonly asset?: KnownAsset
  readonly onSubmit: (formValues: AssetDetailFormValues) => void
  readonly renderHeader?: (formikBag: FormikProps<AssetDetailFormValues>) => ReactNode
  readonly renderFooter?: (formikBag: FormikProps<AssetDetailFormValues>) => ReactNode
  readonly disabled?: boolean
  readonly plantId?: number
}

export const modifyAssetSchemaShape = {
  name: Yup.string()
    .min(2, 'Asset Name must be longer than 2 characters')
    .max(50, 'Asset Name must be less than 50 characters')
    .matches(/^[a-zA-Z0-9 #()"[\]-]*$/, 'Asset Name contains invalid characters')
    .required('Asset Name is required'),
  manufacturer: Yup.string()
    .required('Manufacturer is required'),
  model: Yup.string()
    .max(50, 'Too long'),
  portTypeId: Yup.number().integer(),
  type: Yup.string()
    .required('Machine Type is required'),
  machineId: Yup.number().integer(),
  fluidId: Yup
    .number()
    .integer()
    .required(),
}
export const createAssetSchemaShape = {
  ...modifyAssetSchemaShape,
  testFrequency: Yup.string().required(),
  testPackage: Yup.string(),
}
export const ModifyAssetSchema = Yup.object().shape(modifyAssetSchemaShape)
export const CreateAssetSchema = Yup.object().shape(createAssetSchemaShape)

export interface ModifyAssetDetailFormValues {
  name: string
  manufacturer?: string
  model?: string
  type?: string
  portTypeId?: number
  customerEquipmentId?: string
  fluidId?: number | string
  testFrequency?: string
  testPackage?: string
  sampleInstructions: string
  criticalityRating?: number | undefined
}

export interface CreateAssetDetailFormValues extends ModifyAssetDetailFormValues {
  testFrequency: string
  testPackage: string
}

export type AssetDetailFormValues = ModifyAssetDetailFormValues | CreateAssetDetailFormValues

export const ModifyAssetForm: FC<Props> = (props: Props) => {
  const {
    asset,
    onSubmit,
    renderHeader = () => null,
    renderFooter = () => null,
    disabled = false,
    plantId: plantIdProp,
    ...passedProps
  } = props

  const isNew = typeof asset === 'undefined'

  const plantId = plantIdProp || (asset && asset.plantId)

  if(!plantId) {
    throw new Error('Either an asset or a plantId are required.')
  }

  // Load the different types of assets
  const assetTypesFetchState = useApi<AssetTypeResult>('v1/assets/types/all')
  const assetTypes = assetTypesFetchState.data && assetTypesFetchState.data.data

  const portTypesFetchState = useApi<PortTypeDTOResult>('v1/port-types')
  const portTypes = portTypesFetchState.data && portTypesFetchState.data.data

  const plantFetchState = useApi<PlantDTO>(plantId ? `v1/plants/${plantId}` : undefined)
  const plant = plantFetchState.data

  const assetManufacturersFetchState = useApi<AssetManufacturerResult>('v1/assets/manufacturers/all')
  const assetManufacturers = assetManufacturersFetchState.data && assetManufacturersFetchState.data.data

  const fluidsDb = useContext(fluidsContext)

  const assetTestFrequencyOptions = [
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'QUARTERLY', label: 'Quarterly' },
    { value: 'BI_ANNUALLY', label: 'Bi-Annually' },
    { value: 'ANNUALLY', label: 'Annually' },
    { value: 'AD_HOC', label: 'On-demand' }
  ]

  const testPackageUrl = plant && plant.customerId ? `/v1/customers/${plant.customerId}/test-packages/all` : undefined
  const testPackagesFetchState = useApi<TestPackageDTOResult>(testPackageUrl)
  const testPackages = testPackagesFetchState.data && testPackagesFetchState.data.data
  if(isNew && testPackages && testPackages.length === 0) {
    // Test packages are required for new assets
    throw new Error('No test packages available for this asset')
  }

  const handleSubmitted = async (formVals: AssetDetailFormValues) => {
    const fluidId = formVals.fluidId ? parseInt(formVals.fluidId.toString(), 10) : undefined
    const fluid = fluidId ? await fluidsDb.get(fluidId) : undefined
    // Set to null if -1 Selected
    formVals.criticalityRating = formVals.criticalityRating === -1 ? formVals.criticalityRating = undefined : formVals.criticalityRating
    onSubmit({
      ...formVals,
      ...(fluid ? { fluid } : {}),
    })
  }

  const initialValues = ((): AssetDetailFormValues => {
    const ret: AssetDetailFormValues = Object.assign({}, {
      name: '',
      model: '',
      customerEquipmentId: '',
      testFrequency: '',
      testPackage: '',
      manufacturer: '',
      type: '',
      portTypeId: -1,
      fluidId: -1,
      sampleInstructions: '',
    }, asset ? pruneObjectShallow(asset) : {})
    if(asset && asset.fluid) {
      ret.fluidId = asset.fluid.id
    }
    return ret
  })()
  return (
    <Formik
      {...passedProps}
      initialValues={initialValues}
      validationSchema={asset ? ModifyAssetSchema : CreateAssetSchema}
      onSubmit={handleSubmitted}
      render={(formikBag: FormikProps<AssetDetailFormValues>) => {
        const {
          errors,
          touched,
        } = formikBag
        return (
          <Form>
            {renderHeader(formikBag)}
            <PaddedSection>
              <LabelledValue
                label="Name"
                required
                value={
                  <Field
                    name="name"
                    type="text"
                    disabled={disabled}
                  />
                }
                error={!!(errors.name && touched.name)}
                feedback={<ErrorMessage name="name" />}
              />
              <LabelledValue
                label="Machine Manufacturer"
                error={!!(errors.manufacturer && touched.manufacturer)}
                feedback={<ErrorMessage name="manufacturer" />}
                required
                value={
                  <Field name="manufacturer" component="select" disabled={disabled}>
                    <option disabled key="" value="">Select a manufacturer</option>
                    {(assetManufacturers || []).map(({ name }) => (
                      <option key={name} value={name}>{name}</option>
                    ))}
                  </Field>
                }
              />
              <LabelledValue
                label="Model"
                value={
                  <Field
                    name="model"
                    type="text"
                    disabled={disabled}
                    placeholder="Model"
                  />
                }
                error={!!errors.model && touched.model}
                feedback={<ErrorMessage name="Model" />}
              />
              <LabelledValue
                label="Machine Type"
                error={!!errors.type && touched.type}
                feedback={<ErrorMessage name="type" />}
                required
                value={
                  <Field name="type" component="select" disabled={disabled}>
                    <option disabled key="" value="">Select a machine type</option>
                    {(assetTypes || []).map(({ name }) => (
                      <option key={name} value={name}>{name}</option>
                    ))}
                  </Field>
                }
              />
              <LabelledValue
                label="Port Type"
                error={!!errors.portTypeId && touched.portTypeId}
                value={
                  <Field name="portTypeId" component="select" disabled={disabled}>
                    <option disabled key="unspecified" value={-1}>Select a port type</option>
                    <option key="unknown" value={0}>Unknown</option>
                    {(portTypes || []).map(({ name, id }) => (
                      <option key={name} value={id}>{name}</option>
                    ))}
                  </Field>
                }
              />
              <LabelledValue
                label="Equipment ID"
                value={
                  <Field
                    name="customerEquipmentId"
                    type="text"
                    disabled={disabled}
                    placeholder="Equipment ID"
                  />
                }
                error={!!errors.customerEquipmentId && touched.customerEquipmentId}
                feedback={<ErrorMessage name="customerEquipmentId" />}
                assistiveText="A name you use to identify this asset"
              />
              <LabelledValue
                label="Criticality Rating"
                value={
                  <Field name="criticalityRating" component="select">
                    <option disabled selected value={-1}>Select Criticality Rating</option>
                    <option value={1}>Non-Production Equipment</option>
                    <option value={2}>Less Critical, With Redundancy</option>
                    <option value={3}>Less Critical, No Redundancy</option>
                    <option value={4}>Critical, With Redundancy</option>
                    <option value={5}>Critical, No Redundancy</option>
                  </Field>
                }
                assistiveText="This rating describes how critical this machine is to your business."
              />
              <Field
                name="sampleSourceRating"
                render={({ field }: FieldProps) => (
                  <SampleSourceRating
                    {...field}
                  />
                )}
              />
              <LabelledValue
                style={{ paddingTop: '1.00rem' }}
                label="Sampling Instructions"
                value={
                  <Field
                    name="sampleInstructions"
                    component="textarea"
                    disabled={disabled}
                  />
                }
                assistiveText="Information regarding the sample pulling process for this machine."
              />

            </PaddedSection>

            <TitledSection title="Fluid Information">
              <PaddedSection>
                <Field
                  name="fluidId"
                  render={({ field }: FieldProps) => (
                    <FluidSelect
                      required
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </PaddedSection>
            </TitledSection>
            {!asset && (
              <TitledSection title="Testing Schedule">
                <PaddedSection>
                  <LabelledValue
                    label="Test Frequency"
                    error={!!errors.testFrequency && touched.testFrequency}
                    required
                    value={
                      <Field name="testFrequency" component="select" disabled={disabled}>
                        <option key="" value="" disabled>Please select a test frequency</option>
                        {assetTestFrequencyOptions.map(({ value, label }) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </Field>
                    }
                  />
                  <LabelledValue
                    label="Test Package"
                    error={!!errors.testPackage && touched.testPackage}
                    required
                    value={
                      <Field name="testPackage" component="select" disabled={disabled}>
                        <option key="" value="" disabled>Please select a test package</option>
                        {testPackages && testPackages.map(({ name }) => (
                          <option key={name} value={name}>{name}</option>
                        ))}
                      </Field>
                    }
                  />
                </PaddedSection>
              </TitledSection>
            )}

            {renderFooter(formikBag)}
          </Form>
        )
      }}
    />
  )
}

export default ModifyAssetForm

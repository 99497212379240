import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'

import { UserDTO } from 'api'

export const getUsersMe = new Intercept<UserDTO>({
  pathname: 'v1/users/me',
  fetch: async ({ currentUser, networkState, fetcher, req, opts }) => {
    if(networkState.online) {
      return fetcher(req, opts)
    }
    const data = currentUser
    return new InterceptedResponse({ data, status: 200 })
  },
})

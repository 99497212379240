import React, { ReactNode } from 'react'
import * as Sentry from '@sentry/browser'

import { LinkPlantContactDTO } from 'api'
import { Result } from 'api_supplimental'
import InlineError from 'components/Errors/InlineError'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import ContactMutationSideEffectWarning from './ContactMutationSideEffectWarning'

type Props = {
  contactId: string,
  header: ReactNode
  renderFooter?: (links: LinkPlantContactDTO[]) => React.ReactNode
  children: ReactNode
  bypass?: boolean
}

export default (props: Props) => {
  const {
    contactId,
    children,
    header,
    renderFooter,
    bypass,
  } = props
  const {
    isLoading,
    isError,
    error,
    data,
  } = useApi<Result<LinkPlantContactDTO>>(!bypass ? `v1/contacts/${contactId}/plant-contact-links` : undefined)
  if(isError || error) {
    Sentry.captureException(error)
    console.error('Error while checking plant mutation side effects.', error)
    return (
      <InlineError message="Error while checking plant mutation side effects" />
    )
  }
  if(bypass) {
    return <>{children}</>
  }
  const plantContactLinks = data && data.data
  if(isLoading || !plantContactLinks) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    )
  }
  if(plantContactLinks.length > 1) {
    return (
      <ContactMutationSideEffectWarning
        plantContactLinks={plantContactLinks}
        header={header}
        renderFooter={renderFooter}
      />
    )
  }
  return <>{children}</>
}

import { AssetDTO } from 'api'
import { KnownAsset } from 'api_supplimental'
import useApi, { IApiState } from 'hooks/useApi'

import { CreateAssetDetailFormValues } from './ModifyAssetForm'

export const useCreateAsset = (newAsset?: Partial<CreateAssetDetailFormValues>, customerId?: number, plantId?: number): IApiState<KnownAsset, AssetDTO> => {
  const bodyObj: (AssetDTO | undefined) = ((): (AssetDTO | undefined) => {
    if(customerId && newAsset && plantId) {
      const ret: any = {
        customerId,
        plantId,
        ...newAsset,
        fluid: {
          id: newAsset.fluidId,
        },
      }
      return ret
    }
    return undefined
  })()
  const body = bodyObj ? JSON.stringify(bodyObj) : undefined
  const createState = useApi<KnownAsset, AssetDTO>(body ? 'v1/assets' : undefined, undefined, {
    requestOptions: {
      method: 'PUT',
      body,
    },
    dependencies: [body],
  })

  return createState
}

export default useCreateAsset

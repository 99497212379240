import React, { useContext } from 'react'
import { Box, Button } from '@material-ui/core'
import { activeCustomerContext } from 'contexts/ActiveCustomer'
import { networkStateContext } from 'contexts/NetworkState'

import WarningBubble from './FluidAttributes/WarningBubble'
import LabelledValue from './LabelledValue'
import { PaddedSection } from './PaddedSection'

export const ActiveCustomerDisplay = () => {
  const {
    activeCustomer,
    setActiveCustomer,
    customers,
  } = useContext(activeCustomerContext)
  const { online } = useContext(networkStateContext)
  if(!activeCustomer || customers.length === 1) {
    return null
  }
  const handleChangeClick = () => {
    setActiveCustomer()
  }
  return (
    <PaddedSection>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <LabelledValue label="Active Customer">
          {activeCustomer.name}
        </LabelledValue>
        <Button disabled={!online} onClick={handleChangeClick} variant="contained" color="primary" size="small">Change</Button>
      </Box>
      <WarningBubble>
        {online ? 'Make sure you have a reliable network connection before switching customers.' : 'You must be online to switch your active customer.'}
      </WarningBubble>
    </PaddedSection>
  )
}
export default ActiveCustomerDisplay

import { SampleDataPreview } from "api";
import path from "path";
import { serialize } from "utils/api-utils";

import Intercept from "../Intercept";
import InterceptedResponse from "../InterceptedResponse";
const reportURL = `v1/samples/report/verifyall`;

export const getSamplesHaveReports = new Intercept<SampleDataPreview[]>({
  pathname: reportURL,
  fetch: async ({ fetcher, opts }) => {
    if (!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 });
    }

    const queryString = opts?.body as string;
    const params = JSON.parse(queryString) as { labIds: number[] };

    if (!params || !params.labIds || params.labIds.length === 0) {
      return new InterceptedResponse({ status: 400 });
    }

    const serializedParams = serialize(params);
    const url = path.join(reportURL, serializedParams);
    delete opts?.body;
    return fetcher(url, opts);
  },
});

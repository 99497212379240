import React, { HTMLAttributes, ReactNode, RefAttributes, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion, MotionProps } from 'framer-motion'

import BrowserOnly from 'components/BrowserOnly'
import DynamicPortal, { Props as DynamicPortalProps } from 'components/DynamicPortal'

const styles = require('./Modal.css')

declare type HTMLAttributesWithoutMotionProps<Attributes extends HTMLAttributes<Element>, Element extends HTMLElement> = {
  [K in Exclude<keyof Attributes, keyof MotionProps>]?: Attributes[K];
};

type MotionDiv = HTMLAttributesWithoutMotionProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & MotionProps & RefAttributes<HTMLDivElement>

export interface Props extends OverflowWranglerProps, MotionDiv {
  id: string
  show: boolean | undefined
  className?: string
  children: ReactNode
  portalProps?: Partial<DynamicPortalProps>
}

export const Modal = (props: Props) => {
  const {
    show,
    className,
    id,
    overflowTargetSelector,
    portalProps,
    ...passedProps
  } = props

  return (
    <BrowserOnly>
      <DynamicPortal id={id} {...portalProps}>
        <AnimatePresence>
          {show && (
            <motion.div
              className={clsx(className, styles.root)}
              key={id}
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ ease: 'easeOut' }}
              {...passedProps}
            />
          )}
        </AnimatePresence>
        {show && (
          <OverflowWrangler
            overflowTargetSelector={overflowTargetSelector}
          />
        )}
      </DynamicPortal>
    </BrowserOnly>
  )
}

export default Modal

type OverflowWranglerProps = {
  overflowTargetSelector?: string
}

const OverflowWrangler = (props: OverflowWranglerProps) => {
  const { overflowTargetSelector } = props
  const overflowTargetRef = useRef(overflowTargetSelector && document.querySelector(overflowTargetSelector))
  const lastScrollY = useRef<number>()

  useEffect(() => {
    const overflowTarget = overflowTargetRef.current || document.body
    if(document.documentElement && document.documentElement.scrollTop > 0) {
      lastScrollY.current = document.documentElement.scrollTop
      document.documentElement.scrollTop = 0
    }
    if(overflowTarget) {
      overflowTarget.scrollTop = 0
      overflowTarget.classList.add(styles.overflowTarget)
    }
    return () => {
      if(overflowTarget) {
        overflowTarget.classList.remove(styles.overflowTarget)
      }
      if(lastScrollY.current && document.documentElement) {
        document.documentElement.scrollTop = lastScrollY.current
      }
    }
  }, [])
  return null
}

import { Ref, useEffect, useMemo, useRef, useState } from "react";
import Debug from "debug";
import { QRCode } from "jsqr";

import { DoublyLinkedList } from "@datastructures-js/linked-list";

// @ts-ignore
import QRWorker from "worker-loader?inline!./qr-worker";

//@ts-ignore
import Quagga from "quagga";

import { useElementDimensions } from "hooks/useElementDimensions";
import { useInterval } from "hooks/useInterval";
import { useTimeout } from "hooks/useTimeout";

import useVideoDevices from "./useVideoDevices";
import { BarcodeData, Point, ScannerWorker } from "custom";

const log = Debug("AL:Scanner:useQrScanner");

/**
 * A ratio of the smaller of the dimensions of the video feed that defines the
 * size of the square from the center of the video feed that is used for QR
 * analysis. Using a smaller portion of the whole feed resolution improves
 * scanning responsiveness.
 */
const TARGET_SIZE_RATIO = 0.8;
/**
 * A ratio of the smaller of the dimensions of the video feed that defines the
 * size of the square drawn on the canvas to indicate a target.
 */
const TARGET_VISUAL_SIZE_RATIO = 0.5;

export type ScannerType = "QRCode" | "Barcode";

export type Config = {
  onScanResult?: (scanResult: string) => void;
  onScanResultCleared?: () => void;
  interval?: number;
  scanners?: ScannerType[];
};

type TReturn = {
  /**
   * Assign this ref to the video element to attach the camera feed to. Should be visible.
   */
  videoRef: Ref<HTMLVideoElement>;
  /**
   * Assign this ref to a canvas to use to overlay graphics on the video element.
   * This canvas should be positioned absolutely above the video element.
   */
  overlayCanvasRef: Ref<HTMLCanvasElement>;
  /**
   * Assign this ref to a canvas to use for debugging the QR code data.
   */
  debugCanvasRef?: Ref<HTMLCanvasElement>;
  /**
   * Assign this ref to a canvas to use to grab full-resolution image data.
   * This canvas should be hidden (`display: none;`).
   */
  nativeSizeCanvasRef: Ref<HTMLCanvasElement>;
  error?: Error;
  videoDevices: {
    activeDevice?: MediaDeviceInfo;
    selectedDeviceId?: string;
    setSelectedVideoDeviceId: (deviceId: string) => void;
    availableDevices?: MediaDeviceInfo[];
  };
  getImageDataURL(): string | undefined;
};

type TDimensions = {
  width: number;
  height: number;
};

const configDefaults = {
  interval: 200,
};

export default function useQRAndBarcodeScanner(scannerConfig: Config): TReturn {
  const {
    onScanResult,
    onScanResultCleared,
    interval = configDefaults.interval,
    scanners,
  } = scannerConfig;
  const [streamIsReady, setStreamIsReady] = useState(false);
  const [scanInterval, setScanInterval] = useState<number | null>(interval);
  const [qrResult, setQrResult] = useState<QRCode>();
  const [barcodeResult, setBarcodeResult] = useState<string>();
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const nativeSizeCanvasRef = useRef<HTMLCanvasElement>(null);
  const debugCanvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoElementRect = useElementDimensions(videoRef, [streamIsReady]);
  const [videoStreamResolution, setVideoStreamResolution] =
    useState<TDimensions>();
  const qrWorkerRef = useRef<ScannerWorker>();
  const [returnError, setReturnError] = useState<Error>();
  const videoDevices = useVideoDevices();
  const { activeDevice: videoDevice } = videoDevices;

  /**
   * The ratios of the overlay canvas to the native video resolution.
   */
  const videoStreamRatio: TDimensions | undefined = useMemo(() => {
    if (videoElementRect && videoStreamResolution) {
      return {
        width: videoElementRect.width / videoStreamResolution.width,
        height: videoElementRect.height / videoStreamResolution.height,
      };
    }
  }, [videoElementRect, videoStreamResolution]);

  /**
   * Draw Overlay
   */
  useEffect(() => {
    const overlayCanvas = overlayCanvasRef.current;
    const video = videoRef.current;
    if (!videoElementRect || !overlayCanvas || !video || !videoStreamRatio) {
      return;
    }
    const debug = debugCanvasRef.current !== null;
    log(
      `Setting video dimensions: ${videoElementRect.width} x ${videoElementRect.height}`,
      videoElementRect
    );
    overlayCanvas.height = videoElementRect.height;
    overlayCanvas.width = videoElementRect.width;
    overlayCanvas.style.width = videoElementRect.width.toString() + "px";
    overlayCanvas.style.height = videoElementRect.height.toString() + "px";
    drawOverlay({
      video,
      overlayCanvas,
      videoRect: videoElementRect,
      videoStreamRatio,
      qrResult,
      debug,
    });
  }, [qrResult, videoElementRect, videoStreamRatio, videoStreamResolution]);

  const barcodeSuccessRequirement = 2;
  const barcodeSuccessRef = useRef<DoublyLinkedList<string>>(
    new DoublyLinkedList<string>()
  );

  const handleBarcodeScanResult = (barcodeData: BarcodeData | undefined) => {
    const overlayCanvas = overlayCanvasRef.current;

    if (!barcodeData) {
      if (overlayCanvas) {
        requestAnimationFrame(() => {
          restoreCanvas(overlayCanvas);
          drawTarget(overlayCanvas);
        });
      }
      return;
    }

    if (overlayCanvas && barcodeData.boxes && barcodeData.codeResult) {
      requestAnimationFrame(() => {
        drawBarcodeBoxOverlays(overlayCanvas, barcodeData.boxes, true);
      });
    }

    if (barcodeData.codeResult) {
      handleBarcodeData(barcodeData.codeResult.code);
    }
  };
  const handleBarcodeData = (barcodeData: string) => {
    if (!barcodeData) return;
    //requires consecutive successes to ensure validity -- doubly linked list was more convenient than the @datastructures-js/queue implementation
    barcodeSuccessRef.current.insertLast(barcodeData);
    if (barcodeSuccessRef.current.count() > barcodeSuccessRequirement) {
      barcodeSuccessRef.current.removeFirst();
    }

    if (barcodeSuccessRef.current.count() !== barcodeSuccessRequirement) return;

    //check to see if all values same as head.
    let allValuesSameAsHead = true;
    barcodeSuccessRef.current.forEach((node) => {
      const valueSameAsHead =
        node.getValue() === barcodeSuccessRef.current.head().getValue();
      if (!valueSameAsHead) {
        allValuesSameAsHead = false;
        return;
      }
    });
    if (!allValuesSameAsHead) return;

    log("💌 Got a barcode scan result!", barcodeData);
    setBarcodeResult(barcodeData);
    if (typeof onScanResult === "function") {
      onScanResult(barcodeData);
    }
  };

  /**
   * Init worker
   */
  useEffect(() => {
    qrWorkerRef.current = new QRWorker();
    let mounted = true;
    const handleQRWorkerMessage = (message: MessageEvent) => {
      if (message.data && message.data.payload && message.data.payload.data) {
        if (mounted) {
          const qrData: QRCode = message.data.payload;
          log("💌 Got a scan result!", qrData);
          setQrResult(qrData);
          if (typeof onScanResult === "function") {
            onScanResult(qrData.data);
          }
        }
      } else {
        log("📩 Got an unhandled worker message!", message);
      }
    };

    qrWorkerRef.current!.onmessage = handleQRWorkerMessage;
    return () => {
      mounted = false;
      if (qrWorkerRef.current) {
        qrWorkerRef.current.terminate();
      }
    };
  }, [onScanResult]);

  /**
   * Clear scan results after 2s and resume the scan interval
   */
  useTimeout(
    () => {
      log("Clearing scan results");
      setQrResult(undefined);
      setBarcodeResult(undefined);
      if (onScanResultCleared) {
        onScanResultCleared();
      }
    },
    qrResult || barcodeResult ? 2000 : null
  );

  /**
   * Pause the scan interval if we have a result
   */
  useEffect(() => {
    if (qrResult || barcodeResult) {
      setScanInterval(null);
    } else {
      setScanInterval(interval);
    }
  }, [interval, qrResult, barcodeResult]);

  /**
   * Log scan interval changes
   */
  useEffect(() => {
    if (scanInterval === null) {
      log("⛔️ Scanner stopped");
    } else {
      log(`📸 Starting scanner (${scanInterval}ms)`);
    }
  }, [scanInterval]);

  const getImageDataURLIfAvailable = (): string | undefined => {
    const video = videoRef.current;
    const canvas = nativeSizeCanvasRef.current;
    if (!(streamIsReady && canvas && video && videoElementRect)) return;
    if (!canvas.height || !canvas.width) {
      console.warn("Canvas is too small");
      return;
    }
    return getImageDataURL(video, canvas);
  };

  const getImageDataIfAvailable = (): ImageData | undefined => {
    const video = videoRef.current;
    const canvas = nativeSizeCanvasRef.current;
    if (!(streamIsReady && canvas && video && videoElementRect)) return;
    if (!canvas.height || !canvas.width) {
      console.warn("Canvas is too small");
      return;
    }

    const imageData = getImageData(video, canvas);
    setDebugImageData(imageData);
    return imageData;
  };

  const setDebugImageData = (imageData: ImageData): void => {
    const canvas = debugCanvasRef.current;
    if (canvas) {
      canvas.height = imageData.height;
      canvas.width = imageData.width;
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      if (!ctx) {
        console.warn("Could not get canvas context");
        return;
      }
      ctx.putImageData(imageData, 0, 0);
    }
  };

  function performQRScan(imageData: ImageData) {
    const qrWorker = qrWorkerRef.current;
    if (qrWorker) {
      qrWorker.postMessage({ type: "scanImage", payload: { imageData } });
    }
  }

  function performBarcodeScan(imageUrl: string) {
    //perform barcode scanner scan --- this doesn't work in a web worker for some reason :/
    Quagga.decodeSingle(
      {
        decoder: {
          readers: [
            //NOTE: Limit this appropriately if we know the expected type -- multiple active scanners degrades performance and may lead to incorrect scan results.
            "code_128_reader",
            // "ean_reader",
            // "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            // "codabar_reader",
            // "upc_reader",
            // "upc_e_reader",
            // "i2of5_reader",
            // "2of5_reader",
            // "code_93_reader",
          ],
        },
        locator: {
          halfSample: true,
          showCanvas: false,

          showPatchLabels: true,
          showRemainingPatchLabels: true,
        },
        locate: true,
        src: imageUrl,
      },
      handleBarcodeScanResult
    );
  }

  /**
   * Grabs references to all image scanning workers and libraries, issuing an image scan request from each to handle image data.
   * Response handling precedence: qrCode, barcode.
   * Success: any library responds with success and data.
   */
  const performMultiscan = (imageData: ImageData) => {
    if ((!scanners || scanners.includes("QRCode")) && imageData)
      performQRScan(imageData);

    let imageUrl = getImageDataURLIfAvailable();
    if ((!scanners || scanners.includes("Barcode")) && imageUrl)
      performBarcodeScan(imageUrl);
  };

  function drawBarcodeBoxOverlays(
    overlayCanvas: HTMLCanvasElement,
    boxes: number[][][],
    success?: boolean
  ) {
    if (!boxes) return;
    if (!restoreCanvas) return;

    const ctx = overlayCanvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) return;

    for (const box of boxes) {
      drawBarcodeBoxOverlay(overlayCanvas, box, success);
    }
  }

  function restoreCanvas(overlayCanvas: HTMLCanvasElement) {
    if (!overlayCanvas || !videoElementRect) return;

    const ctx = overlayCanvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) return;

    overlayCanvas.height = videoElementRect.height;
    overlayCanvas.width = videoElementRect.width;
    overlayCanvas.style.width = videoElementRect.width.toString() + "px";
    overlayCanvas.style.height = videoElementRect.height.toString() + "px";

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
  }

  function drawBarcodeBoxOverlay(
    overlayCanvas: HTMLCanvasElement,
    box: number[][],
    success?: boolean
  ) {
    if (!overlayCanvas || !videoElementRect || box.length < 4) return;

    overlayCanvas.height = videoElementRect.height;
    overlayCanvas.width = videoElementRect.width;
    overlayCanvas.style.width = videoElementRect.width.toString() + "px";
    overlayCanvas.style.height = videoElementRect.height.toString() + "px";

    const ctx = overlayCanvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) {
      throw new Error("Could not get canvas context.");
    }

    ctx.save();
    ctx.strokeStyle = success
      ? // ? "rgba(21, 255, 0, 0.5)"
        "rgba(114, 184, 255, 0.75)"
      : "rgba(255, 89, 89, 0.5)";
    ctx.fillStyle = success
      ? // ? "rgba(72, 255, 0, 0.2)"
        "rgba(62, 158, 255, 0.25)"
      : "rgba(255, 154, 154, 0.2)";
    ctx.lineWidth = 2;

    const { width: cw, height: ch } = overlayCanvas;
    const tSize = Math.min(cw, ch) * TARGET_SIZE_RATIO;
    const leftEdge = (cw - tSize) / 2;
    const topEdge = (ch - tSize) / 2;
    const { width: rx, height: ry } = videoStreamRatio || {
      height: 1,
      width: 1,
    };

    ctx.translate(
      leftEdge - cw * 0.005,
      (topEdge * 3) / (2 * TARGET_SIZE_RATIO)
    );
    ctx.scale(rx, ry);

    for (let row = 0; row < box.length; row++)
      for (let col = 0; col < box[row].length; col++)
        box[row][col] = box[row][col] * TARGET_VISUAL_SIZE_RATIO;

    const upperLeft: Point = { x: box[0][0], y: box[0][1] };
    const bottomLeft: Point = { x: box[1][0], y: box[1][1] };
    const bottomRight: Point = { x: box[2][0], y: box[2][1] };
    const upperRight: Point = { x: box[3][0], y: box[3][1] };
    ctx.beginPath();
    ctx.moveTo(upperLeft.x, upperLeft.y);
    ctx.lineTo(upperRight.x, upperRight.y);
    ctx.lineTo(bottomRight.x, bottomRight.y);
    ctx.lineTo(bottomLeft.x, bottomLeft.y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
  }

  /**
   * Scan Image
   */
  useInterval(() => {
    const imageData = getImageDataIfAvailable();
    if (imageData) performMultiscan(imageData);
  }, scanInterval);

  /**
   * Attach Media Stream
   */
  useEffect(() => {
    let mounted = true;
    const video = videoRef.current;
    const stream = streamRef.current;
    if (!video || !videoDevice) return;
    log("📺 Render stream effect");

    function handleStreamPlay() {
      if (video && mounted) {
        log("📺 Setting stream ready");
        setStreamIsReady(video.readyState === video.HAVE_ENOUGH_DATA);
      }
    }
    function handleStreamEmptied() {
      log("Stream emptied");
      setStreamIsReady(false);
    }

    async function renderStream() {
      if (!video || !videoDevice || !mounted) return;
      try {
        const { deviceId } = videoDevice;
        const params =
          deviceId !== ""
            ? {
                video: { deviceId: { exact: deviceId } },
              }
            : {
                video: true,
              };
        const _stream = await navigator.mediaDevices.getUserMedia(params);
        video.setAttribute("autoplay", "");
        video.setAttribute("muted", "");
        video.setAttribute("playsinline", "");
        video.srcObject = _stream;
        streamRef.current = _stream;
      } catch (err) {
        console.warn((err as Error).toString());
        setReturnError(err as Error);
      }
    }
    renderStream();

    video.addEventListener("playing", handleStreamPlay);
    video.addEventListener("emptied", handleStreamEmptied);

    return () => {
      log("🧹 cleanup");
      mounted = false;
      try {
        if (stream) {
          // (stream as any).stop()
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        video.removeEventListener("playing", handleStreamPlay);
        video.removeEventListener("emptied", handleStreamEmptied);
      } catch (err) {
        console.warn("Error occurred 1 during video cleanup", err);
      }
    };
  }, [videoDevice, setReturnError]);

  /**
   * Sets videoStreamResolution when availableb
   */
  useEffect(() => {
    if (returnError) return;
    const stream = streamRef.current;
    const video = stream?.getVideoTracks()[0];
    const mediaSettings = video?.getSettings();
    if (streamIsReady && mediaSettings?.width && mediaSettings?.height) {
      const { width, height } = mediaSettings;
      log(`Setting native video resolution: ${width}x${height}`);
      setVideoStreamResolution({ width, height });
    }
  }, [streamRef, streamIsReady, returnError]);

  /**
   * Sizes the native-size canvas when videoStreamResolution is available
   */
  useEffect(() => {
    log("videoStreamResolution:", videoStreamResolution);
    const c = nativeSizeCanvasRef.current;
    if (videoStreamResolution && c) {
      c.width = videoStreamResolution.width;
      c.height = videoStreamResolution.height;
    }
  }, [videoStreamResolution]);

  return {
    videoRef,
    overlayCanvasRef,
    nativeSizeCanvasRef,
    debugCanvasRef,
    videoDevices,
    error: returnError,
    getImageDataURL: getImageDataURLIfAvailable,
  };
}

/**
 * Gets image data from a portion of the center of the video to send to service
 * worker for QR analysis. Clears the canvas first, draws the video image, gets
 * the bytes, redraws the overlay.
 * @param video Video element
 * @param canvas Canvas element
 */
function getImageData(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement
): ImageData {
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  if (!ctx) {
    throw new Error("Could not get canvas context.");
  }
  const { width: cw, height: ch } = canvas;
  if (!ch || !cw) {
    throw new Error("Canvas height is 0.");
  }

  const tSize = Math.min(cw, ch) * TARGET_SIZE_RATIO;
  // const imageData = ctx.getImageData(
  //   (cw - tSize) / 2,
  //   (ch - tSize) / 2,
  //   tSize,
  //   tSize
  // );
  ctx.drawImage(video, 0, 0, cw, ch);
  // Take a subsection of the video area to send for QR analysis
  // const tSize = Math.min(cw, ch) * TARGET_SIZE_RATIO;
  const imageData = ctx.getImageData(0, 0, cw, ch);
  return imageData;
}

/**
 * Gets image data as Data URI. Clears the canvas first, draws the video image,
 * gets the bytes, redraws the overlay.
 * @param video Video element
 * @param canvas Canvas element
 */
function getImageDataURL(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement
): string {
  const ctx = canvas.getContext("2d", { willReadFrequently: true });

  if (!ctx) {
    throw new Error("Could not get canvas context.");
  }
  const { width: cw, height: ch } = canvas;
  if (!ch || !cw) {
    throw new Error("Canvas height is 0.");
  }
  //This draws the entire image, not only the innersquare of the image. Only draw the inner square of the image.
  const offset = 1 / TARGET_VISUAL_SIZE_RATIO;
  const tSize = Math.min(cw, ch) * offset;
  //size: double the width, double the height. Offset? half the width, half the height
  ctx.drawImage(
    video,
    (cw - tSize) / offset,
    (ch - tSize) / offset,
    tSize,
    tSize
  );
  return canvas.toDataURL();
}

function drawOverlay({
  video,
  overlayCanvas,
  videoRect,
  videoStreamRatio,
  qrResult,
  debug,
}: {
  video: HTMLVideoElement;
  overlayCanvas: HTMLCanvasElement;
  videoRect: ClientRect;
  videoStreamRatio: TDimensions;
  qrResult?: QRCode;
  debug?: boolean;
}) {
  if (!overlayCanvas || !video || !videoRect) {
    return;
  }
  const ctx = overlayCanvas.getContext("2d", { willReadFrequently: true });
  if (!ctx) {
    throw new Error("Could not get canvas context.");
  }
  const cw = overlayCanvas.width;
  const ch = overlayCanvas.height;
  // Reverse the subsection used for QR analysis
  const imageDataSize = Math.min(cw, ch) * TARGET_SIZE_RATIO;
  const tx = (cw - imageDataSize) / 2;
  const ty = (ch - imageDataSize) / 2;
  if (debug) {
    ctx.save();
    ctx.strokeStyle = "";
    ctx.globalAlpha = 0.75;
    ctx.fillStyle = "#f90";
    ctx.rect(tx, ty, imageDataSize, imageDataSize);
    ctx.fill();
    ctx.restore();
  }
  if (qrResult) {
    drawQRResult(ctx, qrResult, videoStreamRatio, { width: tx, height: ty });
  } else {
    drawTarget(overlayCanvas);
  }
}

function drawTarget(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  if (!ctx) {
    throw new Error("Could not get canvas context.");
  }

  ctx.save();
  ctx.beginPath();
  ctx.globalAlpha = 0.75;
  ctx.strokeStyle = "white";
  ctx.setLineDash([10, 5]);
  ctx.lineWidth = 2;
  const cw = canvas.width;
  const ch = canvas.height;
  const tSize = Math.min(cw, ch) * TARGET_VISUAL_SIZE_RATIO;
  ctx.rect((cw - tSize) / 2, (ch - tSize) / 2, tSize, tSize);
  ctx.stroke();
  ctx.restore();
}

function pythagorean(sideA: number, sideB: number): number {
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
}

function drawQRResult(
  ctx: CanvasRenderingContext2D,
  qrResult: QRCode,
  scaleRatio: TDimensions = { width: 1, height: 1 },
  translation: TDimensions = { width: 0, height: 0 }
) {
  log("Drawing qrResult", qrResult.location);
  const { width: rx, height: ry } = scaleRatio;
  // ctx.translate(translation.width, translation.height);
  const {
    topLeftCorner,
    topRightCorner,
    bottomLeftCorner,
    bottomRightCorner,
    topLeftFinderPattern,
    topRightFinderPattern,
    bottomLeftFinderPattern,
    bottomRightAlignmentPattern,
  } = qrResult.location;

  ctx.translate(
    scaleRatio.width,
    (scaleRatio.height * TARGET_VISUAL_SIZE_RATIO) / TARGET_SIZE_RATIO
  );
  ctx.scale(rx, ry);

  ctx.strokeStyle = "rgba(114, 184, 255, 0.75)";
  ctx.fillStyle = "rgba(62, 158, 255, 0.25)";
  ctx.lineWidth = 2;

  // main rect
  ctx.beginPath();
  ctx.moveTo(topLeftCorner.x, topLeftCorner.y);
  ctx.lineTo(topRightCorner.x, topRightCorner.y);
  ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y);
  ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();

  const locWidth = pythagorean(
    topLeftCorner.x - topRightCorner.x,
    topLeftCorner.y - topRightCorner.y
  );
  const pattSize = locWidth / 12;
  // alignment patterns
  ctx.beginPath();
  ctx.arc(
    topLeftFinderPattern.x,
    topLeftFinderPattern.y,
    pattSize,
    0,
    2 * Math.PI
  );
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.arc(
    bottomLeftFinderPattern.x,
    bottomLeftFinderPattern.y,
    pattSize,
    0,
    2 * Math.PI
  );
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.arc(
    topRightFinderPattern.x,
    topRightFinderPattern.y,
    pattSize,
    0,
    2 * Math.PI
  );
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
  if (bottomRightAlignmentPattern) {
    ctx.beginPath();
    ctx.arc(
      bottomRightAlignmentPattern.x,
      bottomRightAlignmentPattern.y,
      pattSize,
      0,
      2 * Math.PI
    );
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
  }
}

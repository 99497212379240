import { ImageDTO } from 'api'
import useApi, { IApiState } from 'hooks/useApi'

export default function useUploadImage(assetId: string | undefined, file: File): IApiState<ImageDTO[]> {
  const url = (assetId && file) ? `v1/assets/${assetId}/images` : undefined
  const formData = new FormData()
  if(assetId && file) {
    formData.append('files', file, file.name)
  }
  const requestOptions = (assetId && file) ? {
    method: 'PUT',
    body: formData,
  } : undefined
  return useApi<ImageDTO[]>(url, undefined, {
    noDefaultContentType: true,
    requestOptions,
  })
}

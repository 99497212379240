/**Determines if a string might possibly maybe be a json object attempt. */
export const basicJsonRegex = /\{\s*\".*\"\s*\:\s*.*\}/;

/**Determines if a string is a valid url. */
export const urlRegex =
  /^(?:http(s)?:\/\/)([\w.-])+((?:[\w\.-]+))+([\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)$/;

/**Parses url parameters from a url string. Specifically targets the parameter names and their corresonding values. */
export const urlParamsRegex = /(\?|\&)([^=]+)\=([^\&]+)/;

export const fileNameContentDispositionRegex = /filename=\"(?<filename>.*)\"/;

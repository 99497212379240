import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { AssetTagLinkDTO } from 'api'
import Entities from 'idb/Entities'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:API:intercept:postAssetTagLinks')

async function updateEntities(entitiesDb: Entities, tagLink: AssetTagLinkDTO): Promise<void> {
  const {
    tagIdentifier,
    assetId,
  } = tagLink
  log(`Retagging assetId "${assetId}" => "${tagIdentifier}`)
  if(!assetId) {
    throw new Error('Asset identifier not supplied')
  }
  if(!tagIdentifier) {
    throw new Error('Tag identifier not supplied')
  }
  const taggedAsset = await entitiesDb.Assets.get({ tagIdentifier })
  if(taggedAsset) {
    taggedAsset.tagIdentifier = undefined
    await entitiesDb.Assets.put(taggedAsset)
  }
  const asset = await entitiesDb.Assets.get(assetId.toString())
  if(!asset) {
    throw new Error(`Asset not found for ID "${assetId}"`)
  }
  asset.tagIdentifier = tagIdentifier
  await entitiesDb.Assets.put(asset)
}

export const postAssetTagLinks = new Intercept<AssetTagLinkDTO>({
  method: 'POST',
  pathname: 'v1/asset-tag-links',
  fetch: async (bag) => {
    const {
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    if(!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 })
    }
    if(networkState.online) {
      log('Not intercepting')
      return fetcher(req, opts)
    }
    log('Intercepting')
    const localBody: AssetTagLinkDTO = JSON.parse(opts.body as string)
    await updateEntities(entitiesDb, localBody)
    // Keep the original request intact, but store the temp UUID for use in
    // postParse to replace with the real one
    const transactionId = await tx.Queue.add(new Transaction(req, opts))
    log(`Transaction ${transactionId} queued`)
    return new InterceptedResponse({ data: localBody, status: 201 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
      originalResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(!(originalResponse instanceof InterceptedResponse)) {
      await updateEntities(entitiesDb, parsedResponse)
    }
  },

})

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'
import Dexie from 'dexie'

import { LocalImageDTO } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

const log = Debug('AL:API:intercept:putImage')

type TExtra = { guid: string }

export const putImage = new Intercept({
  method: 'PUT',
  pathname: 'v1/assets/:assetId/images',
  fetch: async (bag) => {
    const {
      networkState,
      match,
      fetcher,
      req,
      transactionsContext: {
        instance: tx,
      },
      opts,
    } = bag
    const assetId = match?.params.assetId
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    if(!opts || !opts.body || !assetId) {
      return new InterceptedResponse({ status: 401 })
    }
    if(networkState.online) {
      log('Not intercepting')
      return fetcher(req, opts)
    }
    log('Intercepting')
    // Create a temporary GUID for the image that will later be passed to the
    // server and (hopefully) persisted
    const guid = Dexie.Observable.createUUID()
    const transactionBody: LocalImageDTO = { guid, assetId }
    log(`Adding image with temporary GUID: "${guid}"`)

    // Convert the file contents to a serializable data URL so it can be stored
    // in the transactions IDB
    const files = (opts.body as FormData).getAll('files') as File[]
    const file = files[0]
    const fileContentsDataURL = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function(event) {
        var contents = event.target?.result
        resolve(contents?.toString())
      }

      reader.onerror = function() {
        return reject(new Error('Error reading image file'))
      }

      reader.readAsDataURL(file)
    })
    transactionBody.imagePath = fileContentsDataURL

    // Replace the body with the serializable version
    // The TransactionWorker will have to make a special case to translate this
    // back into a FormFile
    opts.body = fileContentsDataURL

    const transactionId = await tx.Queue.add(new Transaction<TExtra>(req, opts, { guid }))
    log(`Transaction ${transactionId} queued`)

    return new InterceptedResponse({ data: transactionBody, status: 201 })
  },

  postParse: async bag => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
      originalResponse,
    } = bag
    if(!(opts && opts.body)) {
      throw new Error('postResponse: No request body provided')
    }
    if(!(originalResponse instanceof InterceptedResponse)) {
      log('Creating local image', parsedResponse)
      parsedResponse.id = parsedResponse.id.toString()
      parsedResponse.assetId = parsedResponse.assetId.toString()
      await entitiesDb.Images.add(parsedResponse)
    }
  },

  postTransaction: async (bag) => {
    const {
      opts,
      entitiesDb,
      parsedResponse,
      transactionsContext: {
        instance: tx,
      },
    } = bag
    log('Post-transaction putImage...')
    const transaction = bag.transaction as Transaction<TExtra>
    const guid = transaction?.extra?.guid
    if(!(opts && opts.body)) {
      throw new Error('postTransaction: No request body provided')
    }
    if(!tx) {
      throw new Error('postTransaction: No transactions idb context provided')
    }
    if(!guid) {
      throw new Error('postTransaction: No GUID for the temporary local image provided.')
    }

    const newId = parsedResponse.id

    //  Mutate existing entities

    log(`Replacing image: "${guid}" with uploaded image ID#${newId}`)
    const tempImage = await entitiesDb.Images.get(guid)
    if(tempImage) {
      log('Deleting old record', tempImage)
      await entitiesDb.Images.delete(guid)
    }
    parsedResponse.id = parsedResponse.id.toString()
    await entitiesDb.Assets.add(parsedResponse)
    log('Added new record', newId)
  },
})

import React from "react";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { Box, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Link } from "gatsby";

import { Blockquote } from "components/Blockquote";
import { FluidAttributes } from "components/FluidAttributes/FluidAttributes";
import LabelledValue, { UnknownValue } from "components/LabelledValue";
import TitledSection from "components/TitledSection";

import AssetTagLabelledValueWithTagButton, {
  Props as AssetTagLabelledValueProps,
} from "./AssetTagLabelledValueWithTagButton";
import { criticalityRating } from "./criticalityRating";
import SampleHistory from "./SampleHistory";

const styles = require("./AssetDetail.css");

export type Props = AssetTagLabelledValueProps;

export default (props: Props) => {
  const { asset } = props;
  if (!asset) {
    return null;
  }
  const $unknown = <UnknownValue>Unknown</UnknownValue>;
  const $notSpecified = <UnknownValue>Not Specified</UnknownValue>;

  const $manufacturer = (
    <LabelledValue label="Manufacturer">
      {asset.manufacturer || $unknown}
    </LabelledValue>
  );
  const $model = (
    <LabelledValue label="Model">{asset.model || $unknown}</LabelledValue>
  );
  const $machineId = asset.customerEquipmentId && (
    <LabelledValue label="Machine ID">
      {asset.customerEquipmentId}
    </LabelledValue>
  );
  const $criticalityRating = (
    <LabelledValue label="Criticality Rating">
      {asset.criticalityRating
        ? criticalityRating[asset.criticalityRating]
        : $notSpecified}
    </LabelledValue>
  );
  const $sampleSourceRating = (
    <LabelledValue label="Sample Source Rating">
      {asset.sampleSourceRating ? (
        <Rating
          value={asset.sampleSourceRating}
          name="sampleSourceRating"
          readOnly
        />
      ) : (
        $notSpecified
      )}{" "}
    </LabelledValue>
  );
  const $plant = asset.plantId && (
    <LabelledValue label="Plant">
      <Link to={`/app/plants/${asset.plantId}`}>
        {asset.plantName || asset.plantId}
      </Link>
    </LabelledValue>
  );
  const $route = asset.routeId && asset.routeId > 0 && (
    <LabelledValue label="Route">
      <Link to={`/app/routes/${asset.routeId}`}>
        {asset.routeName || asset.routeId}
      </Link>
    </LabelledValue>
  );
  const $samplingInstructions = asset.sampleInstructions && (
    <LabelledValue label="Site Instructions">
      <Blockquote>{asset.sampleInstructions}</Blockquote>
    </LabelledValue>
  );
  return (
    <div className={styles.root}>
      <div className={styles.paddedContent}>
        <Box mb={2}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            component={Link}
            to={`/app/sample?asset=${encodeURIComponent(asset.id)}`}
            style={{ color: "white", textShadow: "none" }}
          >
            <FaPrescriptionBottleAlt style={{ marginRight: "0.5em" }} /> Sample
            this Asset
          </Button>
        </Box>
        {$manufacturer}
        {$model}
        {$machineId}
        {$criticalityRating}
        {$sampleSourceRating}
        <AssetTagLabelledValueWithTagButton {...props} />
        {$plant && $route ? (
          <div className={styles.row}>
            {$plant}
            {$route}
          </div>
        ) : (
          <>
            {$plant}
            {$route}
          </>
        )}
        {$samplingInstructions}
      </div>
      {asset.fluid && (
        <TitledSection title="Fluid">
          <div className={styles.paddedContent}>
            <FluidAttributes fluid={asset.fluid} showManufacturer showName />
          </div>
        </TitledSection>
      )}
      <TitledSection title="Sample History">
        <SampleHistory asset={asset} />
      </TitledSection>
    </div>
  );
};

import React, { FC } from 'react'
import { Button } from '@material-ui/core'

import { KnownAsset } from 'api_supplimental'
import AssetCardHorizontal from 'components/Asset/AssetCardHorizontal'
import FetchStateWrapper from 'components/FetchStateWrapper'
import useApi from 'hooks/useApi'

const styles = require('./ConfirmChangeAsset.css')

type Props = {
  sampleAssetId: number
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmChangeAsset: FC<Props> = (props: Props) => {
  const {
    sampleAssetId,
    onConfirm,
    onCancel,
  } = props
  const assetFetchState = useApi<KnownAsset>(sampleAssetId ? `v1/assets/${sampleAssetId}` : undefined)
  const asset = assetFetchState.data
  return (
    <div className={styles.root}>
      <h1>This sample is already linked to a different asset:</h1>
      <div className={styles.asset}>
        <FetchStateWrapper fetchState={assetFetchState} fallback="No asset found for this tag">
          <AssetCardHorizontal asset={asset} />
        </FetchStateWrapper>
      </div>
      <footer className={styles.footer}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          Change Asset
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          Keep the old one
        </Button>
      </footer>
    </div>
  )
}

export default ConfirmChangeAsset

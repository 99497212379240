import React from 'react'

import { AssetType } from 'api'
import { Result } from 'api_supplimental'
import { defaultIApiState } from 'hooks/useApi'

import { ILocalEntityProviderContextBase } from '../ILocalEntityProviderContextBase'

export interface ILocalAssetTypesContext extends ILocalEntityProviderContextBase<AssetType, Result<AssetType>> {
 assetTypes?: AssetType[]
}

export const defaultContext: ILocalAssetTypesContext = {
  assetTypes: [],
  get() {
    return Promise.reject(new Error('NOT IMPLEMENTED'))
  },
  fetchState: defaultIApiState,
}

export default React.createContext<ILocalAssetTypesContext>(defaultContext)

import React, { ReactNode, useState } from 'react'
import { FaExclamationTriangle, FaTrash } from 'react-icons/fa'
import clsx from 'clsx'
import { motion, useSpring, useTransform } from 'framer-motion'
import { navigate } from 'gatsby'

const styles = require('./LinkageMember.css')

export type Props = {
  icon: ReactNode
  hasValue?: boolean
  children?: ReactNode
  className?: string
  hasError?: boolean
  onDelete?: () => void
  slideOffset?: number
  minSlideDistance?: number
}

const defaults = {
  slideOffset: 55,
  minSlideDistance: 5,
}

type PinnedSide = 'left' | 'right' | 'center'

export const LinkageMember = (props: Props) => {
  const {
    icon,
    hasValue,
    hasError,
    children,
    className,
    onDelete,
    slideOffset = defaults.slideOffset,
    minSlideDistance = defaults.minSlideDistance,
  } = props
  const spring = { type: 'spring', stiffness: 400, damping: 20 }
  const pinnedSideTargetMap: Record<PinnedSide, number> = {
    left: slideOffset,
    right: -slideOffset,
    center: 0,
  }
  const [pinnedSide, setPinnedSide] = useState<PinnedSide>('center')
  const targetX = pinnedSideTargetMap[pinnedSide]
  const x = useSpring(0, spring)
  const background = useTransform(
    x,
    [-slideOffset, 0, slideOffset],
    ['#840008', 'rgba(29, 0, 2, 0.75)', '#840008']
  )
  const trashLeftX = useTransform(x, [-slideOffset, 0, slideOffset], [0, -20, 0])
  const trashRightX = useTransform(x, [-slideOffset, 0, slideOffset], [0, 20, 0])
  const trashScale = useTransform(x, [-slideOffset, 0, slideOffset], [1, 0.5, 1])

  const handleDeleteClick = () => {
    setPinnedSide('center')
    if(typeof onDelete === 'function') {
      onDelete()
    }
  }

  const handleTapEnd = (e: TouchEvent) => {
    setPinnedSide('center')
    // TODO: remove this once https://github.com/framer/motion/issues/322 is resolved
    const t = e.target as HTMLAnchorElement
    try {
      if(t && t.attributes) {
        const hrefAttr = t.attributes.getNamedItem('href')
        const href = hrefAttr && hrefAttr.nodeValue
        if(href) {
          navigate(href)
        }
      }
    } catch(err) {
      // console.warn(err)
    }
  }

  const handleDragEnd = (e: TouchEvent) => {
    e.stopImmediatePropagation()
    const _x = x.get()
    if(pinnedSide !== 'center' || Math.abs(_x) < minSlideDistance) {
      setPinnedSide('center')
      return
    }
    if(_x > 0) {
      setPinnedSide('left')
      return
    }
    setPinnedSide('right')
  }

  const dragConstraints = {
    left: pinnedSideTargetMap[pinnedSide],
    right: pinnedSideTargetMap[pinnedSide],
  }

  const rootCls = clsx(
    styles.root,
    className,
    {
      [styles.hasValue]: hasValue,
      [styles.hasError]: hasError,
      [styles.pinned]: pinnedSide,
    }
  )
  if(!hasValue || typeof onDelete !== 'function') {
    return (
      <div className={rootCls}>
        <div className={styles.dragBox}>
          <div className={styles.iconContainer}>
            {hasError ? (<FaExclamationTriangle />) : icon}
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={rootCls}>
      <motion.button
        type="button"
        className={styles.swipeBackdrop}
        onClick={handleDeleteClick}
        style={{ background }}
      >
        <motion.span style={{ x: trashLeftX, scale: trashScale }}>
          <FaTrash />
        </motion.span>
        <motion.span style={{ x: trashRightX, scale: trashScale }}>
          <FaTrash />
        </motion.span>
      </motion.button>
      <motion.div
        className={styles.dragBox}
        style={{ x, perspective: 1 }}
        drag="x"
        dragConstraints={dragConstraints}
        onDragEnd={handleDragEnd}
        onTap={handleTapEnd}
        animate={{ x: targetX }}
      >
        <div className={styles.iconContainer}>
          {hasError ? (<FaExclamationTriangle />) : icon}
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </motion.div>
    </div>
  )
}

LinkageMember.defaultProps = defaults

export default LinkageMember

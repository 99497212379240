import React, { ReactNode, useCallback, useMemo } from 'react'
import LocalEntityProviderFetchStateWrapper from 'contexts/LocalEntities/LocalEntityProviderFetchStateWrapper'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { AddressDTO } from 'api'
import { Result } from 'api_supplimental'
import { sortByProp } from 'utils/sortByProp'

import context, { ILocalApiAddressesContext } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}
export const LocalAddressesProvider = (props: Props) => {
  const {
    children,
  } = props

  const parseResponse = useCallback((r: Result<AddressDTO>) => r && r.data, [])

  const {
    fetchState,
    entities,
    table,
    isResolving,
  } = useLocalAndPopulateFromFetch<Result<AddressDTO>, AddressDTO>({
    url: 'v1/addresses/all',
    getTable: (db) => db.Addresses,
    parseResponse,
    fetchWhenEmpty: false,
  })

  const addresses = useMemo(() => (entities) && sortByProp(entities, ['id']), [entities])

  const value: ILocalApiAddressesContext = {
    addresses,
    fetchState,
    get: async (id: number) => table.get(id),
  }

  return (
    <Provider value={value}>
      <LocalEntityProviderFetchStateWrapper fetchState={fetchState} isResolving={isResolving} loadingMessage="Loading Addresses">
        {children}
      </LocalEntityProviderFetchStateWrapper>
    </Provider>
  )
}

export default LocalAddressesProvider

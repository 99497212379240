import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { useSnackbar } from 'notistack'

import { AssetTagLinkDTO } from 'api'
import { KnownAsset } from 'api_supplimental'
import useApi, { IApiState } from 'hooks/useApi'

export type TagAssetProps = {
  tagIdentifier: string
  asset: KnownAsset
  onSuccess: (link: AssetTagLinkDTO) => void
}

function captureFetchError(fetchState: IApiState<any>): void {
  if(fetchState.error) {
    Sentry.captureException(fetchState.error)
  }
}

export const DoTagAsset = (props: TagAssetProps) => {
  const { asset, tagIdentifier, onSuccess } = props
  const linkPutState = useApi<AssetTagLinkDTO>('v1/asset-tag-links', undefined, {
    requestOptions: {
      method: 'PUT',
      body: JSON.stringify({
        assetId: asset.id,
        tagIdentifier,
        active: true,
      }),
    },
  })
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    captureFetchError(linkPutState)
    if(linkPutState.error) {
      enqueueSnackbar(linkPutState.error.body, { variant: 'error' })
    }
  }, [enqueueSnackbar, linkPutState, linkPutState.error])
  useEffect(() => {
    if(linkPutState.data) {
      enqueueSnackbar(<span><strong>Success!</strong> Linked tag <code>{tagIdentifier}</code></span>, { variant: 'success' })
      onSuccess(linkPutState.data)
    }
  }, [linkPutState.data]) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

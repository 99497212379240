import React from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { UserDTO } from 'api'
import LabelledValue from 'components/LabelledValue'

export type Props = {
  user: UserDTO
}
export const UserDetail = ({ user }: Props) => {
  const {
    firstName = '',
    lastName = '',
    phoneNumber,
    email,
  } = user
  const parsedPhone = phoneNumber && parsePhoneNumberFromString(phoneNumber, 'US')
  const name = `${firstName} ${lastName}`.trim()
  const $name = (name && name.length > 0) && <LabelledValue label="Name" value={name} />
  const $phone = (phoneNumber && phoneNumber.length > 0) && (
    <LabelledValue label="Phone">
      {parsedPhone ? (
        <a href={parsedPhone.format('RFC3966')}>{parsedPhone.formatNational()}</a>
      ) : (
        <span>{phoneNumber}</span>
      )}
    </LabelledValue>
  )
  const $email = (email && email.length > 0) && (
    <LabelledValue label="Email">
      <a href={`mailto:${email}`}>{email}</a>
    </LabelledValue>
  )
  return (
    <>
      {$name}
      {$phone}
      {$email}
    </>
  )
}

export default UserDetail

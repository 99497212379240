import { AddressDTO } from 'api'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getAddress = new Intercept<AddressDTO>({
  pathname: 'v1/addresses/:addressId',
  fetch: async ({ entitiesDb, match }) => {
    const addressId = match && match.params && match.params.addressId
    if(!addressId) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Addresses.get(Number(addressId))
    return new InterceptedResponse({ data, status: 200 })
  },
})

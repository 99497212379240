import { LocalImageDTO } from 'api_supplimental'
import useApi, { IApiState } from 'hooks/useApi'

export default function useDeleteImage(pointImage: LocalImageDTO): IApiState<null> {
  const url = pointImage && `v1/images/${pointImage.id}`
  return useApi<null>(url, undefined, {
    requestOptions: {
      method: 'DELETE',
    },
  })
}

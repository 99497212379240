import React, { FC, useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { Link, RouteComponentProps } from "@reach/router";

import {
  KnownAsset,
  KnownAssetWithReportStatus,
  Result,
} from "api_supplimental";
import AppHeader from "components/AppHeader";
import PaddedFlexCentered from "components/PaddedFlexCentered";
import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import { sortByProp } from "utils/sortByProp";

import FilterableAssetList from "./FilterableAssetList";
import path from "path";
import { AssetReportAvailabilityMap } from "api";
import LocalEntityProviderFetchStateWrapper from "contexts/LocalEntities/LocalEntityProviderFetchStateWrapper";

const reportURL = `assets/latestreport/verifyall`;
export const AssetsList: FC<RouteComponentProps> = () => {
  // const { assets } = useContext(offlineAssetsContext)
  const { data, isLoading } = useApi<Result<KnownAsset>>("v1/assets/all");

  const body = JSON.stringify({
    pointIDs: data?.data?.map((asset) => asset.id),
  });

  const reportAvailabilityFetchState = useApi<AssetReportAvailabilityMap>(
    path.join("v1", reportURL),
    [],
    {
      requestOptions: {
        body,
      },
      dependencies: [data?.data],
    }
  );

  const { data: pointIDsWithReportsData, isLoading: isLoadingReportData } =
    reportAvailabilityFetchState;
  const assetData: KnownAssetWithReportStatus[] | undefined =
    (!!data &&
      !!data.data &&
      !!pointIDsWithReportsData &&
      data.data.map((d) => {
        const matchingEntry = pointIDsWithReportsData[Number(d.id)];
        const available =
          pointIDsWithReportsData && typeof matchingEntry !== "undefined";

        return {
          ...d,
          lastSampleId: available ? matchingEntry.labId : undefined,
          reportAvailable: available,
          machineCondition: available
            ? matchingEntry.machineCondition
            : undefined,
          lubricantCondition: available
            ? matchingEntry.lubricantCondition
            : undefined,
          sampledAt: matchingEntry?.sampledAt,
        };
      })) ||
    undefined;

  const sortedAssets: KnownAssetWithReportStatus[] | undefined =
    assetData && sortByProp(assetData, "name");

  return (
    <LocalEntityProviderFetchStateWrapper
      isResolving={reportAvailabilityFetchState.isLoading}
      fetchState={reportAvailabilityFetchState}
      loadingMessage="Verifying latest asset report availability..."
      blocking
    >
      <AppHeader
        title="Assets"
        actionRight={
          <Button component={Link} to="new">
            New
          </Button>
        }
        subtitle={sortedAssets && `${sortedAssets.length} total`}
        fixed
      />
      <FilterableAssetList assets={sortedAssets} />
    </LocalEntityProviderFetchStateWrapper>
  );
};

export default AssetsList;

import React, { HTMLProps, ReactNode } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'

const styles = require('./SearchInput.css')

type Props = {
  icon?: ReactNode
  containerProps?: HTMLProps<HTMLDivElement>
} & HTMLProps<HTMLInputElement>

export const SearchInput = (props: Props) => {
  const {
    icon = <SearchIcon />,
    className,
    containerProps,
    ...passedProps
  } = props

  return (
    <div {...containerProps} className={clsx(containerProps && containerProps.className, styles.root)}>
      <input
        type="text"
        className={clsx(className, styles.input)}
        {...passedProps}
      />
      <div className={styles.icon}>
        {icon}
      </div>
    </div>
  )
}

export default SearchInput

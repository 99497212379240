import { AddressDTO, LinkPlantAddressDTO, PlantDetailDTO } from 'api'
import useApi, { IApiState } from 'hooks/useApi'

import { getAddressFromPlantRecord } from '.'

export default function usePlantPrimaryAddress(plantDetails?: PlantDetailDTO): IApiState<AddressDTO> {
  const primaryAddressLink: LinkPlantAddressDTO | undefined = getAddressFromPlantRecord(plantDetails)
  const url = (primaryAddressLink && primaryAddressLink.addressId) ? `v1/addresses/${primaryAddressLink.addressId}` : undefined
  return useApi<AddressDTO>(url)
}

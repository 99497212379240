import React, { HTMLProps, ReactNode } from 'react'
import { Link, LinkProps } from '@reach/router'
import clsx from 'clsx'

import { Props as IListItemProps } from './JQUIListItem'

const styles = require('./JQUIListItemLink.css')

type OptionalToLinkProps = Omit<LinkProps<HTMLProps<HTMLAnchorElement>>, 'to'> & {
  to?: string
  href?: never
}

export interface IListItemLinkProps extends IListItemProps {
  linkProps?: OptionalToLinkProps
  render?: <T = HTMLAnchorElement>(props: HTMLProps<T>) => ReactNode
}

const defaultRenderMethod = (renderProps: any) => {
  if(typeof renderProps.to === 'string') {
    return (
      <Link {...renderProps} />
    )
  }
  return <a {...renderProps} />
}

export const JQUIListItemLink = React.forwardRef<HTMLLIElement, IListItemLinkProps>((props: IListItemLinkProps, ref) => {
  const {
    children,
    className,
    iconLeft,
    iconRight,
    pre,
    linkProps,
    render = defaultRenderMethod,
    ...passedProps
  } = props
  const renderProps = {
    ...linkProps,
    className: clsx(styles.link, (linkProps && linkProps.className)),
    children: (
      <>
        {pre}
        {iconLeft}
        <span className={styles.title}>
          {children}
        </span>
        {iconRight}
      </>
    ),
  }
  return (
    <li className={clsx(className, styles.root)} {...passedProps} ref={ref}>
      {render(renderProps)}
    </li>
  )
})

export default JQUIListItemLink

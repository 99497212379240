import React, { ReactNode } from 'react'
import { FaExclamation } from 'react-icons/fa'
import clsx from 'clsx'

const styles = require('./WarningBubble.css')

type Props = {
  children: ReactNode
  arrowSide?: false | 'left' | 'right'
}
export const WarningBubble = (props: Props) => {
  const {
    children,
    arrowSide = 'left',
  } = props
  return (
    <div className={clsx(styles.root, { [styles.withArrow]: arrowSide }, arrowSide && styles[arrowSide])}>
      <FaExclamation />
      <div>
        {children}
      </div>
    </div>
  )
}

export default WarningBubble

import React from 'react'

import { LocalImageDTO } from 'api_supplimental'
import JQUIListItemLinkWithDelete, { IListItemLinkWithDeleteProps } from 'components/JQUIList/JQUIListItemLinkWithDelete'
import Thumbnail from 'components/Thumbnail'
import { getBlobImageUrl } from 'utils/remote-image-utils'

const styles = require('./AssetMediaListItem.css')

export interface Props extends Omit<IListItemLinkWithDeleteProps, 'onDelete'> {
  onDelete: (pointImage: LocalImageDTO) => void
  pointImage: LocalImageDTO
}

export const AssetMediaListItem = (props: Props) => {
  const {
    pointImage,
    children,
    onDelete,
    ...passedProps
  } = props

  const handleDelete = () => {
    onDelete(pointImage)
  }

  const imageUrl = pointImage.imagePath && getBlobImageUrl(pointImage.imagePath, 'sm')

  return (
    <JQUIListItemLinkWithDelete
      linkProps={{ style: { padding: 0 } }}
      onDelete={handleDelete}
      {...passedProps}
    >
      <div className={styles.root}>
        <Thumbnail imageUrl={imageUrl} className={styles.thumbnail} />
        <span className={styles.content}>
          {children}
        </span>
      </div>
    </JQUIListItemLinkWithDelete>
  )
}

export default AssetMediaListItem

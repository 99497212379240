import React, { FC, ReactNode, useContext } from 'react'
import { Box } from '@material-ui/core'
import { networkStateContext } from 'contexts/NetworkState'

export const OnlineOnlyDisplay: FC = () => (
  <Box display="flex" flexDirection="column" textAlign="center" height="100%" alignItems="center">
    <Box fontSize="15vh" mt="10vh" role="img" component="div">🔌</Box>
    <Box fontSize="h6.fontSize" component="h1" width={2 / 3}>You must be online to use this page</Box>
  </Box>
)

type Props = {
  fallback?: ReactNode
  children: ReactNode
}
export const OnlineOnly: FC<Props> = (props: Props) => {
  const {
    fallback = <OnlineOnlyDisplay />,
    children,
  } = props
  const { online } = useContext(networkStateContext)
  if(!online) {
    return <>{fallback}</>
  }
  return <>{children}</>
}

export default OnlineOnly

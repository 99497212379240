import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Sentry from "@sentry/browser";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import { StringParam } from "use-query-params";

import { AssetTagLinkDTO, NewSampleDTO, SampleDTO } from "api";
import { KnownAsset, Result, SampleLinkSansStatus } from "api_supplimental";
import useApi, { IApiState } from "hooks/useApi";
import useApiFromUrlParam from "hooks/useApiFromUrlParam";
import { useTimeout } from "hooks/useTimeout";
import { BOTTLE_STATUS } from "utils/bottle-status";

import useAsset from "./useAsset";

export type EntryMode = "asset" | "bottle" | "link-tag" | undefined;
export type BodyMode =
  | "form"
  | "scan"
  | "entry"
  | "sampleError"
  | "unlinkedTag"
  | "tagLinkedOutsideCustomer"
  | "loading"
  | "sampleAssetConflict";
export type HistoryUpdateMode = "replace" | "replaceIn" | "push" | "pushIn";

export type TParams = {
  populateTagFromSample?: boolean;
};
export type TReturn = {
  assetId?: number;
  tagIdentifier?: string;
  bottleIdentifier?: string;
  asset?: KnownAsset;
  sample?: SampleDTO;
  tagIsUnlinked?: boolean;
  isLinkConflict: boolean;
  bodyMode: BodyMode;
  entryMode: EntryMode;
  tagIsLinked?: boolean;
  tagIsLinkedOutsideCustomerContext?: boolean;
  fetchStates: {
    tag: IApiState<Result<AssetTagLinkDTO>>;
    asset: IApiState<KnownAsset | undefined>;
    equipment: IApiState<KnownAsset | undefined>;
    sample: IApiState<SampleDTO | undefined>;
    sampleDelete: IApiState<Result<SampleDTO>>;
    samplePatch: IApiState<SampleDTO | undefined>;
    samplePut: IApiState<SampleDTO | undefined>;
  };
  fn: {
    reset: () => void;
    resetAsset: () => void;
    resetEquipment: () => void;
    resetBottle: () => void;
    resetTag: () => void;
    setAssetId: (id?: string | number, updateType?: HistoryUpdateMode) => void;
    setEquipmentID: (
      equipmentID?: string,
      updateType?: HistoryUpdateMode
    ) => void;
    setTagIdentifier: (id?: string, updateType?: HistoryUpdateMode) => void;
    setBottleIdentifier: (id?: string, updateType?: HistoryUpdateMode) => void;
    setSampleToPatch: (sample: NewSampleDTO) => void;
    setBottleIdToDelete: (id?: number | string) => void;
    setLinkedAssetId: (id: string | undefined) => void;
    setEntryMode: (mode: EntryMode) => void;
    setSampleAssetChangeConfirmed: (confirmed: boolean) => void;
  };
};

export function useSampleLink(params: TParams): TReturn {
  const { populateTagFromSample } = params;
  const { enqueueSnackbar } = useSnackbar();

  const [sampleToPatch, setSampleToPatch] = useState<SampleLinkSansStatus>();
  const [sampleToCreate, setSampleToCreate] = useState<SampleLinkSansStatus>();
  // The assetId received from linking a new tag. Used as a dependency to trigger tagLinksFetchState.
  const [linkedAssetId, setLinkedAssetId] = useState<string>();
  const {
    tagIdentifier,
    setTagIdentifier,
    assetFetchState,
    equipmentFetchState,
    tagLinksFetchState,
    tagIsLinked,
    tagIsLinkedOutsideCustomerContext,
    asset,
    assetId,
    setAssetId,
    setEquipmentID,
  } = useAsset([linkedAssetId]);
  const putSampleFetchState = usePutSampleLink(sampleToCreate);
  const patchSampleFetchState = usePatchSample(sampleToPatch);

  const [sampleFetchState, setBottleIdentifier, bottleIdentifier] =
    useApiFromUrlParam<SampleDTO, string>(
      "bottle",
      (_id) => `v1/samples/${_id}`,
      undefined,
      StringParam,
      {
        dependencies: [
          putSampleFetchState.statusCode,
          patchSampleFetchState.statusCode,
        ],
      }
    );
  const sample = sampleFetchState.data;
  const [sampleIdToDelete, setSampleIdToDelete] = useState<
    number | string | undefined
  >();
  const sampleDeleteFetchState = useApi<Result<SampleDTO>>(
    sampleIdToDelete && sampleIdToDelete > 0
      ? `v1/samples/${sampleIdToDelete}`
      : undefined,
    undefined,
    {
      requestOptions: { method: "DELETE" },
      dependencies: [sampleIdToDelete],
    }
  );
  const [entryMode, setEntryMode] = useState<EntryMode>();
  const [sampleAssetChangeConfirmed, setSampleAssetChangeConfirmed] =
    useState<boolean>(false);
  const [
    showConfirmSampleAssetChangeModal,
    setShowConfirmSampleAssetChangeModal,
  ] = useState<boolean>(false);

  const isLinkConflict =
    sampleFetchState.isError ||
    sampleDeleteFetchState.isError ||
    showConfirmSampleAssetChangeModal;

  const bodyMode = useMemo<BodyMode>(() => {
    if (showConfirmSampleAssetChangeModal) {
      return "sampleAssetConflict";
    }
    if (sampleFetchState.error && sampleFetchState.statusCode !== 404) {
      return "sampleError";
    }
    if (entryMode) {
      return "entry";
    }
    if (tagIsLinked === false) {
      return "unlinkedTag";
    }
    if (tagIsLinkedOutsideCustomerContext) {
      return "tagLinkedOutsideCustomer";
    }
    if (
      tagIsLinked &&
      assetFetchState.isLoading &&
      sampleFetchState.isLoading
    ) {
      return "loading";
    }
    if (bottleIdentifier && asset && sample) {
      return "form";
    }
    return "scan";
  }, [
    asset,
    assetFetchState.isLoading,
    bottleIdentifier,
    entryMode,
    sample,
    sampleFetchState.error,
    sampleFetchState.isLoading,
    sampleFetchState.statusCode,
    showConfirmSampleAssetChangeModal,
    tagIsLinked,
    tagIsLinkedOutsideCustomerContext,
  ]);

  const resetTag = useCallback(() => {
    setTagIdentifier(undefined, "pushIn");
  }, [setTagIdentifier]);

  const resetAsset = useCallback(() => {
    setTagIdentifier(undefined, "pushIn");
    setAssetId(undefined, "pushIn");
  }, [setAssetId, setTagIdentifier]);

  const resetBottle = useCallback(() => {
    setBottleIdentifier(undefined, "pushIn");
  }, [setBottleIdentifier]);

  const resetEquipment = useCallback(() => {
    setEquipmentID(undefined, "pushIn");
  }, [setEquipmentID]);

  const reset = useCallback(() => {
    setSampleToPatch(undefined);
    setSampleToCreate(undefined);
    setSampleIdToDelete(undefined);
    setSampleAssetChangeConfirmed(false);
    setShowConfirmSampleAssetChangeModal(false);
    resetBottle();
    resetEquipment();
    resetAsset();
    resetTag();
  }, [resetBottle, resetAsset, resetTag]);

  /****************************************************************************
   * SIDE EFFECTS
   ***************************************************************************/

  useEffect(() => {
    setEntryMode(undefined);
  }, [assetId, tagIdentifier, bottleIdentifier]);

  // Create a new sample as soon as we have enough data and one does not exist already
  useEffect(() => {
    if (sampleFetchState.statusCode === 404 && bottleIdentifier && assetId) {
      if (
        putSampleFetchState.statusCode &&
        putSampleFetchState.statusCode >= 200
      ) {
        return;
      }
      setSampleToCreate({
        identifier: bottleIdentifier,
        assetId: assetId,
        sampledAt: new Date().toISOString(),
      });
    }
  }, [
    assetId,
    bottleIdentifier,
    putSampleFetchState.statusCode,
    sampleFetchState.statusCode,
  ]);

  useEffect(() => {
    if (sample && sample.status && sample.status > BOTTLE_STATUS.Linked) {
      navigate(`/app/sample/${sample.identifier}/`, { replace: false });
    }
  }, [sample]);

  // reset asset change confirmation
  useEffect(() => {
    setSampleAssetChangeConfirmed(false);
    setShowConfirmSampleAssetChangeModal(false);
  }, [assetId, bottleIdentifier]);

  // watch for bottles that have samples already and populate the tag from the sample
  useEffect(() => {
    if (sample && sample.assetId && !assetId && populateTagFromSample) {
      setAssetId(sample.assetId, "pushIn");
    }
  }, [assetId, populateTagFromSample, sample, setAssetId]);

  // check for sample asset conflicts
  useEffect(() => {
    if (sample && assetId && sample.assetId) {
      if (sample.assetId !== assetId && asset) {
        if (sampleAssetChangeConfirmed) {
          const nextSample = {
            ...sample,
            assetId,
          };
          setSampleToPatch(nextSample);
          setShowConfirmSampleAssetChangeModal(false);
        } else {
          setShowConfirmSampleAssetChangeModal(true);
        }
      }
    }
  }, [asset, assetId, sample, sampleAssetChangeConfirmed]);

  // watch for bottle delete requests to finish
  useEffect(() => {
    if (sampleIdToDelete) {
      if (
        sampleDeleteFetchState.statusCode &&
        sampleDeleteFetchState.statusCode >= 200
      ) {
        setSampleIdToDelete(undefined);
        setBottleIdentifier(undefined);
        enqueueSnackbar("Sample unlinked", {
          variant: "info",
          disableWindowBlurListener: true,
        });
      }
    }
  }, [
    sampleIdToDelete,
    enqueueSnackbar,
    sampleDeleteFetchState.statusCode,
    setBottleIdentifier,
  ]);

  // watch for sample patch to finish
  useTimeout(
    () => {
      if (sampleAssetChangeConfirmed) {
        enqueueSnackbar("Sample asset changed", {
          variant: "success",
          disableWindowBlurListener: true,
        });
        setSampleAssetChangeConfirmed(false);
      } else {
        reset();
        enqueueSnackbar("Sample linked", {
          variant: "success",
          disableWindowBlurListener: true,
        });
        window.scrollTo(0, 0);
      }
    },
    patchSampleFetchState.statusCode === 200 ? 250 : null
  );

  // Notify sentry on errors
  useEffect(() => {
    if (putSampleFetchState.error) {
      Sentry.captureException(putSampleFetchState.error);
    }
  }, [putSampleFetchState.error]);

  // Attach extras to sentry
  useEffect(() => {
    Sentry.setExtras({ bottleIdentifier, assetId, tagIdentifier });
  }, [assetId, bottleIdentifier, tagIdentifier]);

  return {
    assetId,
    tagIdentifier,
    bottleIdentifier,
    sample,
    asset,
    isLinkConflict,
    bodyMode,
    entryMode,
    tagIsLinked,
    tagIsLinkedOutsideCustomerContext,
    fetchStates: {
      tag: tagLinksFetchState,
      asset: assetFetchState,
      equipment: equipmentFetchState,
      sample: sampleFetchState,
      sampleDelete: sampleDeleteFetchState,
      samplePatch: patchSampleFetchState,
      samplePut: putSampleFetchState,
    },
    fn: {
      reset,
      resetEquipment,
      resetBottle,
      resetAsset,
      resetTag,
      setTagIdentifier,
      setAssetId,
      setEquipmentID,
      setBottleIdentifier,
      setSampleToPatch,
      setBottleIdToDelete: setSampleIdToDelete,
      setLinkedAssetId,
      setEntryMode,
      setSampleAssetChangeConfirmed,
    },
  };
}

function usePutSampleLink(
  sampleLink?: SampleLinkSansStatus,
  dependencies: DependencyList = []
): IApiState<SampleDTO | undefined, SampleLinkSansStatus> {
  const body = sampleLink && JSON.stringify(sampleLink);
  const url = sampleLink ? "v1/samples" : undefined;
  if (sampleLink) {
    Sentry.addBreadcrumb({
      category: "sample",
      message: "Creating new sample",
      level: Sentry.Severity.Info,
      data: sampleLink,
      timestamp: Date.now(),
    });
  }

  return useApi<SampleDTO | undefined, SampleLinkSansStatus>(url, undefined, {
    requestOptions: {
      method: "PUT",
      body,
    },
    dependencies: [body, ...dependencies],
  });
}

function usePatchSample(
  sampleLink?: SampleLinkSansStatus,
  dependencies: DependencyList = []
): IApiState<SampleDTO | undefined, SampleLinkSansStatus> {
  const body = sampleLink && JSON.stringify(sampleLink);
  const url = sampleLink ? `v1/samples/${sampleLink.identifier}` : undefined;
  if (sampleLink) {
    Sentry.addBreadcrumb({
      category: "sample",
      message: "Patching sample",
      level: Sentry.Severity.Info,
      data: sampleLink,
      timestamp: Date.now(),
    });
  }

  return useApi<SampleDTO | undefined, SampleLinkSansStatus>(url, undefined, {
    requestOptions: {
      method: "PATCH",
      body,
    },
    dependencies: [body, ...dependencies],
  });
}

import { useContext, useEffect, useMemo, useRef } from 'react'
import Debug from 'debug'
import { Workbox } from 'workbox-window'

import { LocalImageDTO } from 'api_supplimental'
import useSessionStorage from 'hooks/useSessionStorage'
import { useThrottleFn } from 'hooks/useThrottleFn'
import { getBlobImageUrl, TPointImageBlobSize } from 'utils/remote-image-utils'
import { quickStringHash } from 'utils/string-utils'

import context from './context'

const log = Debug('AL:AssetImagesPrecacher')

export const AssetImagesPrecacher = () => {
  const { images: contextImages } = useContext(context)
  const workboxRef = useRef<Workbox>()
  const enabled = process.env.NODE_ENV === 'production'
  const [lastCachedHash, setLastCachedHash] = useSessionStorage('AssetImagesPrecacherHash', '', true)

  const imagesHash: string = useMemo(() => {
    const str = contextImages?.map(i => i.imagePath).join(';') || ''
    return quickStringHash(str).toString()
  }, [contextImages])

  useEffect(() => {
    if(!enabled) {
      return
    }
    log('AssetImagesPrecacher mounted')
    if('serviceWorker' in navigator) {
      workboxRef.current = new Workbox('/sw.js')
      const wb = workboxRef.current
      wb.register()
    } else {
      log('ServiceWorker API not available.')
    }
  }, [enabled])

  const precacheImages = useThrottleFn((images: LocalImageDTO[]) => {
    if(!images || images.length === 0) {
      log('No images to cache.')
      return
    }
    setLastCachedHash(imagesHash)
    const sizesToCache: TPointImageBlobSize[] = ['tn', 'md']
    log(`Precaching ${images.length} images in ${sizesToCache.length} sizes (${sizesToCache.map(s => `"${s}"`).join(',')})`)
    const urlsToCache = images.reduce((acc, imageDTO) => {
      sizesToCache.forEach(size => {
        if(imageDTO.imagePath) {
          acc.push(getBlobImageUrl(imageDTO.imagePath, size))
        }
      })
      return acc
    }, [] as string[])
    const wb = workboxRef.current
    if(wb) {
      wb.messageSW({
        type: 'CACHE_URLS',
        payload: { urlsToCache },
      })
    }
  }, 1000, { leading: false, trailing: true })

  useEffect(() => {
    if(enabled && contextImages && contextImages.length) {
      if(imagesHash !== lastCachedHash) {
        log(`Images hash does not match. Trigger precaching. ("${lastCachedHash}" => "${imagesHash}")`)
        precacheImages(contextImages)
      }
    }
  }, [lastCachedHash, enabled, precacheImages, workboxRef, imagesHash, contextImages])

  return null
}

export default AssetImagesPrecacher

/**
 * TransactionsProvider
 *
 * Provides a context for Transactions that are kept in sync with state when the database changes.
 *
 * @author Aaron Lampros <alampros@testoil.com>
 */
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import currentUserContext from 'contexts/CurrentUser/context'
import context from 'contexts/Transactions/transactionsDbContext'

import { useThrottleFn } from 'hooks/useThrottleFn'
import Transactions, { Transaction } from 'idb/Transactions'

// import { useResetTransactions } from './useResetTransactions'

const { Provider } = context

export interface Props {
  children: ReactNode
}

export const TransactionsProvider = (props: Props) => {
  const {
    children,
  } = props
  const [lastUpdated, setLastUpdated] = useState<number>()
  const setLastUpdatedThrottled = useThrottleFn(setLastUpdated, 1000)
  const { currentUser } = useContext(currentUserContext)
  const transactionsDb = (currentUser && currentUser.email) ? Transactions.getInstance(currentUser.email) : undefined
  const [pending, setPending] = useState<Transaction[]>([])
  // useResetTransactions(transactionsDb)

  const handleChanged = useCallback(() => {
    setLastUpdatedThrottled(Date.now())
  }, [setLastUpdatedThrottled])

  // React to changed database
  useEffect(() => {
    let mounted = true;
    (async () => {
      if(transactionsDb) {
        const nextPending = await transactionsDb.Queue.where('status').notEqual('complete').sortBy('dateCreated')
        if(mounted) {
          setPending(nextPending || [])
        }
      }
    })()
    return () => { mounted = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated])

  // Subscribe to all changes and unsubscribe on cleanup
  useEffect(() => {
    if(transactionsDb) {
      transactionsDb.on('changes', handleChanged)
    }
    return () => {
      try {
        if(transactionsDb) {
          // @ts-ignore
          transactionsDb.on.changes.unsubscribe(handleChanged)
        }
      } catch(err) {
        // console.warn(err)
      }
    }
  }, [handleChanged, transactionsDb])

  return (
    <Provider value={{
      instance: transactionsDb,
      count: pending.length,
      pending,
    }}
    >
      {children}
    </Provider>
  )
}

export default TransactionsProvider

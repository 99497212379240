import React from 'react'
import { FaAngleRight, FaStoreAlt } from 'react-icons/fa'
import { Box, Typography } from '@material-ui/core'

import { CustomerDTO } from 'api'
import AppHeader from 'components/AppHeader'
import JQUIList from 'components/JQUIList'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'

import { PaddedSection } from './PaddedSection'
import TitledSection from './TitledSection'

type Props = {
  customers: CustomerDTO[],
  onCustomerChange: (customer: CustomerDTO) => void,
}

export const ActiveCustomerSelect = (props: Props) => {
  const {
    customers,
    onCustomerChange,
  } = props
  return (
    <>
      <AppHeader
        title="Select a Customer"
        fixed
      />
      <PaddedSection style={{ textAlign: 'center' }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <FaStoreAlt size="3em" />
          <FaStoreAlt size="4em" style={{ margin: '0 0.5em 0.5em' }} />
          <FaStoreAlt size="3em" />
        </Box>
        <Typography paragraph>You are authorized to multiple customers. Please select a customer to work with.</Typography>
        <Typography variant="caption" paragraph color="textSecondary">(You can change this from the "more" menu later if you are online)</Typography>
      </PaddedSection>
      <TitledSection title="Customers">
        <JQUIList>
          {customers.map(cust => {
            return (
              <JQUIListItemLink
                key={cust.id}
                iconRight={<FaAngleRight />}
                linkProps={{
                  onClick: () => {
                    onCustomerChange(cust)
                  },
                }}
              >
                {cust.name}
              </JQUIListItemLink>
            )
          })}
        </JQUIList>
      </TitledSection>
    </>
  )
}

export default ActiveCustomerSelect

import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import customersContext from 'contexts/LocalEntities/Customers/context'
import useClearLocalCustomerRelatedTables from 'contexts/LocalEntities/useClearLocalCustomerRelatedTables'

import { CustomerDTO } from 'api'
import ActiveCustomerSelect from 'components/ActiveCustomerSelect'
import InlineError from 'components/Errors/InlineError'
import useSessionStorage from 'hooks/useSessionStorage'

import context from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
}

export const ACTIVE_CUSTOMER_STORAGE_KEY = 'activeCustomer'

export const ActiveCustomerProvider = (props: Props) => {
  const {
    children,
  } = props
  const [activeCustomerId, setActiveCustomerId] = useSessionStorage<number | undefined>(ACTIVE_CUSTOMER_STORAGE_KEY)
  const { customers } = useContext(customersContext)
  useClearLocalCustomerRelatedTables(activeCustomerId)

  useEffect(() => {
    if(customers && customers.length === 1) {
      setActiveCustomerId(customers[0].id)
    }
  }, [customers, setActiveCustomerId])

  const activeCustomer = useMemo(() => {
    return customers?.find(c => c.id === activeCustomerId)
  }, [activeCustomerId, customers])

  const setActiveCustomer = useCallback((customer?: CustomerDTO) => {
    setActiveCustomerId(customer?.id)
  }, [setActiveCustomerId])

  if(customers.length === 0) {
    return (
      <InlineError message={(
        <div>
          <p><strong>No customers found!</strong></p>
          <p>Something is misconfigured on our end. Please contact a Customer Engagement Specialist to resolve this configuration issue.</p>
        </div>
      )}
      />
    )
  }

  if(!activeCustomer) {
    return (
      <ActiveCustomerSelect customers={customers} onCustomerChange={setActiveCustomer} />
    )
  }
  return (
    <Provider
      value={{
        activeCustomer,
        setActiveCustomer,
        customers,
      }}
    >
      {children}
    </Provider>
  )
}

export default ActiveCustomerProvider

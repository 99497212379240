import React from 'react'
import { RoleCodes } from 'app_constants'

import Tag from 'components/Tag'

interface Props {
  roleCode?: number
  className?: string
}

const roleLabels = {
  [RoleCodes.Administrator]: 'Admin',
  [RoleCodes.Supervisor]: 'Super',
  [RoleCodes.Raiden]: 'God',
}

const roleColors: { [k: string]: 'info' | 'warning' | 'danger' } = {
  [RoleCodes.Administrator]: 'warning',
  [RoleCodes.Supervisor]: 'info',
  [RoleCodes.Raiden]: 'danger',
}

export const RoleTag = (props: Props) => {
  const {
    roleCode,
    className,
  } = props
  if(!roleCode) {
    return null
  }
  const label = roleLabels[roleCode]
  if(!label) {
    return null
  }
  return (
    <Tag color={roleColors[roleCode]} className={className}>{label}</Tag>
  )
}
export default RoleTag

import { QueryParamConfig, useQueryParam } from "use-query-params";

import useApi, { IApiState, IUseApiConfig } from "hooks/useApi";

export type IApiFromUrlParamState<T, D> = [
  IApiState<T>,
  (
    newValue?: D,
    updateType?: "replace" | "replaceIn" | "push" | "pushIn" | undefined
  ) => void,
  D | undefined
];

export default function useApiFromUrlParam<T, TParsedValue = number>(
  paramName: string,
  getUrl: (paramVal: TParsedValue) => string,
  initialData?: T,
  parser?: QueryParamConfig<any>,
  useApiConfig?: IUseApiConfig
): IApiFromUrlParamState<T, TParsedValue> {
  const [paramVal, setParam] = useQueryParam<
    TParsedValue | undefined,
    TParsedValue
  >(paramName, parser);

  const url = paramVal ? getUrl(paramVal) : undefined;
  const passedDeps = (useApiConfig && useApiConfig.dependencies) || [];
  const cfg: Partial<IUseApiConfig> = {
    ...useApiConfig,
    dependencies: [...passedDeps, paramVal],
  };

  const fetchState = useApi<T>(url, initialData, cfg);
  return [fetchState, setParam, paramVal];
}

export const AuthorizationLevels = {
  Plant: 1,
  Route: 2,
  Customer: 3,
  Division: 4,
  Company: 5,
  User: 6,
}
export const AuthorizationLevelNames = Object.entries(AuthorizationLevels).reduce((acc, [k, v]) => {
  acc[v] = k
  return acc
}, {} as { [k: number]: string})

export const RoleCodes = {
  User: 1,
  Supervisor: 2,
  Administrator: 3,
  Raiden: 4,
}

export const RECENT_SAMPLE_AGE = 1000 * 60 * 60 * 24 * 3 // 3 days

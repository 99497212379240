import React, { FC } from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import ManageUsers from './ManageUsers'
import SelectCompany from './SelectCompany'
import UserDetails from './UserDetails'

export const ManageUsersRouter: FC<RouteComponentProps> = () => {
  return (
    <Router basepath="/app/account/users">
      <SelectCompany path="/" />
      <ManageUsers path="/:companyId" />
      <UserDetails path="/:companyId/:userId" />
    </Router>
  )
}
export default ManageUsersRouter

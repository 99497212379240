import { useCallback, useEffect } from 'react'
import useLocalAndPopulateFromFetch from 'contexts/LocalEntities/useLocalAndPopulateFromFetch'

import { TestPackageDTO } from 'api'
import { CustomerTestPackage, Result } from 'api_supplimental'

export interface Props {
  customerId: number
  onLoaded?: (customerId: number) => void
}
export const TestPackageLoader = (props: Props) => {
  const {
    customerId,
    onLoaded,
  } = props
  const parseResponse = useCallback((d: Result<TestPackageDTO>) => {
    if(d && d.data) {
      return d.data.map(testPackage => ({
        ...testPackage,
        customerId,
      }))
    }
  }, [customerId])
  const {
    entities,
  } = useLocalAndPopulateFromFetch<Result<TestPackageDTO>, CustomerTestPackage>({
    clearBefore: false,
    url: `v1/customers/${customerId}/test-packages/all`,
    getTable: (db) => db.TestPackages,
    parseResponse,
    updateOnChangesToTables: true,
    storageCacheKeyTableName: `TestPackages#${customerId}`,
  })

  useEffect(() => {
    if(entities && onLoaded) {
      onLoaded(customerId)
    }
  }, [customerId, entities, onLoaded])

  return null
}

export default TestPackageLoader

import React, { FC, useContext, useMemo } from 'react'
import { FaMapMarkerAlt, FaPrescriptionBottle } from 'react-icons/fa'
import useAsync from 'react-use/lib/useAsync'
import { Button } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import assetsContext from 'contexts/LocalEntities/Assets/context'
import { Link } from 'gatsby'

import { BottleDTO, SampleDTO } from 'api'
import AppHeader from 'components/AppHeader'
import AssetCardHorizontal from 'components/Asset/AssetCardHorizontal'
import FetchStateWrapper from 'components/FetchStateWrapper'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import BottleCard from './Linkage/BottleCard'
import Linkage, { LinkageMember } from './Linkage'
import SampleReadOnlyBody from './SampleReadOnlyBody'

export type Props = {
  bottleId?: string
}

export const SampleReadOnly: FC<RouteComponentProps & Props> = (props: Props) => {
  const {
    bottleId,
  } = props
  if(!bottleId) {
    throw new Error('No bottleId provided')
  }
  const sampleFetchState = useApi<SampleDTO | undefined>(`v1/samples/${bottleId}`)
  const sample = sampleFetchState.data
  const bottleFetchState = useApi<BottleDTO | undefined>(`v1/bottles/${bottleId}`)
  const bottle = bottleFetchState.data

  const assetsDb = useContext(assetsContext)

  const assetLookup = useAsync(async () => {
    if(!sample || !sample.assetId) return Promise.resolve()
    return assetsDb.get(sample.assetId.toString())
  }, [sample])

  const asset = assetLookup.value

  const memberAsset = (
    <LinkageMember
      icon={<FaMapMarkerAlt />}
      hasValue
    >
      {(sampleFetchState.isLoading || assetLookup.loading || !asset) ? (
        <Spinner size={22} />
      ) : (
        <AssetCardHorizontal
          asset={asset}
        />
      )}
    </LinkageMember>
  )
  const memberBottle = (
    <LinkageMember
      icon={<FaPrescriptionBottle />}
      hasValue
    >
      {bottleFetchState.isLoading || !bottle ? (
        <Spinner size={22} />
      ) : (
        <BottleCard identifier={bottleId} />
      )}
    </LinkageMember>
  )

  const dateSampled: Date | undefined = useMemo(() => {
    if(sample && sample.sampledAt) {
      return new Date(sample.sampledAt)
    }
  }, [sample])

  return (
    <div>
      <AppHeader
        title="Sample"
        actionRight={<Button component={Link} to="/sample">Clear</Button>}
        fixed
      />
      <Linkage
        asset={memberAsset}
        bottle={memberBottle}
        isLinked
        isLoading={sampleFetchState.isLoading || bottleFetchState.isLoading}
      >
        {dateSampled ? (
          <small>Sampled {dateSampled.toLocaleString()}</small>
        ) : null}
      </Linkage>
      <FetchStateWrapper fetchState={sampleFetchState} pad spinnerProps={{ size: 22 }}>
        <SampleReadOnlyBody sample={sample!} />
      </FetchStateWrapper>
    </div>
  )
}

export default SampleReadOnly
